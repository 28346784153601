import React from 'react';
import PropTypes from 'prop-types';
import { Message } from 'semantic-ui-react';

const defaultProps = {
  header: 'Keine Inhalte vorhanden',
  content: 'Dieser Baupunkt enthält noch keine Medien.',
};

const propTypes = {
  header: PropTypes.string,
  content: PropTypes.string,
};

const EmptyState = ({ header, content }) => (
  <Message
    icon="info circle"
    header={header}
    content={content}
  />
);

EmptyState.propTypes = propTypes;
EmptyState.defaultProps = defaultProps;

export default EmptyState;
