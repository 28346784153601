import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Header,
  Button,
  Modal,
  Segment,
  Grid,
} from 'semantic-ui-react';
import _ from 'lodash';
import * as USER_ACTIONS from 'actions/user';

class PremiumModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
  }

  componentDidMount() {
    const { modalMustOpen, modalOpened } = this.props;

    if (modalMustOpen && !modalOpened) {
      this.setState({ modalOpen: true });
    }
  }

  handleClose() {
    this.setState({ modalOpen: false }, () => {
      localStorage.setItem('warn_modal_opened', 'true');
    });
  }

  handleButtonClick() {
    const { history } = this.props;

    this.handleClose();
    history.push('/app/premium');
  }

  render() {
    const { remainingDays, freeTrialExpired, premiumExpired } = this.props;
    const { modalOpen } = this.state;
    const node = document.getElementById('modals');

    return (
      <Modal onClose={this.handleClose} mountNode={node} size="tiny" open={modalOpen} closeIcon>
        <Modal.Content>
          <Modal.Description style={{ textAlign: 'center' }}>
            <Grid>
              {freeTrialExpired && (
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={16} textAlign="center">
                      <Header as="h2">Deine kostenlose Premium-Mitgliedschaft ist beendet!</Header>
                      <p>Um weiterhin die Premium-Funktionen nutzen zu können, kannst du jetzt in ein Premium-Jahresabonnement wechseln.</p>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row style={{ marginTop: '40px' }}>
                    <Grid.Column width={16} textAlign="center">
                      <Segment inverted color="red" style={{ display: 'inline-table', fontSize: '24pt' }} compact>beendet</Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}

              {!freeTrialExpired && !premiumExpired && (
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={16} textAlign="center">
                      <Header as="h2">Deine kostenlose Premium-Mitgliedschaft endet ab.</Header>
                      <p>Um weiterhin die Premium-Funktionen nutzen zu können, musst du deinen Premium-Account bezahlen.</p>
                    </Grid.Column>
                  </Grid.Row>

                  <Grid.Row style={{ marginTop: '40px' }}>
                    <Grid.Column width={16} textAlign="center">
                      <Segment color='yellow' style={{ display: 'inline-table', fontSize: '24pt' }} compact>Noch {remainingDays} Tag(e)</Segment>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}

              {premiumExpired && (
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={16} textAlign="center">
                      <Header as="h2">Deine Premium-Mitgliedschaft ist abgelaufen</Header>
                      <p>Um weiterhin die Premium-Funktionen nutzen zu können, musst du deinen Premium-Account erneut bezahlen.</p>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              )}

              <Grid.Row style={{ margin: '40px 0' }}>
                <Grid.Column width={16} textAlign="center">
                  <Button size="huge" color="blue" content={freeTrialExpired ? "Jetzt wechseln" : "Jetzt bezahlen"} onClick={this.handleButtonClick} />
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions style={{ textAlign: 'center' }}>
          <p>Hast du weitere Fragen? <br />Unser <a href="mailto:feedback@localexpert24.de">Support</a> hilft dir gerne!</p>
        </Modal.Actions>
      </Modal>
    );
  }
}

PremiumModal.defaultProps = {};

PremiumModal.propTypes = {
  // closeInvitesModal: PropTypes.func.isRequired,
  // sendInvites: PropTypes.func.isRequired,
  // list: PropTypes.func.isRequired,
  // modalIsOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  const planValidDate = _.get(state, 'session.user.plan_valid_until');
  const user = _.get(state, 'session.user');
  let response = {
    remainingDays: 0,
    modalOpened: false,
    modalMustOpen: false,
    freeTrialExpired: false,
    premiumExpired: false,
  };

  if (planValidDate) {
    const momentValidDate = moment(planValidDate).endOf('day');
    const today = moment().endOf('day');
    const remainingDays = momentValidDate.diff(today, 'days');
    const modalOpened = localStorage.getItem('warn_modal_opened') || 'false';
    const freeTrialExpired = user.plan_type === 'free_trial_individual' && remainingDays < 0;
    const premiumExpired = user.plan_type !== 'free_trial_individual' && remainingDays < 0;
    const modalMustOpen = [1, 7, 14].indexOf(remainingDays) !== -1 || freeTrialExpired || premiumExpired;

    response = {
      remainingDays,
      modalOpened: modalOpened === 'true',
      modalMustOpen: modalMustOpen,
      freeTrialExpired,
      premiumExpired,
    };
  }

  return {
    ...response,
    user,
  };
};

const mapDispatchToProps = {
  closeInvitesModal: USER_ACTIONS.closeInvitesModal,
  sendInvites: USER_ACTIONS.sendInvites,
  list: USER_ACTIONS.list,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PremiumModal));
