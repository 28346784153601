import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import {
  Grid,
  Card,
  Header,
  Button,
  Segment,
} from 'semantic-ui-react';
import AuthHOC from 'containers/AuthHOC';
import * as STATEAPI from 'utils/stateapi';

class CreateOrganization extends Component {
  componentDidMount() {
    const { changeBlocked } = this.props;
    changeBlocked(true);
  }

  render() {
    const { history, user, initialValues, remainingDays } = this.props;
    const paidPlan = _.get(user, 'plan_type') === 'orga_paid_plan';
    const planIsValid = !_.isEmpty(_.get(user, 'plan_valid_until')) ? moment(user.plan_valid_until).endOf('day').diff(moment().endOf('day'), 'days') >= 0 : false;

    return (
      <Grid centered style={{ zIndex: 2 }}>
        <Grid.Column computer={14} tablet={14} mobile={14} style={{ zIndex: 2 }}>
          <Card fluid>
            <Card.Content style={{ textAlign: 'center' }}>
              <Grid.Row style={{ marginTop: '20px', marginBottom: '40px' }}>
                <img width="40%" alt="Placeholder" src="/images/premium_orga_header.png" />
              </Grid.Row>
              <Grid.Row style={{ marginBottom: '60px' }}>
                <Header as="h1" textAlign="center">Du bist noch kein Mitglied in einer Organisation</Header>
              </Grid.Row>
              <Grid.Row style={{ marginBottom: '60px' }}>
                <Grid centered>
                  <Grid.Column computer={7} mobile={15} textAlign="center">
                    {paidPlan && planIsValid ? (
                        <Button primary size="huge" onClick={() => history.push('/app/organizations/create/data')}>Jetzt eine Organisation anlegen</Button>
                      ) : (
                        <div>
                          <Header as="h3" textAlign="center">Um eine Organisation anlegen zu können, musst du eine bezahlte Premium-Mitgliedschaft für Organisationen haben.</Header>
                          <Button size="huge" color="yellow" onClick={() => history.push('/app/organizations/payment')}>
                            Weiter zur Tarifauswahl
                          </Button>
                        </div>
                      )
                    }
                  </Grid.Column>
                </Grid>
              </Grid.Row>

              <Grid centered divided="vertically" style={{ marginBottom: '40px' }}>
                <Grid.Column computer={7} mobile={15} textAlign="left">
                  <Header as="h3">localexpert24 für Unternehmen, Kommunen und Organisationen</Header>
                  <p style={{ fontSize: '1.2em', lineHeight: '1.5em' }}>Auch Organisationen können Mitglied bei localexpert24 werden, solange sie fest mit mindestens einer Person, die bei der jeweiligen Organisation tätig ist, verknüpft sind. Ein Organisationsprofil zu erstellen hat unterschiedliche Vorteile, die wir dir hier erläutern möchten.</p>
                </Grid.Column>
                <Grid.Column computer={3} mobile={8}>
                  <img width="100%" alt="localexpert24 für Unternehmen, Kommunen und Organisationen" src="/images/icon_group.svg" />
                </Grid.Column>
              </Grid>

              <Grid centered divided="vertically" style={{ marginBottom: '40px' }}>
                <Grid.Column computer={3} mobile={8}>
                  <img width="100%" alt="Sichtbar werden" src="/images/icon_discussions.svg" />
                </Grid.Column>
                <Grid.Column computer={7} mobile={15} textAlign="left">
                  <Header as="h3">Sichtbar werden</Header>
                  <ul style={{ fontSize: '1.2em', lineHeight: '1.5em' }}>
                    <li>Das eigene Organisations-Profil macht eure Expertise im Netzwerk besser auffindbar.</li>
                    <li>Lokale Experten können der Organisation zugeordnet werden, sodass Arbeitgeber und Team sichtbar werden.</li>
                    <li>Alle Baupunkte der Organisation können auf einen Blick dargestellt werden. Das gesamte Wissen des Teams wird abgebildet.</li>
                  </ul>
                </Grid.Column>
              </Grid>

              <Grid centered divided="vertically" style={{ marginBottom: '40px' }}>
                <Grid.Column computer={7} mobile={15} textAlign="left">
                  <Header as="h3">Lokale Experten einbinden</Header>
                  <ul style={{ fontSize: '1.2em', lineHeight: '1.5em' }}>
                    <li>Lokale Experten können mit ihrem Arbeitgeber verknüpft werden.</li>
                    <li>Die Premium-Mitgliedschaft der Organisation geht dann automatisch auf die lokalen Experten über und auch diese genießen alle Premium-Vorteile ohne zusätzliche Mitgliedsgebühren.</li>
                  </ul>
                </Grid.Column>
                <Grid.Column computer={3} mobile={8}>
                  <img width="100%" alt="Lokale Experten einbinden" src="/images/icon_user_add.svg" />
                </Grid.Column>
              </Grid>

              <Grid centered divided="vertically">
                <Grid.Column computer={3} mobile={8}>
                  <img width="100%" alt="Neue Dienstleistungen und Features nutzen" src="/images/icon_star.svg" />
                </Grid.Column>
                <Grid.Column computer={7} mobile={15} textAlign="left">
                  <Header as="h3">Neue Dienstleistungen und Features nutzen</Header>
                  <ul style={{ fontSize: '1.2em', lineHeight: '1.5em' }}>
                    <li>Als Premium-Mitglied erhalten Organisationen erweiterten Einblick in neue Karten-Ebenen.</li>
                    <li>Zusatz-Dienstleistungen werden freigeschaltet.</li>
                    <li>Baupunkte können archiviert werden.</li>
                  </ul>
                </Grid.Column>
              </Grid>

              <Grid.Row style={{ marginTop: '80px' }}>
                <img width="60%" alt="Team" src="/images/team.jpg" />
              </Grid.Row>

            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid>
    );
  }
}

CreateOrganization.propTypes = {
  changeBlocked: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired, // eslint-disable-line
};

const mapStateToProps = (state) => {
  const user = STATEAPI.getCurrentUser(state);
  const fields = [
    'street',
    'postalcode',
    'city',
    'country',
  ];

  const planValidDate = _.get(user, 'plan_valid_until');
  let remainingDays = 0;

  if (planValidDate) {
    const momentValidDate = moment(planValidDate).endOf('day');
    const today = moment().endOf('day');
    remainingDays = momentValidDate.diff(today, 'days');
  }

  return {
    user,
    initialValues: _.pick(user, fields),
    remainingDays,
  };
};

const mapDispatchToProps = {
};

const connectedOrga = connect(mapStateToProps, mapDispatchToProps)(CreateOrganization);

export default withRouter(AuthHOC(connectedOrga));
