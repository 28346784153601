import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  Grid,
  Header,
  Form,
  Button,
  Divider,
} from 'semantic-ui-react';
import * as Alert from 'utils/alert';
import AuthHOC from 'containers/AuthHOC';
import * as C from 'actions/constants';
import * as USER_ACTIONS from 'actions/user';

class ResetPasswordPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      newPassword: '',
      newPasswordConfirm: '',
    };

    this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
    this.handleNewPasswordConfirmChange = this.handleNewPasswordConfirmChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { history, match: { params } } = this.props;

    const uid = _.get(params, 'uid');
    const token = _.get(params, 'token');

    if (_.isEmpty(uid) || _.isEmpty(token)) {
      Alert.error('Invalid credentials.');
      history.push('/');
    }
  }

  handleNewPasswordChange(e) {
    this.setState({ newPassword: e.target.value });
  }

  handleNewPasswordConfirmChange(e) {
    this.setState({ newPasswordConfirm: e.target.value });
  }

  handleSubmit(e) {
    e.preventDefault();

    const { history, match: { params }, resetPasswordConfirm } = this.props;
    const { newPassword, newPasswordConfirm } = this.state;

    if (_.isEmpty(newPassword) || _.isEmpty(newPasswordConfirm) || !_.isEqual(newPassword, newPasswordConfirm)) {
      Alert.error('Das neue Passwort konnte nicht geändert werden. Bitte prüfe deine Eingabe und versichere dich, dass du 2x das gleiche Passwort eingegeben hast.');
      return;
    }

    if (newPassword.length < 8) {
      Alert.error('Das Passwort muss aus mindestens 8 Zeichen bestehen.');
      return;
    }

    const uid = _.get(params, 'uid');
    const token = _.get(params, 'token');

    resetPasswordConfirm({
      uid,
      token,
      newPassword,
    })
      .then(({ type }) => {
        if (type === C.RESET_USER_PASSWORD_CONFIRM_SUCCESS) {
          Alert.success('Das Passwort wurde erfolgreich geändert und kann nun für den Login verwendet werden.');
          history.push('/');
        }

        if (type === C.RESET_USER_PASSWORD_CONFIRM_ERROR) {
          Alert.error('Das Passwort konnte nicht geändert werden.');
        }
      });
  }

  render() {
    const { newPassword, newPasswordConfirm } = this.state;

    return (
      <div>
        <Grid.Row style={{ marginTop: '20px' }}>
          <Header as="h3" textAlign="center">Willkommen bei localexpert24</Header>
        </Grid.Row>

        <Grid centered>
          <Grid.Column computer={12} tablet={12} mobile={16}>
            <Grid.Row style={{ marginTop: '50px' }}>
              <p>Du hast dein Passwort vergessen? Kein Problem! Du kannst hier ein neues Passwort vergeben.</p>
              <Form onSubmit={this.handleSubmit}>
                <Form.Field>
                  <input type="password" value={newPassword} onChange={this.handleNewPasswordChange} placeholder="Neues Passwort" />
                </Form.Field>
                <Form.Field>
                  <input type="password" value={newPasswordConfirm} onChange={this.handleNewPasswordConfirmChange} placeholder="Neues Passwort bestätigen" />
                </Form.Field>

                <Grid centered columns={1} style={{ marginTop: '20px' }}>
                  <Grid.Column>
                    <Button fluid primary size="large">Passwort ändern</Button>
                  </Grid.Column>
                </Grid>
              </Form>
            </Grid.Row>
          </Grid.Column>
        </Grid>

        <Divider style={{ marginTop: '80px' }} />

        <Grid.Row textAlign="center">
          <p>Hast du weitere Probleme mit deinem Login?</p>
          <a href="mailto:feedback@localexpert24.de">Unser Support hilft dir gerne weiter!</a>
        </Grid.Row>
      </div>
    );
  }
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  resetPasswordConfirm: USER_ACTIONS.resetPasswordConfirm,
};

const ConnectedResetPasswordPage = connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);

export default withRouter(AuthHOC(ConnectedResetPasswordPage));
