import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Select,
  Form,
} from 'semantic-ui-react';
import { get as listOrganizations } from 'api/lib/organization';

class OrganizationSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
      loading: false,
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.search = this.search.bind(this);
  }

  componentDidMount() {
    const { input } = this.props;

    if (_.isString(input.value)) {
      this.setState({ options: [{ key: input.value, value: input.value, text: input.value }] });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { input } = this.props;

    if (!_.isEmpty(nextProps.input.value) && input.value !== nextProps.input.value) {
      const { options } = this.state;
      const getCurrentOption = options.find(option => option.value === nextProps.input.value);

      if (_.isEmpty(getCurrentOption)) {
        this.setState({
          options: [{
            key: nextProps.input.value,
            value: nextProps.input.value,
            text: nextProps.input.value,
          }],
        });
      }
    }
  }

  search() {
    const { options } = this.state;

    return options;
  }

  handleSearch(event, data) {
    const { searchQuery } = data;

    if (searchQuery.length < 3) {
      return;
    }

    this.setState({ loading: true }, () => {
      listOrganizations('', { name: searchQuery })
        .then(({ body: { results } }) => {
          if (_.isEmpty(results)) {
            this.setState({
              options: [],
              loading: false,
            });

            return [];
          }

          const options = results
            .map(r => ({
              key: r.id,
              value: r.id,
              text: r.name,
            }));

          return this.setState({
            options: _.uniqBy(options, 'key'),
            loading: false,
          });
        });
    });
  }

  render() {
    const {
      placeholder,
      style,
      input,
      meta: {
        touched,
        error,
      },
    } = this.props;
    const { options, loading } = this.state;

    return (
      <Form.Field style={style}>
        <Select
          {...input}
          style={style}
          fluid
          value={input.value}
          search={this.search}
          loading={loading}
          onSearchChange={_.debounce(this.handleSearch, 500)}
          minCharacters={2}
          name={input.name}
          options={options}
          placeholder={placeholder}
          onBlur={() => {}}
          onChange={(props, data) => {
            const currentOption = data.options.find(option => option.value === data.value);
            input.onChange(currentOption.value);
          }}
        />
        {touched && error && <span>{error}</span>}
      </Form.Field>
    );
  }
}

OrganizationSearch.defaultProps = {
  style: {},
};

OrganizationSearch.propTypes = {
  input: PropTypes.object, // eslint-disable-line
  style: PropTypes.object, // eslint-disable-line
  meta: PropTypes.object, // eslint-disable-line
  placeholder: PropTypes.string.isRequired,
  clickCallback: PropTypes.func.isRequired,
};

export default OrganizationSearch;
