import API from 'api';
import * as C from './constants';
import receiveNetworkException from './network';
import error from './error';
import * as LOCATION_SERIALIZERS from './serializers/location';

const TYPES = (type) => {
  switch (type) {
    case 'experten': {
      return C.LOAD_SIDEBAR_EXPERTEN_SUCCESS;
    }
    case 'organisationen': {
      return C.LOAD_SIDEBAR_ORGANIZATIONS_SUCCESS;
    }
    default: {
      return C.LOAD_SIDEBAR_LOCATIONS_SUCCESS;
    }
  }
};

const LOADING_TYPES = (type) => {
  switch (type) {
    case 'experten': {
      return C.LOAD_SIDEBAR_EXPERTEN;
    }
    case 'organisationen': {
      return C.LOAD_SIDEBAR_ORGANIZATION;
    }
    default: {
      return C.LOAD_SIDEBAR_LOCATION;
    }
  }
};

const LOAD_MORE_TYPES = (type) => {
  switch (type) {
    case 'experten': {
      return C.LOAD_MORE_SIDEBAR_EXPERTEN_SUCCESS;
    }
    case 'organisationen': {
      return C.LOAD_MORE_SIDEBAR_ORGANIZATIONS_SUCCESS;
    }
    default: {
      return C.LOAD_MORE_SIDEBAR_LOCATIONS_SUCCESS;
    }
  }
};

export const load = query => (dispatch) => {
  const { type } = query;

  dispatch({ type: LOADING_TYPES(type) });

  return API.location.mapLocationDetail('', query)
    .then(({
      body: data,
    }) => {
      const actionType = TYPES(type);

      if (actionType === C.LOAD_SIDEBAR_EXPERTEN_SUCCESS) {
        dispatch({ type: actionType, data });
      } else {
        dispatch({ type: actionType, ...data });
      }
    })
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.LOAD_SIDEBAR_ERROR, {
        message: 'Failed to get location data',
        description: 'Please contact server admin.',
        data: err.data,
      }));
    });
};

export const loadMore = (url, type) => (dispatch) => {
  dispatch({ type: LOADING_TYPES(type) });

  return API.notification.loadMore(url)
    .then(({
      body: data,
    }) => dispatch({ type: LOAD_MORE_TYPES(type), ...data }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.LOAD_SIDEBAR_ERROR, {
        message: 'Failed to get sidebar list',
        description: 'Please contact server admin.',
        data: err.data,
      }));
    });
};
