import * as C from 'actions/constants';
import _ from 'lodash';

function updateLocalStorage(state) {
  localStorage.setItem('mapFilters', JSON.stringify(state));
}

function getFiltersFromStorage() {
  const filters = localStorage.getItem('mapFilters');

  return _.isEmpty(filters) ? {} : JSON.parse(filters);
}

export const blankState = {
  hoverId: '',
  organizationHoverId: '',
  filters: getFiltersFromStorage(),
  mapCenter: {},
  layer: '',
  locations: [],
};

const MapReducer = (state = blankState, action) => {
  switch (action.type) {
    case C.EXPERT_MOUSE_ENTER: {
      const { id } = action;
      return { ...state, hoverId: id };
    }
    case C.EXPERT_MOUSE_LEAVE: {
      return { ...state, hoverId: '' };
    }
    case C.ORGANIZATION_MOUSE_ENTER: {
      const { id } = action;
      return { ...state, organizationHoverId: id };
    }
    case C.ORGANIZATION_MOUSE_LEAVE: {
      return { ...state, organizationHoverId: '' };
    }
    case C.FILTER_LOCATION_USER: {
      const { id } = action;
      const filters = _.omit({
        ...state.filters,
        user: id,
      }, ['bookmarked', 'organization']);

      updateLocalStorage(filters);

      return {
        ...state,
        filters,
      };
    }
    case C.FILTER_LOCATION_ORGANIZATION: {
      const { id } = action;
      const filters = _.omit({
        ...state.filters,
        organization: id,
      }, ['bookmarked', 'user']);

      updateLocalStorage(filters);

      return {
        ...state,
        filters,
      };
    }
    case C.FILTER_LOCATION_BOOKMARKED: {
      const filters = _.omit({
        ...state.filters,
        bookmarked: true,
      }, ['organization', 'user']);

      updateLocalStorage(filters);

      return {
        ...state,
        filters,
      };
    }
    case C.FILTER_LOCATION_ALL: {
      const filters = {};

      updateLocalStorage(filters);

      return {
        ...state,
        filters,
      };
    }
    case C.SET_TAG_FILTER: {
      const { values } = action;
      const filters = {
        ...state.filters,
        tags: values,
      };

      updateLocalStorage(filters);

      return {
        ...state,
        filters,
      };
    }
    case C.SET_MAP_CENTER: {
      const { mapCenter } = action;
      return { ...state, mapCenter };
    }
    case C.ENABLE_LAYER: {
      const { id } = action;
      return { ...state, layer: id };
    }
    case C.DISABLE_LAYER: {
      return { ...state, layer: '' };
    }
    case C.UPDATE_BOUNDS: {
      const { bounds } = action;
      return { ...state, bounds };
    }
    case C.FILTER_LOCATIONS_BY_BOUNDS: {
      const { locations } = action;
      return { ...state, locations };
    }
    default:
      return state;
  }
};

export default MapReducer;
