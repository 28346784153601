import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  Grid,
  Icon,
  Form,
  Button,
  Image,
  Label,
  Input,
} from 'semantic-ui-react';
import * as C from 'actions/constants';
import * as ORGANIZATION_ACTIONS from 'actions/organization';
import { isEmail } from 'utils/validate';
import { UserPicture } from 'modules/styled/map';
import * as Alert from 'utils/alert';

class Invites extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
    };

    this.handleAdd = this.handleAdd.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  componentDidMount() {
    const { loadInvites, organizationId } = this.props;

    loadInvites({ organization_id: organizationId });
  }

  handleRemove(email) {
    const { organizationId, deleteInvite } = this.props;

    deleteInvite({
      organization: organizationId,
      email,
    });
  }

  handleAdd(event) {
    event.preventDefault();
    const { email } = this.state;
    const { invite, organizationId, userEmail, invites } = this.props;
    const defaultMessage = 'E-Mail konnte nicht hinzugefügt werden. Entweder gibt es bereits eine aktive Einladung oder die E-Mail ist bereits Mitglied bei einer Organisation';

    if (!isEmail(email) || email.trim() === userEmail) {
      return;
    }

    invite({
      organization_id: organizationId,
      email,
    })
      .then(({ type }) => {
        if (type === C.ORGANIZATION_INVITE_ERROR) {
          Alert.info(defaultMessage);
        } else {
          Alert.success('Die Einladung wurde erfolgreich verschickt.')
        }
      })
      .catch(({ status }) => {
        if (status === 406) {
          Alert.error('Dein aktueller Tarif erlaubt keine weiteren Mitglieder.');
        } else {
          Alert.error('Dieser Benutzer ist bereits Mitglied dieser Organisation.');
        }
      })
      .finally(() => this.setState({
        email: '',
      }));
  }

  handleInputChange(event) {
    const email = event.target.value;

    this.setState({ email });
  }

  render() {
    const { email } = this.state;
    const { visible } = this.props;

    return visible ? (
      <div>
        <Grid centered>
          <Grid.Column mobile={15} tablet={10} computer={10} widescreen={8}>
            <Grid.Row>
              <Grid centered>
                <Grid.Column
                  centered
                  mobile={16}
                  tablet={11}
                  computer={12}
                  widescreen={10}
                >
                  <Form.Field>
                    <Input
                      value={email}
                      placeholder="E-Mail-Adresse"
                      onChange={this.handleInputChange}
                      action={(
                        <Button
                          onClick={this.handleAdd}
                          primary
                        >
                          Hinzufügen
                        </Button>
                      )}
                    />
                  </Form.Field>
                </Grid.Column>
              </Grid>
            </Grid.Row>
            <p><i aria-hidden="true" className="info circle icon"></i>Du kannst entweder bereits registrierte Lokale Experten oder neue Mitglieder zu deiner Organisation einladen. Der Empfänger erhält eine E-Mail und kann so den Beitritt akzeptieren oder ablehnen.</p>
          </Grid.Column>
        </Grid>
      </div>
    ) : null;
  }
}

// {invites.map((invite) => {
//   return _.isEmpty(invite.user) ? (
//     <Label key={invite.email} circular as="div" style={{ marginRight: '10px', marginBottom: '10px' }}>
//       <Icon circular name="user circle" size="large" />
//       {invite.email}
//       <Icon
//         name="delete"
//         onClick={() => this.handleRemove(invite.email)}
//       />
//     </Label>
//   ) : (
//     <Label key={invite.email} circular as="div" color="blue" size="large" style={{ marginRight: '10px', marginBottom: '10px' }}>
//       <UserPicture style={{ marginRight: '5px' }} src={!_.isEmpty(invite.user.avatar_thumbnail_url) ? invite.user.avatar_thumbnail_url : 'https://react.semantic-ui.com/images/wireframe/square-image.png'} />
//       {invite.email}
//       <Icon
//         name="delete"
//         onClick={() => this.handleRemove(invite.email)}
//       />
//     </Label>
//   );
// })}

function mapStateToProps(state) {
  return {
    userEmail: _.get(state, 'session.user.email'),
    loadingInvite: _.get(state, 'organization.loadingInvite', false),
    invites: _.get(state, 'organization.invites', []),
  };
}

const mapDispatchToProps = {
  loadInvites: ORGANIZATION_ACTIONS.invites,
  invite: ORGANIZATION_ACTIONS.invite,
  deleteInvite: ORGANIZATION_ACTIONS.deleteInvite,
};

export default connect(mapStateToProps, mapDispatchToProps)(Invites);
