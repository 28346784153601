import _ from 'lodash';

const error = (type, data = {}) => {
  if (_.get(data, 'data.detail') === 'Authentication credentials were not provided.') {
    localStorage.clear();
    window.location.href = '/';
  }

  return {
    type,
    data,
  };
};

export default error;
