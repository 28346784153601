import _ from 'lodash';
import * as C from 'actions/constants';

export const blankState = {
  data: {},
  list: {},
  mapLocations: [],
  loading: false,
  bookmarked: false,
  loadingBookmark: false,
  voted: false,
  loadingVote: false,
};

const LocationReducer = (state = blankState, action) => {
  switch (action.type) {
    case C.GET_LOCATION:
    case C.GET_LOCATIONS_LIST:
    case C.GET_MAP_LOCATIONS_LIST:
    case C.CREATE_LOCATION: {
      return { ...state, loading: true };
    }
    case C.GET_LOCATION_SUCCESS:
    case C.CREATE_LOCATION_SUCCESS: {
      const { data } = action;
      return { ...state, loading: false, data };
    }
    case C.PATCH_LOCATION_SUCCESS: {
      return { ...state, loading: false };
    }
    case C.CHECK_BOOKMARK_LOCATION: {
      return { ...state, loadingBookmark: true, bookmarked: false };
    }
    case C.CHECK_BOOKMARK_LOCATION_SUCCESS: {
      const { data } = action;
      return { ...state, loadingBookmark: false, bookmarked: !_.isEmpty(data) };
    }
    case C.CHECK_LOCATION_VOTE: {
      return { ...state, loadingVote: true, voted: false };
    }
    case C.CHECK_LOCATION_VOTE_SUCCESS: {
      const { data } = action;
      return { ...state, loadingVote: false, voted: !_.isEmpty(data) };
    }
    case C.GET_LOCATION_VOTE_COUNT_SUCCESS: {
      const { count } = action;
      return {
        ...state,
        data: Object.assign({}, state.data, { votes_count: count }),
      };
    }
    case C.DELETE_LOCATION_BOOKMARK_ERROR:
    case C.BOOKMARK_LOCATION_ERROR: {
      return { ...state, loadingBookmark: false };
    }
    case C.GET_LOCATIONS_LIST_SUCCESS: {
      const { list } = action;
      console.log("LIST", list)
      const newList = _.isEmpty(list.prev) ? list : Object.assign({}, list, {
        data: [...state.list.data, ...list.data]
      })

      return { ...state, loading: false, list: newList };
    }
    case C.GET_MAP_LOCATIONS_LIST_SUCCESS: {
      const { list } = action;

      return { ...state, loading: false, mapLocations: list };
    }
    case C.UPLOAD_LOCATION_DOCUMENT_SUCCESS: {
      const currentData = Object.assign({}, state.data);
      const { data } = action;
      const documents = [..._.get(currentData, 'documents', []), data];

      return { ...state, loading: false, data: Object.assign({}, currentData, { documents }) };
    }
    case C.DELETE_LOCATION_DOCUMENT_SUCCESS: {
      const currentData = Object.assign({}, state.data);
      const { id } = action;
      const documents = [..._.get(currentData, 'documents', [])].filter(document => document.id !== id);

      return { ...state, loading: false, data: Object.assign({}, currentData, { documents }) };
    }
    case C.GET_LOCATION_ERROR:
    case C.GET_LOCATIONS_LIST_ERROR:
    case C.GET_MAP_LOCATIONS_LIST_ERROR:
    case C.CREATE_LOCATION_ERROR: {
      return { ...state, loading: false };
    }
    case C.UPLOAD_LOCATION_FILE_IFC_SUCCESS: {
      const currentData = Object.assign({}, state.data);
      const { data } = action;
      const fileIFC = [..._.get(currentData, 'fileIFC', []), data];

      return { ...state, loading: false, data: Object.assign({}, currentData, { fileIFC }) };
    }
    case C.DELETE_LOCATION_FILE_IFC_SUCCESS: {
      const currentData = Object.assign({}, state.data);
      const { id } = action;
      const fileIFC = [..._.get(currentData, 'fileIFC', [])].filter(fileIFC => fileIFC.id !== id);

      return { ...state, loading: false, data: Object.assign({}, currentData, { fileIFC }) };
    }
    default:
      return state;
  }
};

export default LocationReducer;
