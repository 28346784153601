import * as C from 'actions/constants';

export const blankState = {
  open: false,
};

const TourReducer = (state = blankState, action) => {
  switch (action.type) {
    case C.OPEN_TOUR: {
      return { ...state, open: true };
    }
    case C.CLOSE_TOUR: {
      return { ...state, open: false };
    }
    default:
      return state;
  }
};

export default TourReducer;
