import axios from 'axios';
import { buildURL } from './helpers';

const handleSuccess = ({ data, status }) => ({
  statusCode: status,
  body: data,
});

const handleFailure = (e) => {
  console.log('e', e); // eslint-disable-line

  // if (e.response.status === 401) {
  //   // Since we don't have 'props.history' here,
  //   // we need to change 'window.location' manually
  //   localStorage.clear();
  //   window.location = '/';
  // } else {
  //   throw e.response;
  // }
};

const buildHeaders = () => {
  const headers = [];

  // if (authPresent()) {
  //   headers.push({ Authorization: `Bearer ${authToken()}` });
  // }

  return Object.assign({}, ...headers);
};

const dispatch = (
  method,
  {
    endpoint,
    id = '',
    action = '',
    params = {},
    data = {},
    headers = {},
  },
) => axios({
  method,
  url: buildURL(endpoint, id, action, params),
  data,
  headers: {
    ...buildHeaders(),
    ...headers,
  },
})
  .then(handleSuccess)
  .catch(handleFailure);


const REST = {
  get: params => dispatch('get', params),
};

export default REST;
