import styled from 'styled-components';

export const LocationCoverPicture = styled.img`
  width: 90px;
  height: 80px;
  display: block;
  object-fit: cover;
  max-width: none !important;
  object-position: center center;
`;

export const UserPicture = styled.img`
  width: 56px !important;
  height: 56px !important;
  border-radius: 50%;
  display: block;
  object-fit: cover;
  object-position: center center;
  ${({ loading }) => loading && 'filter: grayscale();'}
`;
