import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import esriLoader from 'esri-loader';
import * as MAP_UTILS from 'utils/map';
import * as LOCATION_ACTIONS from 'actions/location';

const DEBOUNCE_TIMEOUT = 300;

class MiniMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      bounds: {},
    };

    this.webmap = undefined;
    this.mapView = undefined;
    this.queryLocationContent = this.queryLocationContent.bind(this);
    this.renderMap = this.renderMap.bind(this);
    this.updateBounds = _.debounce(this.updateBounds.bind(this), DEBOUNCE_TIMEOUT);
    this.updateMap = _.debounce(this.updateMap.bind(this), DEBOUNCE_TIMEOUT, {
      leading: false,
      trailing: true,
    });

    this.renderMap();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      lat,
      lng,
      locations: currentLocations,
      linkedLocations: currentLinkedLocations,
    } = this.props;
    const { bounds: currentBounds } = this.state
    const { bounds } = nextState;
    const { locations, linkedLocations } = nextProps;
    const sameLocations = !_.isEmpty(locations) && JSON.stringify(locations) === JSON.stringify(currentLocations);
    const sameLinkedLocations = JSON.stringify(currentLinkedLocations) === JSON.stringify(linkedLocations);
    const sameBounds = !_.isEmpty(bounds) && JSON.stringify(currentBounds) === JSON.stringify(bounds);

    return nextProps.lat !== lat || nextProps.lng !== lng || !sameLocations || !sameLinkedLocations || !sameBounds;
  }

  componentDidUpdate() {
    this.updateMap();
  }

  updateBounds() {
    if (!this.mapView) {
      return;
    }

    const { mapLocationsQuery } = this.props;

    esriLoader.loadModules([
      'esri/geometry/support/webMercatorUtils',
    ])
      .then(([webMercatorUtils]) => {
        const { xmax, xmin, ymax, ymin } = this.mapView.extent;
        const [maxLong, maxLat] = webMercatorUtils.xyToLngLat(xmax, ymax);
        const [minLong, minLat] = webMercatorUtils.xyToLngLat(xmin, ymin);
        const bounds = {
          minLong,
          maxLong,
          minLat,
          maxLat,
        };

        if (JSON.stringify(bounds) !== JSON.stringify(this.state.bounds)) {
          this.setState({ bounds }, () => {
            mapLocationsQuery(bounds);
          });
        }
      });
  }

  queryLocationContent(target) {
    const { graphic: { attributes: { id } } } = target;
    const { mapLocationDetail } = this.props;

    return mapLocationDetail(id).then(({ data }) => MAP_UTILS.popupTemplate(data));
  }

  updateMap() {
    const { locations, currentId } = this.props;

    return new Promise((resolve) => {
      if (_.isEmpty(this.mapView)) {
        setTimeout(() => {
          this.updateMap();
        }, 1000);

        return resolve();
      }

      return esriLoader.loadModules([
        'esri/Graphic',
      ])
        .then(([
          Graphic,
        ]) => {
          this.graphicsLayer.removeAll();

          const addAction = {
            title: 'Hinzufügen',
            id: 'select',
          };

          const graphics = locations
            .filter(l => l.id !== currentId)
            .map((location) => {
              const pointGraphic = new Graphic({
                geometry: {
                  type: 'point', // autocasts as new Point()
                  longitude: location.lng,
                  latitude: location.lat,
                },
                symbol: MAP_UTILS.defaultMarker,
                attributes: location,
                popupTemplate: {
                  title: location.street,
                  content: this.queryLocationContent,
                  actions: [addAction],
                  overwriteActions: true,
                },
              });

              return pointGraphic;
            });

          this.graphicsLayer.addMany(graphics);

          return resolve();
        });
    });
  }

  renderMap() {
    const {
      lat,
      lng,
      locations,
      currentId,
      handleMapChange,
    } = this.props;

    if (!lat || !lng) {
      return;
    }

    esriLoader.loadModules([
      'esri/Graphic',
      'esri/layers/GraphicsLayer',
      'esri/views/MapView',
      'esri/WebMap',
      'esri/core/watchUtils',
    ])
      .then(([
        Graphic,
        GraphicsLayer,
        MapView,
        WebMap,
        watchUtils,
      ]) => {
        this.webmap = new WebMap({
          basemap: 'streets',
        });

        this.mapView = new MapView({ // eslint-disable-line
          map: this.webmap,
          container: 'miniMapViewDiv',
          zoom: 17,
          center: [lng, lat],
          ui: {
            components: ["attribution"],
          },
        });

        const addAction = {
          title: 'Hinzufügen',
          id: 'select',
        };

        const graphics = locations
          .filter(l => l.id !== currentId)
          .map((location) => {
            const pointGraphic = new Graphic({
              geometry: {
                type: 'point', // autocasts as new Point()
                longitude: location.lng,
                latitude: location.lat,
              },
              symbol: MAP_UTILS.defaultMarker,
              attributes: location,
              popupTemplate: {
                title: location.street,
                content: this.queryLocationContent,
                actions: [addAction],
                overwriteActions: true,
              },
            });

            return pointGraphic;
          });

        this.graphicsLayer = new GraphicsLayer({
          graphics,
        });

        this.webmap.add(this.graphicsLayer);

        this.mapView.popup.on('trigger-action', (event) => {
          if (event.action.id === 'select') {
            const { target: { content: { graphic: { attributes: location } } } } = event;
            handleMapChange(location.id);
          }
        });

        this.mapView.constraints = {
          minZoom: 17,
          maxZoom: 20,
        };

        this.mapView.on('drag', this.updateBounds);
        this.mapView.on('mouse-wheel', this.updateBounds);
        this.mapView.on('resize', this.updateBounds);


        watchUtils.whenOnce(this.mapView, 'ready')
          .then(() => {
            this.updateBounds();
          });
      })
      .catch((err) => {
        console.log('err', err); // eslint-disable-line
      });
  }

  render() {
    return (
      <div
        style={{
          margin: '20px 0',
          width: '100%',
          height: '440px',
          position: 'relative',
          zIndex: 1,
        }}
      >
        <div
          id="miniMapViewDiv"
          style={{
            width: '100%',
            height: '100%',
            zIndex: 0,
          }}
        />
      </div>
    );
  }
}

MiniMap.defaultProps = {
  currentId: undefined,
  locations: [],
  linkedLocations: [],
};

MiniMap.propTypes = {
  currentId: PropTypes.number,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  locations: PropTypes.array, // eslint-disable-line
  linkedLocations: PropTypes.array, // eslint-disable-line
  mapLocationDetail: PropTypes.func.isRequired,
};

export function mapStateToProps(state) {
  return {
    locations: state.location.mapLocations,
  };
}

const mapDispatchToProps = {
  mapLocationsQuery: LOCATION_ACTIONS.mapLocationsQuery,
  mapLocationDetail: LOCATION_ACTIONS.mapLocationDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(MiniMap);
