import _ from 'lodash';

function difference(object, base) {
  return _.transform(object, (result, value, key) => {
    if (!_.isEqual(value, base[key])) {
      result[key] = value; // eslint-disable-line
      // If want to diff deeper:
      // _.isObject(value) && _.isObject(base[key]) ? difference(value, base[key]) : value;
    }
  });
}
export default difference;
