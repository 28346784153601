import REST from '../rest';

const BASE_URL = '/api/v1/users/';

export const register = data => REST.post(
  '/api/v1/register/',
  data,
  {},
  { 'Content-Type': 'application/json' },
);

export const login = data => REST.post(
  'api/v1/login/',
  data,
  {},
  { 'Content-Type': 'application/json' },
);

export const get = (uuid, params) => REST.get(
  BASE_URL,
  uuid,
  params,
);

export const patch = (id, data, contentType) => REST.patch(
  BASE_URL,
  id,
  data,
  {},
  { 'Content-Type': contentType },
);

export const filterTags = (type, search) => REST.get(
  `/api/v1/tags/${type}/`,
  null,
  {
    search,
  },
);

export const resetPassword = data => REST.post(
  '/api/v1/password/reset/',
  data,
  {},
  { 'Content-Type': 'application/json' },
);

export const deleteUser = id => REST.delete(
  BASE_URL,
  id,
);

export const resetPasswordConfirm = data => REST.post(
  `/api/v1/password/confirm/${data.uid}-${data.token}/`,
  { new_password: data.newPassword },
  {},
  { 'Content-Type': 'application/json' },
);

export const resendConfirm = email => REST.post(
  '/api/v1/account/resend/',
  { email },
  {},
  { 'Content-Type': 'application/json' },
);

export const confirmAccount = data => REST.post(
  `/api/v1/account/confirm/${data.uid}-${data.token}/`,
  {},
  {},
  { 'Content-Type': 'application/json' },
);

export const unsubscribe = uid => REST.post(
  `/api/v1/unsubscribe/${uid}/`,
  {},
  {},
  { 'Content-Type': 'application/json' },
);

export const activateTrial = () => REST.post(
  '/api/v1/users/activate_trial/',
  {},
  {},
  { 'Content-Type': 'application/json' },
);

export const syncUser = () => REST.get(
  `${BASE_URL}sync/`,
  '',
  {},
);

export const createInvoice = () => REST.post(
  '/api/v1/users/create_invoice/',
  {},
  {},
  { 'Content-Type': 'application/json' },
);

export const createOrganizationInvoice = data => REST.post(
  '/api/v1/users/create_organization_invoice/',
  data,
  {},
  { 'Content-Type': 'application/json' },
);

export const resendInvoice = () => REST.post(
  '/api/v1/users/resend_invoice/',
  {},
  {},
  { 'Content-Type': 'application/json' },
);

export const loadMore = url => REST.get(url);

export const sendInvites = invites => REST.post(
  `${BASE_URL}invite/`,
  invites,
  {},
  { 'Content-Type': 'application/json' },
  );

export const cancelSubscription = () => REST.post(
  '/api/v1/users/cancel_subscription/',
  {},
  {},
  { 'Content-Type': 'application/json' },
);

export const registerSommerAktion = data => REST.post(
  '/api/v1/sommeraktion/',
  data,
  {},
  { 'Content-Type': 'application/json' },
);
