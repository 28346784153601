import _ from 'lodash';
import moment from 'moment';

export const get = data => ({
  ...data,
  date: moment(data.date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
});

export const patch = data => (_.has(data, 'date') ? {
  ...data,
  date: moment(data.date, 'DD.MM.YYYY').format('YYYY-MM-DD'),
} : data);
