import * as C from 'actions/constants';

export const blankState = {
  data: {},
  list: [],
  count: 0,
  next: '',
  loading: false,
  invites: [],
  loadingInvite: false,
  members: [],
  invites: [],
  hasInvite: {},
};

const OrganizationReducer = (state = blankState, action) => {
  switch (action.type) {
    case C.GET_ORGANIZATION:
    case C.PATCH_ORGANIZATION:
    case C.LIST_ORGANIZATIONS:
    case C.CREATE_ORGANIZATION: {
      return { ...state, loading: true };
    }
    case C.ORGANIZATION_INVITE:
    case C.ORGANIZATION_DELETE_INVITE:
    case C.LIST_ORGANIZATION_INVITES: {
      return { ...state, loadingInvite: true };
    }
    case C.ORGANIZATION_DELETE_INVITE_SUCCESS:
    case C.ORGANIZATION_INVITE_SUCCESS:
    case C.LIST_ORGANIZATION_INVITES_SUCCESS: {
      const { data } = action;
      return { ...state, loadingInvite: false, invites: data };
    }
    case C.LIST_ORGANIZATION_MEMBERS_SUCCESS: {
      const { data } = action;
      return { ...state, ...data };
    }
    case C.ORGANIZATION_USER_HAS_INVITE_SUCCESS: {
      const { hasInvite } = action;
      return { ...state, hasInvite };
    }
    case C.CREATE_ORGANIZATION_SUCCESS: {
      return { ...state, loading: false };
    }
    case C.GET_ORGANIZATION_SUCCESS: {
      const { data } = action;
      return { ...state, loading: false, data };
    }
    case C.PATCH_ORGANIZATION_SUCCESS: {
      const { data } = action;
      return { ...state, loading: false, data };
    }
    case C.LIST_ORGANIZATIONS_SUCCESS: {
      const { count, next, results } = action;
      return {
        ...state,
        loading: false,
        list: results,
        count,
        next,
      };
    }
    case C.ORGANIZATION_DELETE_SUCCESS: {
      return blankState;
    }
    case C.LOAD_MORE_ORGANIZATIONS_SUCCESS: {
      const { count, next, results } = action;
      const { list } = state;
      return {
        ...state,
        loading: false,
        list: [...list, ...results],
        count,
        next,
      };
    }
    case C.GET_ORGANIZATION_ERROR:
    case C.PATCH_ORGANIZATION_ERROR:
    case C.LIST_ORGANIZATIONS_ERROR:
    case C.CREATE_ORGANIZATION_ERROR: {
      return { ...state, loading: false };
    }
    default:
      return state;
  }
};

export default OrganizationReducer;
