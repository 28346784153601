import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Card, Divider, Grid, Button, Header, List } from 'semantic-ui-react';
import {
  reduxForm,
  Field,
} from 'redux-form';
import * as Fields from 'modules/Fields';
import * as MAP_ACTIONS from 'actions/map';
import Logo from 'assets/images/logo_claim.jpg';
import * as STYLES from './PublicSearch.styled';
import { isMobile } from 'utils/validate';

class PublicSearch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locateMeEnabled: false,
      loadingUserLocation: false,
    };

    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleLocateMe = this.handleLocateMe.bind(this);
  }

  componentDidMount() {
    if (isMobile() && 'geolocation' in navigator) {
      this.setState({ locateMeEnabled: true });
    }
  }

  handleAddressChange(value) {
    const { setMapCenter, history } = this.props;

    setMapCenter({
      center: [value.location.x, value.location.y],
      zoom: 13,
    });

    history.push('/public-search/map');
  }

  handleLocateMe() {
    const { setMapCenter, history } = this.props;
    this.setState({ loadingUserLocation: true });
    navigator.geolocation.getCurrentPosition((position) => {
      if (position.coords.latitude && position.coords.longitude) {
        setMapCenter({
          center: [position.coords.longitude, position.coords.latitude],
          zoom: 13,
        });
        this.setState({ loadingUserLocation: false }, () => {
          history.push('/public-search/map');
        });
      } else {
        this.setState({ loadingUserLocation: false });
      }
    }, (error) => {
      this.setState({ loadingUserLocation: false, locateMeEnabled: false });
    });
  }

  render() {
    const { locateMeEnabled, loadingUserLocation } = this.state;

    return (
      <STYLES.Wrapper>
        <Grid centered style={{ zIndex: 2 }}>
          <Grid.Column widescreen={8} computer={8} tablet={8} mobile={14} style={{ marginTop: '20px', zIndex: 2 }}>
            <Card fluid style={{ overflow: 'hidden', textAlign: 'center' }}>
              <Card.Content>
                <STYLES.Image src={Logo} />
                <Header as="h2" textAlign="center" style={{marginTop: '0px'}}>Unsere Vision</Header>
                <p>Jeder, der in Deutschland im öffentlichen Tiefbau plant oder baut, hat zuvor auf localexpert24 nachgesehen, wer an diesem Ort schon einmal geplant oder gebaut hat.</p>
                <p style={{marginBottom: '40px'}}>Der Austausch unter den lokalen Tiefbauexperten führt immer zu reibungsärmeren, ressourcenschonenderen und wirtschaftlicheren Baumaßnahmen.</p>
                <Header as="h2" textAlign="center">Wissen Sie wie viele Baupunkte heute in Ihrer Umgebung sind?</Header>
                <Field
                  name="search_address"
                  placeholder="Adresse eingeben"
                  disabled={loadingUserLocation}
                  component={Fields.AddressSearch}
                  clickCallback={this.handleAddressChange}
                  style={{ width: '100%', fontSize: '30px' }}
                />
                {locateMeEnabled && (
                  <Button
                    loading={loadingUserLocation}
                    size="large"
                    primary
                    style={{ marginTop: '40px' }}
                    onClick={this.handleLocateMe}
                  >
                    Standort bestimmen
                  </Button>
                )}

                <Grid.Row textAlign="center" style={{marginTop: '2em'}}>
                  <p>Sie möchten mehr über localexpert24 erfahren?</p>
                  <a href="https://localexpert24.de/home" target="_blank">https://localexpert24.de/home</a>
                </Grid.Row>

                <Divider style={{ marginTop: '4em' }} />

                <List horizontal size="medium">
                  <List.Item>
                    <a href="https://localexpert24.de/home/index.php/rahmenbedingungen-agb" target="_blank">AGB</a>
                  </List.Item>
                  <List.Item>
                    <a href="https://localexpert24.de/home/index.php/impressum" target="_blank">Impressum</a>
                  </List.Item>
                  <List.Item>
                    <a href="https://localexpert24.de/home/index.php/datenschutz" target="_blank">Datenschutz</a>
                  </List.Item>
                </List>

              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
      </STYLES.Wrapper>
    );
  }
}

PublicSearch.propTypes = {
  setMapCenter: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  setMapCenter: MAP_ACTIONS.setMapCenter,
};

const SearchForm = reduxForm({
  form: 'publicMapSearch',
})(PublicSearch);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchForm));
