import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import {
  Grid,
  Card,
  Header,
  Button,
  Checkbox,
  Icon,
  List,
} from 'semantic-ui-react';
import * as STATEAPI from 'utils/stateapi';
import * as USER_ACTIONS from 'actions/user';
import AuthHOC from 'containers/AuthHOC';
import * as Alert from 'utils/alert';
import OrgaPaymentDetail from 'containers/forms/OrgaPaymentDetail';

class Premium extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formIsOpen: false,
      planType: 'small',
      loading: false,
    };

    this.toggleForm = this.toggleForm.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangePlan = this.handleChangePlan.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
    this.handleResend = this.handleResend.bind(this);
  }

  componentDidMount() {
    const { changeBlocked } = this.props;

    changeBlocked(true);
  }

  toggleForm() {
    const { formIsOpen } = this.state;

    this.setState({ formIsOpen: !formIsOpen });
  }

  handleForm() {
    const { submitForm } = this.props;

    submitForm();
  }

  handleChangePlan(planType) {
    this.setState({ planType });
  }

  toggleLoading() {
    const { loading } = this.state;

    this.setState({ loading: !loading });
  }

  handleSubmit() {
    const { formData, createInvoice, refreshLocalStorage, user } = this.props;
    const { planType } = this.state;

    this.toggleLoading();
    createInvoice({
      ...formData,
      plan_type: planType,
    })
      .then(() => refreshLocalStorage(user.id))
      .then(() => {
        Alert.success('Deine Rechnung wurde erstellt und dir per E-Mail geschickt. Bitte prüfe nun deine Mailbox.');
      })
      .catch(() => Alert.error('Die Rechnung konnte nicht erstellt werden. Bitte versuche es später noch einmal.'))
      .finally(() => this.toggleLoading());
  }

  handleResend() {
    const { resendInvoice } = this.props;

    this.toggleLoading();
    resendInvoice()
      .then(() => {
        Alert.success('Die Rechnung wurde erneut an deine E-Mail-Adresse geschickt.');
        this.toggleLoading();
      });
  }

  render() {
    const { user, remainingDays } = this.props;
    const { loading } = this.state;
    const planType = _.get(user, 'plan_type');
    const waitingPayment = (['orga_paid_plan_payment_pending'].indexOf(planType) !== -1);
    const planIsValid = (['free_trial_individual', 'orga_paid_plan_payment_pending', 'paid_plan_individual_payment_pending'].indexOf(planType) === -1 && !_.isEmpty(_.get(user, 'plan_valid_until'))) ? moment(user.plan_valid_until).endOf('day').diff(moment().endOf('day'), 'days') >= 0 : false;

    return (
      <Grid centered style={{ zIndex: 2 }}>
        <Grid.Column computer={14} tablet={14} mobile={14} style={{ zIndex: 2 }}>
          <Card fluid>
            <Card.Content style={{ textAlign: 'center' }}>
              <Grid.Row style={{ marginTop: '20px', marginBottom: '60px' }}>
                <img width="300" alt="Placeholder" src="/images/premium_expert_header.png" />
              </Grid.Row>
              {planIsValid && (
                <p>Du hast einen gültigen Premium-Tarif für deine Organisation.</p>
              )}
              {waitingPayment && (
                <Grid>
                  <Grid.Column>
                    <Grid.Row style={{ marginBottom: '30px', textAlign: 'center' }}>
                      <Header as="h1" textAlign="center">Vielen Dank für die Bestellung deines Premium-Accounts für Organisationen</Header>
                      <p>Wir haben dir die Rechnung mit der Zahlungsaufforderung per E-Mail geschickt.<br/>
                      Erst wenn die Zahlung eingegangen ist, wird dein Premium-Account aktiviert und du kannst deine Organisation anlegen.</p>
                    </Grid.Row>

                    <Grid.Row style={{ margin: '60px 0' }}>
                      <Button disabled={loading} loading={loading} size="huge" color="blue" content="Rechnung erneut senden" onClick={this.handleResend} />
                    </Grid.Row>

                    <Grid.Row>
                      <p>Hast du Fragen zu deiner Zahlung? Du erreichst uns unter <a href="mailto:feedback@localexpert24.de">feedback@localexpert24.de</a>.</p>
                    </Grid.Row>
                  </Grid.Column>
                </Grid>
              )}
              {!waitingPayment && !planIsValid && (
                <Grid>
                  <Grid.Column>
                    <Grid.Row style={{ marginBottom: '30px', textAlign: 'center' }}>
                      <Header as="h1" textAlign="center">Wähle nun einen Tarif für deine Organisation</Header>
                      <p>Sobald du den Tarif bezahlt hast, kannst du deine Organisation anlegen und alle eingeladenen Mitglieder erhalten automatisch eine Premium-Mitgliedschaft.</p>
                    </Grid.Row>
                    <Grid.Row style={{ margin: '60px 0' }}>
                      <Grid centered>
                        <Grid.Column computer={4} tablet={4} mobile={12}>
                          <Card style={{ textAlign: 'center' }} color="yellow">
                            <Card.Content header="1-10 Mitglieder" />
                            <Card.Content>
                              <p style={{ color: '#888' }}>2.000,00 € / Jahr<br/>zzgl. MwSt.</p>
                            </Card.Content>
                            <Card.Content extra>
                              <Checkbox
                                radio
                                name="plan_type"
                                value="small"
                                checked={this.state.planType === 'small'}
                                onChange={() => this.handleChangePlan('small')}
                              />
                            </Card.Content>
                          </Card>
                        </Grid.Column>
                        <Grid.Column computer={4} tablet={4} mobile={12}>
                          <Card style={{ textAlign: 'center' }} color="yellow">
                            <Card.Content header="11-30 Mitglieder" />
                            <Card.Content>
                              <p style={{ color: '#888' }}>4.000,00 € / Jahr<br/>zzgl. MwSt.</p>
                            </Card.Content>
                            <Card.Content extra>
                              <Checkbox
                                radio
                                name="plan_type"
                                value="large"
                                checked={this.state.planType === 'large'}
                                onChange={() => this.handleChangePlan('large')}
                              />
                            </Card.Content>
                          </Card>
                        </Grid.Column>
                        <Grid.Column computer={4} tablet={4} mobile={12}>
                          <Card style={{ textAlign: 'center' }} color="blue">
                            <Card.Content header="Mehr als 30 Mitglieder" />
                            <Card.Content>
                              <p style={{ color: '#888' }}>Beitrag auf Anfrage</p>
                            </Card.Content>
                            <Card.Content extra>
                              <Button href="mailto:feedback@localexpert24.de" fluid color="blue" content="Kontaktiere uns" />
                            </Card.Content>
                          </Card>
                        </Grid.Column>
                      </Grid>
                    </Grid.Row>
                  </Grid.Column>
                </Grid>
              )}

              {!waitingPayment && !planIsValid && (
                <Grid.Row style={{ marginBottom: '40px', textAlign: 'center' }}>
                  <hr style={{ margin: '60px 0' }} />
                  <Grid centered>
                    <Grid.Column computer={5} mobile={15} textAlign="center">
                      <OrgaPaymentDetail onSubmit={this.handleSubmit} />
                    </Grid.Column>
                    <Grid.Column computer={1} mobile={15} />
                    <Grid.Column
                      computer={4}
                      mobile={15}
                      style={{
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <p>Sicher bezahlen mit:</p>
                      <List horizontal>
                        <List.Item><Icon name="cc visa" size="big" /></List.Item>
                        <List.Item><Icon name="cc mastercard" size="big" /></List.Item>
                        <List.Item><Icon name="cc paypal" size="big" /></List.Item>
                      </List>
                      <p>Order auf Rechnung</p>
                    </Grid.Column>
                  </Grid>

                  <Button disabled={loading} loading={loading} size="huge" color="blue" content="Kostenpflichtig bestellen" style={{ marginTop: '30px', marginBottom: '30px' }} onClick={this.handleForm} />

                  <p>Mit dem Klick auf "Kostenpflichtig bestellen" erhältst du eine E-Mail mit Zahlungshinweisen und die dazugehörige Rechnung.</p>
                </Grid.Row>
              )}
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid>
    );
  }
}

Premium.propTypes = {
  changeBlocked: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const user = STATEAPI.getCurrentUser(state);

  const planValidDate = _.get(user, 'plan_valid_until');
  let remainingDays = 0;

  if (planValidDate) {
    const momentValidDate = moment(planValidDate).endOf('day');
    const today = moment().endOf('day');
    remainingDays = momentValidDate.diff(today, 'days');
  }

  return {
    user,
    formData: _.get(state.form, 'orgaPaymentDetail.values', {}),
    remainingDays,
  };
};

const mapDispatchToProps = {
  submitForm: USER_ACTIONS.submitOrgaPaymentDetailForm,
  refreshLocalStorage: USER_ACTIONS.refreshLocalStorage,
  createInvoice: USER_ACTIONS.createOrganizationInvoice,
  resendInvoice: USER_ACTIONS.resendInvoice,
};

const connectedPremium = connect(mapStateToProps, mapDispatchToProps)(Premium);

export default withRouter(AuthHOC(connectedPremium));

