const countries = [
  {
    text: 'Afghanistan',
    value: 'AF',
    key: 'AF',
  },
  {
    text: 'Albania',
    value: 'AL',
    key: 'AL',
  },
  {
    text: 'Algeria',
    value: 'DZ',
    key: 'DZ',
  },
  {
    text: 'American Samoa',
    value: 'AS',
    key: 'AS',
  },
  {
    text: 'Andorra',
    value: 'AD',
    key: 'AD',
  },
  {
    text: 'Angola',
    value: 'AO',
    key: 'AO',
  },
  {
    text: 'Anguilla',
    value: 'AI',
    key: 'AI',
  },
  {
    text: 'Antarctica',
    value: 'AQ',
    key: 'AQ',
  },
  {
    text: 'Antigua and Barbuda',
    value: 'AG',
    key: 'AG',
  },
  {
    text: 'Argentina',
    value: 'AR',
    key: 'AR',
  },
  {
    text: 'Armenia',
    value: 'AM',
    key: 'AM',
  },
  {
    text: 'Aruba',
    value: 'AW',
    key: 'AW',
  },
  {
    text: 'Australia',
    value: 'AU',
    key: 'AU',
  },
  {
    text: 'Austria',
    value: 'AT',
    key: 'AT',
  },
  {
    text: 'Azerbaijan',
    value: 'AZ',
    key: 'AZ',
  },
  {
    text: 'Bahamas',
    value: 'BS',
    key: 'BS',
  },
  {
    text: 'Bahrain',
    value: 'BH',
    key: 'BH',
  },
  {
    text: 'Bangladesh',
    value: 'BD',
    key: 'BD',
  },
  {
    text: 'Barbados',
    value: 'BB',
    key: 'BB',
  },
  {
    text: 'Belarus',
    value: 'BY',
    key: 'BY',
  },
  {
    text: 'Belgium',
    value: 'BE',
    key: 'BE',
  },
  {
    text: 'Belize',
    value: 'BZ',
    key: 'BZ',
  },
  {
    text: 'Benin',
    value: 'BJ',
    key: 'BJ',
  },
  {
    text: 'Bermuda',
    value: 'BM',
    key: 'BM',
  },
  {
    text: 'Bhutan',
    value: 'BT',
    key: 'BT',
  },
  {
    text: 'Bolivia',
    value: 'BO',
    key: 'BO',
  },
  {
    text: 'Bosnia and Herzegovina',
    value: 'BA',
    key: 'BA',
  },
  {
    text: 'Botswana',
    value: 'BW',
    key: 'BW',
  },
  {
    text: 'Bouvet Island',
    value: 'BV',
    key: 'BV',
  },
  {
    text: 'Brazil',
    value: 'BR',
    key: 'BR',
  },
  {
    text: 'British Indian Ocean Territory',
    value: 'IO',
    key: 'IO',
  },
  {
    text: 'Brunei',
    value: 'BN',
    key: 'BN',
  },
  {
    text: 'Bulgaria',
    value: 'BG',
    key: 'BG',
  },
  {
    text: 'Burkina Faso',
    value: 'BF',
    key: 'BF',
  },
  {
    text: 'Burundi',
    value: 'BI',
    key: 'BI',
  },
  {
    text: 'Cambodia',
    value: 'KH',
    key: 'KH',
  },
  {
    text: 'Cameroon',
    value: 'CM',
    key: 'CM',
  },
  {
    text: 'Canada',
    value: 'CA',
    key: 'CA',
  },
  {
    text: 'Cape Verde',
    value: 'CV',
    key: 'CV',
  },
  {
    text: 'Cayman Islands',
    value: 'KY',
    key: 'KY',
  },
  {
    text: 'Central African Republic',
    value: 'CF',
    key: 'CF',
  },
  {
    text: 'Chad',
    value: 'TD',
    key: 'TD',
  },
  {
    text: 'Chile',
    value: 'CL',
    key: 'CL',
  },
  {
    text: 'China',
    value: 'CN',
    key: 'CN',
  },
  {
    text: 'Christmas Island',
    value: 'CX',
    key: 'CX',
  },
  {
    text: 'Cocos (Keeling) Islands',
    value: 'CC',
    key: 'CC',
  },
  {
    text: 'Colombia',
    value: 'CO',
    key: 'CO',
  },
  {
    text: 'Comoros',
    value: 'KM',
    key: 'KM',
  },
  {
    text: 'Congo',
    value: 'CG',
    key: 'CG',
  },
  {
    text: 'Cook Islands',
    value: 'CK',
    key: 'CK',
  },
  {
    text: 'Costa Rica',
    value: 'CR',
    key: 'CR',
  },
  {
    text: 'Croatia',
    value: 'HR',
    key: 'HR',
  },
  {
    text: 'Cuba',
    value: 'CU',
    key: 'CU',
  },
  {
    text: 'Cyprus',
    value: 'CY',
    key: 'CY',
  },
  {
    text: 'Czech Republic',
    value: 'CZ',
    key: 'CZ',
  },
  {
    text: 'Denmark',
    value: 'DK',
    key: 'DK',
  },
  {
    text: 'Deutschland',
    value: 'DE',
    key: 'DE',
  },
  {
    text: 'Djibouti',
    value: 'DJ',
    key: 'DJ',
  },
  {
    text: 'Dominica',
    value: 'DM',
    key: 'DM',
  },
  {
    text: 'Dominican Republic',
    value: 'DO',
    key: 'DO',
  },
  {
    text: 'East Timor',
    value: 'TP',
    key: 'TP',
  },
  {
    text: 'Ecuador',
    value: 'EC',
    key: 'EC',
  },
  {
    text: 'Egypt',
    value: 'EG',
    key: 'EG',
  },
  {
    text: 'El Salvador',
    value: 'SV',
    key: 'SV',
  },
  {
    text: 'Equatorial Guinea',
    value: 'GQ',
    key: 'GQ',
  },
  {
    text: 'Eritrea',
    value: 'ER',
    key: 'ER',
  },
  {
    text: 'Estonia',
    value: 'EE',
    key: 'EE',
  },
  {
    text: 'Ethiopia',
    value: 'ET',
    key: 'ET',
  },
  {
    text: 'Falkland Islands',
    value: 'FK',
    key: 'FK',
  },
  {
    text: 'Faroe Islands',
    value: 'FO',
    key: 'FO',
  },
  {
    text: 'Fiji Islands',
    value: 'FJ',
    key: 'FJ',
  },
  {
    text: 'Finland',
    value: 'FI',
    key: 'FI',
  },
  {
    text: 'France',
    value: 'FR',
    key: 'FR',
  },
  {
    text: 'French Guiana',
    value: 'GF',
    key: 'GF',
  },
  {
    text: 'French Polynesia',
    value: 'PF',
    key: 'PF',
  },
  {
    text: 'French Southern territories',
    value: 'TF',
    key: 'TF',
  },
  {
    text: 'Gabon',
    value: 'GA',
    key: 'GA',
  },
  {
    text: 'Gambia',
    value: 'GM',
    key: 'GM',
  },
  {
    text: 'Georgia',
    value: 'GE',
    key: 'GE',
  },
  {
    text: 'Ghana',
    value: 'GH',
    key: 'GH',
  },
  {
    text: 'Gibraltar',
    value: 'GI',
    key: 'GI',
  },
  {
    text: 'Greece',
    value: 'GR',
    key: 'GR',
  },
  {
    text: 'Greenland',
    value: 'GL',
    key: 'GL',
  },
  {
    text: 'Grenada',
    value: 'GD',
    key: 'GD',
  },
  {
    text: 'Guadeloupe',
    value: 'GP',
    key: 'GP',
  },
  {
    text: 'Guam',
    value: 'GU',
    key: 'GU',
  },
  {
    text: 'Guatemala',
    value: 'GT',
    key: 'GT',
  },
  {
    text: 'Guinea',
    value: 'GN',
    key: 'GN',
  },
  {
    text: 'Guinea-Bissau',
    value: 'GW',
    key: 'GW',
  },
  {
    text: 'Guyana',
    value: 'GY',
    key: 'GY',
  },
  {
    text: 'Haiti',
    value: 'HT',
    key: 'HT',
  },
  {
    text: 'Heard Island and McDonald Islands',
    value: 'HM',
    key: 'HM',
  },
  {
    text: 'Holy See (Vatican City State)',
    value: 'VA',
    key: 'VA',
  },
  {
    text: 'Honduras',
    value: 'HN',
    key: 'HN',
  },
  {
    text: 'Hong Kong',
    value: 'HK',
    key: 'HK',
  },
  {
    text: 'Hungary',
    value: 'HU',
    key: 'HU',
  },
  {
    text: 'Iceland',
    value: 'IS',
    key: 'IS',
  },
  {
    text: 'India',
    value: 'IN',
    key: 'IN',
  },
  {
    text: 'Indonesia',
    value: 'ID',
    key: 'ID',
  },
  {
    text: 'Iran',
    value: 'IR',
    key: 'IR',
  },
  {
    text: 'Iraq',
    value: 'IQ',
    key: 'IQ',
  },
  {
    text: 'Ireland',
    value: 'IE',
    key: 'IE',
  },
  {
    text: 'Israel',
    value: 'IL',
    key: 'IL',
  },
  {
    text: 'Italy',
    value: 'IT',
    key: 'IT',
  },
  {
    text: 'Ivory Coast',
    value: 'CI',
    key: 'CI',
  },
  {
    text: 'Jamaica',
    value: 'JM',
    key: 'JM',
  },
  {
    text: 'Japan',
    value: 'JP',
    key: 'JP',
  },
  {
    text: 'Jordan',
    value: 'JO',
    key: 'JO',
  },
  {
    text: 'Kazakhstan',
    value: 'KZ',
    key: 'KZ',
  },
  {
    text: 'Kenya',
    value: 'KE',
    key: 'KE',
  },
  {
    text: 'Kiribati',
    value: 'KI',
    key: 'KI',
  },
  {
    text: 'Kuwait',
    value: 'KW',
    key: 'KW',
  },
  {
    text: 'Kyrgyzstan',
    value: 'KG',
    key: 'KG',
  },
  {
    text: 'Laos',
    value: 'LA',
    key: 'LA',
  },
  {
    text: 'Latvia',
    value: 'LV',
    key: 'LV',
  },
  {
    text: 'Lebanon',
    value: 'LB',
    key: 'LB',
  },
  {
    text: 'Lesotho',
    value: 'LS',
    key: 'LS',
  },
  {
    text: 'Liberia',
    value: 'LR',
    key: 'LR',
  },
  {
    text: 'Libyan Arab Jamahiriya',
    value: 'LY',
    key: 'LY',
  },
  {
    text: 'Liechtenstein',
    value: 'LI',
    key: 'LI',
  },
  {
    text: 'Lithuania',
    value: 'LT',
    key: 'LT',
  },
  {
    text: 'Luxembourg',
    value: 'LU',
    key: 'LU',
  },
  {
    text: 'Macao',
    value: 'MO',
    key: 'MO',
  },
  {
    text: 'North Macedonia',
    value: 'MK',
    key: 'MK',
  },
  {
    text: 'Madagascar',
    value: 'MG',
    key: 'MG',
  },
  {
    text: 'Malawi',
    value: 'MW',
    key: 'MW',
  },
  {
    text: 'Malaysia',
    value: 'MY',
    key: 'MY',
  },
  {
    text: 'Maldives',
    value: 'MV',
    key: 'MV',
  },
  {
    text: 'Mali',
    value: 'ML',
    key: 'ML',
  },
  {
    text: 'Malta',
    value: 'MT',
    key: 'MT',
  },
  {
    text: 'Marshall Islands',
    value: 'MH',
    key: 'MH',
  },
  {
    text: 'Martinique',
    value: 'MQ',
    key: 'MQ',
  },
  {
    text: 'Mauritania',
    value: 'MR',
    key: 'MR',
  },
  {
    text: 'Mauritius',
    value: 'MU',
    key: 'MU',
  },
  {
    text: 'Mayotte',
    value: 'YT',
    key: 'YT',
  },
  {
    text: 'Mexico',
    value: 'MX',
    key: 'MX',
  },
  {
    text: 'Micronesia, Federated States of',
    value: 'FM',
    key: 'FM',
  },
  {
    text: 'Moldova',
    value: 'MD',
    key: 'MD',
  },
  {
    text: 'Monaco',
    value: 'MC',
    key: 'MC',
  },
  {
    text: 'Mongolia',
    value: 'MN',
    key: 'MN',
  },
  {
    text: 'Montserrat',
    value: 'MS',
    key: 'MS',
  },
  {
    text: 'Morocco',
    value: 'MA',
    key: 'MA',
  },
  {
    text: 'Mozambique',
    value: 'MZ',
    key: 'MZ',
  },
  {
    text: 'Myanmar',
    value: 'MM',
    key: 'MM',
  },
  {
    text: 'Namibia',
    value: 'NA',
    key: 'NA',
  },
  {
    text: 'Nauru',
    value: 'NR',
    key: 'NR',
  },
  {
    text: 'Nepal',
    value: 'NP',
    key: 'NP',
  },
  {
    text: 'Netherlands',
    value: 'NL',
    key: 'NL',
  },
  {
    text: 'Netherlands Antilles',
    value: 'AN',
    key: 'AN',
  },
  {
    text: 'New Caledonia',
    value: 'NC',
    key: 'NC',
  },
  {
    text: 'New Zealand',
    value: 'NZ',
    key: 'NZ',
  },
  {
    text: 'Nicaragua',
    value: 'NI',
    key: 'NI',
  },
  {
    text: 'Niger',
    value: 'NE',
    key: 'NE',
  },
  {
    text: 'Nigeria',
    value: 'NG',
    key: 'NG',
  },
  {
    text: 'Niue',
    value: 'NU',
    key: 'NU',
  },
  {
    text: 'Norfolk Island',
    value: 'NF',
    key: 'NF',
  },
  {
    text: 'North Korea',
    value: 'KP',
    key: 'KP',
  },
  {
    text: 'Northern Ireland',
    value: 'GB',
    key: 'GB',
  },
  {
    text: 'Northern Mariana Islands',
    value: 'MP',
    key: 'MP',
  },
  {
    text: 'Norway',
    value: 'NO',
    key: 'NO',
  },
  {
    text: 'Oman',
    value: 'OM',
    key: 'OM',
  },
  {
    text: 'Pakistan',
    value: 'PK',
    key: 'PK',
  },
  {
    text: 'Palau',
    value: 'PW',
    key: 'PW',
  },
  {
    text: 'Palestine',
    value: 'PS',
    key: 'PS',
  },
  {
    text: 'Panama',
    value: 'PA',
    key: 'PA',
  },
  {
    text: 'Papua New Guinea',
    value: 'PG',
    key: 'PG',
  },
  {
    text: 'Paraguay',
    value: 'PY',
    key: 'PY',
  },
  {
    text: 'Peru',
    value: 'PE',
    key: 'PE',
  },
  {
    text: 'Philippines',
    value: 'PH',
    key: 'PH',
  },
  {
    text: 'Pitcairn',
    value: 'PN',
    key: 'PN',
  },
  {
    text: 'Poland',
    value: 'PL',
    key: 'PL',
  },
  {
    text: 'Portugal',
    value: 'PT',
    key: 'PT',
  },
  {
    text: 'Puerto Rico',
    value: 'PR',
    key: 'PR',
  },
  {
    text: 'Qatar',
    value: 'QA',
    key: 'QA',
  },
  {
    text: 'Reunion',
    value: 'RE',
    key: 'RE',
  },
  {
    text: 'Romania',
    value: 'RO',
    key: 'RO',
  },
  {
    text: 'Russian Federation',
    value: 'RU',
    key: 'RU',
  },
  {
    text: 'Rwanda',
    value: 'RW',
    key: 'RW',
  },
  {
    text: 'Saint Helena',
    value: 'SH',
    key: 'SH',
  },
  {
    text: 'Saint Kitts and Nevis',
    value: 'KN',
    key: 'KN',
  },
  {
    text: 'Saint Lucia',
    value: 'LC',
    key: 'LC',
  },
  {
    text: 'Saint Pierre and Miquelon',
    value: 'PM',
    key: 'PM',
  },
  {
    text: 'Saint Vincent and the Grenadines',
    value: 'VC',
    key: 'VC',
  },
  {
    text: 'Samoa',
    value: 'WS',
    key: 'WS',
  },
  {
    text: 'San Marino',
    value: 'SM',
    key: 'SM',
  },
  {
    text: 'Sao Tome and Principe',
    value: 'ST',
    key: 'ST',
  },
  {
    text: 'Saudi Arabia',
    value: 'SA',
    key: 'SA',
  },
  {
    text: 'Senegal',
    value: 'SN',
    key: 'SN',
  },
  {
    text: 'Seychelles',
    value: 'SC',
    key: 'SC',
  },
  {
    text: 'Sierra Leone',
    value: 'SL',
    key: 'SL',
  },
  {
    text: 'Singapore',
    value: 'SG',
    key: 'SG',
  },
  {
    text: 'Slovakia',
    value: 'SK',
    key: 'SK',
  },
  {
    text: 'Slovenia',
    value: 'SI',
    key: 'SI',
  },
  {
    text: 'Solomon Islands',
    value: 'SB',
    key: 'SB',
  },
  {
    text: 'Somalia',
    value: 'SO',
    key: 'SO',
  },
  {
    text: 'South Africa',
    value: 'ZA',
    key: 'ZA',
  },
  {
    text: 'South Georgia and the South Sandwich Islands',
    value: 'GS',
    key: 'GS',
  },
  {
    text: 'South Korea',
    value: 'KR',
    key: 'KR',
  },
  {
    text: 'South Sudan',
    value: 'SS',
    key: 'SS',
  },
  {
    text: 'Spain',
    value: 'ES',
    key: 'ES',
  },
  {
    text: 'Sri Lanka',
    value: 'LK',
    key: 'LK',
  },
  {
    text: 'Sudan',
    value: 'SD',
    key: 'SD',
  },
  {
    text: 'Suriname',
    value: 'SR',
    key: 'SR',
  },
  {
    text: 'Svalbard and Jan Mayen',
    value: 'SJ',
    key: 'SJ',
  },
  {
    text: 'Swaziland',
    value: 'SZ',
    key: 'SZ',
  },
  {
    text: 'Sweden',
    value: 'SE',
    key: 'SE',
  },
  {
    text: 'Switzerland',
    value: 'CH',
    key: 'CH',
  },
  {
    text: 'Syria',
    value: 'SY',
    key: 'SY',
  },
  {
    text: 'Tajikistan',
    value: 'TJ',
    key: 'TJ',
  },
  {
    text: 'Tanzania',
    value: 'TZ',
    key: 'TZ',
  },
  {
    text: 'Thailand',
    value: 'TH',
    key: 'TH',
  },
  {
    text: 'The Democratic Republic of Congo',
    value: 'CD',
    key: 'CD',
  },
  {
    text: 'Togo',
    value: 'TG',
    key: 'TG',
  },
  {
    text: 'Tokelau',
    value: 'TK',
    key: 'TK',
  },
  {
    text: 'Tonga',
    value: 'TO',
    key: 'TO',
  },
  {
    text: 'Trinidad and Tobago',
    value: 'TT',
    key: 'TT',
  },
  {
    text: 'Tunisia',
    value: 'TN',
    key: 'TN',
  },
  {
    text: 'Turkey',
    value: 'TR',
    key: 'TR',
  },
  {
    text: 'Turkmenistan',
    value: 'TM',
    key: 'TM',
  },
  {
    text: 'Turks and Caicos Islands',
    value: 'TC',
    key: 'TC',
  },
  {
    text: 'Tuvalu',
    value: 'TV',
    key: 'TV',
  },
  {
    text: 'Uganda',
    value: 'UG',
    key: 'UG',
  },
  {
    text: 'Ukraine',
    value: 'UA',
    key: 'UA',
  },
  {
    text: 'United Arab Emirates',
    value: 'AE',
    key: 'AE',
  },
  {
    text: 'United Kingdom',
    value: 'GB',
    key: 'GB',
  },
  {
    text: 'United States',
    value: 'US',
    key: 'US',
  },
  {
    text: 'United States Minor Outlying Islands',
    value: 'UM',
    key: 'UM',
  },
  {
    text: 'Uruguay',
    value: 'UY',
    key: 'UY',
  },
  {
    text: 'Uzbekistan',
    value: 'UZ',
    key: 'UZ',
  },
  {
    text: 'Vanuatu',
    value: 'VU',
    key: 'VU',
  },
  {
    text: 'Venezuela',
    value: 'VE',
    key: 'VE',
  },
  {
    text: 'Vietnam',
    value: 'VN',
    key: 'VN',
  },
  {
    text: 'Virgin Islands, British',
    value: 'VG',
    key: 'VG',
  },
  {
    text: 'Virgin Islands, U.S.',
    value: 'VI',
    key: 'VI',
  },
  {
    text: 'Wallis and Futuna',
    value: 'WF',
    key: 'WF',
  },
  {
    text: 'Western Sahara',
    value: 'EH',
    key: 'EH',
  },
  {
    text: 'Yemen',
    value: 'YE',
    key: 'YE',
  },
  {
    text: 'Yugoslavia',
    value: 'YU',
    key: 'YU',
  },
  {
    text: 'Zambia',
    value: 'ZM',
    key: 'ZM',
  },
  {
    text: 'Zimbabwe',
    value: 'ZW',
    key: 'ZW',
  },
];

export default countries;
