import React, { Component } from 'react';
import { connect } from 'react-redux';
import Breakpoint from 'react-socks';
import PropTypes from 'prop-types';
import { reduxForm, Field, change } from 'redux-form';
import * as Fields from 'modules/Fields';
import {
  Grid,
  Popup,
  Button,
  Form,
  Icon,
} from 'semantic-ui-react';
import _ from 'lodash';
import * as USER_CONST from 'const/user';
import { filterTags } from 'api/lib/user';
import UserAvatarImage from 'modules/UserAvatarImage';
import UserTitleImage from 'modules/UserTitleImage';
import getBase64 from 'utils/file';

const validate = (values) => {
  let errors = [];
  const requiredFields = [
    'name',
    'street',
    'postalcode',
    'city',
    'description',
    'date',
  ];

  for (let i = 0; i < requiredFields.length; i++) { // eslint-disable-line
    const field = requiredFields[i];
    if (!values[field]) {
      errors = [...errors, field];
    }
  }

  const errorsObj = _.reduce(errors, (obj, param) => {
    obj[param] = 'Pflichtfeld'; // eslint-disable-line
    return obj;
  }, {});

  return errorsObj;
};

const EventForm = ({
  handleSubmit,
  onSubmit,
  changeFieldValue,
  formData,
  handleReturn,
}) => (
  <Form
    onSubmit={handleSubmit(onSubmit)}
  >
    <Grid centered>
      <Grid.Column width={6}>
        <Grid centered>
          <Grid.Column width={15}>
            <div style={{ textAlign: 'center' }}>
              <Field
                name="image"
                component={Fields.renderInput}
                style={{ display: 'none' }}
              />
              <UserAvatarImage
                type="image"
                imageUrl={_.get(formData, 'image_base64', formData.image)}
                handleChange={(event) => {
                  event.preventDefault();
                  const files = [...event.target.files];

                  if (files.length > 0) {
                    getBase64(files[0])
                      .then((res) => {
                        changeFieldValue('image_base64', res);
                        changeFieldValue('image', files[0]);
                      });
                  }
                }}
                containerStyle={{
                  marginTop: 0,
                }}
              />
            </div>
          </Grid.Column>
        </Grid>

        <Grid centered>
          <Grid.Column width={15}>
            <div style={{ textAlign: 'center' }}>
              <Field
                name="title_image_url"
                component={Fields.renderInput}
                style={{ display: 'none' }}
              />
              <UserTitleImage
                type="title_image_url"
                imageUrl={_.get(formData, 'title_image_url_base64', formData.title_image_url)}
                handleChange={(event) => {
                  event.preventDefault();
                  const files = [...event.target.files];

                  if (files.length > 0) {
                    getBase64(files[0])
                      .then((res) => {
                        changeFieldValue('title_image_url_base64', res);
                        changeFieldValue('title_image_url', files[0]);
                      });
                  }
                }}
              />
            </div>
          </Grid.Column>
        </Grid>

        <Grid>
          <Grid.Column width={16}>
            <Field
              fluid
              name="name"
              placeholder="Name des Termins"
              component={Fields.renderInput}
            />
          </Grid.Column>
          <Grid.Column width={16}>
            <Field
              fluid
              name="street"
              placeholder="Straße"
              component={Fields.renderInput}
            />
          </Grid.Column>
          <Grid.Column width={16}>
            <Field
              fluid
              name="postalcode"
              placeholder="PLZ"
              component={Fields.renderInput}
            />
          </Grid.Column>
          <Grid.Column width={16}>
            <Field
              fluid
              name="city"
              placeholder="Ort"
              component={Fields.renderInput}
            />
          </Grid.Column>
          <Grid.Column width={16}>
            <Field
              fluid
              name="description"
              placeholder="Beschreibung des Termins"
              component={Fields.renderRichTextInput}
            />
          </Grid.Column>
          <Grid.Column width={5}>
            <Field
              name="date"
              placeholder="Datum"
              component={Fields.renderDatePicker}
            />
          </Grid.Column>
        </Grid>

        <Grid.Row textAlign="right">
          <Grid centered style={{ marginBottom: '5px' }}>
            <Grid.Column width={8} textAlign="left">
              <Button
                size="large"
                onClick={(e) => {
                  e.preventDefault();
                  handleReturn();
                }}
              >Abbrechen</Button>
            </Grid.Column>
            <Grid.Column width={8} textAlign="right">
              <Button primary size="large">Speichern</Button>
            </Grid.Column>
          </Grid>
        </Grid.Row>
      </Grid.Column>
    </Grid>
  </Form>
);

EventForm.defaultProps = {};

EventForm.propTypes = {};

const FORM_NAME = 'event';
const EventReduxForm = reduxForm({
  form: FORM_NAME,
  validate,
})(EventForm);

const mapStateToProps = state => ({
  formData: _.get(state, `form.${FORM_NAME}.values`, {}),
});

const mapDispatchToProps = dispatch => ({
  changeFieldValue: (field, value) => {
    dispatch(change(FORM_NAME, field, value));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(EventReduxForm);
