import _ from 'lodash'

import REST from '../mapRest';
import API_REST from '../rest';

const BASE_URL = 'https://geocode.arcgis.com/';
const API_BASE_URL = '/api/v1/locations/';

export const search = magicKey => REST.get({
  endpoint: `${BASE_URL}arcgis/rest/services/World/GeocodeServer/findAddressCandidates`,
  params: {
    magicKey,
    f: 'json',
    sourceCountry: 'DE',
    outFields: 'StAddr,Match_addr,Place_addr,Postal,City',
  },
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
});

export const suggest = (query, country) => REST.get({
  endpoint: `${BASE_URL}arcgis/rest/services/World/GeocodeServer/suggest`,
  params: {
    text: query,
    f: 'json',
    countryCode: country || 'DE',
    category: 'Address,Postal,City',
  },
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
});

export const searchByLngLat = lngLat => REST.get({
  endpoint: `${BASE_URL}arcgis/rest/services/World/GeocodeServer/reverseGeocode`,
  params: {
    location: lngLat,
    f: 'json',
  },
  headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
});

export const filterTags = (type, query) => API_REST.get(
  '/api/v1/location-tags/',
  null,
  {
    search: query,
  },
);

export const create = data => API_REST.post(
  API_BASE_URL,
  data,
  {},
  { 'Content-Type': 'application/json' },
);

export const get = (uuid = '', params = {}) => API_REST.get(
  params.url ? params.url : API_BASE_URL,
  uuid,
  _.omit(params, ['url']),
);

export const mapLocations = (params = {}) => API_REST.get(
  '/api/v1/map-locations/',
  '',
  params,
);

export const mapLocationsQuery = (params = {}) => API_REST.get(
  '/api/v1/map-locations/query/',
  '',
  params,
);

export const mapLocationDetail = (id, query) => API_REST.get(
  '/api/v1/map-location-detail/',
  id,
  query,
);

export const mapLocationsBookmarked = () => API_REST.get(
  '/api/v1/map-locations/bookmarked/',
  '',
);

export const patch = (id, data) => API_REST.patch(
  API_BASE_URL,
  id,
  data,
  {},
  { 'Content-Type': 'application/json' },
);

export const uploadDocument = (id, data) => API_REST.post(
  '/api/v1/location-document/',
  data,
  {},
  { 'Content-Type': 'application/form-data' },
);

export const deleteDocument = id => API_REST.delete(
  '/api/v1/location-document/',
  id,
);

export const uploadContent = (id, data) => API_REST.put(
  '/api/v1/location-content/',
  id,
  data,
  {},
  { 'Content-Type': 'application/form-data' },
);

export const rotateContent = data => API_REST.post(
  '/api/v1/location-content/rotate/',
  data,
  {},
  { 'Content-Type': 'application/json' },
);

export const deleteContent = id => API_REST.delete(
  '/api/v1/location-content/',
  id,
);

export const isBookmarked = data => API_REST.get(
  '/api/v1/bookmark/',
  '',
  data,
);

export const bookmark = data => API_REST.post(
  '/api/v1/bookmark/',
  data,
  {},
  { 'Content-Type': 'application/json' },
);

export const deleteBookmark = data => API_REST.post(
  '/api/v1/bookmark/delete/',
  data,
  {},
  { 'Content-Type': 'application/json' },
);

export const isVoted = data => API_REST.get(
  '/api/v1/vote/',
  '',
  data,
);

export const countVotes = id => API_REST.get(
  '/api/v1/vote/count/',
  '',
  { location: id },
);

export const vote = data => API_REST.post(
  '/api/v1/vote/',
  data,
  {},
  { 'Content-Type': 'application/json' },
);

export const deleteVote = data => API_REST.post(
  '/api/v1/vote/delete/',
  data,
  {},
  { 'Content-Type': 'application/json' },
);

export const deleteLocation = id => API_REST.delete(
  API_BASE_URL,
  id,
);

export const massImport = data => API_REST.post(
  `${API_BASE_URL}mass_import/`,
  data,
  {},
  { 'Content-Type': 'application/x-www-form-urlencoded' },
);

export const uploadFileIFC = (id, data) => API_REST.post(
  '/api/v1/location-documentIFC/',
  data,
  {},
  { 'Content-Type': 'application/form-data' },
);

export const getXKTFile = id => API_REST.get(
  `/api/v1/locations/check_xkt/?id=${id}`,
);
