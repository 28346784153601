import React, { Component } from 'react';
import PropTypes from 'prop-types';
import esriLoader from 'esri-loader';
import * as MAP_UTILS from 'utils/map';

class MiniMap extends Component {
  constructor(props) {
    super(props);

    this.state = {
      basemap: 'streets',
    }

    this.mapView = undefined;
    this.renderMap = this.renderMap.bind(this);
  }

  componentDidMount() {
    this.renderMap();
  }

  shouldComponentUpdate(nextProps) {
    const { lat, lng } = this.props;

    return nextProps.lat !== lat || nextProps.lng !== lng;
  }

  componentDidUpdate() {
    this.renderMap();
  }

  renderMap() {
    const { lat, lng, handleMapChange } = this.props;
    const { basemap } = this.state;

    if (!lat || !lng) {
      return;
    }

    esriLoader.loadModules([
      'esri/tasks/Locator',
      'esri/Graphic',
      'esri/views/MapView',
      'esri/widgets/BasemapToggle',
      'esri/WebMap',
    ])
      .then(([
        Locator,
        Graphic,
        MapView,
        BasemapToggle,
        WebMap,
      ]) => {
        const locatorTask = new Locator({
          url: 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer',
        });

        const webmap = new WebMap({ basemap });

        this.mapView = new MapView({ // eslint-disable-line
          map: webmap,
          container: 'miniMapViewDiv',
          zoom: 19,
          center: [lng, lat],
          ui: {
            components: [],
          },
        });


        const toggle = new BasemapToggle({
          view: this.mapView,
          nextBasemap: basemap === 'streets' ? 'hybrid' : 'streets',
        });

        toggle.on('toggle', (event) => {
          this.setState({ basemap: event.current.id })
        });

        this.mapView.ui.add(toggle, 'bottom-left');

        const point = {
          type: 'point', // autocasts as new Point()
          longitude: lng,
          latitude: lat,
        };

        const pointGraphic = new Graphic({
          geometry: point,
          symbol: MAP_UTILS.defaultMarker,
        });

        this.mapView.graphics.add(pointGraphic);

        this.mapView.on('click', (event) => {
          handleMapChange({
            location: {
              x: event.mapPoint.longitude,
              y: event.mapPoint.latitude,
            },
          });
        });

        this.mapView.constraints = {
          minZoom: 16,
          maxZoom: 20,
        };
      })
      .catch((err) => {
        console.log('err', err); // eslint-disable-line
      });
  }

  render() {
    return (
      <div
        style={{
          margin: '20px 0',
          width: '100%',
          height: '500px',
          position: 'relative',
          zIndex: 1,
        }}
      >
        <div
          id="miniMapViewDiv"
          style={{
            width: '100%',
            height: '100%',
            zIndex: 0,
          }}
        />
      </div>
    );
  }
}

export default MiniMap;
