import _ from 'lodash';
import * as C from 'actions/constants';

export const blankState = {
  loadingExperts: false,
  experts: [],
  expertsNext: '',
  loadingOrganizations: false,
  organizations: [],
  organizationsNext: '',
  loadingLocations: false,
  locations: [],
  locationsNext: '',
};

const SidebarReducer = (state = blankState, action) => {
  switch (action.type) {
    case C.LOAD_SIDEBAR_EXPERTEN: {
      return { ...state, loadingExperts: true };
    }
    case C.LOAD_SIDEBAR_ORGANIZATION: {
      return { ...state, loadingOrganizations: true };
    }
    case C.LOAD_SIDEBAR_LOCATION: {
      return { ...state, loadingLocations: true };
    }
    case C.LOAD_SIDEBAR_EXPERTEN_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        loadingExperts: false,
        experts: data,
      };
    }
    case C.LOAD_SIDEBAR_ORGANIZATIONS_SUCCESS: {
      const {
        next,
        results,
      } = action;

      return {
        ...state,
        loadingOrganizations: false,
        organizationsNext: next,
        organizations: results,
      };
    }
    case C.LOAD_SIDEBAR_LOCATIONS_SUCCESS: {
      const {
        next,
        results,
      } = action;

      return {
        ...state,
        loadingLocations: false,
        locationsNext: next,
        locations: results,
      };
    }
    case C.LOAD_MORE_SIDEBAR_LOCATIONS_SUCCESS: {
      const {
        next,
        results,
      } = action;

      return {
        ...state,
        loadingLocations: false,
        locationsNext: next,
        locations: [...state.locations, ...results],
      };
    }
    default:
      return state;
  }
};

export default SidebarReducer;
