import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
} from 'semantic-ui-react';
import ElementMedia from './ElementMedia';
import RichTextArea from '../../../../RichTextArea';

const VideoElement = ({ element, addElement, handleUpload, handleTextChange, updateOnUpload }) => (
  <Grid.Row>
    <Grid.Column mobile={16} tablet={4} computer={4}>
      <ElementMedia type="video" addElement={addElement} element={element} handleUpload={handleUpload} updateOnUpload={updateOnUpload} />
    </Grid.Column>
    <Grid.Column mobile={16} tablet={12} computer={12}>
      <RichTextArea
        style={{
          width: '100%',
          border: '1px solid rgba(34,36,38,.15)',
          padding: '10px',
        }}
        value={element.data.text}
        onChange={value => handleTextChange(element, value)}
      />
    </Grid.Column>
  </Grid.Row>
);

VideoElement.propTypes = {
  handleTextChange: PropTypes.func.isRequired,
  updateOnUpload: PropTypes.func.isRequired,
  handleUpload: PropTypes.func.isRequired,
  addElement: PropTypes.func.isRequired,
  element: PropTypes.object.isRequired, // eslint-disable-line
};

export default VideoElement;
