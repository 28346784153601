import ContentElements from './partials/ContentElements';
import Documents from './partials/Documents';
import FileIFC from './partials/FileIFC';
import Links from './partials/Links';
import RelatedLocations from './partials/RelatedLocations';
import LocationContentForm from './LocationContentForm';

export default {
  ContentElements,
  Documents,
  FileIFC,
  Links,
  RelatedLocations,
  LocationContentForm,
};
