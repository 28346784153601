import React from 'react';
import PropTypes from 'prop-types';
import {
  Input,
  Form,
} from 'semantic-ui-react';

const InputField = ({
  input,
  disabled,
  placeholder,
  type,
  icon,
  style,
  meta: {
    touched,
    error,
  },
}) => (
  <Form.Field style={style}>
    <Input {...input} disabled={disabled} placeholder={placeholder} type={type} icon={icon || null} />
    {touched && error && <span className='ui negative message mini'>{error}</span>}
  </Form.Field>
);

InputField.defaultProps = {
  type: 'text',
  disabled: false,
  icon: '',
  style: {},
};

InputField.propTypes = {
  style: PropTypes.object, // eslint-disable-line
  input: PropTypes.object, // eslint-disable-line
  placeholder: PropTypes.string.isRequired,
  type: PropTypes.string,
  icon: PropTypes.string,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
};

export default InputField;
