import React, { Component } from 'react';
import Breakpoint from 'react-socks';
import { Link, withRouter } from 'react-router-dom';
import {
  Grid,
  Menu,
  Dropdown,
  Icon,
} from 'semantic-ui-react';

import * as SRC from 'modules/styled';

import Logo from 'assets/images/logo.png';
import LogoSmall from 'assets/images/logo-small.png';
import UserDropdown from './partials/UserDropdown';
import Notifications from './partials/Notifications';

const NAVBAR_ENDPOINTS = {
  KARTE: '/app',
  COMMUNITY: '/app/community',
  TERMINE: '/app/events',
};

const LOGO = () => (
  <Link to="/app" style={{ display: 'flex', alignItems: 'center' }}>
    <Breakpoint medium up>
      <SRC.Logo small logo={Logo} style={{ marginLeft: '30px', marginRight: '40px' }} />
    </Breakpoint>
    <Breakpoint small down>
      <SRC.LogoMobile logo={LogoSmall} />
    </Breakpoint>
  </Link>
);

class NavBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeItem: 'KARTE',
    };

    this.handleItemClick = this.handleItemClick.bind(this);
  }

  handleItemClick(e, { name }) {
    const { history } = this.props;
    this.setState({ activeItem: name }, () => {
      history.push(NAVBAR_ENDPOINTS[name]);
    });
  }

  render() {
    const { activeItem } = this.state;

    return (
      <Grid style={{ zIndex: 3, marginLeft: 0, marginRight: 0 }}>
        <Grid.Column width={16} only="mobile" style={{ zIndex: 3 }}>
          <Breakpoint small down>
            <Menu
              secondary
              style={{
                backgroundColor: '#FFF',
                position: 'fixed',
                left: 0,
                top: 0,
                right: 0,
                margin: 0,
                borderBottom: '1px solid #dededf',
              }}>
              <Menu.Item
                as={LOGO}
              />

              <Menu.Menu position="right">
                <Menu.Item>
                  <Notifications mobile />
                </Menu.Item>
                <Menu.Item>
                  <UserDropdown />
                </Menu.Item>
                <Menu.Item>
                  <Dropdown icon={<Icon color="yellow" name="bars" size="large" />}>
                    <Dropdown.Menu style={{ marginTop: '5px' }}>
                      <Dropdown.Item name="KARTE" onClick={this.handleItemClick}>
                        Karte
                      </Dropdown.Item>
                      <Dropdown.Item name="COMMUNITY" onClick={this.handleItemClick}>
                        Community
                      </Dropdown.Item>
                      <Dropdown.Item name="TERMINE" onClick={this.handleItemClick}>
                        Termine
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </Menu.Item>
              </Menu.Menu>
            </Menu>
          </Breakpoint>
        </Grid.Column>
        <Grid.Column width={16} only="tablet computer" style={{ zIndex: 3 }}>
          <Breakpoint medium up>
            <Menu
              secondary
              style={{
                backgroundColor: '#FFF',
                zIndex: 3,
                position: 'fixed',
                left: 0,
                top: 0,
                right: 0,
                borderBottom: '1px solid #dededf',
              }}
            >
              <Menu.Item
                as={LOGO}
              />
              <Menu.Item name="KARTE" active={activeItem === 'karte'} onClick={this.handleItemClick} />
              <Menu.Item name="COMMUNITY" active={activeItem === 'community'} onClick={this.handleItemClick} />
              <Menu.Item name="TERMINE" active={activeItem === 'termine'} onClick={this.handleItemClick} />
              <Menu.Menu position="right">
                <Menu.Item>
                  <Notifications />
                </Menu.Item>
                <Menu.Item>
                  <UserDropdown />
                </Menu.Item>
              </Menu.Menu>
            </Menu>
          </Breakpoint>
        </Grid.Column>
      </Grid>
    );
  }
}

export default withRouter(NavBar)
