import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment'
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Grid,
  Icon,
  List,
  Header,
  Form,
  Image,
  Segment,
  Menu,
  Item,
  Loader,
  Button,
  Popup,
  Message,
  Label,
  Modal,
} from 'semantic-ui-react';
import ReactMarkdown from 'react-markdown'
import AuthHOC from 'containers/AuthHOC';
import { InfoItem, ExpertByteLabelLarge } from 'modules/styled/profile';
import ORGANIZATION_TYPES from 'utils/organization';
import UserTitleImage from 'modules/UserTitleImage';
import UserAvatarImage from 'modules/UserAvatarImage';
import LocationItem from 'modules/search/partials/LocationItem';
import EmptyState from 'modules/EmptyState';
import * as ORGANIZATION_ACTIONS from 'actions/organization';
import * as LOCATION_ACTIONS from 'actions/location';
import * as STATEAPI from 'utils/stateapi';
import * as USER_ACTIONS from 'actions/user';
import { nl2br } from 'utils/string';
import * as Alert from 'utils/alert';


class OrganizationProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 'Profil',
      deleteModalOpen: false,
    };

    this.handleItemClick = this.handleItemClick.bind(this);
    this.handleApplyClick = this.handleApplyClick.bind(this);
    this.handleApproveClick = this.handleApproveClick.bind(this);
    this.handleRejectClick = this.handleRejectClick.bind(this);
    this.handleAcceptInviteClick = this.handleAcceptInviteClick.bind(this);
    this.handleRejectInviteClick = this.handleRejectInviteClick.bind(this);
    this.handleKickMemberClick = this.handleKickMemberClick.bind(this);
    this.leaveOrganization = this.leaveOrganization.bind(this);
    this.deleteOrganization = this.deleteOrganization.bind(this);
    this.handleToggleDeleteModal = this.handleToggleDeleteModal.bind(this);
    this.handleUserClick = this.handleUserClick.bind(this);
    this.handleSetMemberAdmin = this.handleSetMemberAdmin.bind(this);
    this.handleRevokeMemberAdmin = this.handleRevokeMemberAdmin.bind(this);
    this.loadPageContent = this.loadPageContent.bind(this);
    this.handleLoadMoreLocations = this.handleLoadMoreLocations.bind(this);
  }

  componentDidMount() {
    const {
      changeBlocked,
      mapLocations,
      match: {
        params: {
          id,
        },
      },
      history: { location: { hash } },
    } = this.props;

    changeBlocked(true);

    if (!_.isEmpty(id)) {
      if (hash.includes('baupunkte')) {
        this.setState({ currentStep: 'Baupunkte' })
        mapLocations({ organization: id });
      } else {
        this.loadPageContent()

        if(hash.includes('mitglieder')) {
          this.setState({ currentStep: 'Mitglieder' })
        }
      }
    }
  }

  loadPageContent() {
    const {
      getMembers,
      loadInvites,
      get,
      match: {
        params: {
          id,
        },
      },
      userHasInvite,
    } = this.props;

    Promise.all([
      get(id),
      userHasInvite(id),
      getMembers(id)
    ]).then(() => loadInvites({ organization_id: id }));
  }

  handleItemClick(e, { name }) {
    const { mapLocations, organization, history } = this.props;
    this.setState({ currentStep: name });

    if (name === 'Baupunkte') {
      mapLocations({ organization: organization.id });
    } else {
      this.loadPageContent()
    }

    history.push(`/app/organizations/${organization.id}#${name.toLowerCase()}`)
  }

  handleApplyClick() {
    const { apply, userHasInvite, match: { params: { id } } } = this.props;

    apply({ organization_id: id })
      .then(() => {
        Alert.success('Du hast dich erfolgreich für die Organisation beworben!');
        userHasInvite(id);
      })
      .catch(({ status }) => {
        if (status === 406) {
          Alert.error('Der aktuelle Tarif dieser Organisation erlaubt keine weiteren Mitglieder.');
        } else {
          Alert.error('Anfrage fehlgeschlagen, bitte versuche es später noch einmal.');
        }
      });
  }

  handleApproveClick(id) {
    const { approve, organization, getMembers } = this.props;

    approve(id)
      .then(() => {
          Alert.success('Einladung wurde angenommen!');
          getMembers(organization.id);
        },
      );
  }

  handleRejectClick(id) {
    const { reject, organization, getMembers } = this.props;

    reject(id)
      .then(() => {
          Alert.success('Einladung wurde abgelehnt!');
          getMembers(organization.id)
        }
      );
  }

  handleAcceptInviteClick() {
    const { history: { push, location: { pathname } }, approve, hasInvite: { id }, refreshLocalStorage, userId } = this.props;

    approve(id)
      .then(() => refreshLocalStorage(userId))
      .then(() => {
        Alert.success('Einladung wurde akzeptiert!');
        push('/');
        push(pathname);
      });
  }

  handleRejectInviteClick() {
    const { history: { push, location: { pathname } }, reject, hasInvite: { id } } = this.props;

    reject(id)
      .then(() => {
        Alert.success('Einladung wurde abgelehnt!');
        push('/');
        push(pathname);
      });
  }

  handleKickMemberClick(memberId) {
    const { getMembers, organization, kickMember } = this.props;

    kickMember(memberId)
      .then(() => {
          Alert.success('Mitglied wurde erfolgreich entfernt!');
          getMembers(organization.id)
        }
      );
  }

  leaveOrganization() {
    const { history: { push, location: { pathname } }, leave, organization, refreshLocalStorage, userId } = this.props;

    leave(organization.id)
      .then(() => refreshLocalStorage(userId))
      .then(() => {
        Alert.success('Organisation erfolgreich verlassen!');
        push('/');
        push(pathname);
      });
  }

  deleteOrganization() {
    const { history: { push }, deleteOrganization, refreshLocalStorage, userId } = this.props;

    deleteOrganization()
      .then(() => refreshLocalStorage(userId))
      .then(() => {
        Alert.success('Organisation erfolgreich gelöscht!');
        push('/app/organizations/create');
      });
  }

  handleToggleDeleteModal() {
    const { deleteModalOpen } = this.state;

    this.setState({ deleteModalOpen: !deleteModalOpen });
  }

  handleUserClick(userId) {
    const { history } = this.props;

    history.push(`/app/users/${userId}`);
  }

  handleSetMemberAdmin(id) {
    const { patchOrganizationMember, organization, getMembers } = this.props;

    patchOrganizationMember(id, { is_admin: true })
      .then(() => {
          Alert.success('Administrationsrechte hinzugefügt!');
          getMembers(organization.id);
        },
      );
  }

  handleRevokeMemberAdmin(id) {
    const { patchOrganizationMember, organization, getMembers } = this.props;

    patchOrganizationMember(id, { is_admin: false })
      .then(() => {
          Alert.success('Administrationsrechte entfernt!');
          getMembers(organization.id);
        },
      );
  }

  handleLoadMoreLocations() {
    const { listLocations, listNext } = this.props;

    listLocations({ url: listNext });
  }

  render() {
    const { currentStep, deleteModalOpen } = this.state;
    const {
      history,
      userId,
      userOrganization,
      organization,
      loadingLocations,
      locations,
      members,
      isAdmin,
      invites,
      hasInvite,
      loading,
      invitedUsers,
      listNext
    } = this.props;
    const type = ORGANIZATION_TYPES.find(orgType => orgType.key === organization.type);
    let sameUser = userId === _.get(organization, 'user.id') || isAdmin;
    const isMember = !sameUser && !_.isEmpty(userOrganization) && userOrganization.id === _.get(organization, 'id');
    const invitesCount = sameUser && _.get(organization, 'invites_count', 0) || 0;
    const membersCount = _.get(organization, 'members_count', 0) || 0;
    const canApply = !sameUser; // && _.isEmpty(userOrganization);
    sameUser = sameUser || sameUser === false && isMember && _.get(userOrganization, 'is_admin', false);
    const hasValidInvite = !_.isEmpty(hasInvite) && hasInvite.type === 'invite';
    const alreadyApplied = !_.isEmpty(hasInvite) && hasInvite.type === 'apply';

    if (loading) {
      return <Loader active inline="centered" />
    }

    return _.isEmpty(organization) ? (
      <Grid centered style={{ zIndex: 2 }}>
        <Grid.Column computer={14} tablet={14} mobile={14} style={{ zIndex: 2 }}>
          <Segment attached="bottom" style={{ padding: '40px', display: 'relative' }}>
            <EmptyState header="Achtung" content="Die Organisation ist nicht mehr verfügbar" />
          </Segment>
        </Grid.Column>
      </Grid>
    ) : (
      <Grid centered style={{ zIndex: 2 }}>
      <Modal size="mini" open={deleteModalOpen}>
        <Modal.Header>Organisation löschen?</Modal.Header>
        <Modal.Content>
          <p>Bist du sicher, dass du diese Organisation unwiderruflich löschen willst? Du verlierste jegliche zugeordnete Baupunkte und alle Mitglieder!</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={this.handleToggleDeleteModal}>Nein</Button>
          <Button negative content="Ja" onClick={this.deleteOrganization} />
        </Modal.Actions>
      </Modal>
      <Grid.Column computer={14} tablet={14} mobile={14} style={{ zIndex: 2 }}>
        <Menu attached="top" tabular>
          <Menu.Item name="Profil" active={currentStep === 'Profil'} onClick={this.handleItemClick} />
          <Menu.Item name="Mitglieder" active={currentStep === 'Mitglieder'} onClick={this.handleItemClick}>
            Mitglieder
            {sameUser && (invites.length > 0 || invitesCount > 0) && (
              <Label color="red">{invites.length > 0 ? invites.length : invitesCount}</Label>
            )}
          </Menu.Item>
          <Menu.Item name="Baupunkte" active={currentStep === 'Baupunkte'} onClick={this.handleItemClick} />
        </Menu>

        <Segment attached="bottom" style={{ padding: '20px', display: 'relative' }}>
          <div style={{ position: 'absolute', right: '-25px', top: '40px', width: '49px' }}>
            {sameUser && (
              <Popup
                trigger={(
                  <Button
                    size="mini"
                    color="blue"
                    onClick={() => history.push(`/app/organizations/create/data/${organization.id}`)}
                  >
                    <Button.Content visible>
                      <Icon name="pencil" />
                    </Button.Content>
                  </Button>
                )}
                content="Organisation bearbeiten"
              />
            )}
            {sameUser && (
              <Popup
                trigger={(
                  <Button
                    size="mini"
                    color="red"
                    style={{ margin: '10px 0' }}
                    onClick={this.handleToggleDeleteModal}
                  >
                    <Button.Content visible>
                      <Icon name="trash" />
                    </Button.Content>
                  </Button>
                )}
                content="Organisation löschen"
              />
            )}
            {!hasValidInvite && canApply && (
              <Popup
                trigger={(
                  <Button
                    disabled={alreadyApplied}
                    size="mini"
                    color="blue"
                    onClick={this.handleApplyClick}
                  >
                    <Button.Content visible>
                      <Icon name="user plus" />
                    </Button.Content>
                  </Button>
                )}
                content="Dieser Organisation beitreten"
              />
            )}
            {hasValidInvite && canApply && (
              <Popup
                trigger={(
                  <Button
                    size="mini"
                    color="green"
                    onClick={this.handleAcceptInviteClick}
                  >
                    <Button.Content visible>
                      <Icon name="check circle" />
                    </Button.Content>
                  </Button>
                )}
                content="Einladung akzeptieren"
              />
            )}
            {hasValidInvite && canApply && (
              <Popup
                trigger={(
                  <Button
                    size="mini"
                    color="red"
                    onClick={this.handleRejectInviteClick}
                    style={{ marginTop: '10px' }}
                  >
                    <Button.Content visible>
                      <Icon name="times circle" />
                    </Button.Content>
                  </Button>
                )}
                content="Einladung ablehnen"
              />
            )}
            {isMember && (
              <Popup
                trigger={(
                  <Button
                    size="mini"
                    color="red"
                    onClick={this.leaveOrganization}
                    style={{ marginTop: '10px' }}
                  >
                    <Button.Content visible>
                      <Icon name="external alternate" />
                    </Button.Content>
                  </Button>
                )}
                content="Organisation verlassen"
              />
            )}
          </div>
          {currentStep === 'Profil' && (
            <div>
              <Form>
                <Grid centered style={{ marginTop: '20px' }}>
                  <Grid.Column width={15}>
                    {!hasValidInvite && canApply && alreadyApplied && (
                      <Message
                        visible
                        warning
                        icon='send'
                        header='Einladung zum Beitritt verschickt'
                        content='Ein Administrator dieser Organisation wurde benachricht und kann deinen Beitritt bestätigen.'
                      />
                    )}
                    <UserTitleImage editing={false} imageUrl={organization.title_image_url} />
                  </Grid.Column>
                </Grid>

                <Grid centered>
                  <Grid.Column width={15}>
                    <Grid>
                      <Grid.Column mobile={15} tablet={3} computer={4} widescreen={3}>
                        <UserAvatarImage editing={false} imageUrl={organization.logo_url_thumbnail} />
                      </Grid.Column>
                      <Grid.Column mobile={15} tablet={15} computer={12} widescreen={13}>
                        <Grid.Row>
                          <Grid>
                            <Grid.Column mobile={16} tablet={16} computer={10}>
                              <Header as="h2">{organization.name} <Header.Subheader>{_.get(type, 'text')}</Header.Subheader></Header>
                              <Grid.Row style={{ marginTop: '20px' }}>
                                <p>{organization.street}, {organization.city}</p>
                                <ReactMarkdown>{_.get(organization, 'about', '')}</ReactMarkdown>
                              </Grid.Row>
                            </Grid.Column>
                            <Grid.Column mobile={16} tablet={16} computer={6} style={{ marginBottom: '15px' }}>
                              <ExpertByteLabelLarge
                                expertBytes={_.get(organization, 'total_expert_bytes')}
                              />
                              {membersCount > 0 && (
                                <Segment>
                                  Der {organization.name} sind <strong>{membersCount} Mitarbeiter</strong> auf localexpert24 zugehörig
                                </Segment>
                              )}
                            </Grid.Column>
                          </Grid>
                        </Grid.Row>
                      </Grid.Column>
                    </Grid>
                  </Grid.Column>
                </Grid>

                <Grid centered style={{ marginTop: '50px' }}>
                  <Grid.Column width={14}>
                    <Grid.Row>
                      <Header as="h2">Allgemeine Informationen</Header>
                    </Grid.Row>
                    <Grid.Row style={{ marginTop: '15px' }}>
                      <Grid>
                        <InfoItem
                          colProps={{ mobile: 15, tablet: 5, computer: 4, widescreen: 2 }}
                          title="Mitarbeiter"
                          value={organization.number_of_employees}
                        />
                        <InfoItem
                          colProps={{ mobile: 15, tablet: 5, computer: 4, widescreen: 2 }}
                          title="Gründungsjahr"
                          value={organization.year_established}
                        />
                      </Grid>
                    </Grid.Row>
                  </Grid.Column>
                </Grid>

                <Grid centered style={{ marginTop: '50px' }}>
                  <Grid.Column width={14}>
                    <Grid.Row>
                      <Header as="h2">Kontakt-Informationen</Header>
                    </Grid.Row>
                    <Grid.Row style={{ marginTop: '15px' }}>
                      <Grid>
                        <InfoItem
                          colProps={{ mobile: 15, tablet: 5, computer: 4, widescreen: 2 }}
                          title="Festnetz"
                          value={organization.phone}
                        />
                        <InfoItem
                          colProps={{ mobile: 15, tablet: 5, computer: 4, widescreen: 2 }}
                          title="Fax"
                          value={organization.fax}
                        />
                        <InfoItem
                          colProps={{ mobile: 15, tablet: 5, computer: 7, widescreen: 5 }}
                          title="E-Mail"
                          link
                          href={`mailto:${organization.email}`}
                          value={organization.email}
                        />
                      </Grid>
                    </Grid.Row>
                    <Grid.Row style={{ marginTop: '15px' }}>
                      <Grid>
                        <InfoItem
                          colProps={{ mobile: 15, tablet: 5, computer: 4, widescreen: 2 }}
                          title="Xing"
                          link
                          value={organization.xing_url}
                          displayName="Profil auf XING ansehen"
                        />
                        <InfoItem
                          colProps={{ mobile: 15, tablet: 5, computer: 4, widescreen: 2 }}
                          title="LinkedIn"
                          link
                          value={organization.linkedin_url}
                          displayName="Profil auf LinkedIn ansehen"
                        />
                        <InfoItem
                          colProps={{ mobile: 15, tablet: 5, computer: 4, widescreen: 2 }}
                          title="Kununu"
                          link
                          value={organization.kununu_url}
                          displayName="Profil auf Kununu ansehen"
                        />
                        <InfoItem
                          colProps={{ mobile: 15, tablet: 5, computer: 3 }}
                          title="Website"
                          link
                          value={organization.website_url}
                        />
                      </Grid>
                    </Grid.Row>
                  </Grid.Column>
                </Grid>
              </Form>
            </div>
          )}
          {currentStep === 'Mitglieder' && (
            <div>
              {!_.isEmpty(_.get(organization, 'user')) && (
                <div>
                  <Header as="h2">Administrator</Header>
                  <Item.Group divided link>
                    <Item>
                      <Image
                        src={!_.isEmpty(organization.user.avatar_image_url) ? organization.user.avatar_thumbnail_url : 'https://react.semantic-ui.com/images/wireframe/square-image.png'}
                        circular
                        style={{ width: '56px', height: '56px' }}
                      />
                      <Item.Content style={{ marginLeft: '10px' }} onClick={() => this.handleUserClick(organization.user.id)}>
                        <Grid>
                          <Grid.Column width={16}>
                            <Item.Header as="p">{organization.user.first_name} {organization.user.last_name}</Item.Header>
                            <Item.Extra>
                              <List horizontal>
                                <List.Item>
                                  <Icon name="ticket" /> {organization.user.expert_bytes}
                                </List.Item>
                                <List.Item>
                                  <Icon name="map marker" /> {organization.user.locations_count}
                                </List.Item>
                              </List>
                            </Item.Extra>
                          </Grid.Column>
                        </Grid>
                      </Item.Content>
                    </Item>
                  </Item.Group>
                </div>
              )}

              {!_.isEmpty(invites) && (
                <div style={{ marginTop: '60px' }}>
                  <Header as="h2">
                    Offene Bewerbungen
                    {invites.length > 0 && (
                      <Label color='red'>{invites.length}</Label>
                    )}
                  </Header>
                  <Item.Group divided link>
                    {invites.filter(i => i.type === 'apply').map(invite => (
                      <Item key={invite.id}>
                        <Image
                          src={!_.isEmpty(invite.user.avatar_image_url) ? invite.user.avatar_thumbnail_url : 'https://react.semantic-ui.com/images/wireframe/square-image.png'}
                          circular
                          style={{ width: '56px', height: '56px' }}
                        />
                        <Item.Content style={{ marginLeft: '10px' }}>
                          <Grid>
                            <Grid.Column width={10} onClick={() => this.handleUserClick(invite.user.id)}>
                              <Item.Header as="a">{invite.user.first_name} {invite.user.last_name}</Item.Header>
                              <Item.Extra>
                                <List horizontal>
                                  <List.Item>
                                    <Icon name="ticket" /> {invite.user.expert_bytes}
                                  </List.Item>
                                  <List.Item>
                                    <Icon name="map marker" /> {invite.user.locations_count}
                                  </List.Item>
                                </List>
                              </Item.Extra>
                            </Grid.Column>
                            <Grid.Column width={5} textAlign="right">
                              <Button
                                color='green'
                                style={{ marginRight: '10px' }}
                                onClick={() => this.handleApproveClick(invite.id)}
                              >
                                <Icon name="check circle" /> Annehmen
                              </Button>
                              <Button
                                color='red'
                                onClick={() => this.handleRejectClick(invite.id)}
                              >
                                <Icon name="times circle" /> Ablehnen
                              </Button>
                            </Grid.Column>
                          </Grid>
                        </Item.Content>
                      </Item>
                    ))}
                  </Item.Group>
                </div>
              )}

              {!_.isEmpty(invitedUsers) && (
                <div>
                  <Header as="h2" style={{ marginTop: '60px' }}>
                    Einladungen
                  </Header>
                  <Item.Group divided link>
                    {invitedUsers.map(invite => _.isEmpty(invite.user) ? (
                      <Item key={invite.email}>
                        <Image
                          src={'https://react.semantic-ui.com/images/wireframe/square-image.png'}
                          circular
                          style={{ width: '56px', height: '56px' }}
                        />
                        <Item.Content style={{ marginLeft: '10px' }}>
                          <Grid>
                            <Grid.Column width={10}>
                              <Item.Header as="p">{invite.email}</Item.Header>
                              <Item.Extra>
                                <List horizontal>
                                  <List.Item>
                                    <Icon name="calendar" /> {moment(invite.created_at).format('DD.MM.YYYY')}
                                  </List.Item>
                                </List>
                              </Item.Extra>
                            </Grid.Column>
                          </Grid>
                        </Item.Content>
                      </Item>
                    ) : (
                      <Item key={invite.email}>
                        <Image
                          src={!_.isEmpty(invite.user.avatar_image_url) ? invite.user.avatar_thumbnail_url : 'https://react.semantic-ui.com/images/wireframe/square-image.png'}
                          circular
                          style={{ width: '56px', height: '56px' }}
                        />
                        <Item.Content style={{ marginLeft: '10px' }}>
                          <Grid>
                            <Grid.Column width={10} onClick={() => this.handleUserClick(invite.user.id)}>
                              <Item.Header as="p">{invite.user.first_name} {invite.user.last_name}</Item.Header>
                              <Item.Extra>
                                <List horizontal>
                                  <List.Item>
                                    <Icon name="ticket" /> {invite.user.expert_bytes}
                                  </List.Item>
                                  <List.Item>
                                    <Icon name="map marker" /> {invite.user.locations_count}
                                  </List.Item>
                                  <List.Item>
                                    <Icon name="calendar" /> {moment(invite.created_at).format('DD.MM.YYYY')}
                                  </List.Item>
                                </List>
                              </Item.Extra>
                            </Grid.Column>
                          </Grid>
                        </Item.Content>
                      </Item>
                    ))}
                  </Item.Group>
                </div>
              )}
              <Header as="h2" style={{ marginTop: '60px' }}>
                Mitglieder
                {sameUser && (
                  <div style={{ marginBottom: '20px', float: 'right' }}>
                    <Button
                      size="medium"
                      color="blue"
                      right
                      onClick={() => history.push(`/app/organizations/create/data/${organization.id}/invite`)}
                    >
                      <Icon name="user add" />
                      Mitglieder einladen
                    </Button>
                  </div>
                )}
              </Header>
              {!_.isEmpty(members) ? (
                <Item.Group divided link>
                  {members.map(member => (
                    <Item key={member.id}>
                      <Image
                        src={!_.isEmpty(member.user.avatar_image_url) ? member.user.avatar_thumbnail_url : 'https://react.semantic-ui.com/images/wireframe/square-image.png'}
                        circular
                        style={{ width: '56px', height: '56px' }}
                      />
                      <Item.Content style={{ marginLeft: '10px' }}>
                        <Grid>
                          <Grid.Column width={10} onClick={() => this.handleUserClick(member.user.id)}>
                            <Item.Header as="p">{member.user.first_name} {member.user.last_name}</Item.Header>
                            <Item.Extra>
                              <List horizontal>
                                <List.Item>
                                  <Icon name="ticket" /> {member.user.expert_bytes}
                                </List.Item>
                                <List.Item>
                                  <Icon name="map marker" /> {member.user.locations_count}
                                </List.Item>
                              </List>
                            </Item.Extra>
                          </Grid.Column>
                          {sameUser && (
                            <Grid.Column width={6} textAlign="right">
                              <Button
                                color={member.is_admin ? 'red' : 'green'}
                                onClick={() => member.is_admin ? this.handleRevokeMemberAdmin(member.id) : this.handleSetMemberAdmin(member.id)}
                              >
                                <Button.Content visible>
                                  {member.is_admin ? (
                                    "Adminrechte entfernen"
                                    ) : (
                                    "Adminrechte vergeben"
                                  )}
                                </Button.Content>
                              </Button>

                              <Popup
                                trigger={(
                                  <Button
                                    color='red'
                                    onClick={() => this.handleKickMemberClick(member.id)}
                                  >
                                    <Button.Content visible>
                                      <Icon name="trash" />
                                    </Button.Content>
                                  </Button>
                                )}
                                content="Mitglied aus Organisation entfernen"
                              />
                            </Grid.Column>
                          )}
                        </Grid>
                      </Item.Content>
                    </Item>
                  ))}
                </Item.Group>
              ) : (
                <EmptyState header="Keine Mitglieder vorhanden" content="Diese Organisation hat noch keine Mitglieder" />
              )}
              {sameUser && (
                <p><Icon name="info circle" />Du kannst einzelnen Mitgliedern Adminrechte vergeben. Diese haben dann die Möglichkeit das Organisationsprofil zu bearbeiten und weitere Mitglieder einzuladen.</p>
              )}
            </div>
          )}
          {currentStep === 'Baupunkte' && (
            <Item.Group divided link style={{ textAlign: 'left' }}>
              {!_.isEmpty(locations) && locations.map(location => (
                <LocationItem key={location.id} data={location} />
              ))}
              {_.isEmpty(locations) && !loadingLocations && (
                <EmptyState header="Keine Baupunkte vorhanden" content="Die Mitglieder dieser Organisation haben noch keine Baupunkte erstellt" />
              )}

              {loadingLocations && <Loader active inline="centered" />}

              {!_.isEmpty(listNext) && (
                <div style={{ marginTop: '40px', display: 'block', textAlign: 'center' }}>
                  <Button color="blue" onClick={this.handleLoadMoreLocations}>Mehr anzeigen</Button>
                </div>
              )}
            </Item.Group>
          )}
        </Segment>
      </Grid.Column>
    </Grid>
    )
  }
}

OrganizationProfile.defaultProps = {
  userId: '',
  members: [],
  invites: [],
};

OrganizationProfile.propTypes = {
  changeBlocked: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  userId: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
  loadingLocations: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  mapLocations: PropTypes.func.isRequired,
  getMembers: PropTypes.func.isRequired,
  approve: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired,
  deleteOrganization: PropTypes.func.isRequired,
  patchOrganizationMember: PropTypes.func.isRequired,
  listNext: PropTypes.string,
  locations: PropTypes.array, // eslint-disable-line
  members: PropTypes.array, // eslint-disable-line
  invites: PropTypes.array, // eslint-disable-line
  invitedUsers: PropTypes.array, // eslint-disable-line
  match: PropTypes.object.isRequired, // eslint-disable-line
  history: PropTypes.object.isRequired, // eslint-disable-line
  organization: PropTypes.object.isRequired, // eslint-disable-line
};

function mapStateToProps(state) {
  const organization = _.get(state, 'organization.data', {});
  const userOrganization = !_.isEmpty(_.get(state, 'session.user.organization')) ? _.get(state, 'session.user.organization') : _.get(state, 'session.user.organization_member');

  return {
    userId: _.get(state, 'session.user.id'),
    isAdmin: STATEAPI.isAdmin(state),
    userOrganization,
    members: _.get(state, 'organization.members'),
    invites: _.get(state, 'organization.invites').filter(i => i.type !== 'invite'),
    invitedUsers: _.get(state, 'organization.invites').filter(i => i.type === 'invite'),
    hasInvite: _.get(state, 'organization.hasInvite'),
    organization,
    loadingLocations: state.location.loading,
    loading: state.organization.loading,
    locations: state.location.list.data,
    listNext: state.location.list.next,
  };
}

const mapDispatchToProps = {
  get: ORGANIZATION_ACTIONS.get,
  mapLocations: LOCATION_ACTIONS.list,
  apply: ORGANIZATION_ACTIONS.apply,
  getMembers: ORGANIZATION_ACTIONS.members,
  approve: ORGANIZATION_ACTIONS.approve,
  reject: ORGANIZATION_ACTIONS.reject,
  userHasInvite: ORGANIZATION_ACTIONS.hasInvite,
  kickMember: ORGANIZATION_ACTIONS.kick,
  leave: ORGANIZATION_ACTIONS.leave,
  loadInvites: ORGANIZATION_ACTIONS.invites,
  deleteOrganization: ORGANIZATION_ACTIONS.deleteOrganization,
  refreshLocalStorage: USER_ACTIONS.refreshLocalStorage,
  patchOrganizationMember: ORGANIZATION_ACTIONS.patchOrganizationMember,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthHOC(OrganizationProfile));
