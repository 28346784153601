import * as C from 'actions/constants';

export const blankState = {
  data: {},
  list: [],
  count: 0,
  next: '',
  loading: false,
  invitesModal: false,
};

const UserReducer = (state = blankState, action) => {
  switch (action.type) {
    case C.GET_USER:
    case C.PATCH_USER:
    case C.LIST_USERS:
    case C.REGISTER_USER: {
      return { ...state, loading: true };
    }
    case C.REGISTER_USER_SUCCESS: {
      return { ...state, loading: false };
    }
    case C.GET_USER_SUCCESS:
    case C.PATCH_USER_SUCCESS: {
      const { data } = action;
      return { ...state, loading: false, data };
    }
    case C.LIST_USERS_SUCCESS: {
      const { count, next, results } = action;
      return {
        ...state,
        loading: false,
        list: results,
        count,
        next,
      };
    }
    case C.LOAD_MORE_USERS_SUCCESS: {
      const { count, next, results } = action;
      const { list } = state;
      return {
        ...state,
        loading: false,
        list: [...list, ...results],
        count,
        next,
      };
    }
    case C.GET_USER_ERROR:
    case C.PATCH_USER_ERROR:
    case C.LIST_USERS_ERROR:
    case C.REGISTER_USER_ERROR: {
      return { ...state, loading: false };
    }
    case C.OPEN_INVITES_MODAL: {
      return { ...state, invitesModal: true };
    }
    case C.CLOSE_INVITES_MODAL: {
      return { ...state, invitesModal: false };
    }
    default:
      return state;
  }
};

export default UserReducer;
