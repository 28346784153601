import * as C from 'actions/constants';

export const blankState = {
  counts: {},
  latest: {},
  loading: true,
};

const CommunityReducer = (state = blankState, action) => {
  switch (action.type) {
    case C.LOAD_COMMUNITY_STATS: {
      return { ...state, loading: true };
    }
    case C.LOAD_COMMUNITY_STATS_SUCCESS: {
      const { data } = action;
      return { ...state, loading: false, ...data };
    }
    default:
      return state;
  }
};

export default CommunityReducer;
