import React, { Component } from 'react';
import uuid from 'uuid';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  Grid,
  Header,
  Button,
  Segment,
} from 'semantic-ui-react';
import ElementWrapper from './partials/ElementWrapper';

class ContentElements extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: props.data,
      previewUuid: '',
    };

    this.addElement = this.addElement.bind(this);
    this.removeElement = this.removeElement.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handlePositionChange = this.handlePositionChange.bind(this);
    this.handleSelectPreview = this.handleSelectPreview.bind(this);
    this.handleRotate = this.handleRotate.bind(this);
    this.updateOnUpload = this.updateOnUpload.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { updateContentElements } = this.props;
    const { data } = this.state;
    const dataUpdated = JSON.stringify(data) !== JSON.stringify(prevState.data);

    if (dataUpdated) {
      updateContentElements(data);
    }
  }

  handleSelectPreview(element, checked) {
    const { data } = this.state;
    const newData = [...data];

    if (checked) {
      this.setState({ previewUuid: element.uuid });
    } else {
      this.setState({ previewUuid: '' });
    }

    newData.splice(element.position, 1, Object.assign(
      {}, element, { preview: checked },
    ));

    this.setState({ data: newData });
  }

  handlePositionChange(element, direction) {
    const { data } = this.state;
    const limit = data.length - 1;
    const currentPosition = element.position;

    if ((currentPosition === 0 && direction === 'up') || (currentPosition === limit && direction === 'down')) {
      return;
    }

    const newData = [...data];
    let newPosition;

    if (direction === 'up') {
      newPosition = currentPosition - 1;
      newData.splice(newPosition, 0, newData.splice(currentPosition, 1)[0]);
    }

    if (direction === 'down') {
      newPosition = currentPosition + 1;
      newData.splice(newPosition, 0, newData.splice(currentPosition, 1)[0]);
    }

    newData.splice(currentPosition, 1, Object.assign(
      {}, newData[currentPosition], { position: currentPosition },
    ));
    newData.splice(newPosition, 1, Object.assign(
      {}, newData[newPosition], { position: newPosition },
    ));

    this.setState({ data: newData });
  }

  handleTextChange(element, text) {
    const { data } = this.state;
    const newData = [...data];

    newData.splice(element.position, 1, Object.assign(
      {}, element, { data: { ...element.data, text } },
    ));

    this.setState({ data: newData });
  }

  handleRotate(element, direction) {
    const { handleRotateContent } = this.props;

    return handleRotateContent(element, direction)
      .then(({ data: newElement }) => {
        if (_.isEmpty(newElement)) {
          return;
        }

        const { data } = this.state;
        const newData = [...data];
        newData.splice(element.position, 1, newElement);
        this.setState({ data: newData });
      });
  }

  addElement(type) {
    const { data } = this.state;
    const firstPreviewImage = data.find(d => d.type === 'image' && d.preview === true);
    const preview = type === 'image' && _.isEmpty(firstPreviewImage);

    const element = {
      uuid: uuid.v4(),
      type,
      position: data.length,
      preview,
      data: {
        text: '',
        url: '',
      },
    };

    this.setState({ data: [...data, element] });

    return element
  }

  removeElement(element) {
    const { data } = this.state;
    const resetPreview = element.preview ? {
      previewUuid: '',
    } : {};
    let index = 0;
    const newData = data.filter(item => item.uuid !== element.uuid).map((item) => {
      const newItem = {
        ...item,
        position: index,
      };

      index += 1;

      return newItem;
    });

    this.setState({
      data: newData,
      ...resetPreview,
    });
  }

  updateOnUpload(element, url, thumbnail_url) {
    const { data } = this.state;
    const newData = [...data];

    newData.splice(element.position, 1, Object.assign(
      {}, element, { data: { ...element.data, url, thumbnail_url } },
    ));

    this.setState({ data: newData });
  }

  renderButtons() {
    return (
      <Grid.Row style={{ textAlign: 'center' }}>
        <strong>Inhalte hinzufügen:</strong>
        <Button.Group
          style={{
            marginLeft: '20px',
          }}
        >
          <Button size='large' icon="align left" onClick={() => this.addElement('text')} />
          <Button size='large' icon="camera" onClick={() => this.addElement('image')} />
          <Button size='large' icon="play circle" onClick={() => this.addElement('video')} />
        </Button.Group>
      </Grid.Row>
    );
  }

  render() {
    const { data, previewUuid } = this.state;

    const { handleUpload, handleDelete } = this.props;
    const props = {
      previewUuid,
      handleTextChange: this.handleTextChange,
      handlePositionChange: this.handlePositionChange,
      removeElement: this.removeElement,
      handleSelectPreview: this.handleSelectPreview,
      updateOnUpload: this.updateOnUpload,
      addElement: this.addElement,
      handleUpload,
      handleDelete,
    };

    const content = _.isEmpty(data) ? (
      <Segment placeholder textAlign="center">Bitte wähle aus, welche Inhalte du hinzufügen möchtest.<hr/>{this.renderButtons()}</Segment>
    ) : (
      <div>
        {data.map((element) => {
          const firstImagePosition = _.isEmpty(previewUuid) && _.get(data.find(el => el.type === 'image'), 'position');
          return (
            <ElementWrapper
              firstImage={firstImagePosition === element.position}
              key={element.position}
              element={element}
              handleRotate={this.handleRotate}
              {...props}
            />
          );
        })}
        {this.renderButtons()}
      </div>
    );

    return (
      <div>
        {content}
      </div>
    );
  }
}

ContentElements.defaultProps = {
  data: [],
};

ContentElements.propTypes = {
  data: PropTypes.array, // eslint-disable-line
  handleUpload: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  handleRotateContent: PropTypes.func.isRequired,
  updateContentElements: PropTypes.func.isRequired,
};

export default ContentElements;
