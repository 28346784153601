import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import _ from 'lodash';
import difference from 'utils/difference';
import * as USER_ACTIONS from 'actions/user';
import * as ORGANIZATION_ACTIONS from 'actions/organization';
import * as STATEAPI from 'utils/stateapi';
import RegisterForm from 'modules/forms/RegisterForm';

class UserDetailFormContainer extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.approveInvite = this.approveInvite.bind(this);
    this.rejectInvite = this.rejectInvite.bind(this);
  }

  componentDidMount() {
    const {
      getUser,
      userId,
      loadInvites,
      userEmail,
    } = this.props;

    Promise.all([
      getUser(userId),
      loadInvites({ user_id: userId, email: userEmail }),
    ]);
  }

  handleSubmit() {
    const {
      patchUser,
      userId,
      initialValues,
      formData,
      applyToOrganization,
      refreshLocalStorage,
      sessionUserId,
      history,
      handleSubmit,
      match: {
        params: {
          id,
        },
      },
    } = this.props;
    const diff = difference(_.omit(formData, ['organization']), initialValues);
    const organizationId = _.get(formData, 'organization');

    patchUser(userId, diff)
      .then(() => !_.isEmpty(organizationId) ? applyToOrganization({ organization_id: organizationId }) : null)
      .then(() => {
        if (sessionUserId === id) {
          refreshLocalStorage(userId);
        }
      })
      .then(() => {
        if (handleSubmit) {
          handleSubmit();
        } else {
          history.push(`/app/users/${userId}`);
        }
      });
  }

  approveInvite(invite) {
    const {
      approve,
      refreshLocalStorage,
      loadInvites,
      sessionUserId,
      match: {
        params: {
          id,
        },
      },
    } = this.props;

    approve(invite.id)
      .then(() => id === sessionUserId && refreshLocalStorage(sessionUserId))
      .then(() => loadInvites(invite.organization));
  }

  rejectInvite(invite) {
    const { reject, loadInvites } = this.props;

    reject(invite.id)
      .then(() => loadInvites(invite.organization));
  }

  render() {
    const {
      loading,
      initialValues,
      showFooter,
      hasOrganization,
      invites,
    } = this.props;

    return _.isEmpty(initialValues) ? <Loader active inline="centered" /> : (
      <RegisterForm
        showFooter={showFooter}
        loading={loading}
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
        hasOrganization={hasOrganization}
        invites={invites}
        approveInvite={this.approveInvite}
        rejectInvite={this.rejectInvite}
      />
    );
  }
}

UserDetailFormContainer.defaultProps = {
  initialValues: {},
  showFooter: true,
  invites: [],
};

UserDetailFormContainer.propTypes = {
  getUser: PropTypes.func.isRequired,
  patchUser: PropTypes.func.isRequired,
  applyToOrganization: PropTypes.func.isRequired,
  loadInvites: PropTypes.func.isRequired,
  approve: PropTypes.func.isRequired,
  reject: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  showFooter: PropTypes.bool,
  hasOrganization: PropTypes.bool,
  userId: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  sessionUserId: PropTypes.string.isRequired,
  invites: PropTypes.array, // eslint-disable-line
  initialValues: PropTypes.object, // eslint-disable-line
  formData: PropTypes.object, // eslint-disable-line
};

function mapStateToProps(state, props) {
  const fields = [
    'about_me',
    'avatar_image_url',
    'avatar_thumbnail_url',
    'birth_date',
    'core_qualification',
    'first_name',
    'last_name',
    'lat',
    'lng',
    'postalcode',
    'full_address',
    'gender',
    'level_of_education',
    'position',
    'title_image_url',
    'xing_url',
    'linkedin_url',
    'website_url',
    'email',
    'phone',
    'phone_mobile',
    'recommended_links',
    'focuses',
    'qualifications',
    'user_interests',
    'specializations',
    'focuses_pretty',
    'qualifications_pretty',
    'user_interests_pretty',
    'specializations_pretty',
  ];

  const {
    match: {
      params: {
        id,
      },
    },
  } = props;
  const isAdmin = STATEAPI.isAdmin(state);
  const userId = isAdmin ? id : _.get(state.session, 'user.id', '');
  const user = isAdmin ? _.get(state, 'user.data', {}) : _.get(state, 'session.user', {});
  const organization = _.get(user, 'organization');
  const organizationMember = _.get(user, 'organization_member');

  return {
    sessionUserId: _.get(state, 'session.user.id', ''),
    loading: state.user.loading,
    userId,
    userEmail: _.get(user, 'email', ''),
    initialValues: _.omitBy(_.pick(user, fields), _.isEmpty),
    formData: _.get(state, 'form.userDetailForm.values', {}),
    hasOrganization: !_.isEmpty(organization) || !_.isEmpty(organizationMember),
    invites: _.get(state, 'organization.invites', []),
  };
}

const mapDispatchToProps = {
  getUser: USER_ACTIONS.get,
  patchUser: USER_ACTIONS.patch,
  refreshLocalStorage: USER_ACTIONS.refreshLocalStorage,
  applyToOrganization: ORGANIZATION_ACTIONS.apply,
  loadInvites: ORGANIZATION_ACTIONS.invites,
  approve: ORGANIZATION_ACTIONS.approve,
  reject: ORGANIZATION_ACTIONS.reject,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserDetailFormContainer));
