import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  Grid,
  Icon,
  List,
  Header,
  Loader,
  Form,
  Segment,
  Label,
  Button,
  Menu,
  Image,
  Item,
  Popup,
} from 'semantic-ui-react';
import moment from 'moment';
import ReactMarkdown from 'react-markdown'
import AuthHOC from 'containers/AuthHOC';
import { InfoItem, RankLabel, PremiumLabel, ExpertByteLabelLarge } from 'modules/styled/profile';
import UserTitleImage from 'modules/UserTitleImage';
import UserAvatarImage from 'modules/UserAvatarImage';
import LocationItem from 'modules/search/partials/LocationItem';
import EmptyState from 'modules/EmptyState';
import * as USER_CONSTS from 'const/user';
import * as USER_ACTIONS from 'actions/user';
import * as LOCATION_ACTIONS from 'actions/location';
import * as STATEAPI from 'utils/stateapi';
import { nl2br } from 'utils/string';
import ORGANIZATION_TYPES from 'utils/organization';

class UserProfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 'Profil',
    };

    this.handleItemClick = this.handleItemClick.bind(this);
    this.handleUserClick = this.handleUserClick.bind(this);
    this.handleLoadMoreLocations = this.handleLoadMoreLocations.bind(this);
  }

  componentDidMount() {
    const { changeBlocked, listLocations, getUser, match: { params: { id } }, history: { location: { hash } } } = this.props;

    changeBlocked(true);

    if (_.isEmpty(id)) {
      return;
    }

    if(hash.includes('baupunkte')) {
      this.setState({ currentStep: 'Baupunkte' })
      listLocations({ user: id });
    } else {
      getUser(id)
        .then(() => {
          if(hash.includes('profilbesucher')) {
            this.setState({ currentStep: 'Profilbesucher' })
          }
        })
    }
  }

  handleUserClick(userId) {
    const { history } = this.props;

    history.push(`/app/users/${userId}`);
  }

  componentDidUpdate(prevProps) {
    const { match: { params: { id: prevId } } } = prevProps;
    const { getUser, match: { params: { id } }, history: { location: { hash } } } = this.props;

    if (prevId !== id) {
      getUser(id)
        .then(() => {
          if (hash.includes('profilbesucher')) {
            this.setState({ currentStep: 'Profilbesucher' })
          } else {
            this.setState({
              currentStep: 'Profil',
            });
          }
        })
    }
  }

  handleItemClick(e, { name }) {
    const { getUser, listLocations, history, match: { params: { id } } } = this.props;
    this.setState({ currentStep: name });

    history.push(`/app/users/${id}#${name.toLowerCase()}`)

    if (name === 'Baupunkte') {
      listLocations({ user: id });
    }

    if (name === 'Profil') {
      getUser(id);
    }
  }

  handleLoadMoreLocations() {
    const { listLocations, listNext } = this.props;

    listLocations({ url: listNext });
  }

  render() {
    const { currentStep } = this.state;
    const {
      history,
      user,
      userId,
      loading,
      loadingLocations,
      locations,
      isAdmin,
      listNext,
    } = this.props;
    const ownProfile = userId === user.id || isAdmin;
    const levelOfEducation = user.level_of_education && user.level_of_education !== 'none' && _.get(USER_CONSTS.LEVEL_OF_EDUCATION_OPTIONS.find(l => l.key === user.level_of_education), 'text')
    const type = ORGANIZATION_TYPES.find(orgType => orgType.key === _.get(user, 'organization.type'));
    const organization = !_.isEmpty(_.get(user, 'organization')) ? _.get(user, 'organization') : _.get(user, 'organization_member', {});
    const organizationId = _.get(user, 'organization.id', _.get(user, 'organization_member.id'));
    const organizationMembers = !_.isEmpty(_.get(user, 'organization_members')) ? _.get(user, 'organization_members', []).filter(member => member.id !== organizationId) : [];
    const badgesCount = USER_CONSTS.BADGES.map(badge => user.expert_bytes >= badge.bytes ? 1 : 0).reduce((sum, x) => sum + x);

    return (
      <Grid centered style={{ zIndex: 2 }}>
        <Grid.Column computer={14} tablet={14} mobile={14} style={{ zIndex: 2 }}>
          <Menu attached="top" tabular>
            <Menu.Item name="Profil" active={currentStep === 'Profil'} onClick={this.handleItemClick} />
            <Menu.Item name="Baupunkte" active={currentStep === 'Baupunkte'} onClick={this.handleItemClick} />
            {ownProfile && (
              <Menu.Item name="Profilbesucher" active={currentStep === 'Profilbesucher'} onClick={this.handleItemClick} />
            )}
          </Menu>

          <Segment attached="bottom" style={{ padding: '40px', display: 'relative' }}>
            {currentStep === 'Profil' && (
              <div>
                <div style={{ position: 'absolute', right: '-25px', top: '40px' }}>
                  {ownProfile && (
                    <Popup
                      trigger={(
                        <Button size="mini" color="blue" onClick={() => history.push(`/app/users/edit/${user.id}`)}>
                          <Button.Content visible>
                            <Icon name="pencil" />
                          </Button.Content>
                        </Button>
                      )}
                      content="Profil bearbeiten"
                    />
                  )}
                </div>
                {loading ? <Loader active inline="centered" /> : (
                  <Form>
                    <UserTitleImage editing={false} imageUrl={user.title_image_url} />
                    <Grid style={{ marginTop: '20px' }}>
                      <Grid.Column mobile={16} tablet={10} computer={12} widescreen={13}>
                        <Grid centered>
                          <Grid.Column width={15}>
                            <Grid>
                              <Grid.Column
                                mobile={15}
                                tablet={3}
                                computer={4}
                                widescreen={3}
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  alignItems: 'center',
                                }}
                              >
                                <UserAvatarImage editing={false} imageUrl={!_.isEmpty(user.avatar_image_url) && user.avatar_thumbnail_url} />
                                {user.is_premium && (
                                  <PremiumLabel
                                    labelProps={{
                                      style: { marginTop: '20px' },
                                    }}
                                  />
                                )}
                                <RankLabel
                                  labelProps={{
                                    style: { marginTop: '20px' },
                                  }}
                                  rank={user.rank}
                                />
                              </Grid.Column>
                              <Grid.Column mobile={15} tablet={15} computer={10} widescreen={13}>
                                <Grid.Row>
                                  <Grid>
                                    <Grid.Column mobile={16} tablet={16} computer={10}>
                                      <Header as="h2">{user.first_name} {user.last_name}</Header>
                                    </Grid.Column>
                                  </Grid>
                                </Grid.Row>
                                <Grid.Row>
                                  <p>{user.full_address}</p>
                                  <ReactMarkdown>{_.get(user, 'about_me', '')}</ReactMarkdown>
                                </Grid.Row>
                              </Grid.Column>
                            </Grid>
                          </Grid.Column>
                        </Grid>

                        <Grid centered style={{ marginTop: '50px' }}>
                          <Grid.Column widescreen={15}>
                            <Grid.Row>
                              <Header as="h2">Berufliche Informationen</Header>
                            </Grid.Row>
                            <Grid.Row style={{ marginTop: '15px' }}>
                              <Grid>
                                <InfoItem
                                  colProps={{
                                    mobile: 15,
                                    tablet: 7,
                                    computer: 7,
                                  }}
                                  title="Position"
                                  value={user.position}
                                />
                                <InfoItem
                                  colProps={{
                                    mobile: 15,
                                    tablet: 8,
                                    computer: 8,
                                  }}
                                  title="Ausbildungsgrad"
                                  value={levelOfEducation}
                                />
                              </Grid>
                            </Grid.Row>

                            <Grid.Row>
                              <Grid>
                                <Grid.Column mobile={15} tablet={7} computer={7}>
                                  {!_.isEmpty(user.specializations) && (
                                    <Grid.Row style={{ marginTop: '30px' }}>
                                      <InfoItem
                                        colProps={{
                                          mobile: 15,
                                          tablet: 5,
                                          computer: 4,
                                          widescreen: 2,
                                        }}
                                        title="Fachthemen"
                                      />
                                      {user.specializations_pretty.map(specialization => (
                                        <Label
                                          key={specialization.value}
                                          circular
                                          size="large"
                                          color="blue"
                                          style={{ marginRight: '10px' }}
                                        >
                                          {specialization.title}
                                        </Label>
                                      ))}
                                    </Grid.Row>
                                  )}
                                </Grid.Column>
                                <Grid.Column mobile={15} tablet={8} computer={8}>
                                  {!_.isEmpty(user.qualifications) && (
                                    <Grid.Row style={{ marginTop: '30px' }}>
                                      <InfoItem
                                        colProps={{
                                          mobile: 15,
                                          tablet: 5,
                                          computer: 4,
                                          widescreen: 2,
                                        }}
                                        title="Qualifikationen"
                                      />
                                      {user.qualifications_pretty.map(qualification => (
                                        <Label
                                          key={qualification.value}
                                          circular
                                          size="large"
                                          color="blue"
                                          style={{ marginRight: '10px' }}
                                        >
                                          {qualification.title}
                                        </Label>
                                      ))}
                                    </Grid.Row>
                                  )}
                                </Grid.Column>
                              </Grid>
                            </Grid.Row>
                            {((!_.isEmpty(organization) && user.is_premium) || organizationMembers.length > 0) && (
                              <Grid.Row style={{ marginTop: '30px' }}>
                                <InfoItem
                                  colProps={{
                                    mobile: 15,
                                    tablet: 7,
                                    computer: 7,
                                    widescreen: 7,
                                  }}
                                  title="Organisation"
                                />
                              </Grid.Row>
                            )}
                            {!_.isEmpty(organization) && user.is_premium && (
                              <Segment>
                                <Item.Group divided link style={{ textAlign: 'left' }}>
                                  <Item onClick={() => history.push(`/app/organizations/${organization.id}`)}>
                                    <Item.Image size="tiny">
                                      <div style={{ position: 'relative' }}>
                                        <Image
                                          src={!_.isEmpty(organization.logo_url_thumbnail) ? organization.logo_url_thumbnail : 'https://react.semantic-ui.com/images/wireframe/square-image.png'}
                                          style={{ width: '56px', height: '56px' }}
                                        />
                                      </div>
                                    </Item.Image>
                                    <Item.Content verticalAlign="middle">
                                      <Header as="h4">{organization.name} <Header.Subheader>{_.get(type, 'text')}</Header.Subheader></Header>
                                      <p>{organization.street}, {organization.city}</p>
                                    </Item.Content>
                                  </Item>
                                </Item.Group>
                              </Segment>
                            )}
                            { organizationMembers.length > 0 && (
                                organizationMembers.map(member => (
                                  <Segment>
                                    <Item.Group divided link style={{ textAlign: 'left' }}>
                                      <Item onClick={() => history.push(`/app/organizations/${member.id}`)}>
                                        <Item.Image size="tiny">
                                          <div style={{ position: 'relative' }}>
                                            <Image
                                              src={!_.isEmpty(member.logo_url_thumbnail) ? member.logo_url_thumbnail : 'https://react.semantic-ui.com/images/wireframe/square-image.png'}
                                              style={{ width: '56px', height: '56px' }}
                                            />
                                          </div>
                                        </Item.Image>
                                        <Item.Content verticalAlign="middle">
                                          <Header as="h4">{member.name} <Header.Subheader>{_.get(ORGANIZATION_TYPES.find(orgType => orgType.key === member.type), 'text')}</Header.Subheader></Header>
                                          <p>{member.street}, {member.city}</p>
                                        </Item.Content>
                                      </Item>
                                    </Item.Group>
                                  </Segment>
                                ))
                              )
                            }
                          </Grid.Column>
                        </Grid>

                        <Grid centered style={{ marginTop: '50px' }}>
                          <Grid.Column widescreen={15}>
                            <Grid.Row>
                              <Header as="h2">Kontakt-Informationen</Header>
                            </Grid.Row>
                            <Grid.Row style={{ marginTop: '15px' }}>
                              <Grid>
                                <InfoItem
                                  colProps={{
                                    mobile: 15,
                                    tablet: 5,
                                    computer: 4,
                                    widescreen: 2,
                                  }}
                                  title="Telefon"
                                  value={user.phone}
                                />
                                <InfoItem
                                  colProps={{
                                    mobile: 15,
                                    tablet: 5,
                                    computer: 4,
                                    widescreen: 2,
                                  }}
                                  title="Mobilnummer"
                                  value={user.phone_mobile}
                                />
                                <InfoItem
                                  colProps={{
                                    mobile: 15,
                                    tablet: 5,
                                    computer: 7,
                                    widescreen: 5,
                                  }}
                                  link
                                  href={`mailto:${user.email}`}
                                  title="E-Mail"
                                  value={user.email}
                                />
                              </Grid>
                            </Grid.Row>
                            <Grid.Row style={{ marginTop: '15px' }}>
                              <Grid>
                                <InfoItem
                                  colProps={{
                                    mobile: 15,
                                    tablet: 5,
                                    computer: 4,
                                    widescreen: 2,
                                  }}
                                  link
                                  title="Xing"
                                  value={user.xing_url}
                                  displayName="Profil auf XING ansehen"
                                />
                                <InfoItem
                                  colProps={{
                                    mobile: 15,
                                    tablet: 5,
                                    computer: 4,
                                    widescreen: 2,
                                  }}
                                  link
                                  title="LinkedIn"
                                  value={user.linkedin_url}
                                  displayName="Profil auf LinkedIn ansehen"
                                />
                                <InfoItem
                                  colProps={{
                                    mobile: 15,
                                    tablet: 5,
                                    computer: 7,
                                    widescreen: 5,
                                  }}
                                  link
                                  title="Website"
                                  value={user.website_url}
                                />
                              </Grid>
                            </Grid.Row>
                          </Grid.Column>
                        </Grid>

                        <Grid centered style={{ marginTop: '50px' }}>
                          <Grid.Column widescreen={15}>
                            <Grid.Row>
                              <Header as="h2">Persönliche Informationen</Header>
                            </Grid.Row>
                            <Grid.Row style={{ marginTop: '15px' }}>
                              <Grid>
                                <InfoItem
                                  colProps={{
                                    mobile: 15,
                                    tablet: 5,
                                    computer: 4,
                                    widescreen: 2,
                                  }}
                                  title="Geburtstag"
                                  value={user.birth_date}
                                />
                              </Grid>
                            </Grid.Row>
                            {!_.isEmpty(user.user_interests) && (
                              <Grid.Row style={{ marginTop: '15px' }}>
                                <Grid>
                                  <InfoItem
                                    colProps={{
                                      mobile: 15,
                                      tablet: 15,
                                      computer: 15,
                                    }}
                                    title="Interessen & Hobbies"
                                    value={(
                                      <Grid.Row style={{ marginTop: '10px' }}>
                                        {user.user_interests_pretty.map(interest => (
                                          <Label
                                            key={interest.value}
                                            circular
                                            size="large"
                                            color="blue"
                                            style={{ marginRight: '10px' }}
                                          >
                                            {interest.title}
                                          </Label>
                                        ))}
                                      </Grid.Row>
                                    )}
                                  />
                                </Grid>
                              </Grid.Row>
                            )}

                            {!_.isEmpty(user.recommended_links) && (
                              <Grid.Row style={{ marginTop: '15px' }}>
                                <Grid>
                                  <InfoItem
                                    colProps={{
                                      mobile: 15,
                                      tablet: 15,
                                      computer: 15,
                                    }}
                                    title="Empfohlene Links"
                                    value={(
                                      <Grid.Row style={{ marginTop: '10px' }}>
                                        <ul>
                                        {user.recommended_links.map(link => (
                                          <li><a key={link.url} href={link.url} target="_blank">{link.url}</a></li>
                                        ))}
                                        </ul>
                                      </Grid.Row>
                                    )}
                                  />
                                </Grid>
                              </Grid.Row>
                            )}
                          </Grid.Column>
                        </Grid>

                      </Grid.Column>

                      <Grid.Column mobile={16} tablet={6} computer={4} widescreen={3}>
                        <Grid.Row>
                          <ExpertByteLabelLarge
                            expertBytes={user.expert_bytes}
                          />
                          <Segment>
                            <Icon name="map marker" /> {user.locations_count} Baupunkt(e)
                          </Segment>
                        </Grid.Row>

                        <Grid.Row style={{ marginTop: '40px' }}>
                          <Header as="h2">Abzeichen</Header>

                          <Grid style={{ marginTop: '20px' }}>
                            {USER_CONSTS.BADGES.map(badge => user.expert_bytes >= badge.bytes ? (
                              <Grid.Column width={8} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginBottom: '20px' }}>
                                <Image src={badge.badgeImage}/>
                                <p style={{ marginTop: '10px' }}><strong>{badge.name}</strong></p>
                              </Grid.Column>
                            ) : null)}
                            {badgesCount == 0 && (
                              <Segment>Bisher noch keine Abzeichen verdient</Segment>
                            )}
                          </Grid>
                        </Grid.Row>
                      </Grid.Column>
                    </Grid>
                  </Form>
                )}
              </div>
            )}

            {currentStep === 'Baupunkte' && (
              <div>
                <Item.Group divided link style={{ textAlign: 'left' }}>
                  {!_.isEmpty(locations) ? locations.map(location => (
                    <LocationItem key={location.id} data={location} />
                  )) : (
                    <EmptyState header="Keine Baupunkte vorhanden" content="Dieser Benutzer hat noch keine Baupunkte erstellt" />
                  )}
                </Item.Group>
                {loadingLocations && <Loader active inline="centered" />}
                {!_.isEmpty(listNext) && (
                  <div style={{ marginTop: '40px', display: 'block', textAlign: 'center' }}>
                    <Button color="blue" onClick={this.handleLoadMoreLocations}>Mehr anzeigen</Button>
                  </div>
                )}
              </div>
            )}

            {currentStep === 'Profilbesucher' && (
              <Item.Group divided link style={{ textAlign: 'left' }}>
                {!_.isEmpty(user.recent_visitors) ? user.recent_visitors.map(visitor => (
                  <Item key={visitor.id}>
                    <Image
                      src={!_.isEmpty(visitor.avatar_image_url) ? visitor.avatar_thumbnail_url : 'https://react.semantic-ui.com/images/wireframe/square-image.png'}
                      circular
                      style={{ width: '56px', height: '56px' }}
                    />
                    <Item.Content style={{ marginLeft: '10px' }}>
                      <Grid>
                        <Grid.Column width={6} onClick={() => this.handleUserClick(visitor.id)}>
                          <Item.Header as="p">{visitor.name}</Item.Header>
                          <Item.Extra>
                            <List horizontal>
                              <List.Item>
                                <Icon name="ticket" /> {visitor.expert_bytes}
                              </List.Item>
                              <List.Item>
                                <Icon name="map marker" /> {visitor.locations_count}
                              </List.Item>
                            </List>
                          </Item.Extra>
                        </Grid.Column>
                        <Grid.Column width={5}>
                          <p>{moment(visitor.timestamp, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY')}</p>
                        </Grid.Column>
                      </Grid>
                    </Item.Content>
                  </Item>
                )) : (
                  <EmptyState header="Keine Besucher gefunden" content="Bis jetzt hat noch niemand dein Profil besucht" />
                )}
              </Item.Group>
            )}
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

UserProfile.defaultProps = {
  locations: [],
};

UserProfile.propTypes = {
  changeBlocked: PropTypes.func.isRequired,
  getUser: PropTypes.func.isRequired,
  mapLocations: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingLocations: PropTypes.bool.isRequired,
  userId: PropTypes.string.isRequired,
  listNext: PropTypes.string,
  locations: PropTypes.array, // eslint-disable-line
  user: PropTypes.object.isRequired, // eslint-disable-line
  match: PropTypes.object.isRequired, // eslint-disable-line
  history: PropTypes.object.isRequired, // eslint-disable-line
};

function mapStateToProps(state) {
  return {
    user: state.user.data,
    isAdmin: STATEAPI.isAdmin(state),
    userId: _.get(state, 'session.user.id'),
    loading: state.user.loading,
    loadingLocations: state.location.loading,
    locations: state.location.list.data,
    listNext: state.location.list.next,
  };
}

const mapDispatchToProps = {
  getUser: USER_ACTIONS.get,
  listLocations: LOCATION_ACTIONS.list,
};

export default AuthHOC(withRouter(connect(mapStateToProps, mapDispatchToProps)(UserProfile)));
