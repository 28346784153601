import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Grid,
  Header,
  Segment,
  Item,
  Image,
  Label,
  Icon,
} from 'semantic-ui-react';
import * as LOCATION_ACTIONS from 'actions/location';
import AuthHOC from 'containers/AuthHOC';
import { UserPicture } from 'modules/styled/map';
import 'lightbox-react/style.css';
import { ExpertByteLabelSmall } from 'modules/styled/profile';
import PrintableMap from 'modules/PrintableMap';
import { nl2br } from 'utils/string';
import Logo from 'assets/images/logo.png';
import VideoPlaceHolder from 'assets/images/video-placeholder.svg';


class PrintLocation extends Component {
  constructor(props) {
    super(props);

    this.loadLocation = this.loadLocation.bind(this);
  }

  componentDidMount() {
    const {
      match: {
        params: { id },
      },
    } = this.props;

    if (!_.isEmpty(id)) {
      this.loadLocation(id);
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      match: {
        params: { id },
      },
    } = nextProps;
    const {
      match: {
        params: { id: oldId },
      },
    } = this.props;

    if (id !== oldId) {
      this.loadLocation(id);
    }
  }

  loadLocation(id) {
    const { get } = this.props;

    get(id);
  }

  render() {
    const {
      history,
      isPremium,
      location,
      contentElements,
      links,
      documents,
      linkedLocations,
    } = this.props;
    const avatarUrl = _.get(location, 'user_data.avatar_image_url');
    const avatarUrlThumbnail = _.get(location, 'user_data.avatar_thumbnail_url');
    const userExpertBytes = _.get(location, 'user_data.expert_bytes');
    const userOrganization = _.get(location, 'user_data.organization');
    const locationOrganization = _.get(location, 'organization');
    const displayOrganization = !_.isEmpty(locationOrganization) ? locationOrganization : userOrganization;

    return !_.isEmpty(location) && isPremium && (
      <Grid>
        <Grid.Row columns={3}>
          <Grid.Column />
          <Grid.Column textAlign="center">
            <img src={Logo} style={{ width: '300px' }} />
          </Grid.Column>
          <Grid.Column textAlign="right">
            <p>Erstellt am:<br/>{location.created_at}</p>
            <p>Zuletzt editiert:<br/>{location.updated_at}</p>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Header as="h4" style={{ marginBottom: 0, marginRight: '50px' }}>#{location.id}</Header>
            <Header as="h2" style={{ marginTop: 0 }}>{location.street}<br/>{location.city}, {location.country}</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column textAlign="center">
            <Header as="h3">{nl2br(location.description || '')}</Header>
            {!_.isEmpty(location.tags_pretty) && location.tags_pretty.map(tag => (
              <Label key={tag.value} as="p" style={{ marginRight: '15px' }}>{tag.title}</Label>
            ))}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column>
            <PrintableMap
              lat={location.lat}
              lng={location.lng}
              id={location.id}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={9}>
            {!_.isEmpty(links) && (
              <Segment textAlign="center">
                <Header as="h4" textAlign="left">
                  <Icon name='linkify'/> Links
                </Header>
                {links.map(link => (
                  <Segment>
                    <Grid>
                      <Grid.Column computer={14} textAlign="left" style={{ overflowWrap: 'break-word' }}>
                        <p>{link.url}</p>
                      </Grid.Column>
                    </Grid>
                  </Segment>
                ))}
              </Segment>
            )}

            {!_.isEmpty(documents) && (
              <Segment textAlign="center">
                <Header as="h4" textAlign="left">
                  <Icon name='file'/> Dateianhänge</Header>
                {documents.map(document => (
                  <Segment>
                    <Grid>
                      <Grid.Column computer={14} textAlign="left" style={{ overflowWrap: 'break-word' }}>
                        {decodeURIComponent(_.last(document.file_url.split('/')))}
                      </Grid.Column>
                    </Grid>
                  </Segment>
                ))}
              </Segment>
            )}
          </Grid.Column>
          <Grid.Column width={1} />
          <Grid.Column width={6}>
            <Segment>
              <Item.Group divided link style={{ textAlign: 'left' }}>
                <Item>
                  <Item.Image size="tiny">
                    <div style={{ position: 'relative' }}>
                      <UserPicture
                        src={!_.isEmpty(avatarUrl) ? avatarUrlThumbnail : 'https://react.semantic-ui.com/images/wireframe/square-image.png'}
                      />
                      <ExpertByteLabelSmall
                        expertBytes={userExpertBytes}
                        style={{ marginLeft: '-40px' }}
                      />
                    </div>
                  </Item.Image>
                  <Item.Content verticalAlign="middle">
                    <Header as="h4">
                      {_.get(location, 'user_data.first_name')} {_.get(location, 'user_data.last_name')}
                    </Header>
                    {!_.isEmpty(userOrganization) && (<Item.Meta style={{ marginTop: 0 }}>{userOrganization.name}</Item.Meta>)}
                  </Item.Content>
                </Item>
              </Item.Group>
            </Segment>

            {!_.isEmpty(locationOrganization) && (
              <p>Dieser Baupunkt wurde für folgende Organisation erstellt:</p>
            )}

            {!_.isEmpty(displayOrganization) && (
              <Segment>
                <Item.Group divided link style={{ textAlign: 'left' }}>
                  <Item>
                    <Item.Image size="tiny">
                      <div style={{ position: 'relative' }}>
                        <Image
                          src={!_.isEmpty(displayOrganization.logo_url_thumbnail) ? displayOrganization.logo_url_thumbnail : 'https://react.semantic-ui.com/images/wireframe/square-image.png'}
                          style={{ width: '56px', height: '56px' }}
                        />
                        <ExpertByteLabelSmall
                          expertBytes="250"
                          style={{ marginLeft: '-40px' }}
                        />
                      </div>
                    </Item.Image>
                    <Item.Content verticalAlign="middle">
                      <Header as="h4">{displayOrganization.name}</Header>
                    </Item.Content>
                  </Item>
                </Item.Group>
              </Segment>
            )}

            {!_.isEmpty(linkedLocations) && (
              <Segment textAlign="center">
                <Header as="h4" textAlign="left">
                  <Icon name='point'/> Verknüpfte Baupunkte
                </Header>
                <Grid>
                  <Grid.Column mobile={16} tablet={16} computer={16}>
                    {linkedLocations.map(linkedLocation => (
                      <Segment key={linkedLocation.id}>
                        <Grid>
                          <Grid.Column computer={16} textAlign="left">
                            <strong>{linkedLocation.street}</strong>
                            <p>
                              {linkedLocation.user_data.first_name} {linkedLocation.user_data.last_name}
                              <br />{_.get(linkedLocation, 'user_data.organization.name')}
                            </p>
                          </Grid.Column>
                        </Grid>
                      </Segment>
                    ))}
                  </Grid.Column>
                </Grid>
              </Segment>
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row columns={1}>
          <Grid.Column textAlign="center">
            <Segment>
              {contentElements.map((element) => {
                if (element.type === 'text') {
                  return (
                    <Grid.Row key={element.uuid} style={{ marginBottom: '60px' }}>
                      <p>{element.data.text}</p>
                    </Grid.Row>
                  );
                }

                if (element.type === 'image') {
                  return (
                    <Grid.Row style={{ marginBottom: '60px' }} key={element.uuid}>
                      <img style={{ width: '80%' }} src={element.data.url} />
                      <p>{element.data.text}</p>
                    </Grid.Row>
                  );
                }

                if (element.type === 'video') {
                  return (
                    <Grid.Row style={{ marginBottom: '60px' }} key={element.uuid}>
                      <Item.Image src={VideoPlaceHolder} size="large" />
                      <p>{element.data.text}</p>
                    </Grid.Row>
                  );
                }

                return null;
              })}
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

PrintLocation.defaultProps = {
  userId: '',
};

PrintLocation.propTypes = {
  get: PropTypes.func.isRequired,
  userId: PropTypes.string,
  isPremium: PropTypes.bool.isRequired,
  documents: PropTypes.array.isRequired, // eslint-disable-line
  contentElements: PropTypes.array.isRequired, // eslint-disable-line
  links: PropTypes.array.isRequired, // eslint-disable-line
  linkedLocations: PropTypes.array.isRequired, // eslint-disable-line
  match: PropTypes.object.isRequired, // eslint-disable-line
  history: PropTypes.object.isRequired, // eslint-disable-line
  location: PropTypes.object.isRequired, // eslint-disable-line
};

function mapStateToProps(state) {
  const location = _.get(state, 'location.data', {});

  return {
    userId: _.get(state, 'session.user.id'),
    location,
    isPremium: _.get(state, 'session.user.is_premium', false),
    contentElements: _.get(location, 'content_elements', []),
    documents: _.get(location, 'documents', []).filter(document => !_.isEmpty(document.file_url)),
    links: _.get(location, 'links', []),
    linkedLocations: _.get(location, 'linked_locations', []),
  };
}

const mapDispatchToProps = {
  get: LOCATION_ACTIONS.get,
};

export default AuthHOC(withRouter(connect(mapStateToProps, mapDispatchToProps)(PrintLocation)));
