import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
} from 'semantic-ui-react';
import { Field } from 'redux-form';
import * as Fields from 'modules/Fields';
import ORGANIZATION_TYPES from 'utils/organization';

const Info = ({
  changeFieldValue,
  formData,
  visible,
}) => (
  <div style={{ display: visible ? 'block' : 'none' }}>
    <Grid.Row style={{ marginTop: '20px' }}>
      <Grid centered style={{ marginTop: '50px' }}>
        <Grid.Column mobile={15} tablet={15} computer={15} widescreen={8}>
          <Grid.Row>
            <Grid>
              <Grid.Column mobile={15} tablet={8} computer={10}>
                <Field
                  name="name"
                  placeholder="Name der Organisation"
                  component={Fields.renderInput}
                />
              </Grid.Column>
              <Grid.Column mobile={15} tablet={8} computer={6}>
                <Field
                  name="type"
                  placeholder="Typ der Organisation"
                  component={Fields.renderSelect}
                  options={ORGANIZATION_TYPES}
                />
              </Grid.Column>
            </Grid>
          </Grid.Row>
          <Grid.Row style={{ marginTop: '40px' }}>
            <i>
              Bitte trage hier den Hauptsitz deiner Organisation ein.
            </i>
            <Grid style={{ marginTop: 0 }}>
              <Grid.Column mobile={15} tablet={16} computer={10}>
                <Field
                  name="street"
                  placeholder="Adresse suchen..."
                  component={Fields.AddressSearch}
                  clickCallback={(value) => {
                    const { attributes: { City, Postal } } = value;

                    changeFieldValue('city', City);
                    changeFieldValue('postalcode', Postal);
                  }}
                />
              </Grid.Column>
              <Grid.Column mobile={15} tablet={4} computer={2}>
                <Field
                  disabled
                  name="postalcode"
                  placeholder="PLZ"
                  component={Fields.renderInput}
                />
              </Grid.Column>
              <Grid.Column mobile={15} tablet={12} computer={4}>
                <Field
                  disabled
                  name="city"
                  placeholder="Ort"
                  component={Fields.renderInput}
                />
              </Grid.Column>
            </Grid>
          </Grid.Row>
        </Grid.Column>
      </Grid>
    </Grid.Row>
  </div>
);

Info.defaultProps = {
  formData: {},
};

Info.propTypes = {
  formData: PropTypes.object, // eslint-disable-line
  visible: PropTypes.bool.isRequired,
  changeFieldValue: PropTypes.func.isRequired,
};

export default Info;
