import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import * as C from 'actions/constants';
import * as Fields from 'modules/Fields';
import {
  Grid,
  Button,
  Form,
} from 'semantic-ui-react';
import _ from 'lodash';
import * as USER_ACTIONS from 'actions/user';
import * as Alert from 'utils/alert';

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Pflichtfeld';
  }

  return errors;
};

class RecoverPassword extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    const {
      formData: { email },
      resetPassword,
    } = this.props;

    resetPassword({ email })
      .then((res) => {
        if (res.type === C.RESET_USER_PASSWORD_SUCCESS) {
          Alert.success('Bitte prüfe nun dein Postfach. Du hast eine E-Mail mit Instruktionen zum Passwort-Reset erhalten.');
        }

        if (res.type === C.RESET_USER_PASSWORD_ERROR) {
          Alert.error('Passwort konnte nicht zurückgesetzt werden. Ggf. wurde deine E-Mail nicht gefunden.');
        }
      })
      .catch(() => {
        Alert.error('Passwort konnte nicht zurückgesetzt werden.');
      });
  }

  render() {
    const { loading, handleSubmit } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.handleSubmit)}>
        <Grid centered columns={1} style={{ marginTop: '20px' }}>
          <Grid.Column computer={12} tablet={12} mobile={16}>
            <Field component={Fields.renderInput} name="email" type="email" placeholder="E-Mail" />
            <Button fluid primary size="large" loading={loading}>Passwort zurücksetzen</Button>
          </Grid.Column>
        </Grid>
      </Form>
    );
  }
}

RecoverPassword.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  formData: PropTypes.object.isRequired, // eslint-disable-line
  history: PropTypes.object.isRequired, // eslint-disable-line
};

export function mapStateToProps(state) {
  return {
    formData: _.get(state.form, 'resetPassword.values', {}),
    loading: state.user.loading,
  };
}

const mapDispatchToProps = {
  resetPassword: USER_ACTIONS.resetPassword,
};

const RecoverPasswordForm = reduxForm({
  form: 'resetPassword',
  validate,
})(RecoverPassword);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RecoverPasswordForm));
