import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
} from 'semantic-ui-react';
import {
  DateInput,
} from 'semantic-ui-calendar-react';
import moment from 'moment';
import 'moment/locale/de';

const DatePickerInput = ({
  input,
  placeholder,
  meta: {
    touched,
    error,
  },
}) => (
  <Form.Field>
    <DateInput
      {...input}
      closable
      dateFormat="DD.MM.YYYY"
      localization="de"
      placeholder={placeholder}
      iconPosition="left"
      onChange={(event, { value }) => input.onChange(value)}
    />
    {touched && error && <span class='ui negative message mini'>{error}</span>}
  </Form.Field>
);

DatePickerInput.propTypes = {
  input: PropTypes.object, // eslint-disable-line
  placeholder: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
};

export default DatePickerInput;
