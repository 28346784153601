import React, { Component } from 'react';
import { connect } from 'react-redux';
import Breakpoint from 'react-socks';
import PropTypes from 'prop-types';
import { reduxForm, Field } from 'redux-form';
import * as Fields from 'modules/Fields';
import {
  Grid,
  Popup,
  Button,
  Form,
  Icon,
} from 'semantic-ui-react';
import _ from 'lodash';
import * as USER_CONST from 'const/user';
import { filterTags } from 'api/lib/user';

const validate = (values) => {
  let errors = [];
  const requiredFields = [
    'name',
    'type',
    'number_of_employees',
    'street',
    'city',
    'postalcode',
  ];

  for (let i = 0; i < requiredFields.length; i++) { // eslint-disable-line
    const field = requiredFields[i];
    if (!values[field]) {
      errors = [...errors, field];
    }
  }

  const errorsObj = _.reduce(errors, (obj, param) => {
    obj[param] = 'Pflichtfeld'; // eslint-disable-line
    return obj;
  }, {});

  return errorsObj;
};

class SearchFormComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.switchOpen = this.switchOpen.bind(this);
    this.handleKeyDown = this.handleKeyDown.bind(this);
  }

  switchOpen(event) {
    event.preventDefault();
    const { open } = this.state;

    this.setState({ open: !open });
  }

  handleKeyDown(e, cb) {
    if (e.key === 'Enter' && e.shiftKey === false) {
      e.preventDefault();
      cb();
    }
  }

  render() {
    const { open } = this.state;
    const {
      handleSubmit,
      onSubmit,
      advancedSearchEnabled,
    } = this.props;

    return (
      <Form
        onSubmit={handleSubmit(onSubmit)}
        onKeyDown={(e) => { this.handleKeyDown(e, handleSubmit(onSubmit)); }}
      >
        <Grid centered>
          <Grid.Column computer={14} tablet={16} mobile={16}>
            <Grid.Row>
              <Field
                icon="search"
                fluid
                name="name"
                placeholder="Suche nach Experten oder Organisation..."
                component={Fields.renderInput}
              />
            </Grid.Row>

            <Grid.Row textAlign="right" style={{ display: 'flex', alignItems: 'flex-end', flexDirection: 'column', margin: '20px auto' }}>
            {!advancedSearchEnabled ? (
              <Popup
                on='click'
                trigger={(
                  <Button
                    basic
                    onClick={this.switchOpen}
                  >
                    Erweiterte Suchfunktionen <Icon name={`angle ${open ? 'up' : 'down'}`} style={{ marginLeft: '15px', marginRight: 0 }} />
                  </Button>
                )}
              >
                <Popup.Header>Premium-Account benötigt</Popup.Header>
                <Popup.Content>
                  Die erweiterten Suchfunktionen stehen nur Premium-Mitgliedern zur Verfügung.
                  <div class='ui divider'></div>
                  <a href='/app/premium-features' class='ui button mini yellow'>Jetzt Premium-Mitglied werden</a>
                </Popup.Content>
              </Popup>
            ) : (
              <Button
                basic
                onClick={this.switchOpen}
              >
                Erweiterte Suchfunktionen <Icon name={`angle ${open ? 'up' : 'down'}`} style={{ marginLeft: '15px', marginRight: 0 }} />
              </Button>
            )}
            </Grid.Row>

            {open && (
              <div>
                <Grid.Row style={{ marginTop: '20px' }}>
                  <Grid>
                    <Grid.Column computer={4} tablet={6} mobile={16}>
                      <Field
                        disabled={!advancedSearchEnabled}
                        name="postalcode"
                        placeholder="PLZ"
                        component={Fields.renderInput}
                      />
                    </Grid.Column>

                    <Grid.Column computer={4} tablet={6} mobile={16}>
                      <Field
                        disabled={!advancedSearchEnabled}
                        name="position"
                        placeholder="Position"
                        component={Fields.renderInput}
                      />
                    </Grid.Column>
                  </Grid>
                </Grid.Row>
                <Grid.Row style={{ marginTop: '20px' }}>
                  <Grid>  
                    <Grid.Column computer={4} tablet={6} mobile={16}>
                      <Field
                        multiple
                        disabled={!advancedSearchEnabled}
                        name="specializations"
                        tagType="specialization"
                        placeholder="Fachthemen"
                        component={Fields.renderTagSelect}
                        filterTags={filterTags}
                        options={[]}
                      />
                    </Grid.Column>

                    <Grid.Column computer={4} tablet={6} mobile={16}>
                      <Field
                        multiple
                        disabled={!advancedSearchEnabled}
                        name="qualifications"
                        tagType="qualification"
                        placeholder="Qualifikationen"
                        component={Fields.renderTagSelect}
                        filterTags={filterTags}
                        options={[]}
                      />
                    </Grid.Column>

                    <Grid.Column computer={4} tablet={16} mobile={16}>
                      <Field
                        multiple
                        disabled={!advancedSearchEnabled}
                        name="user_interests"
                        tagType="user-interest"
                        placeholder="Interessen / Hobbies"
                        component={Fields.renderTagSelect}
                        filterTags={filterTags}
                        options={[]}
                      />
                    </Grid.Column>
                  </Grid>
                </Grid.Row>
              </div>
            )}

            <Grid.Row textAlign="right">
              <Grid centered style={{ marginBottom: '5px' }}>
                <Grid.Column width={16} textAlign="right">
                  <Button primary size="large">Suchen</Button>
                </Grid.Column>
              </Grid>
            </Grid.Row>
          </Grid.Column>
        </Grid>
      </Form>
    );
  }
}

SearchFormComponent.defaultProps = {};

SearchFormComponent.propTypes = {};


const SearchForm = reduxForm({
  form: 'search',
})(SearchFormComponent);

export default SearchForm;
