import React from 'react';
import PropTypes from 'prop-types';
import {
  Select,
  Form,
} from 'semantic-ui-react';

const SelectInput = ({
  input,
  placeholder,
  options,
  initialValue,
  multiple,
  meta: {
    touched,
    error,
  },
}) => (
  <Form.Field>
    <Select
      fluid
      value={input.value || initialValue}
      multiple={multiple}
      name={input.name}
      options={options}
      placeholder={placeholder}
      onChange={(e, { value }) => input.onChange(value)}
    />
    {touched && error && <span class='ui negative message mini'>{error}</span>}
  </Form.Field>
);

SelectInput.defaultProps = {
  multiple: false,
};

SelectInput.propTypes = {
  input: PropTypes.object, // eslint-disable-line
  placeholder: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired, // eslint-disable-line
  multiple: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
};

export default SelectInput;
