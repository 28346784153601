import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import { BreakpointProvider } from 'react-socks';
import PrintProvider from 'react-easy-print';
import * as SRC from 'modules/styled';
import Auth from 'containers/Auth';
import RouteLayout from 'containers/RouteLayout';
import HomePage from 'pages/Home';
import CreateOrganizationPage from 'pages/CreateOrganization';
import CreateOrganizationForm from 'pages/CreateOrganizationForm';
import Community from 'pages/Community';
import OrganizationProfile from 'pages/OrganizationProfile';

import UserProfile from 'pages/UserProfile';
import UserEdit from 'pages/UserEdit';
import RegisterProfilePage from 'pages/RegisterProfilePage';
import CreateLocation from 'pages/CreateLocation';
import ViewLocation from 'pages/ViewLocation';
import ExpertTimeline from 'pages/ExpertTimeline';
import Premium from 'pages/Premium';
import PremiumStatus from 'pages/PremiumStatus';
import OrgaPremiumPayment from 'pages/OrgaPremiumPayment';
import PrintLocation from 'pages/PrintLocation';
import Settings from 'pages/Settings';
import Downloads from 'pages/Downloads';
import Events from 'pages/Events';
import EventCreate from 'pages/EventCreate';
import PublicSearch from './containers/PublicSearch';

export default (
  <PrintProvider>
    <Router>
      <SRC.MainScreen>
        <BreakpointProvider>
          <Route
            render={() => (
              <Switch>
                <Route exact path="/" component={Auth} />
                <Route exact path="/auth/register/profile" component={RegisterProfilePage} />
                <Route path="/auth" component={Auth} />
                <Route path="/public-search" component={PublicSearch} />
                <RouteLayout path="/app/organizations/create/data/step?" component={CreateOrganizationForm} />
                <RouteLayout path="/app/organizations/create/data/:id/:step?" component={CreateOrganizationForm} />
                <RouteLayout path="/app/organizations/create/data" component={CreateOrganizationForm} />
                <RouteLayout path="/app/organizations/create" component={CreateOrganizationPage} />
                <RouteLayout path="/app/organizations/payment" component={OrgaPremiumPayment} />
                <RouteLayout exact path="/app/organizations/:id" component={OrganizationProfile} />
                <RouteLayout path="/app/community" component={Community} />
                <RouteLayout path="/app/location/detail/:id" component={ViewLocation} />
                <Route exact path="/app/location/print/:id" component={PrintLocation} />
                <RouteLayout path="/app/location/:action/:id?/:step?" component={CreateLocation} />
                <RouteLayout exact path="/app/users/:id" component={UserProfile} />
                <RouteLayout exact path="/app/users/edit/:id" component={UserEdit} />
                <RouteLayout exact path="/app/timeline" component={ExpertTimeline} />
                <RouteLayout exact path="/app/premium-features" component={Premium} />
                <RouteLayout exact path="/app/premium" component={PremiumStatus} />
                <RouteLayout exact path="/app/settings" component={Settings} />
                <RouteLayout exact path="/app/downloads" component={Downloads} />
                <RouteLayout exact path="/app/events" component={Events} />
                <RouteLayout exact path="/app/events/:action/:id?" component={EventCreate} />
                <RouteLayout path="/app" component={HomePage} />
                <Redirect to="/" />
              </Switch>
            )}
          />
        </BreakpointProvider>
      </SRC.MainScreen>
    </Router>
  </PrintProvider>
);
