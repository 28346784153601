import axios from 'axios';
import { authToken, authPresent } from 'utils/session';
import { buildURL } from './helpers';

const handleSuccess = ({ data, status }) => ({
  statusCode: status,
  body: data,
});

const handleFailure = (e) => {
  // if (e.response.status === 401) {
  //   // Since we don't have 'props.history' here,
  //   // we need to change 'window.location' manually
  //   localStorage.clear();
  //   window.location = '/';
  // } else {
  //   throw e.response;
  // }
  throw e.response;
};

const buildHeaders = () => {
  const headers = [];

  if (authPresent()) {
    headers.push({ Authorization: `Bearer ${authToken()}` });
  }

  return Object.assign({}, ...headers);
};

const dispatch = (method, endpoint, id = '', action = '', params = {}, data = {}, headers = {}) => axios({
  baseURL: process.env.REACT_APP_API_ENDPOINT,
  method,
  url: buildURL(endpoint, id, action, params),
  data,
  headers: {
    ...buildHeaders(),
    ...headers,
  },
})
  .then(handleSuccess)
  .catch(handleFailure);


const REST = {
  get: (endpoint, id, params, headers) => dispatch('get', endpoint, id, '', params, headers),
  getAction: (endpoint, id, action, params, headers) => dispatch('get', endpoint, id, action, params, headers),
  post: (endpoint, body, params, headers) => dispatch('post', endpoint, '', '', params, body, headers),
  put: (endpoint, id, body, params, headers) => dispatch('put', endpoint, id, '', params, body, headers),
  patch: (endpoint, id, body, params, headers) => dispatch('patch', endpoint, id, '', params, body, headers),
  delete: (endpoint, id, body, params, headers) => dispatch('delete', endpoint, id, body, params, headers),
};

export default REST;
