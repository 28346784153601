import API from 'api';
import * as C from './constants';

const get = () => (dispatch) => {
  dispatch({ type: C.LOAD_COMMUNITY_STATS });

  return API.community.get()
    .then(({ body: data }) => dispatch({ type: C.LOAD_COMMUNITY_STATS_SUCCESS, data }));
};

export default get;
