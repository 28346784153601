import React, { Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Grid,
  Card,
  Divider,
  Step,
  Button,
  Modal,
  Dimmer,
  Loader,
  Table,
  Icon,
  Popup,
} from 'semantic-ui-react';
import * as LOCATION_ACTIONS from 'actions/location';
import * as C from 'actions/constants';
import AuthHOC from 'containers/AuthHOC';
import LocationDetailContainer from 'containers/forms/LocationDetail';
import * as STATEAPI from 'utils/stateapi';
import LocationContentContainer from 'containers/forms/LocationContent';
import * as Alert from 'utils/alert';

class CreateLocation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentStep: 'detail',
      contentElements: {},
      importResult: [],
      importLoading: false,
      checkIFCtoSave: true
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleStepChange = this.handleStepChange.bind(this);
    this.updateContentElements = this.updateContentElements.bind(this);
    this.handleSaveContentElements = this.handleSaveContentElements.bind(this);
    this.handleMassImportChange = this.handleMassImportChange.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }

  componentDidMount() {
    const { changeBlocked, get } = this.props;
    const { history, match: { params: { step, action, id } }, canEdit } = this.props;

    if (!canEdit && !_.isEmpty(id)) {
      history.push(`/app/location/detail/${id}`);
      return;
    }

    if (!_.isEmpty(step)) {
      this.setState({ currentStep: step });
    }

    if (action === 'edit' && !_.isEmpty(id)) {
      get(id);
    }

    changeBlocked(true);
  }

  componentWillReceiveProps(nextProps) {
    const { currentStep } = this.state;
    const { match: { params: { step } } } = nextProps;

    if (!_.isEmpty(step) && currentStep !== step) {
      this.setState({ currentStep: step });
    }
  }

  updateContentElements(data) {
    this.setState({ contentElements: JSON.stringify(data) }, () => {
      this.handleSaveContentElements();
    });
  }

  handleSubmit() {
    const { currentStep } = this.state;
    const { submitDetailForm, history, match: { params: { id } } } = this.props;

    if (currentStep === 'detail') {
      submitDetailForm();
    } else {
      this.handleSaveContentElements()
        .then(() => {
          Alert.success('Der Baupunkt wurde erfolgreich gespeichert.');
          history.push(`/app/location/detail/${id}`);
        }
      );
    }
  }

  handleStepChange(step) {
    this.setState({ currentStep: step });
  }

  handleSaveContentElements() {
    const { contentElements } = this.state;
    const { patch, match: { params: { id } } } = this.props;

    const patchData = !_.isEmpty(contentElements) ? {
      content_elements: contentElements,
    } : {};

    return patch(id, patchData);
  }

  handleMassImportChange(event) {
    event.preventDefault();

    const { massImport } = this.props;
    const files = [...event.target.files];

    if (_.isEmpty(files)) {
      return;
    }

    this.setState({ importLoading: true }, () => {
      massImport(files[0])
        .then((res) => {
          if (res.type === C.MASS_IMPORT_SUCCESS) {
            this.setState({
              importResult: res.data,
              importLoading: false,
            });
          }
        });
    })
  }

  handleCloseModal() {
    this.setState({
      importResult: [],
      importLoading: false,
    });
  }


  handleUploadComplete = (checkIFC) => {
    this.setState({ checkIFC });
    this.setState({ checkIFCtoSave: checkIFC})
  }

  render() {
    const { currentStep, importResult, importLoading } = this.state;
    const { history, match: { params: { action } }, isAdmin, checkIFC } = this.props;

    const steps = [
      {
        key: 'detail',
        active: currentStep === 'detail',
        icon: 'map marker alternate',
        disabled: !currentStep === 'detail',
        title: '01',
        description: 'Allgemeine Angaben',
      },
      {
        key: 'content',
        active: currentStep === 'content',
        icon: 'th list',
        disabled: !currentStep === 'content',
        title: '02',
        description: 'Baupunkt-Inhalte',
      },
    ];

    return (
      <Grid centered style={{ zIndex: 2 }}>
        <Modal
          open={!_.isEmpty(importResult) || importLoading}
          onClose={this.handleCloseModal}
        >
          <Modal.Header>Status Baupunkte-Massenimport</Modal.Header>
          <Modal.Content>
            {importLoading ? (
              <Dimmer active>
                <Loader />
              </Dimmer>
            ) : (
              <Table>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell textAlign="center">Zeile</Table.HeaderCell>
                    <Table.HeaderCell>Adresse</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Status</Table.HeaderCell>
                    <Table.HeaderCell textAlign="center">Link</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>

                <Table.Body>
                  {importResult.map(r => (
                    <Table.Row key={r.line}>
                      <Table.Cell textAlign="center">{r.line}</Table.Cell>
                      <Table.Cell>{r.address}</Table.Cell>
                      <Table.Cell textAlign="center">
                        {r.detail}
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        <Link to={`/app/location/detail/${r.location}`} target="_blank">
                          Baupunkt bearbeiten
                        </Link>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            )}
          </Modal.Content>
          <Modal.Actions>
          <Button primary content="Ok" onClick={this.handleCloseModal} />
          </Modal.Actions>
        </Modal>
        <Grid.Column computer={14} tablet={14} mobile={14} style={{ zIndex: 2 }}>
          <Card fluid>
            <Card.Content style={{ textAlign: 'center' }}>
              <Grid.Row style={{ marginTop: '20px', display: 'flex', justifyContent: 'center', alignItems: 'baseline' }}>
                <div style={{ flexGrow: 1 }}><Step.Group items={steps} size="mini" /></div>
                {isAdmin && (
                  <label htmlFor="massenimport-input">
                    <Button as="span" basic size="large" content="Massenimport" />
                    <input type="file" id="massenimport-input" onChange={this.handleMassImportChange} style={{ display: 'none' }} />
                  </label>
                )}
              </Grid.Row>

              <Grid.Row style={{ marginTop: '20px' }}>
                <Grid centered>
                  <Grid.Column computer={9} tablet={9} mobile={14}>
                    <h4>Beschreibe die durch den Baupunkt dokumentierte Maßnahme.</h4>
                    <p>
                      Insbesondere der Titel der Baumaßnahme, Ansprechpartner der Auftraggeber,
                      Baufirma und Ingenieurbüro sind hilfreich. Angaben über den Typ der Baumaßnahme,
                      Beschreibung der aktuellen Situation sowie geplantem Baufortschritt machen deine
                      Informationen sehr wertvoll.
                    </p>
                  </Grid.Column>
                </Grid>
                <Divider />
              </Grid.Row>

              {currentStep === 'detail' && (
                <LocationDetailContainer action={action} />
              )}

              {currentStep === 'content' && (
                <LocationContentContainer
                  updateContentElements={this.updateContentElements}
                  onUploadComplete={this.handleUploadComplete}
                  />
              )}

              <Divider style={{ marginTop: '30px' }} />
              <Grid.Row textAlign="right">
                <Grid centered style={{ marginBottom: '5px' }}>
                  <Grid.Column width={7} textAlign="left">
                    {currentStep === 'detail' && (
                      <Button size="large" onClick={() => history.push('/app/')}>
                        Abbrechen
                      </Button>
                    )}
                    {currentStep === 'content' && (
                      <Button size="large" onClick={() => this.handleStepChange('detail')}>
                        Zurück
                      </Button>
                    )}
                  </Grid.Column>
                  <Grid.Column width={8} textAlign="right">
                    {
                      this.state.checkIFCtoSave ?
                        <Button primary size="large" onClick={this.handleSubmit}>
                          {currentStep === 'content' ? 'Speichern' : 'Weiter'}
                        </Button> :
                        <Button primary size="large" style={{ background: '#e0e1e2', color: '#00000099'}}>Speichern</Button>
                    }
                  </Grid.Column>
                </Grid>
              </Grid.Row>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid>
    );
  }
}

CreateLocation.propTypes = {
  get: PropTypes.func.isRequired,
  patch: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  submitDetailForm: PropTypes.func.isRequired,
  changeBlocked: PropTypes.func.isRequired,
  massImport: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired, // eslint-disable-line
  history: PropTypes.object.isRequired, // eslint-disable-line
  locationData: PropTypes.object.isRequired, // eslint-disable-line
};

function mapStateToProps(state) {
  const user = STATEAPI.getCurrentUser(state);
  const isAdmin = STATEAPI.isAdmin(state);
  const locationData = _.get(state, 'location.data', {});

  return {
    locationData,
    canEdit: _.get(locationData, 'user') === user.id || isAdmin,
    isAdmin: isAdmin,
  };
}

const mapDispatchToProps = {
  get: LOCATION_ACTIONS.get,
  patch: LOCATION_ACTIONS.patch,
  massImport: LOCATION_ACTIONS.massImport,
  submitDetailForm: LOCATION_ACTIONS.submitDetailForm,
};

export default withRouter(AuthHOC(connect(mapStateToProps, mapDispatchToProps)(CreateLocation)));
