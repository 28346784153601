import React from 'react';
import { Link } from 'react-router-dom';
import {
  Grid,
  Header,
  Divider,
} from 'semantic-ui-react';
import SimpleRegisterContainer from 'containers/forms/SimpleRegister';

const RegisterPage = () => (
  <div>
    <Grid.Row style={{ marginTop: '20px' }}>
      <Header as="h2" textAlign="center">
        Es kann losgehen
        <Header.Subheader textAlign="center">Registriere dich als lokaler Experte!</Header.Subheader>
      </Header>
    </Grid.Row>

    <Grid centered>
      <Grid.Column computer={12} tablet={12} mobile={16}>
        <Grid.Row style={{ marginTop: '50px' }}>
          <SimpleRegisterContainer />
        </Grid.Row>
      </Grid.Column>
    </Grid>

    <Divider style={{ marginTop: '30px' }} />

    <Grid.Row textAlign="center">
      <p>Bist du bereits Mitglied?</p>
      <Link to="/">Jetzt einloggen!</Link>
    </Grid.Row>
  </div>
);

export default RegisterPage;
