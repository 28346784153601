import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as USER_ACTIONS from 'actions/user';

class ImageUpload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    event.preventDefault();

    const { patchUser, userId, type } = this.props;
    const files = [...event.target.files];

    if (_.isEmpty(files)) {
      return;
    }

    this.setState({ loading: true }, () => {
      patchUser(userId, { [type]: files[0] })
        .then(() => this.setState({ loading: false }));
    });
  }

  render() {
    const {
      type,
      imageUrl,
      children,
    } = this.props;
    const { loading } = this.state;

    const childrenWithProps = React.Children.map(children, child => (
      React.cloneElement(child, {
        loading,
        type,
        imageUrl,
        handleChange: this.handleChange,
      })
    ));

    return <div>{childrenWithProps}</div>;
  }
}

ImageUpload.propTypes = {
  type: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  patchUser: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
};

function mapStateToProps(state) {
  return {
    userId: _.get(state, 'user.data.id'),
  };
}

const mapDispatchToProps = {
  patchUser: USER_ACTIONS.patch,
};

export default connect(mapStateToProps, mapDispatchToProps)(ImageUpload);
