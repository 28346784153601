import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import posed, { PoseGroup } from 'react-pose';
import {
  Grid,
  Header,
  Icon,
  Button,
  Segment,
  Progress,
} from 'semantic-ui-react';

const Item = posed.div({
  enter: {
    x: 0,
    opacity: 1,
  },
  exit: {
    x: 20,
    opacity: 0,
  },
});

const FileIFC = ({
  fileIFC,
  uploading,
  percentUploadIFC,
  handleChange,
  handleDelete,
  nameFileIFC
}) => {
  return (
    <Segment textAlign="center">
      <Header as="h4" textAlign="left">
        <Icon name='cube'/> IFC-Datei </Header>
      <label htmlFor="file-ifc-upload">
        <input
          type="file"
          value = {nameFileIFC}
          id="file-ifc-upload"
          style={{ display: 'none' }}
          accept=".ifc"
          onChange={handleChange}
        />
        {
          fileIFC.length === 0 ?
            <Button
              loading={uploading}
              type="button"
              onClick={(event) => {
                event.preventDefault();
                if (!uploading) {
                  document.getElementById('file-ifc-upload').click();
                }
              }}
            >
              Datei hochladen
            </Button>
          : <></>
        }
      </label>
      {percentUploadIFC == 0 || percentUploadIFC == 100 ?
        <></>:<Progress percent={percentUploadIFC} progress style = {{marginTop: '5px'}}/>
      }
      {!_.isEmpty(fileIFC) && (
        <div style={{ marginTop: '20px' }}>
          <PoseGroup>
            {fileIFC.map((fileIFC, index) => (
              <Item i={index} key={fileIFC.id} style={{ marginBottom: '15px' }}>
                <Segment>
                  <Grid>
                    <Grid.Column computer={14} tablet={13} textAlign="left" style={{ overflowWrap: 'break-word' }}>
                      <a href={fileIFC.file_url} rel="noopener noreferrer" target="_blank">{_.isEmpty(fileIFC.file_url) ? fileIFC.id : decodeURIComponent(_.last(fileIFC.file_url.split('/'))).split('?')[0]}</a>
                    </Grid.Column>
                    <Grid.Column computer={2} tablet={3} style={{ display: 'flex', alignItems: 'center' }}>
                      <Icon name="delete" onClick={() => handleDelete(fileIFC.id)} style={{ cursor: 'pointer', display: 'flex', justifyContent: 'center', alignItems: 'center' }} />
                    </Grid.Column>
                  </Grid>
                </Segment>
              </Item>
            ))}
          </PoseGroup>
        </div>
      )}
    </Segment>
  );
};

FileIFC.defaultProps = {
  fileIFC: [],
};

FileIFC.propTypes = {
  uploading: PropTypes.bool.isRequired,
  fileIFC: PropTypes.array, // eslint-disable-line
  handleChange: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default FileIFC;
