import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Card,
  Header,
  Button,
} from 'semantic-ui-react';

export default class Downloads extends Component {
 render() {
    return (
      <Grid centered style={{ zIndex: 2 }}>
        <Grid.Column computer={14} tablet={14} mobile={14} style={{ zIndex: 2 }}>
          <Card fluid>
            <Card.Content style={{ textAlign: 'center' }}>
              <Grid.Row style={{ marginTop: '40px', marginBottom: '60px' }}>
                <Header as="h1" textAlign="center">Downloads</Header>
                  <Card.Group centered style={{ marginTop: '40px' }}>
                    <Card>
                      <Card.Content>
                        <Card.Header>Anlieger-<br/>schreiben</Card.Header>
                        <Card.Meta>PDF</Card.Meta>
                        <Card.Description>
                          <Button target="_blank" href="https://le24-media.s3.eu-central-1.amazonaws.com/downloads/anliegerschreiben.pdf">Download</Button>
                        </Card.Description>
                      </Card.Content>
                    </Card>
                    <Card>
                      <Card.Content>
                        <Card.Header>Muster-Positionen Dokumentation</Card.Header>
                        <Card.Meta>L24.D81</Card.Meta>
                        <Card.Description>
                          <Button target="_blank" href="https://le24-media.s3.eu-central-1.amazonaws.com/downloads/Muster-Positionen+Dokumentation+L24.D81">Download</Button>
                        </Card.Description>
                      </Card.Content>
                    </Card>
                    <Card>
                      <Card.Content>
                        <Card.Header>Muster-Positionen Dokumentation</Card.Header>
                        <Card.Meta>L24.PDF</Card.Meta>
                        <Card.Description>
                          <Button target="_blank" href="https://le24-media.s3.eu-central-1.amazonaws.com/downloads/Muster-Positionen+Dokumentation+L24.PDF">Download</Button>
                        </Card.Description>
                      </Card.Content>
                    </Card>
                    <Card>
                      <Card.Content>
                        <Card.Header>Muster-Positionen Dokumentation</Card.Header>
                        <Card.Meta>L24.X81</Card.Meta>
                        <Card.Description>
                          <Button target="_blank" href="https://le24-media.s3.eu-central-1.amazonaws.com/downloads/Muster-Positionen+Dokumentation+L24.X81">Download</Button>
                        </Card.Description>
                      </Card.Content>
                    </Card>
                    <Card>
                      <Card.Content>
                        <Card.Header>Community-<br/>richtlinien</Card.Header>
                        <Card.Meta>PDF</Card.Meta>
                        <Card.Description>
                          <Button target="_blank" href="https://le24-media.s3.eu-central-1.amazonaws.com/downloads/localexpert24_Richtlinien_DINA4_Lay_2.pdf">Download</Button>
                        </Card.Description>
                      </Card.Content>
                    </Card>
                  </Card.Group>
              </Grid.Row>
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid>
    );
  }
}
