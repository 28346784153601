import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _, { round } from 'lodash';
import { Loader } from 'semantic-ui-react';
// import * as C from 'actions/constants';
import ContentForms from 'modules/forms/LocationContent';
import * as LOCATION_ACTIONS from 'actions/location';
import * as Alert from 'utils/alert';
import { v4 as uuidv4 } from 'uuid';

class LocationContentFormContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploading: false,
      uploadingIFC: false,
      percentUploadIFC: 0,
      nameFileIFC: '',
    };

    this.handleAddLink = this.handleAddLink.bind(this);
    this.handleDeleteLink = this.handleDeleteLink.bind(this);
    this.patch = this.patch.bind(this);
    this.handleUploadFile = this.handleUploadFile.bind(this);
    this.handleDeleteFile = this.handleDeleteFile.bind(this);
    this.handleUploadFileIFC = this.handleUploadFileIFC.bind(this);
    this.handleDeleteFileIFC = this.handleDeleteFileIFC.bind(this);
    this.handleUploadContent = this.handleUploadContent.bind(this);
    this.handleDeleteContent = this.handleDeleteContent.bind(this);
    this.handleAddLocation = this.handleAddLocation.bind(this);
    this.handleDeleteLocation = this.handleDeleteLocation.bind(this);
    this.handleRotateContent = this.handleRotateContent.bind(this);
  }

  patch(data) {
    const { patch, get, locationData: { id } } = this.props;

    return patch(id, data)
      .then(get(id));
  }

  handleAddLink(newLink) {
    const { locationData } = this.props;
    const links = [
      ..._.get(locationData, 'links', []),
      newLink,
    ];

    return this.patch({ links });
  }

  handleDeleteLink(id) {
    const { locationData } = this.props;
    const links = [..._.get(locationData, 'links', [])].filter(link => link.id !== id);

    return this.patch({ links });
  }

  handleUploadFile(event) {
    event.preventDefault();

    const { uploadDocument, locationData: { id } } = this.props;
    const files = [...event.target.files];

    if (_.isEmpty(files)) {
      return;
    }

    this.setState({ uploading: true }, () => {
      uploadDocument(id, { file_url: files[0] })
        .then(this.setState({ uploading: false }));
    });
  }

  handleDeleteFile(id) {
    const { deleteDocument } = this.props;

    deleteDocument(id);
  }

  handleUploadFileIFC(event) {
    event.preventDefault();
    const { uploadFileIFC, locationData: { id } } = this.props;
    const file = event.target.files[0];
    const nameFile = file.name;
    const fileExtension = nameFile.split('.').pop();
    if (fileExtension !== 'ifc') {
      Alert.error('Bitte wählen Sie die IFC-Datei aus.');
      return;
    }

    const fileSize = file.size;
    const chunkSize = 3000000; // 3MB
    const chunkCount = Math.ceil(fileSize / chunkSize);

    const fileChunks = [];
    let currentChunk = 0;
    while (currentChunk < chunkCount) {
      const start = currentChunk * chunkSize;
      const end = ((currentChunk + 1) * chunkSize);
      const chunk = file.slice(start, end);
      fileChunks.push(chunk);
      currentChunk++;
    }

    const idFileIFC = uuidv4();
    this.props.onUploadComplete(false);
    this.setState({ uploadingIFC: true }, () => {
      const chunk = fileChunks[0];
      const formData = new FormData();
      formData.append("file", chunk, file.name);
      formData.append("index", 0);
      formData.append("chunkCount", chunkCount);
      formData.append("timestamp", Date.now());
      formData.append("userId", this.props.locationData.user);
      formData.append("idFileIFC", idFileIFC);

      uploadFileIFC(id, formData).then((response) => {
        if(response === 'the location exist IFC file') {
          Alert.error('Die Position enthält bereits eine IFC-Datei. Bitte laden Sie die Seite neu.');
          return;
        }
        let currentChunk = 1;

        const uploadNextChunk = () => {
          if (currentChunk >= chunkCount) {
            this.props.onUploadComplete(true);
            this.setState({ percentUploadIFC: 100});
            Alert.success('Der Upload ist abgeschlossen.');
            return;
          }

          const chunk = fileChunks[currentChunk];
          const formData = new FormData();
          formData.append("file", chunk, file.name);
          formData.append("index", currentChunk);
          formData.append("chunkCount", chunkCount);
          formData.append("timestamp", Date.now());
          formData.append("userId", this.props.locationData.user);
          formData.append("idFileIFC", idFileIFC);

          uploadFileIFC(id, formData)
            .then((response) => {
              if(response === 'the location exist IFC file') {
                Alert.error('Die Position enthält bereits eine IFC-Datei. Bitte laden Sie die Seite neu.');
                return;
              }
              currentChunk++;
              this.setState({ percentUploadIFC: round((currentChunk * 100.0) / chunkCount) });
              uploadNextChunk();
            })
            .catch((err) => {
              console.log(err);
              this.setState({ uploadingIFC: false });
              this.props.onUploadComplete(true);
            });
        };

        uploadNextChunk();
      }).catch((err) => {
        console.log(err);
        this.setState({ uploadingIFC: false });
        this.props.onUploadComplete(true);
      });
    });

  }

  handleDeleteFileIFC(id) {
    const { deleteFileIFC } = this.props;
    this.setState({ uploadingIFC: false });
    deleteFileIFC(id);
    this.setState({nameFileIFC: ''}) ;
  }

  handleUploadContent(files, id, type) {
    const { uploadContent, locationData } = this.props;

    if (_.isEmpty(files)) {
      return new Promise(resolve => resolve());
    }

    return uploadContent(id, {
      file_url: files[0],
      type,
      location: locationData.id,
    });
  }

  handleDeleteContent(id) {
    const { deleteContent } = this.props;

    return deleteContent(id);
  }

  handleAddLocation(id) {
    const { locationData } = this.props;
    const locations = _.get(locationData, 'linked_locations', []).map(l => l.id);

    if (locations.indexOf(id) !== -1) {
      return;
    }

    const linkedLocations = [
      ...locations,
      id,
    ];

    this.patch({ linked_locations: linkedLocations });
  }

  handleDeleteLocation(id) {
    const { locationData } = this.props;
    const linkedLocations = _.get(locationData, 'linked_locations', []).filter(l => l.id !== id).map(l => l.id);

    return this.patch({ linked_locations: linkedLocations });
  }

  handleRotateContent(element, direction) {
    const { rotateContent } = this.props;

    return rotateContent({
      id: element.uuid,
      direction,
    });
  }

  render() {
    const { uploading } = this.state;
    const { nameFileIFC } = this.state;
    const { uploadingIFC } = this.state;
    const { percentUploadIFC } = this.state;
    const { initialValues, locationData, updateContentElements } = this.props;
    const contentElements = _.get(locationData, 'content_elements');

    return _.isEmpty(initialValues) ? <Loader active inline="centered" /> : (
      <ContentForms.LocationContentForm
        initialValues={initialValues}
        contentElements={!_.isEmpty(contentElements) ? contentElements : []}
        documents={_.get(locationData, 'documents', [])}
        fileIFC={_.get(locationData, 'fileIFC', [])}
        linkedLocations={_.get(locationData, 'linked_locations', [])}
        links={_.get(locationData, 'links', [])}
        handleAddLink={this.handleAddLink}
        handleDeleteLink={this.handleDeleteLink}
        handleUploadFile={this.handleUploadFile}
        handleDeleteFile={this.handleDeleteFile}
        handleUploadFileIFC={this.handleUploadFileIFC}
        handleDeleteFileIFC={this.handleDeleteFileIFC}
        handleUploadContent={this.handleUploadContent}
        handleDeleteContent={this.handleDeleteContent}
        handleAddLocation={this.handleAddLocation}
        handleDeleteLocation={this.handleDeleteLocation}
        updateContentElements={updateContentElements}
        uploading={uploading}
        uploadingIFC={uploadingIFC}
        percentUploadIFC={percentUploadIFC}
        handleRotateContent={this.handleRotateContent}
        nameFileIFC={nameFileIFC}
      />
    );
  }
}

LocationContentFormContainer.defaultProps = {
  initialValues: {},
};

LocationContentFormContainer.propTypes = {
  patch: PropTypes.func.isRequired,
  uploadDocument: PropTypes.func.isRequired,
  deleteDocument: PropTypes.func.isRequired,
  uploadFileIFC: PropTypes.func.isRequired,
  deleteFileIFC: PropTypes.func.isRequired,
  uploadContent: PropTypes.func.isRequired,
  deleteContent: PropTypes.func.isRequired,
  rotateContent: PropTypes.func.isRequired,
  updateContentElements: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  locationData: PropTypes.object.isRequired, // eslint-disable-line
  initialValues: PropTypes.object, // eslint-disable-line
  formData: PropTypes.object.isRequired, // eslint-disable-line
};

function mapStateToProps(state) {
  const fields = [
    'id',
    'content_elements',
    'links',
    'documents',
    'fileIFC',
    'linked_locations',
    'lat',
    'lng',
  ];
  const locationData = _.get(state, 'location.data', {});

  return {
    locationData,
    initialValues: _.pick(locationData, fields),
    formData: _.get(state.form, 'locationContentForm.values', {}),
  };
}

const mapDispatchToProps = {
  patch: LOCATION_ACTIONS.patch,
  uploadDocument: LOCATION_ACTIONS.uploadDocument,
  deleteDocument: LOCATION_ACTIONS.deleteDocument,
  uploadFileIFC: LOCATION_ACTIONS.uploadFileIFC,
  deleteFileIFC: LOCATION_ACTIONS.deleteFIleIFC,
  uploadContent: LOCATION_ACTIONS.uploadContent,
  deleteContent: LOCATION_ACTIONS.deleteContent,
  rotateContent: LOCATION_ACTIONS.rotateContent,
  get: LOCATION_ACTIONS.get,
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationContentFormContainer);
