import API from 'api';
import { submit } from 'redux-form';
import _ from 'lodash';
import * as C from './constants';
import receiveNetworkException from './network';
import error from './error';

export const submitCreateForm = () => dispatch => dispatch(submit('createOrganization'));

const transformData = (body) => {
  const imageKeys = ['logo_url', 'title_image_url'];
  const data = body;
  const dataKeys = Object.keys(data);
  const hasImage = !_.isEmpty(_.intersection(imageKeys, dataKeys));
  const contentType = hasImage ? 'application/x-www-form-urlencoded' : 'application/json';
  let formData = data;

  if (hasImage) {
    formData = new FormData();

    _.each(dataKeys, (key) => {
      formData.append(key, data[key]);
    });
  }

  return {
    formData,
    contentType,
  };
};

export const create = body => (dispatch) => {
  dispatch({ type: C.CREATE_ORGANIZATION });

  const { formData, contentType } = transformData(body);

  return API.organization.create(formData, contentType)
    .then(({ body: data }) => dispatch({ type: C.CREATE_ORGANIZATION_SUCCESS, data }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.CREATE_ORGANIZATION_ERROR, {
        message: 'Failed to register organization',
        description: 'Please contact server admin.',
        data: err.data,
      }));
    });
};

export const get = uuid => (dispatch) => {
  dispatch({ type: C.GET_ORGANIZATION });

  return API.organization.get(uuid)
    .then(({
      body: data,
    }) => dispatch({ type: C.GET_ORGANIZATION_SUCCESS, data }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.GET_ORGANIZATION_ERROR, {
        message: 'Daten zur Orgnisation konnten nicht abgerufen werden',
        description: 'Bitte kontaktiere den Admin.',
        data: err.data,
      }));
    });
};

export const list = params => (dispatch) => {
  dispatch({ type: C.LIST_ORGANIZATIONS });

  return API.organization.get(null, params)
    .then(({
      body: data,
    }) => dispatch({ type: C.LIST_ORGANIZATIONS_SUCCESS, ...data }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.LIST_ORGANIZATIONS_ERROR, {
        message: 'Daten zur Orgnisation konnten nicht abgerufen werden',
        description: 'Bitte kontaktiere den Admin.',
        data: err.data,
      }));
    });
};

export const patch = (id, body) => (dispatch) => {
  dispatch({ type: C.PATCH_ORGANIZATION });

  const { formData, contentType } = transformData(body);

  return API.organization.patch(id, formData, contentType)
    .then(({
      body: organizationData,
    }) => dispatch({ type: C.PATCH_ORGANIZATION_SUCCESS, data: organizationData }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.PATCH_ORGANIZATION_ERROR, {
        message: 'Orgnisation konnte nicht gespeichert werden',
        description: 'Bitte kontaktiere den Admin.',
        data: err.data,
      }));
    });
};

export const invites = params => (dispatch) => {
  dispatch({ type: C.LIST_ORGANIZATION_INVITES });

  return API.organization.invites(params)
    .then(({
      body: data,
    }) => dispatch({ type: C.LIST_ORGANIZATION_INVITES_SUCCESS, data }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.LIST_ORGANIZATION_INVITES_ERROR, {
        message: 'Einladungen konnten nicht geladen werden',
        description: 'Bitte kontaktiere den Admin.',
        data: err.data,
      }));
    });
};

export const invite = body => (dispatch) => {
  dispatch({ type: C.ORGANIZATION_INVITE });

  return API.organization.invite(body)
    .then(({ body: data }) => dispatch({ type: C.ORGANIZATION_INVITE_SUCCESS, data }));
};

export const apply = body => (dispatch) => {
  dispatch({ type: C.ORGANIZATION_APPLY });

  return API.organization.apply(body)
    .then(({ body: data }) => dispatch({ type: C.ORGANIZATION_APPLY_SUCCESS, data }));
};

export const deleteInvite = body => (dispatch) => {
  dispatch({ type: C.ORGANIZATION_DELETE_INVITE });

  return API.organization.deleteInvite(body)
    .then(({ body: data }) => dispatch({ type: C.ORGANIZATION_DELETE_INVITE_SUCCESS, data }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.ORGANIZATION_DELETE_INVITE_ERROR, {
        message: 'Daten konnten nicht geladen werden',
        description: 'Bitte kontaktiere den Admin.',
        data: err.data,
      }));
    });
};

export const members = organizationId => (dispatch) => {
  dispatch({ type: C.LIST_ORGANIZATION_MEMBERS });

  return API.organization.members(organizationId)
    .then(({
      body: data,
    }) => dispatch({ type: C.LIST_ORGANIZATION_MEMBERS_SUCCESS, data }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.LIST_ORGANIZATION_MEMBERS_ERROR, {
        message: 'Einladungen der Organisation konnten nicht geladen werden',
        description: 'Bitte kontaktiere den Admin.',
        data: err.data,
      }));
    });
};

export const approve = inviteId => (dispatch) => {
  dispatch({ type: C.ORGANIZATION_APPROVE_APPLY });

  return API.organization.approve(inviteId)
    .then(() => dispatch({ type: C.ORGANIZATION_APPROVE_APPLY_SUCCESS }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.ORGANIZATION_APPROVE_APPLY_ERROR, {
        message: 'Einladungen der Organisation konnten nicht geladen werden',
        description: 'Bitte kontaktiere den Admin.',
        data: err.data,
      }));
    });
};

export const reject = inviteId => (dispatch) => {
  dispatch({ type: C.ORGANIZATION_REJECT_APPLY });

  return API.organization.reject(inviteId)
    .then(() => dispatch({ type: C.ORGANIZATION_REJECT_APPLY_SUCCESS }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.ORGANIZATION_REJECT_APPLY_ERROR, {
        message: 'Einladungen der Organisation konnten nicht geladen werden',
        description: 'Bitte kontaktiere den Admin.',
        data: err.data,
      }));
    });
};

export const hasInvite = organizationId => (dispatch) => {
  dispatch({ type: C.ORGANIZATION_USER_HAS_INVITE });

  return API.organization.hasInvite(organizationId)
    .then(({
      body: data,
    }) => dispatch({ type: C.ORGANIZATION_USER_HAS_INVITE_SUCCESS, hasInvite: data }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.ORGANIZATION_USER_HAS_INVITE_ERROR, {
        message: 'Einladungen der Organisation konnten nicht geladen werden',
        description: 'Bitte kontaktiere den Admin.',
        data: err.data,
      }));
    });
};

export const kick = memberId => (dispatch) => {
  dispatch({ type: C.ORGANIZATION_KICK_MEMBER });

  return API.organization.kick(memberId)
    .then(() => dispatch({ type: C.ORGANIZATION_KICK_MEMBER_SUCCESS }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.ORGANIZATION_KICK_MEMBER_ERROR, {
        message: 'Einladungen der Organisation konnten nicht geladen werden',
        description: 'Bitte kontaktiere den Admin.',
        data: err.data,
      }));
    });
};

export const leave = organizationId => (dispatch) => {
  dispatch({ type: C.ORGANIZATION_LEAVE });

  return API.organization.leave(organizationId)
    .then(() => dispatch({ type: C.ORGANIZATION_LEAVE_SUCCESS }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.ORGANIZATION_LEAVE_ERROR, {
        message: 'Einladungen der Organisation konnten nicht geladen werden',
        description: 'Bitte kontaktiere den Admin.',
        data: err.data,
      }));
    });
};

export const deleteOrganization = () => (dispatch) => {
  dispatch({ type: C.ORGANIZATION_DELETE });

  return API.organization.deleteOrganization()
    .then(() => dispatch({ type: C.ORGANIZATION_DELETE_SUCCESS }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.ORGANIZATION_DELETE_ERROR, {
        message: 'Einladungen der Organisation konnten nicht geladen werden',
        description: 'Bitte kontaktiere den Admin.',
        data: err.data,
      }));
    });
};

export const patchOrganizationMember = (id, data) => (dispatch) => {
  dispatch({ type: C.PATCH_ORGANIZATION_MEMBER });

  return API.organization.patchOrganizationMember(id, data)
    .then(() => dispatch({ type: C.PATCH_ORGANIZATION_MEMBER_SUCCESS }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.PATCH_ORGANIZATION_MEMBER_ERROR, {
        message: 'Einladungen der Organisation konnten nicht geladen werden',
        description: 'Bitte kontaktiere den Admin.',
        data: err.data,
      }));
    });
};

export const setAdmin = (organizationId, userId) => (dispatch) => {
  dispatch({ type: C.SET_ADMIN });

  return API.organization.setAdmin({ user_id: userId, organization_id: organizationId })
    .then(() => dispatch({ type: C.SET_ADMIN_SUCCESS }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.SET_ADMIN_ERROR, {
        message: 'Einladungen der Organisation konnten nicht geladen werden',
        description: 'Bitte kontaktiere den Admin.',
        data: err.data,
      }));
    });
};

export const loadMore = url => (dispatch) => {
  dispatch({ type: C.LOAD_MORE_ORGANIZATIONS });

  return API.organization.loadMore(url)
    .then(({
      body: data,
    }) => dispatch({ type: C.LOAD_MORE_ORGANIZATIONS_SUCCESS, ...data }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.LOAD_MORE_ORGANIZATIONS_ERROR, {
        message: 'Weitere Benutzer konnten nicht aufgelistet werden.',
        description: 'Bitte kontaktiere den Support.',
        data: err.data,
      }));
    });
};
