import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import posed, { PoseGroup } from 'react-pose';
import {
  Grid,
  Header,
  Form,
  Segment,
  Input,
  Icon,
  Button,
} from 'semantic-ui-react';
import isURL from 'utils/validate';

const Item = posed.div({
  enter: {
    x: 0,
    opacity: 1,
  },
  exit: {
    x: 20,
    opacity: 0,
  },
});

class Links extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      value: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleAddLink = this.handleAddLink.bind(this);
  }

  handleInputChange(e, { value }) {
    const { error } = this.state;
    let errorState = {};

    if (error) {
      errorState = { error: false };
    }

    this.setState({ value, ...errorState });
  }

  handleAddLink(event) {
    event.preventDefault();

    const { handleChange } = this.props;
    const { value } = this.state;

    if (_.isEmpty(value)) {
      return;
    }

    if (!isURL(value)) {
      this.setState({ error: true });
      return;
    }

    this.setState({ loading: true }, () => {
      const newLink = { url: value };

      handleChange(newLink)
        .then(this.setState({ loading: false, value: '' }));
    });
  }

  render() {
    const {
      value,
      loading,
      error,
    } = this.state;
    const { links, handleDelete } = this.props;

    return (
      <Segment textAlign="center">
        <Header as="h4" textAlign="left">
          <Icon name='linkify'/> Links
        </Header>
        <Grid>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Grid.Row>
              <Form.Field>
                <Input error={error} fluid action placeholder="URL" onChange={this.handleInputChange}>
                  <input disabled={loading} value={value} />
                  <Button
                    loading={loading}
                    type="button"
                    onClick={this.handleAddLink}
                  >
                    Hinzufügen
                  </Button>
                </Input>
              </Form.Field>
            </Grid.Row>
            {!_.isEmpty(links) && (
              <Grid.Row style={{ marginTop: '20px' }}>
                <PoseGroup>
                  {links.map((link, index) => (
                    <Item i={index} key={link.id} style={{ marginBottom: '15px' }}>
                      <Segment>
                        <Grid>
                          <Grid.Column computer={14} textAlign="left" style={{ overflowWrap: 'break-word' }}>
                            <p>{link.url}</p>
                          </Grid.Column>
                          <Grid.Column computer={2} textAlign="right">
                            <Icon
                              name="delete"
                              onClick={() => handleDelete(link.id)}
                              style={{ cursor: 'pointer' }}
                            />
                          </Grid.Column>
                        </Grid>
                      </Segment>
                    </Item>
                  ))}
                </PoseGroup>
              </Grid.Row>
            )}
          </Grid.Column>
        </Grid>
      </Segment>
    );
  }
}

Links.propTypes = {
  links: PropTypes.array, // eslint-disable-line
  handleChange: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default Links;
