import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import * as C from 'actions/constants';
import * as Fields from 'modules/Fields';
import {
  Grid,
  Checkbox,
  Button,
  Form,
} from 'semantic-ui-react';
import _ from 'lodash';
import * as SESSION_ACTIONS from 'actions/session';
import * as Alert from 'utils/alert';

const renderCheckBox = field => (
  <Form.Field
    checked={!!field.input.value}
    name={field.input.name}
    control={Checkbox}
    label={{ children: field.label }}
    onChange={(e, { checked }) => field.input.onChange(checked)}
  />
);

const validate = (values) => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Pflichtfeld';
  }

  if (!values.password) {
    errors.password = 'Pflichtfeld';
  }

  return errors;
};

class LoginContainer extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    const {
      formData: { email, password },
      getToken,
      history,
      location: { search },
    } = this.props;

    getToken({
      username: email,
      password,
    })
      .then((res) => {
        if (res.type === C.GET_TOKEN_SUCCESS) {
          const nextLocation = !_.isEmpty(search) ? new URLSearchParams(search).get('next') : '';
          history.push(_.isEmpty(nextLocation) ? '/app' : nextLocation);
        }

        if (res.type === C.GET_TOKEN_ERROR) {
          Alert.error('Das Passwort oder die eingegebene E-Mail-Adresse sind leider falsch. Bitte überprüfe deine Eingabe und versuche es erneut oder klicke auf „Passwort vergessen“.');
        }
      })
      .catch(() => {
        Alert.error('Login nicht möglich. Bitte versuche es später noch einmal.');
      });
  }

  render() {
    const { loading, handleSubmit } = this.props;
    return (
      <Form onSubmit={handleSubmit(this.handleSubmit)}>
        <Field component={Fields.renderInput} name="email" type="email" placeholder="E-Mail" />
        <Field component={Fields.renderInput} name="password" type="password" placeholder="Passwort" />
        <Field
          name="keep"
          component={renderCheckBox}
          label="Login merken"
        />
        <Grid centered columns={1} style={{ marginTop: '20px' }}>
          <Grid.Column>
            <Button fluid primary size="large" loading={loading}>Einloggen</Button>
          </Grid.Column>
        </Grid>
      </Form>
    );
  }
}

LoginContainer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  getToken: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  formData: PropTypes.object.isRequired, // eslint-disable-line
  history: PropTypes.object.isRequired, // eslint-disable-line
};

export function mapStateToProps(state) {
  return {
    formData: _.get(state.form, 'login.values', {}),
    session: state.session,
    loading: state.session.loading,
  };
}

const mapDispatchToProps = {
  getToken: SESSION_ACTIONS.getToken,
};

const LoginForm = reduxForm({
  form: 'login',
  validate,
})(LoginContainer);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginForm));
