import React from 'react';
import PropTypes from 'prop-types';

const Header = ({ title }) => (
  <li className="timeline-item period">
    <div className="timeline-info" />
    <div className="timeline-marker" />
    <div className="timeline-content">
      <h3 className="timeline-title">{title}</h3>
    </div>
  </li>
);

Header.defaultProps = {
  title: '',
};

Header.propTypes = {
  title: PropTypes.string,
};

export default Header;
