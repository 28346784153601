import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Grid,
  Card,
} from 'semantic-ui-react';
import ResetPasswordPage from 'pages/ResetPasswordPage';
import RegisterPage from 'pages/RegisterPage';
import ConfirmAccountPage from 'pages/ConfirmAccountPage';
import UnsubscribePage from 'pages/UnsubscribePage';
import LoginPage from 'pages/LoginPage';
import LogoutPage from 'pages/LogoutPage';
import AuthHOC from 'containers/AuthHOC';
import * as SRC from 'modules/styled';
import Logo from 'assets/images/logo.png';

const Auth = ({ match }) => (
  <SRC.MapBG blocked>
    <Grid centered style={{ zIndex: 2 }}>
      <Grid.Column width={14} style={{ marginTop: '20px', zIndex: 2 }}>
        <a href="/"><SRC.Logo logo={Logo} /></a>
      </Grid.Column>
    </Grid>
    <Grid centered style={{ zIndex: 2 }}>
      <Grid.Column widescreen={4} computer={6} tablet={8} mobile={14} style={{ marginTop: '20px', zIndex: 2 }}>
        <Card fluid style={{ overflow: 'hidden' }}>
          <Card.Content>
            <Switch>
              <Route path="/auth/reset-password/:uid/:token/" component={ResetPasswordPage} />
              <Route path="/auth/register/confirm/:uid?/:token?" component={ConfirmAccountPage} />
              <Route path="/auth/unsubscribe/:uid" component={UnsubscribePage} />
              <Route path="/auth/register/" component={RegisterPage} />
              <Route path="/auth/logout" component={LogoutPage} />
              <Route path="/" component={LoginPage} />
            </Switch>
          </Card.Content>
        </Card>
      </Grid.Column>
    </Grid>
  </SRC.MapBG>
);

Auth.propTypes = {
  match: PropTypes.object.isRequired, // eslint-disable-line
};

export default AuthHOC(Auth);
