import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import * as C from 'actions/constants';
import {
  Grid,
  Header,
  Form,
} from 'semantic-ui-react';
import _ from 'lodash';
import * as USER_ACTIONS from 'actions/user';
import * as SESSION_ACTIONS from 'actions/session';
import * as Fields from 'modules/Fields';
import * as Alert from 'utils/alert';
import * as USER_CONST from 'const/user';

const validate = (values) => {
  const errors = {};
  const errorMessage = 'Pflichtfeld';

  if (!values.street) {
    errors.street = errorMessage;
  }

  if (!values.postalcode) {
    errors.postalcode = errorMessage;
  }

  if (!values.city) {
    errors.city = errorMessage;
  }

  if (!values.country) {
    errors.country = errorMessage;
  }

  return errors;
};

class UserPaymentDetailContainer extends Component {
  render() {
    const { handleSubmit, user, initialValues, onSubmit } = this.props;
    const gender = USER_CONST.GENDER_OPTIONS.find(g => g.value === user.gender);

    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Header as="h2" textAlign="left">Deine Rechnungsanschrift</Header>
        <p style={{ textAlign: 'left' }}>{_.get(gender, 'text')} {user.first_name} {user.last_name}</p>
        <Grid>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Field
              name="street"
              placeholder="Straße"
              component={Fields.renderInput}
              type="text"
            />
          </Grid.Column>

          <Grid.Column mobile={5} tablet={5} computer={5}>
            <Field
              name="postalcode"
              placeholder="PLZ"
              component={Fields.renderInput}
              type="text"
            />
          </Grid.Column>
          <Grid.Column mobile={11} tablet={11} computer={11}>
            <Field
              name="city"
              placeholder="Ort"
              component={Fields.renderInput}
              type="text"
            />
          </Grid.Column>

          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Field
              name="country"
              placeholder="Land"
              component={Fields.renderInput}
              type="text"
            />
          </Grid.Column>
        </Grid>
      </Form>
    );
  }
}

UserPaymentDetailContainer.propTypes = {
  // getToken: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  // register: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired, // eslint-disable-line
  history: PropTypes.object.isRequired, // eslint-disable-line
};

function mapStateToProps(state) {
  return {
    formData: _.get(state.form, 'userPaymentDetail.values', {}),
  };
}

const mapDispatchToProps = {};

const UserPaymentDetailForm = reduxForm({
  form: 'userPaymentDetail',
  validate,
  onSubmit: (values, dispatch, props) => props.onSubmit,
})(UserPaymentDetailContainer);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserPaymentDetailForm));
