import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import _ from 'lodash';
import * as C from 'actions/constants';
import { searchByLngLat } from 'api/lib/location';
import LocationDetailForm from 'modules/forms/LocationDetailForm';
import { get as getUser } from 'api/lib/user';
import * as Alert from 'utils/alert';
import * as STATEAPI from 'utils/stateapi';
import * as LOCATION_ACTIONS from 'actions/location';

class LocationDetailFormContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      initialValues: {},
      loading: true,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { action, locationData } = this.props;

    if (action === 'create') {
      const { location: { state }, currentUser } = this.props;
      const lat = _.get(state, 'lat');
      const lng = _.get(state, 'lng');

      if (lat && lng) {
        searchByLngLat(`${lng},${lat}`)
          .then((res) => {
            this.setState({
              loading: false,
              initialValues: {
                created_at: new Date(),
                street: _.get(res.body, 'address.Match_addr'),
                city: _.get(res.body, 'address.City'),
                postalcode: _.get(res.body, 'address.Postal'),
                lat,
                lng,
                user: currentUser.id,
              },
            });
          });
      } else {
        this.setState({
          loading: false,
          initialValues: {
            created_at: new Date(),
            user: currentUser.id,
          },
        });
      }
    } else if (!_.isEmpty(locationData)) {
      this.setState({ loading: false, initialValues: locationData });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { locationData: currentLocationData } = this.props;
    const { locationData } = nextProps;
    const sameData = JSON.stringify(currentLocationData) === JSON.stringify(locationData);

    if (!_.isEmpty(locationData) && !sameData) {
      this.setState({ initialValues: locationData, loading: false });
    }
  }

  async handleSubmit() {
    const {
      match: { params: { action } },
      patch,
      create,
      formData: currentData,
      history,
      currentUser,
      isAdmin,
    } = this.props;
    let fields = [
      'city',
      'country',
      'created_at',
      'description',
      'tags',
      'lat',
      'lng',
      'organization',
      'postalcode',
      'street',
      'what_3_words'
    ];

    const isOrganization = _.get(currentData, 'organization', false);
    let organizationAdmin = _.get(currentUser, 'organization');
    let organizationMember = _.get(currentUser, 'organization_member');
    let organization = !_.isEmpty(organizationAdmin) ? organizationAdmin : organizationMember;

    if (isAdmin) {
      fields = [...fields, 'user'];

      if (currentUser.id !== currentData.user) {
        await getUser(currentData.user)
          .then(({ body }) => {
            organizationAdmin = _.get(body, 'organization');
            organizationMember = _.get(body, 'organization_member');
            organization = !_.isEmpty(organizationAdmin) ? organizationAdmin : organizationMember;
          });
      }
    }

    const formData = {
      ...currentData,
      organization: isOrganization && !_.isEmpty(organization) ? organization.id : '',
    };

    if (action === 'edit') {
      patch(formData.id, _.pick(formData, fields))
        .then(({ type, data }) => {
          if (type === C.PATCH_LOCATION_SUCCESS) {
            history.push(`/app/location/edit/${data.id}/content/`);
          }
        });
    } else {
      const dataWithUser = {
        ...formData,
        user: isAdmin ? formData.user : currentUser.id,
      };

      create(dataWithUser)
        .then(({ type, data }) => {
          if (type === C.CREATE_LOCATION_SUCCESS) {
            history.push(`/app/location/edit/${data.id}/content/`);
          }

          if (type === C.CREATE_LOCATION_ERROR) {
            Alert.error('This location does not have postal code. Please try to refine your address.');
          }
        });
    }
  }

  render() {
    const { initialValues, loading } = this.state;

    if (loading) {
      return <Loader active inline="centered" />;
    }

    return (
      <LocationDetailForm
        initialValues={initialValues}
        onSubmit={this.handleSubmit}
      />
    );
  }
}

LocationDetailFormContainer.defaultProps = {
  location: {
    state: {},
  },
};

LocationDetailFormContainer.propTypes = {
  action: PropTypes.string.isRequired,
  create: PropTypes.func.isRequired,
  patch: PropTypes.func.isRequired,
  currentUser: PropTypes.object, // eslint-disable-line
  location: PropTypes.object, // eslint-disable-line
  locationData: PropTypes.object.isRequired, // eslint-disable-line
  match: PropTypes.object.isRequired, // eslint-disable-line
  history: PropTypes.object.isRequired, // eslint-disable-line
  formData: PropTypes.object.isRequired, // eslint-disable-line
};

function mapStateToProps(state) {
  return {
    isAdmin: STATEAPI.isAdmin(state),
    currentUser: _.get(state, 'session.user'),
    locationData: _.get(state, 'location.data', {}),
    formData: _.get(state.form, 'locationDetailForm.values', {}),
  };
}

const mapDispatchToProps = {
  create: LOCATION_ACTIONS.create,
  patch: LOCATION_ACTIONS.patch,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LocationDetailFormContainer));
