
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  Grid,
  Header,
  Form,
  Button,
  Divider,
} from 'semantic-ui-react';
import * as Alert from 'utils/alert';
import AuthHOC from 'containers/AuthHOC';
import * as C from 'actions/constants';
import * as USER_ACTIONS from 'actions/user';

class UnsubscribePage extends Component {
  constructor(props) {
    super(props);

    this.handleUnsubscribe = this.handleUnsubscribe.bind(this);
  }

  componentDidMount() {
    const { match: { params } } = this.props;
    const uid = _.get(params, 'uid');

    if (!_.isEmpty(uid)) {
      this.handleUnsubscribe(uid);
    }
  }

  handleUnsubscribe(uid) {
    const { unsubscribe } = this.props;

    unsubscribe(uid);
  }

  render() {
    return (
      <div>
        <Grid.Row style={{ marginTop: '20px' }}>
          <Header as="h3" textAlign="center">Du hast dich erfolgreich von unseren Benachrichtigungen abgemeldet</Header>
        </Grid.Row>

        <Grid centered>
          <Grid.Column computer={12} tablet={12} mobile={16}>
            <Grid.Row style={{ marginTop: '50px', textAlign: 'center' }}>
              <p>Zukünftig wirst du keine weiteren E-Mails mehr von unserer localexpert24-Plattform erhalten.</p>
            </Grid.Row>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: _.get(state, 'session.user'),
});

const mapDispatchToProps = {
  unsubscribe: USER_ACTIONS.unsubscribe,
};

const ConnectedUnsubscribePage = connect(mapStateToProps, mapDispatchToProps)(UnsubscribePage);

export default withRouter(AuthHOC(ConnectedUnsubscribePage));
