import API from 'api';
import { submit } from 'redux-form';
import * as C from './constants';
import receiveNetworkException from './network';
import error from './error';
import * as LOCATION_SERIALIZERS from './serializers/location';
import { filterLocationsByBounds } from './map';

export const submitDetailForm = () => dispatch => dispatch(submit('locationDetailForm'));

export const create = body => (dispatch) => {
  dispatch({ type: C.CREATE_LOCATION });

  return API.location.create(LOCATION_SERIALIZERS.patch(body))
    .then(({ body: data }) => dispatch({
      type: C.CREATE_LOCATION_SUCCESS,
      data: LOCATION_SERIALIZERS.get(data),
    }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.CREATE_LOCATION_ERROR, {
        message: 'Failed to create location',
        description: 'Please contact server admin.',
        data: err.data,
      }));
    });
};

export const list = params => (dispatch) => {
  dispatch({ type: C.GET_LOCATIONS_LIST });

  return API.location.get(null, params)
    .then(({
      body: data,
    }) => dispatch({ type: C.GET_LOCATIONS_LIST_SUCCESS, list: LOCATION_SERIALIZERS.listPaginated(data) }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.GET_LOCATIONS_LIST_ERROR, {
        message: 'Failed to get location data',
        description: 'Please contact server admin.',
        data: err.data,
      }));
    });
};

export const mapLocations = params => (dispatch) => {
  dispatch({ type: C.GET_MAP_LOCATIONS_LIST });

  return API.location.mapLocations(params)
    .then(({
      body: data,
    }) => dispatch({
      type: C.GET_MAP_LOCATIONS_LIST_SUCCESS,
      list: LOCATION_SERIALIZERS.list(data),
    }))
    .then(() => {
      dispatch(filterLocationsByBounds());
    })
    .catch((err) => {
      console.log('err', err); // eslint-disable-line

      dispatch(receiveNetworkException(err));
      return dispatch(error(C.GET_MAP_LOCATIONS_LIST_ERROR, {
        message: 'Failed to get location data',
        description: 'Please contact server admin.',
        data: err.data,
      }));
    });
};

export const mapLocationsQuery = params => (dispatch) => {
  dispatch({ type: C.GET_MAP_LOCATIONS_LIST });

  return API.location.mapLocationsQuery(params)
    .then(({
      body: data,
    }) => dispatch({
      type: C.GET_MAP_LOCATIONS_LIST_SUCCESS,
      list: LOCATION_SERIALIZERS.list(data),
    }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.GET_MAP_LOCATIONS_LIST_ERROR, {
        message: 'Failed to get location data',
        description: 'Please contact server admin.',
        data: err.data,
      }));
    });
};

export const mapLocationsBookmarked = () => (dispatch) => {
  dispatch({ type: C.GET_MAP_LOCATIONS_LIST });

  return API.location.mapLocationsBookmarked()
    .then(({
      body: data,
    }) => dispatch({
      type: C.GET_MAP_LOCATIONS_LIST_SUCCESS,
      list: LOCATION_SERIALIZERS.list(data),
    }))
    .then(() => {
      dispatch(filterLocationsByBounds());
    })
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.GET_MAP_LOCATIONS_LIST_ERROR, {
        message: 'Failed to get location data',
        description: 'Please contact server admin.',
        data: err.data,
      }));
    });
};

export const get = uuid => (dispatch) => {
  dispatch({ type: C.GET_LOCATION });

  return API.location.get(uuid)
    .then(({
      body: data,
    }) => dispatch({ type: C.GET_LOCATION_SUCCESS, data: LOCATION_SERIALIZERS.get(data) }))
};

export const mapLocationDetail = (id, query) => (dispatch) => {
  dispatch({ type: C.GET_MAP_LOCATION_DETAIL });

  return API.location.mapLocationDetail(id, query)
    .then(({
      body: data,
    }) => dispatch({ type: C.GET_MAP_LOCATION_DETAIL_SUCCESS, data: LOCATION_SERIALIZERS.get(data) }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.GET_MAP_LOCATION_DETAIL_ERROR, {
        message: 'Failed to get location data',
        description: 'Please contact server admin.',
        data: err.data,
      }));
    });
};

export const patch = (id, body) => (dispatch) => {
  dispatch({ type: C.PATCH_LOCATION });

  return API.location.patch(id, LOCATION_SERIALIZERS.patch(body))
    .then(({
      body: locationData,
    }) => dispatch({
      type: C.PATCH_LOCATION_SUCCESS,
      data: LOCATION_SERIALIZERS.get(locationData),
    }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.PATCH_LOCATION_ERROR, {
        message: 'Failed to update location details',
        description: 'Please contact server admin.',
        data: err.data,
      }));
    });
};

export const uploadDocument = (id, body) => (dispatch) => {
  dispatch({ type: C.UPLOAD_LOCATION_DOCUMENT });

  const patchData = new FormData();
  patchData.append('file_url', body.file_url);
  patchData.append('location', id);

  return API.location.uploadDocument(id, patchData)
    .then(({
      body: data,
    }) => dispatch({
      type: C.UPLOAD_LOCATION_DOCUMENT_SUCCESS,
      data,
    }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.UPLOAD_LOCATION_DOCUMENT_ERROR, {
        message: 'Failed to upload location document',
        description: 'Please contact server admin.',
        data: err.data,
      }));
    });
};

export const uploadContent = (id, body) => (dispatch) => {
  dispatch({ type: C.UPLOAD_LOCATION_CONTENT });

  const patchData = new FormData();
  patchData.append('id', id);
  patchData.append('file_url', body.file_url);
  patchData.append('type', body.type);
  patchData.append('location', body.location);

  return API.location.uploadContent(id, patchData)
    .then(({
      body: data,
    }) => dispatch({
      type: C.UPLOAD_LOCATION_CONTENT_SUCCESS,
      data,
    }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.UPLOAD_LOCATION_CONTENT_ERROR, {
        message: 'Failed to upload location content',
        description: 'Please contact server admin.',
        data: err.data,
      }));
    });
};

export const rotateContent = data => (dispatch) => {
  dispatch({ type: C.ROTATE_LOCATION_CONTENT });

  return API.location.rotateContent(data)
    .then(({ body }) => dispatch({ type: C.ROTATE_LOCATION_CONTENT_SUCCESS, data: body }));
};

export const deleteDocument = id => (dispatch) => {
  dispatch({ type: C.DELETE_LOCATION_DOCUMENT });

  return API.location.deleteDocument(id)
    .then(() => dispatch({ type: C.DELETE_LOCATION_DOCUMENT_SUCCESS, id }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.DELETE_LOCATION_DOCUMENT_ERROR, {
        message: 'Failed to delete location document',
        description: 'Please contact server admin.',
        data: err.data,
      }));
    });
};

export const deleteContent = id => (dispatch) => {
  dispatch({ type: C.DELETE_LOCATION_CONTENT });

  return API.location.deleteContent(id)
    .then(() => dispatch({ type: C.DELETE_LOCATION_CONTENT_SUCCESS, id }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.DELETE_LOCATION_CONTENT_ERROR, {
        message: 'Failed to delete location content',
        description: 'Please contact server admin.',
        data: err.data,
      }));
    });
};

export const isBookmarked = data => (dispatch) => {
  dispatch({ type: C.CHECK_BOOKMARK_LOCATION });

  return API.location.isBookmarked(data)
    .then(({
      body,
    }) => dispatch({ type: C.CHECK_BOOKMARK_LOCATION_SUCCESS, data: body }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.CHECK_BOOKMARK_LOCATION_ERROR, {
        message: 'Failed to check if location is bookmarked',
        description: 'Please contact server admin.',
        data: err.data,
      }));
    });
};

export const bookmark = data => (dispatch) => {
  dispatch({ type: C.BOOKMARK_LOCATION });
  dispatch({ type: C.CHECK_BOOKMARK_LOCATION });

  return API.location.bookmark(data)
    .then(() => {
      dispatch({ type: C.BOOKMARK_LOCATION_SUCCESS });
      dispatch({ type: C.CHECK_BOOKMARK_LOCATION_SUCCESS, data });
    })
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.BOOKMARK_LOCATION_ERROR, {
        message: 'Failed to insert bookmark',
        description: 'Please contact server admin.',
        data: err.data,
      }));
    });
};

export const deleteBookmark = data => (dispatch) => {
  dispatch({ type: C.DELETE_LOCATION_BOOKMARK });
  dispatch({ type: C.CHECK_BOOKMARK_LOCATION });

  return API.location.deleteBookmark(data)
    .then(() => {
      dispatch({ type: C.DELETE_LOCATION_BOOKMARK_SUCCESS });
      dispatch({ type: C.CHECK_BOOKMARK_LOCATION_SUCCESS });
    })
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.DELETE_LOCATION_BOOKMARK_ERROR, {
        message: 'Failed to delete bookmark',
        description: 'Please contact server admin.',
        data: err.data,
      }));
    });
};

export const isVoted = data => (dispatch) => {
  dispatch({ type: C.CHECK_LOCATION_VOTE });

  return API.location.isVoted(Object.assign({}, data, { active: 'True' }))
    .then(({ body }) => dispatch({ type: C.CHECK_LOCATION_VOTE_SUCCESS, data: body }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.CHECK_LOCATION_VOTE_ERROR, {
        message: 'Failed to check if is voted',
        description: 'Please contact server admin.',
        data: err.data,
      }));
    });
};

export const countVotes = locationId => (dispatch) => {
  dispatch({ type: C.GET_LOCATION_VOTE_COUNT });

  return API.location.countVotes(locationId)
    .then(({ body: { count } }) => {
      dispatch({ type: C.GET_LOCATION_VOTE_COUNT_SUCCESS, count });
    })
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.GET_LOCATION_VOTE_COUNT_ERROR, {
        message: 'Failed to get votes count',
        description: 'Please contact server admin.',
        data: err.data,
      }));
    });
};

export const vote = data => (dispatch) => {
  dispatch({ type: C.VOTE_LOCATION });
  dispatch({ type: C.CHECK_LOCATION_VOTE });

  return API.location.vote(data)
    .then(() => {
      dispatch({ type: C.VOTE_LOCATION_SUCCESS });
      dispatch(countVotes(data.location));
      dispatch({ type: C.CHECK_LOCATION_VOTE_SUCCESS, data });
    })
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.VOTE_LOCATION_ERROR, {
        message: 'Failed to insert vote',
        description: 'Please contact server admin.',
        data: err.data,
      }));
    });
};

export const deleteVote = data => (dispatch) => {
  dispatch({ type: C.DELETE_LOCATION_VOTE });
  dispatch({ type: C.CHECK_LOCATION_VOTE });

  return API.location.deleteVote(data)
    .then(() => {
      dispatch({ type: C.DELETE_LOCATION_VOTE_SUCCESS });
      dispatch(countVotes(data.location));
      dispatch({ type: C.CHECK_LOCATION_VOTE_SUCCESS });
    })
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.DELETE_LOCATION_VOTE_ERROR, {
        message: 'Failed to delete vote',
        description: 'Please contact server admin.',
        data: err.data,
      }));
    });
};

export const deleteLocation = id => (dispatch) => {
  dispatch({ type: C.DELETE_LOCATION });

  return API.location.deleteLocation(id)
    .then(() => dispatch({ type: C.DELETE_LOCATION_SUCCESS }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.DELETE_LOCATION_ERROR, {
        message: 'Failed to get location data',
        description: 'Please contact server admin.',
        data: err.data,
      }));
    });
};

export const massImport = file => (dispatch) => {
  dispatch({ type: C.MASS_IMPORT });

  let postData = new FormData();
  postData.append('file', file);

  return API.location.massImport(postData)
    .then(({ body: { data }}) => dispatch({ type: C.MASS_IMPORT_SUCCESS, data }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.MASS_IMPORT_ERROR, {
        message: 'Failed to mass import locations',
        description: 'Please contact server admin.',
        data: err.data,
      }));
    });
};

export const uploadFileIFC = (id, body) => (dispatch) => {
  dispatch({ type: C.UPLOAD_LOCATION_FILE_IFC });

  const patchData = new FormData();
  patchData.append('file_url', body.get('file'));
  patchData.append('location', id);
  patchData.append('index', body.get('index'));
  patchData.append('chunkCount', body.get('chunkCount'));
  patchData.append('timestamp', body.get('timestamp'));
  patchData.append('userId', body.get('userId'));
  patchData.append('idFileIFC', body.get('idFileIFC'));

  return API.location.uploadFileIFC(id, patchData)
    .then(({ body: data }) => {
      const currentChunk = body.get('index');
      const chunkCount = body.get('chunkCount');
      if (currentChunk == chunkCount - 1) {
        dispatch({
          type: C.UPLOAD_LOCATION_FILE_IFC_SUCCESS,
          data,
        });
      }
    })
    .catch((err) => {
      if (err.status === 409 && err.data.message === 'the location exist IFC file') {
        return 'the location exist IFC file';
      }
      dispatch(receiveNetworkException(err));
      dispatch(
        error(C.UPLOAD_LOCATION_FILE_IFC_ERROR, {
          message: 'Failed to upload location file IFC',
          description: 'Please contact server admin.',
          data: err.data,
        })
      );
    });
};

export const deleteFIleIFC = id => (dispatch) => {
  dispatch({ type: C.DELETE_LOCATION_FILE_IFC });

  return API.location.deleteDocument(id)
    .then(() => dispatch({ type: C.DELETE_LOCATION_FILE_IFC_SUCCESS, id }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.DELETE_LOCATION_FILE_IFC_ERROR, {
        message: 'Failed to delete location file IFC',
        description: 'Please contact server admin.',
        data: err.data,
      }));
    });
};

