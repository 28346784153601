import React from 'react';
import styled from 'styled-components';
import {
  Grid,
  Divider,
  Label,
  Segment,
  Image,
  Icon,
} from 'semantic-ui-react';
import * as USER_CONSTS from 'const/user';
import RankYoung from 'assets/images/rank-young.svg';
import RankDiamond from 'assets/images/rank-diamond.svg';
import RankGold from 'assets/images/rank-gold.svg';
import RankSilver from 'assets/images/rank-silver.svg';
import RankBronze from 'assets/images/rank-bronze.svg';
import PremiumBadge from 'assets/images/premium-badge.svg';
import ExpertByteIcon from 'assets/images/expert-byte-white.svg';

export const InfoItem = ({ link, href, colProps, title, value, displayName }) => (
  <Grid.Column {...colProps}>
    <div style={{ textTransform: 'uppercase', marginBottom: 0, color: '#969696' }}>
      {title} <Divider style={{ marginTop: 0, marginBottom: '5px' }} />
    </div>
    {link ? (
      <a href={href || value} target={href ? '' : '_blank'} rel="noopener noreferrer">{value ? displayName || value : ''}</a>
    ) : value}
  </Grid.Column>
);

const RankImage = (rank) => ({
  "bronze": RankBronze,
  "silver": RankSilver,
  "gold": RankGold,
  "diamond": RankDiamond,
  "young": RankYoung,
})[rank]

export const RankLabel = ({labelProps, rank}) => (
  <Label {...labelProps}>
    <img src={RankImage(rank)}/>
    {USER_CONSTS.RANKS[rank]}
  </Label>
);

export const PremiumLabel = ({ labelProps }) => (
  <Label {...labelProps}>
    <img src={PremiumBadge}/>
    <span style={{ marginLeft: '10px' }}>Premium</span>
  </Label>
);

export const ExpertByteLabelLarge = ({ expertBytes }) => (
  <Segment compact inverted style={{ backgroundColor: '#2185D0', display: 'flex', flexDirection: 'column', minWidth: '200px', alignItems: 'center' }}>
    <small>ExpertScore</small>
    <p style={{ fontSize: '40px', fontWeight: 'bold' }}>{expertBytes}</p>
  </Segment>
);

export const ExpertByteLabelSmall = ({ expertBytes, style = {} }) => (
  <Label
    size="small"
    style={Object.assign({}, {
      padding: '4px',
      position: 'absolute',
      bottom: 0,
      backgroundColor: '#2185D0',
      color: 'white',
      width: '56px',
      left: '50%',
      marginLeft: '-28px',
      textAlign: 'center',
    }, style)}
  >
    <Icon style={{ marginRight: '4px' }} name="ticket" />{expertBytes}
  </Label>
);

export const UserTitlePicture = styled.img`
  width: 100%;
  height: 240px;
  display: block;
  object-fit: cover;
  object-position: center center;
  ${({ loading }) => loading && 'filter: grayscale();'}
`;

export const UserProfilePicture = styled.img`
  width: ${({ size }) => (size ? `${size}px !important` : '180px')};
  height: ${({ size }) => (size ? `${size}px !important` : '180px')};
  border-radius: 50%;
  display: block;
  object-fit: cover;
  object-position: center center;
  ${({ loading }) => loading && 'filter: grayscale();'}
`;

export default InfoItem;
