import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Select,
  Form,
} from 'semantic-ui-react';
import { toComponent } from 'utils/select';

class TagSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
      loading: false,
    };
  }

  componentDidMount() {
    const { tagType, filterTags } = this.props;

    this.setState({ loading: true }, () => {
      filterTags(tagType, '')
        .then(({ body }) => {
          const options = _.isEmpty(body) ? [] : _.uniqBy([...toComponent(body)], 'key');

          return this.setState({
            options,
            loading: false,
          });
        });
    });
  }

  render() {
    const {
      input,
      placeholder,
      multiple,
      disabled,
      meta: {
        touched,
        error,
      },
    } = this.props;
    const { options, loading } = this.state;

    return (
      <Form.Field>
        <Select
          fluid
          disabled={disabled}
          value={input.value || []}
          loading={loading}
          multiple={multiple}
          minCharacters={3}
          name={input.name}
          options={options}
          placeholder={placeholder}
          onChange={(e, { value }) => input.onChange(value)}
        />
        {touched && error && <span className="ui negative message mini">{error}</span>}
      </Form.Field>
    );
  }
}

TagSelect.defaultProps = {
  multiple: false,
  disabled: false,
};

TagSelect.propTypes = {
  input: PropTypes.object, // eslint-disable-line
  filterTags: PropTypes.func.isRequired,
  placeholder: PropTypes.string.isRequired,
  tagType: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired, // eslint-disable-line
  multiple: PropTypes.bool,
  disabled: PropTypes.bool,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
};

export default TagSelect;
