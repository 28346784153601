import React, { Component } from 'react';
import Breakpoint from 'react-socks';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Dropdown,
  Icon,
  Progress,
  Label,
} from 'semantic-ui-react';
import _ from 'lodash';
import * as SESSION_ACTIONS from 'actions/session';
import * as TOUR_ACTIONS from 'actions/tour';
import * as USER_ACTIONS from 'actions/user';
import * as USER_CONSTS from 'const/user';
import * as STATEAPI from 'utils/stateapi';
import { UserProfilePicture, RankLabel } from 'modules/styled/profile';

class UserDropdown extends Component {
  constructor(props) {
    super(props);

    this.handleSignOut = this.handleSignOut.bind(this);
    this.handleOpenTour = this.handleOpenTour.bind(this);
    this.handleInviteClick = this.handleInviteClick.bind(this);
  }

  handleSignOut() {
    const { history, signOut, token } = this.props;

    signOut(token)
      .then(() => {
        history.push('/auth/logout');
      });
  }

  handleOpenTour() {
    const { history, openTour } = this.props;
    history.push('/app/');
    openTour();
  }

  handleInviteClick() {
    const { openInvitesModal } = this.props;

    openInvitesModal();
  }

  render() {
    const { history, user, userIsAdmin, planType } = this.props;
    const organizationId = _.get(user, 'organization.id', _.get(user, 'organization_member.id'));
    const hasOrganization = !_.isEmpty(organizationId);
    const profileImage = !_.isEmpty(_.get(user, 'avatar_thumbnail_url')) ? _.get(user, 'avatar_thumbnail_url') : _.get(user, 'avatar_image_url');
    const percent = USER_CONSTS.getRankPercentage(user.rank, user.expert_bytes);
    const userOrganization = _.get(user, 'organization.name', _.get(user, 'organization_member.name'));
    const organizationMembers = !_.isEmpty(_.get(user, 'organization_members')) ? _.get(user, 'organization_members', []).filter(member => member.id !== organizationId) : [];
    const userIsPremium = !_.isEmpty(planType);
    const organizationUrl = () => {
      if ((planType === 'orga_paid_plan' && !user.is_premium) || planType === 'orga_paid_plan_payment_pending' || planType === 'paid_plan_individual_payment_pending') {
        return '/app/premium'
      }

      return `/app/organizations/${hasOrganization ? organizationId : 'create'}`
    }

    return (
      <Dropdown
        className="reactour__user-dropdown user-dropdown"
        fluid
        icon={(
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {profileImage ? (
              <UserProfilePicture size={42} src={profileImage} />
            ) : (
              <Icon
                circular
                inverted
                color="yellow"
                name="user"
                size="large"
              />
            )}
            <Breakpoint medium up>
              <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '10px' }}>
                <span><strong>{user.first_name} {user.last_name} {userIsAdmin && (<Label color="red" size="mini" circular>Admin</Label>)}</strong></span>
                <p style={{ color: '#aaa' }}>{userOrganization}</p>
              </div>
            </Breakpoint>
          </div>
        )}
      >
        <Dropdown.Menu style={{ marginTop: '5px' }}>
          <Dropdown.Item icon="send" text="Jemanden zu LE24 einladen" onClick={this.handleInviteClick} />
          <Dropdown.Item
            icon="star"
            text={userIsPremium ? 'Meine Premium-Mitgliedschaft' : 'Jetzt Premium-Mitglied werden'}
            onClick={() => history.push(`/app/premium${!userIsPremium ? '-features' : ''}`)}
          />
          <Dropdown.Divider />
          <Dropdown.Item style={{ textAlign: 'center' }} onClick={() => history.push('/app/timeline')}>
            <RankLabel
              labelProps={{
                style: { marginBottom: '5px', marginRight: '0px' },
              }}
              rank={user.rank}
            />
            <p style={{ color: '#ccc', fontSize: '12px' }}>{USER_CONSTS.getRankProgressLabel(user.rank, user.expert_bytes)}</p>
            {(['diamond', 'young', 'gold'].indexOf(user.rank) === -1) && (
              <Progress style={{ marginBottom: '0px' }} size="small" percent={percent} color={USER_CONSTS.getRankProgressColor(percent)} />
            )}
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item icon="question circle" text="Tour" onClick={this.handleOpenTour} />
          <Dropdown.Divider />
          <Dropdown.Item icon="user circle" text="Mein Profil" onClick={() => history.push(`/app/users/${_.get(user, 'id')}`)} />
          <Dropdown.Item icon="building" text="Meine Organisation" onClick={() => history.push(organizationUrl())} />
          { organizationMembers.length > 0 && (
              organizationMembers.map(member => (<Dropdown.Item icon="building" text={_.get(member, 'name')} onClick={() => window.location.assign(`/app/organizations/${_.get(member, 'id')}`)} />))
            )
          }
          <Dropdown.Divider />
          <Dropdown.Item icon="download" text="Downloads" onClick={() => history.push('/app/downloads')} />
          <Dropdown.Item icon="cog" text="Einstellungen" onClick={() => history.push('/app/settings')} />
          <Dropdown.Item icon="mail" text="Impressum/Kontakt" onClick={() => window.open('https://www.localexpert24.de/index.php/impressum')} />
          <Dropdown.Divider />
          <Dropdown.Item icon="sign out" text="Ausloggen" onClick={this.handleSignOut} />
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

UserDropdown.defaultProps = {
  token: '',
  user: {},
};

UserDropdown.propTypes = {
  signOut: PropTypes.func.isRequired,
  openTour: PropTypes.func.isRequired,
  openInvitesModal: PropTypes.func.isRequired,
  userIsPremium: PropTypes.bool,
  userIsAdmin: PropTypes.bool,
  token: PropTypes.string,
  user: PropTypes.object, // eslint-disable-line
  history: PropTypes.object.isRequired, // eslint-disable-line
};

const mapStateToProps = state => {
  const user = _.get(state, 'session.user')
  const planType = _.get(user, 'plan_type')

  return {
    user,
    planType,
    token: _.get(state, 'session.data.access_token'),
    userIsAdmin: STATEAPI.isAdmin(state),
  }
};

const mapDispatchToProps = {
  signOut: SESSION_ACTIONS.signOut,
  openTour: TOUR_ACTIONS.open,
  openInvitesModal: USER_ACTIONS.openInvitesModal,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserDropdown));
