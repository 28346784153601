import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Grid,
  Header,
  Segment,
  Button,
  Modal,
} from 'semantic-ui-react';
import difference from 'utils/difference';
import AuthHOC from 'containers/AuthHOC';
import * as C from 'actions/constants';
import * as Alert from 'utils/alert';
import EventForm from 'modules/forms/Event';
import * as EVENT_ACTIONS from 'actions/event';
import * as STATEAPI from 'utils/stateapi';

class EventCreate extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleReturn = this.handleReturn.bind(this);
  }

  componentDidMount() {
    const {
      changeBlocked,
      loadEvent,
      canEdit,
      history,
      match: { params: { action, id } }
    } = this.props;

    changeBlocked(true);

    if (action === 'edit' && !_.isEmpty(id)) {
      if (canEdit) {
        loadEvent(id);
      } else {
        history.push('/app/events');
      }
    }
  }

  handleSubmit() {
    const {
      create,
      history,
      user,
      event,
      formData,
      patch,
      match: { params: { action, id } },
    } = this.props;
    if (action === 'edit' && !_.isEmpty(id)) {
      const diff = difference(formData, event);

      if (!_.isEmpty(diff)) {
        patch(event.id, diff)
          .then(({ type }) => {
            if (type === C.PATCH_EVENT_SUCCESS) {
              Alert.success('Termin wurden geändert!');
              history.push('/app/events');
            }
          })
          .catch(() => {
            Alert.error('Termin konnte nicht geändert werden!');
          });
      } else {
        history.push('/app/events');
      }
    } else {
      const dataWithUser = {
        ...formData,
        user: user.id,
      };

      create(dataWithUser)
        .then(({ type }) => {
          if (type === C.CREATE_EVENT_SUCCESS) {
            history.push('/app/events/');
          }
        });
    }
  }

  handleReturn() {
    const { history } = this.props;

    history.push('/app/events');
  }

  render() {
    const { event, loading, match: { params: { action } } } = this.props;
    const showForm = action === 'create' || (action === 'edit' && !loading && !_.isEmpty(event));

    return (
      <Grid centered style={{ zIndex: 2 }}>
        <Grid.Column computer={14} tablet={14} mobile={14} style={{ zIndex: 2 }}>
          <Segment attached="bottom" style={{ padding: '20px', display: 'relative' }}>
            <Header as="h2" textAlign="center" style={{ marginBottom: '40px' }}>
            {action === 'edit' ? (
              <span>Termin bearbeiten</span>
            ) : (
              <span>Neuer Termin</span>
            )}</Header>
            <Grid style={{ justifyContent: 'center' }}>
              <Grid.Column width={15}>
                {showForm && (
                  <EventForm
                    initialValues={action === 'edit' ? event : {}}
                    onSubmit={this.handleSubmit}
                    handleReturn={this.handleReturn}
                  />
                )}
              </Grid.Column>
            </Grid>
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

EventCreate.defaultProps = {
  canEdit: false,
  loading: true,
  event: {},
};

EventCreate.propTypes = {
  changeBlocked: PropTypes.func.isRequired,
  loadEvent: PropTypes.func.isRequired,
  create: PropTypes.func.isRequired,
  patch: PropTypes.func.isRequired,
  canEdit: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  events: PropTypes.array, // eslint-disable-line
  history: PropTypes.object.isRequired, // eslint-disable-line
  match: PropTypes.object.isRequired, // eslint-disable-line
  user: PropTypes.object.isRequired, // eslint-disable-line
};

function mapStateToProps(state) {
  const user = STATEAPI.getCurrentUser(state);
  const isAdmin = STATEAPI.isAdmin(state);
  const event = _.get(state, 'event.data', {});
  return {
    user,
    canEdit: isAdmin || _.get(event, 'user.id') === user.id,
    event: _.get(state, 'event.data', {}),
    formData: _.get(state, 'form.event.values', {}),
    loading: _.get(state, 'event.loading', true),
  };
}

const mapDispatchToProps = {
  loadEvent: EVENT_ACTIONS.get,
  create: EVENT_ACTIONS.create,
  patch: EVENT_ACTIONS.patch,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthHOC(EventCreate));
