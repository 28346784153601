import API from 'api';
import { submit } from 'redux-form';
import _ from 'lodash';
import * as C from './constants';
import receiveNetworkException from './network';
import error from './error';
import * as UserSerializers from './serializers/user';

export const submitOrgaPaymentDetailForm = () => dispatch => dispatch(submit('orgaPaymentDetail'));
export const submitUserPaymentDetailForm = () => dispatch => dispatch(submit('userPaymentDetail'));

export const register = body => (dispatch) => {
  dispatch({ type: C.REGISTER_USER });

  return API.user.register(body)
    .then(({ body: data }) => dispatch({ type: C.REGISTER_USER_SUCCESS, data }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.REGISTER_USER_ERROR, {
        message: 'Benutzer konnte nicht registriert werden.',
        description: 'Bitte kontaktiere den Support.',
        data: err.data,
      }));
    });
};

export const syncUser = () => (dispatch) => {
  dispatch({ type: C.SYNC_USER });

  return API.user.syncUser()
    .then(() => dispatch({ type: C.SYNC_USER_SUCCESS }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.SYNC_USER_ERROR, {
        message: 'Benutzerdaten konnten nicht abgerufen werden.',
        description: 'Bitte kontaktiere den Support.',
        data: err.data,
      }));
    });
};

export const refreshLocalStorage = userId => (dispatch) => {
  dispatch({ type: C.REFRESH_USER_DATA });

  return API.user.get(userId)
    .then(({
      body: data,
    }) => {
      const user = UserSerializers.get(data);
      localStorage.setItem('user', JSON.stringify(user));
      return dispatch({ type: C.REFRESH_USER_DATA_SUCCESS, data: user });
    })
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.REFRESH_USER_DATA_ERROR, {
        message: 'Benutzerdaten konnten nicht abgerufen werden.',
        description: 'Bitte kontaktiere den Support.',
        data: err.data,
      }));
    });
};

let timer = null;
export const syncUserPeriodic = userId => (dispatch) => {
  clearInterval(timer);

  return dispatch(syncUser())
    .then(() => dispatch(refreshLocalStorage(userId)))
    .then(() => {
      timer = setInterval(() => dispatch(syncUserPeriodic(userId)), 1000 * 60 * 5);
    });
};

export const login = params => (dispatch) => {
  dispatch({ type: C.LOGIN_USER });

  return API.user.login(params)
    .then(({ body: data }) => data.id)
    .then(id => dispatch(syncUserPeriodic(id)))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.LOGIN_USER_ERROR, {
        message: 'Benutzerdaten konnten nicht abgerufen werden.',
        description: 'Bitte kontaktiere den Support.',
        data: err.data,
      }));
    });
};

export const get = uuid => (dispatch) => {
  dispatch({ type: C.GET_USER });

  return API.user.get(uuid)
    .then(({
      body: data,
    }) => dispatch({ type: C.GET_USER_SUCCESS, data: UserSerializers.get(data) }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.GET_USER_ERROR, {
        message: 'Benutzerdaten konnten nicht abgerufen werden.',
        description: 'Bitte kontaktiere den Support.',
        data: err.data,
      }));
    });
};

export const deleteUser = id => (dispatch) => {
  dispatch({ type: C.DELETE_USER });

  return API.user.deleteUser(id)
    .then(() => dispatch({ type: C.DELETE_USER_SUCCESS }));
};

export const list = params => (dispatch) => {
  dispatch({ type: C.LIST_USERS });

  return API.user.get(null, params)
    .then(({
      body: data,
    }) => dispatch({ type: C.LIST_USERS_SUCCESS, ...data }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.LIST_USERS_ERROR, {
        message: 'Benutzer konnten nicht aufgelistet werden.',
        description: 'Bitte kontaktiere den Support.',
        data: err.data,
      }));
    });
};

export const loadMore = url => (dispatch) => {
  dispatch({ type: C.LOAD_MORE_USERS });

  return API.user.loadMore(url)
    .then(({
      body: data,
    }) => dispatch({ type: C.LOAD_MORE_USERS_SUCCESS, ...data }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.LOAD_MORE_USERS_ERROR, {
        message: 'Weitere Benutzer konnten nicht aufgelistet werden.',
        description: 'Bitte kontaktiere den Support.',
        data: err.data,
      }));
    });
};

export const patch = (id, body) => (dispatch) => {
  dispatch({ type: C.PATCH_USER });
  const data = UserSerializers.patch(body);
  const dataKeys = Object.keys(data);
  const hasImage = !_.isEmpty(_.intersection(['avatar_image_url', 'title_image_url'], dataKeys));
  const contentType = hasImage ? 'application/x-www-form-urlencoded' : 'application/json';
  let patchData = data;

  if (hasImage) {
    patchData = new FormData();

    _.each(dataKeys, (key) => {
      patchData.append(key, data[key]);
    });
  }

  return API.user.patch(id, patchData, contentType)
    .then(({
      body: userData,
    }) => dispatch({ type: C.PATCH_USER_SUCCESS, data: UserSerializers.get(userData) }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.PATCH_USER_ERROR, {
        message: 'Benutzer konnte nicht aktualisiert werden.',
        description: 'Bitte kontaktiere den Support.',
        data: err.data,
      }));
    });
};

export const resetPassword = data => (dispatch) => {
  dispatch({ type: C.RESET_USER_PASSWORD });

  return API.user.resetPassword(data)
    .then(() => dispatch({ type: C.RESET_USER_PASSWORD_SUCCESS }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.RESET_USER_PASSWORD_ERROR, {
        message: 'Passwort konnte nicht zurückgesetzt werden.',
        description: 'Bitte kontaktiere den Support.',
        data: err.data,
      }));
    });
};

export const resetPasswordConfirm = data => (dispatch) => {
  dispatch({ type: C.RESET_USER_PASSWORD_CONFIRM });

  return API.user.resetPasswordConfirm(data)
    .then(() => dispatch({ type: C.RESET_USER_PASSWORD_CONFIRM_SUCCESS }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.RESET_USER_PASSWORD_CONFIRM_ERROR, {
        message: 'Passwort konnte nicht zurückgesetzt werden.',
        description: 'Bitte kontaktiere den Support.',
        data: err.data,
      }));
    });
};

export const confirmAccount = data => (dispatch) => {
  dispatch({ type: C.CONFIRM_ACCOUNT });
  return API.user.confirmAccount(data)
    .then(() => {
      localStorage.clear();

      return dispatch({ type: C.CONFIRM_ACCOUNT_SUCCESS });
    })
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.CONFIRM_ACCOUNT_ERROR, {
        message: 'Account konnte nicht verifiziert werden.',
        description: 'Bitte kontaktiere den Support.',
        data: err.data,
      }));
    });
};

export const resendConfirm = email => (dispatch) => {
  dispatch({ type: C.RESEND_CONFIRM_ACCOUNT });

  return API.user.resendConfirm(email)
    .then(() => dispatch({ type: C.RESEND_CONFIRM_ACCOUNT_SUCCESS }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.RESEND_CONFIRM_ACCOUNT_ERROR, {
        message: 'Verifizierungsmail konnte nicht erneut gesendet werden.',
        description: 'Bitte kontaktiere den Support.',
        data: err.data,
      }));
    });
};

export const unsubscribe = uid => (dispatch) => {
  dispatch({ type: C.UNSUBSCRIBE });

  return API.user.unsubscribe(uid)
    .then(() => dispatch({ type: C.UNSUBSCRIBE_SUCCESS }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.UNSUBSCRIBE_ERROR, {
        message: 'Abmeldung von E-Mails war nicht erfolgreich.',
        description: 'Bitte kontaktiere den Support.',
        data: err.data,
      }));
    });
};

export const activateTrial = () => (dispatch) => {
  dispatch({ type: C.ACTIVATE_TRIAL });

  return API.user.activateTrial()
    .then(({ body: data }) => {
      localStorage.setItem('user', JSON.stringify(data));

      dispatch({ type: C.REFRESH_USER_DATA_SUCCESS, data })
      return dispatch({ type: C.ACTIVATE_TRIAL_SUCCESS });
    })
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.ACTIVATE_TRIAL_ERROR, {
        message: 'Abmeldung von E-Mails war nicht erfolgreich.',
        description: 'Bitte kontaktiere den Support.',
        data: err.data,
      }));
    });
};

export const createInvoice = () => (dispatch) => {
  dispatch({ type: C.CREATE_INVOICE });

  return API.user.createInvoice()
    .then(() => dispatch({ type: C.CREATE_INVOICE_SUCCESS }));
};

export const createOrganizationInvoice = (data) => (dispatch) => {
  dispatch({ type: C.CREATE_ORGANIZATION_INVOICE });

  return API.user.createOrganizationInvoice(data)
    .then(() => dispatch({ type: C.CREATE_ORGANIZATION_INVOICE_SUCCESS }));
};

export const resendInvoice = () => (dispatch) => {
  dispatch({ type: C.RESEND_INVOICE });

  return API.user.resendInvoice()
    .then(() => dispatch({ type: C.RESEND_INVOICE_SUCCESS }));
};

export const openInvitesModal = () => dispatch => dispatch({ type: C.OPEN_INVITES_MODAL });
export const closeInvitesModal = () => dispatch => dispatch({ type: C.CLOSE_INVITES_MODAL });

export const sendInvites = invites => (dispatch) => {
  dispatch({ type: C.SEND_INVITES });

  return API.user.sendInvites(invites)
  .then(() => dispatch({ type: C.SEND_INVITES_SUCCESS }))
  .catch((err) => {
    dispatch(receiveNetworkException(err));
    return dispatch(error(C.SEND_INVITES_ERROR, {
      message: 'Die Einladungen konnten nicht verschickt werden.',
      description: 'Bitte kontaktiere den Support.',
      data: err.data,
    }));
  });
};

export const cancelSubscription = () => (dispatch) => {
  dispatch({ type: C.CANCEL_SUBSCRIPTION });

  return API.user.cancelSubscription()
    .then(({ body: data }) => data.id)
    .then(id => dispatch(syncUserPeriodic(id)))
    .then(() => dispatch({ type: C.CANCEL_SUBSCRIPTION_SUCCESS }))
};

export const registerSommerAktion = data => (dispatch) => {
  dispatch({ type: C.REGISTER_SOMMER_AKTION });

  return API.user.registerSommerAktion(data)
    .then(() => dispatch({ type: C.REGISTER_SOMMER_AKTION_SUCCESS }));
};
