import React, { Component } from 'react';
import _ from 'lodash';
import { Field } from 'redux-form';
import posed, { PoseGroup } from 'react-pose';
import {
  Grid,
  Icon,
  Button,
  Input,
  Segment,
} from 'semantic-ui-react';

const Item = posed.div({
  enter: {
    x: 0,
    opacity: 1,
  },
  exit: {
    x: 20,
    opacity: 0,
  },
});

const renderField = ({ input }) => (
  <p>{input.value}</p>
);

class renderRecommendedLinks extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(e, { value }) {
    this.setState({ value });
  }

  render() {
    const { fields } = this.props;
    const { value } = this.state;
    console.log('fields', fields.getAll()); // eslint-disable-line

    return (
      <Grid.Column width={15}>
        <Grid.Row style={{ marginBottom: '20px' }}>
          <Grid>
            <Grid.Column mobile={15} tablet={16} computer={16}>
              <Input action fluid placeholder="Empfohlener Link" onChange={this.handleInputChange}>
                <input value={value} />
                <Button
                  type="button"
                  onClick={(event) => {
                    event.preventDefault();

                    if (_.isEmpty(value)) {
                      return;
                    }

                    fields.push({ url: value.startsWith('http') ? value : `http://${value}` });
                    this.setState({ value: '' });
                  }}
                >
                  +
                </Button>
              </Input>
            </Grid.Column>
          </Grid>
        </Grid.Row>

        <Grid.Row>
          {!_.isEmpty(fields) && (
            <PoseGroup>
              {fields.map((link, index) => (
                <Item i={index} key={index} style={{ marginBottom: '15px' }}>
                  <Segment>
                    <Grid>
                      <Grid.Column computer={14} textAlign="left">
                        <Field
                          name={`${link}.url`}
                          component={renderField}
                        />
                      </Grid.Column>
                      <Grid.Column computer={2} textAlign="right">
                        <Icon name="delete" onClick={() => fields.remove(index)} style={{ cursor: 'pointer' }} />
                      </Grid.Column>
                    </Grid>
                  </Segment>
                </Item>
              ))}
            </PoseGroup>
          )}
        </Grid.Row>
      </Grid.Column>
    );
  }
}

export default renderRecommendedLinks;
