import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid,
  Card,
  Header,
  Button,
} from 'semantic-ui-react';
import AuthHOC from 'containers/AuthHOC';
import * as Alert from 'utils/alert';
import * as C from 'actions/constants';
import * as USER_ACTIONS from 'actions/user';

class Premium extends Component {
  constructor(props) {
    super(props);

    this.handleActivateTrial = this.handleActivateTrial.bind(this);
  }

  componentDidMount() {
    const { changeBlocked } = this.props;

    changeBlocked(true);
  }

  handleActivateTrial() {
    const { activateTrial, history } = this.props;

    activateTrial()
      .then(({ type }) => {

        if (type === C.ACTIVATE_TRIAL_SUCCESS) {
          Alert.success('Dein Testzeitraum für die Premium-Mitgliedschaft wurde aktiviert!');
          history.push('/app/premium');
        } else {
          Alert.error('Dein Testzeitraum für die Premium-Mitgliedschaft konnte nicht aktiviert werde. Bitte versuche es später noch einmal!');
        }
      });
  }

  render() {
    return (
      <Grid centered style={{ zIndex: 2 }}>
        <Grid.Column computer={14} tablet={14} mobile={14} style={{ zIndex: 2 }}>
          <Card fluid>
            <Card.Content style={{ textAlign: 'center' }}>
              <Grid.Row style={{ marginTop: '20px', marginBottom: '60px' }}>
                <img width="40%" alt="Premium-Mitgliedschaft" src="/images/premium_expert_header.png" />
              </Grid.Row>
              <Grid.Row style={{ marginBottom: '60px' }}>
                <Header as="h1" textAlign="center">Deine Vorteile der Premium-Mitgliedschaft</Header>
                <p>Als Premium-Mitglied erhältst du erweiterten Einblick in neue Karten-Ebenen.</p>
                <p>Zusatz-Dienstleistungen werden freigeschaltet.</p>
                <p>Baupunkte können archiviert werden.</p>
              </Grid.Row>
              <Grid.Row style={{ marginBottom: '60px' }}>
                <Button primary size="huge" onClick={this.handleActivateTrial}>Jetzt Premium-Mitglied werden*</Button>
                <p style={{ color: '#888' }}>* 30 Tage kostenlos testen. Danach nur 99,00 EUR / Jahr (inkl. MwSt.)</p>
              </Grid.Row>

              <Grid centered divided="vertically" style={{ marginBottom: '40px' }}>
                <Grid.Column computer={7} mobile={15} textAlign="left">
                  <Header as="h3">localexpert24 für lokale Experten mit Premium-Mitgliedschaft</Header>
                  <p style={{ fontSize: '1.2em', lineHeight: '1.5em' }}>Jeder Tiefbauakteur kann lokaler Experte werden. Ein beruflicher Bezug zum Tiefbau genügt. – ob du noch in deinem Studium oder deiner Ausbildung bist, ob du mitten im Berufleben stehst oder ob du bereits deine aktive Berufslaufbahn abgeschlossen hast.</p>
                  <p style={{ fontSize: '1.2em', lineHeight: '1.5em' }}>Die Premium-Mitgliedschaft hat für dich unterschiedliche Vorteile, die wir dir hier erläutern möchten.</p>
                </Grid.Column>
                <Grid.Column computer={3} mobile={8}>
                  <img width="100%" alt="" src="/images/icon_man.svg" />
                </Grid.Column>
              </Grid>

              <Grid centered divided="vertically" style={{ marginBottom: '40px' }}>
                <Grid.Column computer={3} mobile={8}>
                  <img width="100%" alt="" src="/images/icon_magazine.svg" />
                </Grid.Column>
                <Grid.Column computer={7} mobile={15} textAlign="left">
                  <Header as="h3">Optimierte Baupunkt-Funktionen</Header>
                  <ul style={{ fontSize: '1.2em', lineHeight: '1.5em' }}>
                    <li>Als Premium-Mitglied siehst du je Baupunkt alle Informationen, die andere lokale Experten hinterlegt haben.</li>
                    <li>Du hast die Möglichkeit, deine Baupunkte zu archivieren bzw. herunterzuladen und direkt mit anderen Partnern zu teilen.</li>
                    <li>Durch eine optimierte Druckfunktion wird dein Baupunkt optisch wertvoll aufbereitet, damit du die Informationen leicht ausdrucken und ggf. für eine Korrespondenz nutzen oder zu einem Termin mitnehmen kannst.</li>
                  </ul>
                </Grid.Column>
              </Grid>

              <Grid centered divided="vertically" style={{ marginBottom: '40px' }}>
                <Grid.Column computer={7} mobile={15} textAlign="left">
                  <Header as="h3">Zugriff auf weitere Hintergrundkarten</Header>
                  <p style={{ fontSize: '1.2em', lineHeight: '1.5em' }}>Als Premium-Mitglied erhältst du erweiterten Einblick in neue Karten-Ebenen. Wertvolle Zusatzinformationen (z. B. Naturschutzgebiete, Bodenkarten, Verwaltungsgrenzen etc...) können eingeblendet und erleichtern deine tägliche Arbeit, da keine unterschiedliche Kartendienste aufgerufen werden müssen.</p>
                  <p style={{ fontSize: '1.2em', lineHeight: '1.5em' }}>Du hast die Möglichkeit, eigene Hintergrundkarten zu erstellen. So kannst du deine eigene Daten-Welt erschaffen und einfach mit anderen teilen.</p>
                </Grid.Column>
                <Grid.Column computer={3} mobile={8}>
                  <img width="100%" alt="" src="/images/icon_certificate.svg" />
                </Grid.Column>
              </Grid>

              <Grid centered divided="vertically">
                <Grid.Column computer={3} mobile={8}>
                  <img width="100%" alt="Neue Dienstleistungen und Features nutzen" src="/images/icon_discussions.svg" />
                </Grid.Column>
                <Grid.Column computer={7} mobile={15} textAlign="left">
                  <Header as="h3">Erweiterte Mitglieds- und Profilfunktionen</Header>
                  <p style={{ fontSize: '1.2em', lineHeight: '1.5em' }}>Du siehst jetzt alle Mitglieder auf der Plattform in unserem Mitgliederverzeichnis und kannst dieses auch durchsuchen z. B. nach spezifischen Qualifikationen. Außerdem wird dir nun angezeigt, wer dein Profil besucht hat und sich für deine Expertise interessiert.</p>
                </Grid.Column>
              </Grid>

            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid>
    );
  }
}

Premium.propTypes = {
  activateTrial: PropTypes.func.isRequired,
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  activateTrial: USER_ACTIONS.activateTrial,
};

const connectedPremium = connect(mapStateToProps, mapDispatchToProps)(Premium);

export default withRouter(AuthHOC(connectedPremium));
