import REST from '../rest';

const BASE_URL = '/api/v1/notifications/';

export const list = () => REST.get(BASE_URL);

export const loadMore = url => REST.get(url);

export const unreadCount = () => REST.get(
  `${BASE_URL}count/`,
);

export const setRead = data => REST.post(
  `${BASE_URL}read/`,
  data,
  {},
  { 'Content-Type': 'application/json' },
);
