import _ from 'lodash';

const REFRESH_PERIOD = 36000;

export function authTokenMustRefresh(creationDate) {
  if (!creationDate) {
    return false;
  }

  const now = new Date();
  const expirationDate = new Date((creationDate + REFRESH_PERIOD) * 1000);

  return now > expirationDate;
}

function authTokenIsStillValid(creationDate, expiresIn) {
  const now = new Date();
  const expirationDate = new Date((creationDate + expiresIn) * 1000);

  return now < expirationDate;
}

export function userHasValidAuthToken(user) {
  let isAuthenticated = false;

  if (!_.isEmpty(user) && !_.isEmpty(_.get(user, 'access_token'))) {
    isAuthenticated = authTokenIsStillValid(user.created_at, user.expires_in);
  }

  return isAuthenticated;
}

export function saveAuth(tokenData) {
  const data = Object.assign({}, tokenData, {
    created_at: Date.now(),
  });

  localStorage.setItem('auth', JSON.stringify(data));

  return data;
}

export function authInfo() {
  return JSON.parse(localStorage.getItem('auth'));
}

export function authToken() {
  return _.get(authInfo(), 'access_token');
}

export function authPresent() {
  return !_.isEmpty(authInfo());
}
