import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Loader } from 'semantic-ui-react';
import _ from 'lodash';
import difference from 'utils/difference';
import * as USER_ACTIONS from 'actions/user';
import SearchForm from 'modules/forms/SearchForm';

class SearchContainer extends Component {
  constructor(props) {
    super(props);

    this.onSubmit = this.onSubmit.bind(this);
  }

  onSubmit() {
    const { applyFilters } = this.props;

    applyFilters();
  }

  render() {
    const { isPremium } = this.props;

    return (
      <SearchForm
        onSubmit={this.onSubmit}
        advancedSearchEnabled={isPremium}
      />
    );
  }
}

SearchContainer.defaultProps = {
  formData: {},
};

SearchContainer.propTypes = {
  formData: PropTypes.object, // eslint-disable-line
};

function mapStateToProps(state) {
  return {
    formData: _.get(state, 'form.search.values', {}),
    isPremium: _.get(state, 'session.user.is_premium', false),
  };
}

const mapDispatchToProps = {};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchContainer));
