import React from 'react';
import PropTypes from 'prop-types';
import { Provider, connect } from 'react-redux';
import Alert from 'react-s-alert';
import Tour from 'reactour';
import routes from 'routes';

import * as TOUR_ACTIONS from 'actions/tour';
import * as SRC from 'modules/styled';

import 'assets/style/app/index.css';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

import YouTube from 'react-youtube';

const youtubeOpts = {
    height: '195',
    width: '320',
};

const steps = [
  {
    position: 'center',
    style: {
      maxWidth: '375px',
    },
    content: ({ goTo, inDOM }) => (
      <div>
        <h4 className="ui header">Herzlich willkommen bei localexpert24!</h4>
        <p>In dieser kurzen Tour erklären wir dir die Grundfunktionen der Plattform.</p>
        <p>Du kannst sowohl mit den Pfeilen als auch mit der Tastatur vor und zurück navigieren.</p>
        <YouTube
          videoId="6JKtPFsjVRA"
          opts={youtubeOpts}
        />
      </div>
    ),
  },
  {
    selector: '.reactour__filter',
    content: ({ goTo, inDOM }) => (
      <div>
        <h4 className="ui header">Filter & Adresssuche</h4>
        <p>Hier kannst du Baupunkte in der Kartenansicht nach bestimmten Merkmalen filtern (z. B. alle Baupunkte, meine Baupunkte etc.).</p>
      </div>
    ),
  },
  {
    selector: '.reactour__toggle-tags',
    content: ({ goTo, inDOM }) => (
      <div>
        <h4 className="ui header">Filter & Adresssuche</h4>
        <p>Schalte hier zwischen Adresssuche, Kategorie-Filter oder what3words-Adresse um.</p>
      </div>
    ),
  },
  {
    selector: '.reactour__list',
    content: ({ goTo, inDOM }) => (
      <div>
        <h4 className="ui header">Baupunkte anzeigen</h4>
        <p>Lasse dir hier eine Listenansicht über alle auf der Karte angezeigten Baupunkte anzeigen.</p>
      </div>
    ),
  },
  {
    selector: '.reactour__create-location',
    content: ({ goTo, inDOM }) => (
      <div>
        <h4 className="ui header">Baupunkte erstellen</h4>
        <p>Über diesen Button kannst du einen neuen Baupunkt erstellen.</p>
      </div>
    ),
  },
  {
    selector: '.reactour__active-experts',
    content: ({ goTo, inDOM }) => (
      <div>
        <h4 className="ui header">Lokale Experten kennenlernen</h4>
        <p>Du möchtest herausfinden, welche lokalen Experten oder Organisationen in einer Region tätig sind? Filtere dies einfach über die rechte Seitenleiste!</p>
      </div>
    ),
  },
  {
    selector: '.esri-basemap-toggle',
    content: ({ goTo, inDOM }) => (
      <div>
        <h4 className="ui header">Ansicht verändern</h4>
        <p>Schalte hier von der Vektoransicht in die Satellitenansicht und orientiere dich somit besser an örtlichen Gegebenheiten.</p>
      </div>
    ),
  },
  {
    position: 'center',
    content: ({ goTo, inDOM }) => (
      <div>
        <h4 className="ui header">Baupunkt erstellen</h4>
        <p>Mit einem Rechtsklick auf eine bestimmte Adresse in der Karte gelangst du ebenfalls in das Menü, um einen neuen Baupunkt anzulegen.</p>
      </div>
    ),
  },
  {
    selector: '.reactour__user-dropdown',
    content: ({ goTo, inDOM }) => (
      <div>
        <h4 className="ui header">Profil vervollständigen</h4>
        <p>Hier kannst du unter "Mein Profil" dein Profil vervollständigen und anderen mitteilen, über welche Fachexpertise du verfügst.</p>
      </div>
    ),
  },
  {
    position: 'center',
    content: ({ goTo, inDOM }) => (
      <div>
        <h4 className="ui header">🎉 Geschafft!</h4>
        <p>Du hast nun alle Grundfunktionen kennengelernt.</p>
        <p>Wir wünschen dir viel Spaß beim Entdecken der weiteren Funktionen und natürlich viel Erfolg mit localexpert24!</p>
        <p>Du kannst diese Tour jederzeit über das Benutzermenü aufrufen. Bei Fragen helfen wir dir gerne über <a href="mailto:feedback@localexpert24.de">feedback@localexpert24.de</a> weiter.</p>
      </div>
    ),
  },

];

const Root = ({ store, tourOpen, closeTour }) => (
  <SRC.AppWrapper className="root">
    <Alert stack={{ limit: 3 }} />
    <Tour
      isOpen={tourOpen}
      steps={steps}
      onRequestClose={closeTour}
      showNumber={false}
    />
    <Provider store={store}>
      {routes}
    </Provider>
  </SRC.AppWrapper>
);

Root.displayName = 'Root';

Root.propTypes = {
  store: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

function mapStateToProps(state) {
  return {
    tourOpen: state.tour.open,
  };
}

const mapDispatchToProps = {
  closeTour: TOUR_ACTIONS.close,
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
