import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import * as C from 'actions/constants';
import { authPresent, userHasValidAuthToken } from 'utils/session';
import * as SESSION_ACTIONS from 'actions/session';

function authenticate(WrappedComponent) {
  class AuthHOC extends React.Component {
    constructor(props) {
      super(props);
      this.isValidToken = this.isValidToken.bind(this);
      this.checkAuth = this.checkAuth.bind(this);
    }

    componentWillMount() {
      this.checkAuth();
    }

    componentWillReceiveProps() {
      this.checkAuth();
    }

    isValidToken() {
      const { auth } = this.props;

      return userHasValidAuthToken(auth);
    }

    checkAuth() {
      const {
        auth,
        user,
        history,
        location: { pathname },
        refreshToken,
      } = this.props;
      const ENABLED_PATHS = [
        '/',
        '/auth/register',
        '/auth/register/confirm/',
        '/auth/logout',
      ];
      const notStartWith = !pathname.startsWith('/auth/reset-password/') && !pathname.startsWith('/auth/register/confirm/');
      const notAllowedPath = pathname !== '/' && ENABLED_PATHS.indexOf(pathname) === -1 && notStartWith;
      const pushObject = {
        pathname: '/',
        search: `?next=${pathname}`,
      };

      if (pathname.startsWith('/auth/unsubscribe')) {
        return;
      }

      if (!authPresent() && notAllowedPath) {
        history.push(pushObject);
        return;
      }

      if (this.isValidToken() && pathname === '/') {
        history.push('/app');
        return;
      }

      if ((!authPresent() || !this.isValidToken()) && notAllowedPath) {
        const token = _.get(auth, 'refresh_token');
        refreshToken(token)
          .then((res) => {
            if (res.type === C.REFRESH_TOKEN_ERROR) {
              localStorage.clear();
              history.push(pushObject);
            }
          })
          .catch(() => {
            localStorage.clear();
            history.push(pushObject);
          });
      }

      if (notAllowedPath && authPresent()) {
        const verified = _.get(user, 'verified');

        if (!verified) {
          history.push('/auth/register/confirm/');
        }
      }
    }

    render() {
      const { ...props } = this.props;

      return (
        <WrappedComponent
          {...props}
        />
      );
    }
  }

  AuthHOC.defaultProps = {
    auth: {},
    user: {},
  };

  AuthHOC.propTypes = {
    auth: PropTypes.object, // eslint-disable-line
    user: PropTypes.object, // eslint-disable-line
    history: PropTypes.object.isRequired, // eslint-disable-line
    location: PropTypes.object.isRequired, // eslint-disable-line
    refreshToken: PropTypes.func.isRequired,
  };

  function mapStateToProps(state) {
    return {
      auth: state.session.data,
      user: state.session.user,
    };
  }

  const mapDispatchToProps = {
    refreshToken: SESSION_ACTIONS.refreshToken,
  };

  return connect(mapStateToProps, mapDispatchToProps)(withRouter(AuthHOC));
}

export default authenticate;
