import API from 'api';
import _ from 'lodash';
import * as C from './constants';
import * as serializers from './serializers/event';

export const list = () => (dispatch) => {
  dispatch({ type: C.LIST_EVENTS });

  return API.event.list()
    .then(({
      body: data,
    }) => dispatch({ type: C.LIST_EVENTS_SUCCESS, ...data }));
};

export const get = id => (dispatch) => {
  dispatch({ type: C.GET_EVENT });

  return API.event.get(id)
    .then(({
      body: data,
    }) => dispatch({ type: C.GET_EVENT_SUCCESS, data: serializers.get(data) }));
};

const transformData = (body) => {
  const imageKeys = ['image', 'title_image_url'];
  const data = serializers.patch(body);
  const dataKeys = Object.keys(data);
  const hasImage = !_.isEmpty(_.intersection(imageKeys, dataKeys));
  const contentType = hasImage ? 'application/x-www-form-urlencoded' : 'application/json';
  let formData = data;

  if (hasImage) {
    formData = new FormData();

    _.each(dataKeys, (key) => {
      formData.append(key, data[key]);
    });
  }

  return {
    formData,
    contentType,
  };
};

export const create = body => (dispatch) => {
  dispatch({ type: C.CREATE_EVENT });

  const { formData, contentType } = transformData(body);

  return API.event.create(formData, contentType)
    .then(() => dispatch({ type: C.CREATE_EVENT_SUCCESS }));
};

export const patch = (id, body) => (dispatch) => {
  dispatch({ type: C.PATCH_EVENT });

  const { formData, contentType } = transformData(body);

  return API.event.patch(id, formData, contentType)
    .then(() => dispatch({ type: C.PATCH_EVENT_SUCCESS }));
};

export const deleteEvent = id => (dispatch) => {
  dispatch({ type: C.DELETE_EVENT });

  return API.event.deleteEvent(id)
    .then(() => dispatch({ type: C.DELETE_EVENT_SUCCESS }));
};
