// SESSION
export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_ERROR = 'SIGN_IN_ERROR';

export const SIGN_OUT = 'SIGN_OUT';
export const SIGN_OUT_SUCCESS = 'SIGN_OUT_SUCCESS';
export const SIGN_OUT_ERROR = 'SIGN_OUT_ERROR';

export const GET_TOKEN = 'GET_TOKEN';
export const GET_TOKEN_SUCCESS = 'GET_TOKEN_SUCCESS';
export const GET_TOKEN_ERROR = 'GET_TOKEN_ERROR';

export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const REFRESH_TOKEN_SUCCESS = 'REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_ERROR = 'REFRESH_TOKEN_ERROR';

export const REFRESH_USER_DATA = 'REFRESH_USER_DATA';
export const REFRESH_USER_DATA_SUCCESS = 'REFRESH_USER_DATA_SUCCESS';
export const REFRESH_USER_DATA_ERROR = 'REFRESH_USER_DATA_ERROR';

// TOUR
export const OPEN_TOUR = 'OPEN_TOUR';
export const CLOSE_TOUR = 'CLOSE_TOUR';

// USER
export const REGISTER_USER = 'REGISTER_USER';
export const REGISTER_USER_SUCCESS = 'REGISTER_USER_SUCCESS';
export const REGISTER_USER_ERROR = 'REGISTER_USER_ERROR';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_ERROR = 'DELETE_USER_ERROR';

export const UPDATE_ORGANIZATION = 'UPDATE_ORGANIZATION';

export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'LOGIN_USER_ERROR';

export const GET_USER = 'GET_USER';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_ERROR = 'GET_USER_ERROR';

export const LIST_USERS = 'LIST_USERS';
export const LIST_USERS_SUCCESS = 'LIST_USERS_SUCCESS';
export const LIST_USERS_ERROR = 'LIST_USERS_ERROR';

export const LOAD_MORE_USERS = 'LOAD_MORE_USERS';
export const LOAD_MORE_USERS_SUCCESS = 'LOAD_MORE_USERS_SUCCESS';
export const LOAD_MORE_USERS_ERROR = 'LOAD_MORE_USERS_ERROR';

export const PATCH_USER = 'PATCH_USER';
export const PATCH_USER_SUCCESS = 'PATCH_USER_SUCCESS';
export const PATCH_USER_ERROR = 'PATCH_USER_ERROR';

export const RESET_USER_PASSWORD = 'RESET_USER_PASSWORD';
export const RESET_USER_PASSWORD_SUCCESS = 'RESET_USER_PASSWORD_SUCCESS';
export const RESET_USER_PASSWORD_ERROR = 'RESET_USER_PASSWORD_ERROR';

export const RESET_USER_PASSWORD_CONFIRM = 'RESET_USER_PASSWORD_CONFIRM';
export const RESET_USER_PASSWORD_CONFIRM_SUCCESS = 'RESET_USER_PASSWORD_CONFIRM_SUCCESS';
export const RESET_USER_PASSWORD_CONFIRM_ERROR = 'RESET_USER_PASSWORD_CONFIRM_ERROR';

export const CONFIRM_ACCOUNT = 'CONFIRM_ACCOUNT';
export const CONFIRM_ACCOUNT_SUCCESS = 'CONFIRM_ACCOUNT_SUCCESS';
export const CONFIRM_ACCOUNT_ERROR = 'CONFIRM_ACCOUNT_ERROR';

export const RESEND_CONFIRM_ACCOUNT = 'RESEND_CONFIRM_ACCOUNT';
export const RESEND_CONFIRM_ACCOUNT_SUCCESS = 'RESEND_CONFIRM_ACCOUNT_SUCCESS';
export const RESEND_CONFIRM_ACCOUNT_ERROR = 'RESEND_CONFIRM_ACCOUNT_ERROR';

export const UNSUBSCRIBE = 'UNSUBSCRIBE';
export const UNSUBSCRIBE_SUCCESS = 'UNSUBSCRIBE_SUCCESS';
export const UNSUBSCRIBE_ERROR = 'UNSUBSCRIBE_ERROR';

export const REGISTER_SOMMER_AKTION = 'REGISTER_SOMMER_AKTION';
export const REGISTER_SOMMER_AKTION_SUCCESS = 'REGISTER_SOMMER_AKTION_SUCCESS';
export const REGISTER_SOMMER_AKTION_ERROR = 'REGISTER_SOMMER_AKTION_ERROR';

export const SEND_INVITES = 'SEND_INVITES';
export const SEND_INVITES_SUCCESS = 'SEND_INVITES_SUCCESS';
export const SEND_INVITES_ERROR = 'SEND_INVITES_ERROR';

export const ACTIVATE_TRIAL = 'ACTIVATE_TRIAL';
export const ACTIVATE_TRIAL_SUCCESS = 'ACTIVATE_TRIAL_SUCCESS';
export const ACTIVATE_TRIAL_ERROR = 'ACTIVATE_TRIAL_ERROR';

export const SYNC_USER = 'SYNC_USER';
export const SYNC_USER_SUCCESS = 'SYNC_USER_SUCCESS';
export const SYNC_USER_ERROR = 'SYNC_USER_ERROR';

export const CREATE_INVOICE = 'CREATE_INVOICE';
export const CREATE_INVOICE_SUCCESS = 'CREATE_INVOICE_SUCCESS';
export const CREATE_INVOICE_ERROR = 'CREATE_INVOICE_ERROR';

export const CREATE_ORGANIZATION_INVOICE = 'CREATE_ORGANIZATION_INVOICE';
export const CREATE_ORGANIZATION_INVOICE_SUCCESS = 'CREATE_ORGANIZATION_INVOICE_SUCCESS';
export const CREATE_ORGANIZATION_INVOICE_ERROR = 'CREATE_ORGANIZATION_INVOICE_ERROR';

export const RESEND_INVOICE = 'RESEND_INVOICE';
export const RESEND_INVOICE_SUCCESS = 'RESEND_INVOICE_SUCCESS';
export const RESEND_INVOICE_ERROR = 'RESEND_INVOICE_ERROR';

export const CANCEL_SUBSCRIPTION = 'CANCEL_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_ERROR = 'CANCEL_SUBSCRIPTION_ERROR';

export const OPEN_INVITES_MODAL = 'OPEN_INVITES_MODAL';
export const CLOSE_INVITES_MODAL = 'CLOSE_INVITES_MODAL';


// LOCATION
export const CREATE_LOCATION = 'CREATE_LOCATION';
export const CREATE_LOCATION_SUCCESS = 'CREATE_LOCATION_SUCCESS';
export const CREATE_LOCATION_ERROR = 'CREATE_LOCATION_ERROR';

export const GET_LOCATIONS_LIST = 'GET_LOCATIONS_LIST';
export const GET_LOCATIONS_LIST_SUCCESS = 'GET_LOCATIONS_LIST_SUCCESS';
export const GET_LOCATIONS_LIST_ERROR = 'GET_LOCATIONS_LIST_ERROR';

export const GET_MAP_LOCATIONS_LIST = 'GET_MAP_LOCATIONS_LIST';
export const GET_MAP_LOCATIONS_LIST_SUCCESS = 'GET_MAP_LOCATIONS_LIST_SUCCESS';
export const GET_MAP_LOCATIONS_LIST_ERROR = 'GET_MAP_LOCATIONS_LIST_ERROR';

export const GET_MAP_LOCATION_DETAIL = 'GET_MAP_LOCATION_DETAIL';
export const GET_MAP_LOCATION_DETAIL_SUCCESS = 'GET_MAP_LOCATION_DETAIL_SUCCESS';
export const GET_MAP_LOCATION_DETAIL_ERROR = 'GET_MAP_LOCATION_DETAIL_ERROR';

export const GET_LOCATION = 'GET_LOCATION';
export const GET_LOCATION_SUCCESS = 'GET_LOCATION_SUCCESS';
export const GET_LOCATION_ERROR = 'GET_LOCATION_ERROR';

export const PATCH_LOCATION = 'PATCH_LOCATION';
export const PATCH_LOCATION_SUCCESS = 'PATCH_LOCATION_SUCCESS';
export const PATCH_LOCATION_ERROR = 'PATCH_LOCATION_ERROR';

export const UPLOAD_LOCATION_DOCUMENT = 'UPLOAD_LOCATION_DOCUMENT';
export const UPLOAD_LOCATION_DOCUMENT_SUCCESS = 'UPLOAD_LOCATION_DOCUMENT_SUCCESS';
export const UPLOAD_LOCATION_DOCUMENT_ERROR = 'UPLOAD_LOCATION_DOCUMENT_ERROR';

export const DELETE_LOCATION_DOCUMENT = 'DELETE_LOCATION_DOCUMENT';
export const DELETE_LOCATION_DOCUMENT_SUCCESS = 'DELETE_LOCATION_DOCUMENT_SUCCESS';
export const DELETE_LOCATION_DOCUMENT_ERROR = 'DELETE_LOCATION_DOCUMENT_ERROR';

export const UPLOAD_LOCATION_CONTENT = 'UPLOAD_LOCATION_CONTENT';
export const UPLOAD_LOCATION_CONTENT_SUCCESS = 'UPLOAD_LOCATION_CONTENT_SUCCESS';
export const UPLOAD_LOCATION_CONTENT_ERROR = 'UPLOAD_LOCATION_CONTENT_ERROR';

export const DELETE_LOCATION_CONTENT = 'DELETE_LOCATION_CONTENT';
export const DELETE_LOCATION_CONTENT_SUCCESS = 'DELETE_LOCATION_CONTENT_SUCCESS';
export const DELETE_LOCATION_CONTENT_ERROR = 'DELETE_LOCATION_CONTENT_ERROR';

export const ROTATE_LOCATION_CONTENT = 'ROTATE_LOCATION_CONTENT';
export const ROTATE_LOCATION_CONTENT_SUCCESS = 'ROTATE_LOCATION_CONTENT_SUCCESS';
export const ROTATE_LOCATION_CONTENT_ERROR = 'ROTATE_LOCATION_CONTENT_ERROR';

export const CHECK_BOOKMARK_LOCATION = 'CHECK_BOOKMARK_LOCATION';
export const CHECK_BOOKMARK_LOCATION_SUCCESS = 'CHECK_BOOKMARK_LOCATION_SUCCESS';
export const CHECK_BOOKMARK_LOCATION_ERROR = 'CHECK_BOOKMARK_LOCATION_ERROR';

export const BOOKMARK_LOCATION = 'BOOKMARK_LOCATION';
export const BOOKMARK_LOCATION_SUCCESS = 'BOOKMARK_LOCATION_SUCCESS';
export const BOOKMARK_LOCATION_ERROR = 'BOOKMARK_LOCATION_ERROR';

export const DELETE_LOCATION_BOOKMARK = 'DELETE_LOCATION_BOOKMARK';
export const DELETE_LOCATION_BOOKMARK_SUCCESS = 'DELETE_LOCATION_BOOKMARK_SUCCESS';
export const DELETE_LOCATION_BOOKMARK_ERROR = 'DELETE_LOCATION_BOOKMARK_ERROR';

export const CHECK_LOCATION_VOTE = 'CHECK_LOCATION_VOTE';
export const CHECK_LOCATION_VOTE_SUCCESS = 'CHECK_LOCATION_VOTE_SUCCESS';
export const CHECK_LOCATION_VOTE_ERROR = 'CHECK_LOCATION_VOTE_ERROR';

export const GET_LOCATION_VOTE_COUNT = 'GET_LOCATION_VOTE_COUNT';
export const GET_LOCATION_VOTE_COUNT_SUCCESS = 'GET_LOCATION_VOTE_COUNT_SUCCESS';
export const GET_LOCATION_VOTE_COUNT_ERROR = 'GET_LOCATION_VOTE_COUNT_ERROR';

export const VOTE_LOCATION = 'VOTE_LOCATION';
export const VOTE_LOCATION_SUCCESS = 'VOTE_LOCATION_SUCCESS';
export const VOTE_LOCATION_ERROR = 'VOTE_LOCATION_ERROR';

export const DELETE_LOCATION_VOTE = 'DELETE_LOCATION_VOTE';
export const DELETE_LOCATION_VOTE_SUCCESS = 'DELETE_LOCATION_VOTE_SUCCESS';
export const DELETE_LOCATION_VOTE_ERROR = 'DELETE_LOCATION_VOTE_ERROR';

export const DELETE_LOCATION = 'DELETE_LOCATION';
export const DELETE_LOCATION_SUCCESS = 'DELETE_LOCATION_SUCCESS';
export const DELETE_LOCATION_ERROR = 'DELETE_LOCATION_ERROR';

export const MASS_IMPORT = 'MASS_IMPORT';
export const MASS_IMPORT_SUCCESS = 'MASS_IMPORT_SUCCESS';
export const MASS_IMPORT_ERROR = 'MASS_IMPORT_ERROR';

export const UPLOAD_LOCATION_FILE_IFC = 'UPLOAD_LOCATION_FILE_IFC';
export const UPLOAD_LOCATION_FILE_IFC_SUCCESS = 'UPLOAD_LOCATION_FILE_IFC_SUCCESS';
export const UPLOAD_LOCATION_FILE_IFC_ERROR = 'UPLOAD_LOCATION_FILE_IFC_ERROR';

export const DELETE_LOCATION_FILE_IFC = 'DELETE_LOCATION_FILE_IFC';
export const DELETE_LOCATION_FILE_IFC_SUCCESS = 'DELETE_LOCATION_FILE_IFC_SUCCESS';
export const DELETE_LOCATION_FILE_IFC_ERROR = 'DELETE_LOCATION_FILE_IFC_ERROR';

// MAP
export const FILTER_LOCATION_USER = 'FILTER_LOCATION_USER';
export const FILTER_LOCATION_ALL = 'FILTER_LOCATION_ALL';
export const FILTER_LOCATION_ORGANIZATION = 'FILTER_LOCATION_ORGANIZATION';
export const FILTER_LOCATION_BOOKMARKED = 'FILTER_LOCATION_BOOKMARKED';
export const SET_TAG_FILTER = 'SET_TAG_FILTER';
export const SET_MAP_CENTER = 'SET_MAP_CENTER';
export const EXPERT_MOUSE_ENTER = 'EXPERT_MOUSE_ENTER';
export const EXPERT_MOUSE_LEAVE = 'EXPERT_MOUSE_LEAVE';
export const ORGANIZATION_MOUSE_ENTER = 'ORGANIZATION_MOUSE_ENTER';
export const ORGANIZATION_MOUSE_LEAVE = 'ORGANIZATION_MOUSE_LEAVE';
export const ENABLE_LAYER = 'ENABLE_LAYER';
export const DISABLE_LAYER = 'DISABLE_LAYER';
export const FILTER_LOCATIONS_BY_BOUNDS = 'FILTER_LOCATIONS_BY_BOUNDS';
export const UPDATE_BOUNDS = 'UPDATE_BOUNDS';


// ORGANIZATION
export const CREATE_ORGANIZATION = 'CREATE_ORGANIZATION';
export const CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS';
export const CREATE_ORGANIZATION_ERROR = 'CREATE_ORGANIZATION_ERROR';

export const GET_ORGANIZATION = 'GET_ORGANIZATION';
export const GET_ORGANIZATION_SUCCESS = 'GET_ORGANIZATION_SUCCESS';
export const GET_ORGANIZATION_ERROR = 'GET_ORGANIZATION_ERROR';

export const LIST_ORGANIZATIONS = 'LIST_ORGANIZATIONS';
export const LIST_ORGANIZATIONS_SUCCESS = 'LIST_ORGANIZATIONS_SUCCESS';
export const LIST_ORGANIZATIONS_ERROR = 'LIST_ORGANIZATIONS_ERROR';

export const PATCH_ORGANIZATION = 'PATCH_ORGANIZATION';
export const PATCH_ORGANIZATION_SUCCESS = 'PATCH_ORGANIZATION_SUCCESS';
export const PATCH_ORGANIZATION_ERROR = 'PATCH_ORGANIZATION_ERROR';

export const LIST_ORGANIZATION_INVITES = 'LIST_ORGANIZATION_INVITES';
export const LIST_ORGANIZATION_INVITES_SUCCESS = 'LIST_ORGANIZATION_INVITES_SUCCESS';
export const LIST_ORGANIZATION_INVITES_ERROR = 'LIST_ORGANIZATION_INVITES_ERROR';

export const ORGANIZATION_INVITE = 'ORGANIZATION_INVITE';
export const ORGANIZATION_INVITE_SUCCESS = 'ORGANIZATION_INVITE_SUCCESS';
export const ORGANIZATION_INVITE_ERROR = 'ORGANIZATION_INVITE_ERROR';

export const ORGANIZATION_DELETE_INVITE = 'ORGANIZATION_DELETE_INVITE';
export const ORGANIZATION_DELETE_INVITE_SUCCESS = 'ORGANIZATION_DELETE_INVITE_SUCCESS';
export const ORGANIZATION_DELETE_INVITE_ERROR = 'ORGANIZATION_DELETE_INVITE_ERROR';

export const ORGANIZATION_APPLY = 'ORGANIZATION_APPLY';
export const ORGANIZATION_APPLY_SUCCESS = 'ORGANIZATION_APPLY_SUCCESS';
export const ORGANIZATION_APPLY_ERROR = 'ORGANIZATION_APPLY_ERROR';

export const LIST_ORGANIZATION_MEMBERS = 'LIST_ORGANIZATION_MEMBERS';
export const LIST_ORGANIZATION_MEMBERS_SUCCESS = 'LIST_ORGANIZATION_MEMBERS_SUCCESS';
export const LIST_ORGANIZATION_MEMBERS_ERROR = 'LIST_ORGANIZATION_MEMBERS_ERROR';

export const ORGANIZATION_APPROVE_APPLY = 'ORGANIZATION_APPROVE_APPLY';
export const ORGANIZATION_APPROVE_APPLY_SUCCESS = 'ORGANIZATION_APPROVE_APPLY_SUCCESS';
export const ORGANIZATION_APPROVE_APPLY_ERROR = 'ORGANIZATION_APPROVE_APPLY_ERROR';

export const ORGANIZATION_REJECT_APPLY = 'ORGANIZATION_REJECT_APPLY';
export const ORGANIZATION_REJECT_APPLY_SUCCESS = 'ORGANIZATION_REJECT_APPLY_SUCCESS';
export const ORGANIZATION_REJECT_APPLY_ERROR = 'ORGANIZATION_REJECT_APPLY_ERROR';

export const ORGANIZATION_USER_HAS_INVITE = 'ORGANIZATION_USER_HAS_INVITE';
export const ORGANIZATION_USER_HAS_INVITE_SUCCESS = 'ORGANIZATION_USER_HAS_INVITE_SUCCESS';
export const ORGANIZATION_USER_HAS_INVITE_ERROR = 'ORGANIZATION_USER_HAS_INVITE_ERROR';

export const ORGANIZATION_KICK_MEMBER = 'ORGANIZATION_KICK_MEMBER';
export const ORGANIZATION_KICK_MEMBER_SUCCESS = 'ORGANIZATION_KICK_MEMBER_SUCCESS';
export const ORGANIZATION_KICK_MEMBER_ERROR = 'ORGANIZATION_KICK_MEMBER_ERROR';

export const ORGANIZATION_LEAVE = 'ORGANIZATION_LEAVE';
export const ORGANIZATION_LEAVE_SUCCESS = 'ORGANIZATION_LEAVE_SUCCESS';
export const ORGANIZATION_LEAVE_ERROR = 'ORGANIZATION_LEAVE_ERROR';

export const ORGANIZATION_DELETE = 'ORGANIZATION_DELETE';
export const ORGANIZATION_DELETE_SUCCESS = 'ORGANIZATION_DELETE_SUCCESS';
export const ORGANIZATION_DELETE_ERROR = 'ORGANIZATION_DELETE_ERROR';

export const PATCH_ORGANIZATION_MEMBER = 'PATCH_ORGANIZATION_MEMBER';
export const PATCH_ORGANIZATION_MEMBER_SUCCESS = 'PATCH_ORGANIZATION_MEMBER_SUCCESS';
export const PATCH_ORGANIZATION_MEMBER_ERROR = 'PATCH_ORGANIZATION_MEMBER_ERROR';

export const SET_ADMIN = 'SET_ADMIN';
export const SET_ADMIN_SUCCESS = 'SET_ADMIN_SUCCESS';
export const SET_ADMIN_ERROR = 'SET_ADMIN_ERROR';

export const LOAD_MORE_ORGANIZATIONS = 'LOAD_MORE_ORGANIZATIONS';
export const LOAD_MORE_ORGANIZATIONS_SUCCESS = 'LOAD_MORE_ORGANIZATIONS_SUCCESS';
export const LOAD_MORE_ORGANIZATIONS_ERROR = 'LOAD_MORE_ORGANIZATIONS_ERROR';


// NOTIFICATION
export const LIST_NOTIFICATIONS = 'LIST_NOTIFICATIONS';
export const LIST_NOTIFICATIONS_SUCCESS = 'LIST_NOTIFICATIONS_SUCCESS';
export const LIST_NOTIFICATIONS_ERROR = 'LIST_NOTIFICATIONS_ERROR';

export const LOAD_MORE_NOTIFICATIONS = 'LOAD_MORE_NOTIFICATIONS';
export const LOAD_MORE_NOTIFICATIONS_SUCCESS = 'LOAD_MORE_NOTIFICATIONS_SUCCESS';
export const LOAD_MORE_NOTIFICATIONS_ERROR = 'LOAD_MORE_NOTIFICATIONS_ERROR';

export const GET_UNREAD_NOTIFICATIONS = 'GET_UNREAD_NOTIFICATIONS';
export const GET_UNREAD_NOTIFICATIONS_SUCCESS = 'GET_UNREAD_NOTIFICATIONS_SUCCESS';
export const GET_UNREAD_NOTIFICATIONS_ERROR = 'GET_UNREAD_NOTIFICATIONS_ERROR';

export const READ_NOTIFICATIONS = 'READ_NOTIFICATIONS';
export const READ_NOTIFICATIONS_SUCCESS = 'READ_NOTIFICATIONS_SUCCESS';
export const READ_NOTIFICATIONS_ERROR = 'READ_NOTIFICATIONS_ERROR';

// EVENT
export const LIST_EVENTS = 'LIST_EVENTS';
export const LIST_EVENTS_SUCCESS = 'LIST_EVENTS_SUCCESS';

export const GET_EVENT = 'GET_EVENT';
export const GET_EVENT_SUCCESS = 'GET_EVENT_SUCCESS';

export const CREATE_EVENT = 'CREATE_EVENT';
export const CREATE_EVENT_SUCCESS = 'CREATE_EVENT_SUCCESS';

export const PATCH_EVENT = 'PATCH_EVENT';
export const PATCH_EVENT_SUCCESS = 'PATCH_EVENT_SUCCESS';

export const DELETE_EVENT = 'DELETE_EVENT';
export const DELETE_EVENT_SUCCESS = 'DELETE_EVENT_SUCCESS';

// SIDEBAR
export const LOAD_SIDEBAR_EXPERTEN = 'LOAD_SIDEBAR_EXPERTEN';
export const LOAD_SIDEBAR_ORGANIZATION = 'LOAD_SIDEBAR_ORGANIZATION';
export const LOAD_SIDEBAR_LOCATION = 'LOAD_SIDEBAR_LOCATION';
export const LOAD_SIDEBAR_EXPERTEN_SUCCESS = 'LOAD_SIDEBAR_EXPERTEN_SUCCESS';
export const LOAD_SIDEBAR_ORGANIZATIONS_SUCCESS = 'LOAD_SIDEBAR_ORGANIZATIONS_SUCCESS';
export const LOAD_SIDEBAR_LOCATIONS_SUCCESS = 'LOAD_SIDEBAR_LOCATIONS_SUCCESS';
export const LOAD_MORE_SIDEBAR_EXPERTEN_SUCCESS = 'LOAD_MORE_SIDEBAR_EXPERTEN_SUCCESS';
export const LOAD_MORE_SIDEBAR_ORGANIZATIONS_SUCCESS = 'LOAD_MORE_SIDEBAR_ORGANIZATIONS_SUCCESS';
export const LOAD_MORE_SIDEBAR_LOCATIONS_SUCCESS = 'LOAD_MORE_SIDEBAR_LOCATIONS_SUCCESS';
export const LOAD_SIDEBAR_ERROR = 'LOAD_SIDEBAR_ERROR';

// NETWORK EXCEPTIONS
export const RECEIVE_NETWORK_EXCEPTION = 'RECEIVE_NETWORK_EXCEPTION';


// COMMUNITY
export const LOAD_COMMUNITY_STATS = 'LOAD_COMMUNITY_STATS';
export const LOAD_COMMUNITY_STATS_SUCCESS = 'LOAD_COMMUNITY_STATS_SUCCESS';

