import React from 'react';

export default function urlify(txt) {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return txt.replace(urlRegex, function(url) {
    return '<a target="_blank" href="' + url + '">' + url + '</a>';
  });
}

export const nl2br = (text) => text && text.split('\n').map((item, key) => <span key={key}>{item}<br/></span>) || text;
