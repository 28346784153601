import React from 'react';
import PropTypes from 'prop-types';
import {
  Icon,
  Button,
} from 'semantic-ui-react';
import { UserTitlePicture } from 'modules/styled/profile';

const UserTitleImage = ({
  imageUrl,
  type,
  handleChange,
  loading,
  editing,
}) => (
  <div style={{ width: '100%', height: '240px', position: 'relative' }}>
    <UserTitlePicture loading={loading} src={imageUrl || 'https://via.placeholder.com/1000x240/f7b100/FFFFFF/?text=Titelbild'} />
    {editing && (
      <label htmlFor={type}>
        <input
          id={type}
          type="file"
          accept="image/x-png,image/gif,image/jpeg"
          style={{ display: 'none' }}
          onChange={handleChange}
        />
        <Button
          primary
          type="button"
          loading={loading}
          onClick={(event) => {
            event.preventDefault();
            document.getElementById(type).click();
          }}
          style={{
            position: 'absolute',
            left: '50%',
            marginLeft: '-76px',
            top: '50%',
            marginTop: '-18px',
          }}
        >
          <Icon name="plus" />
          Titelbild hinzufügen
        </Button>
      </label>
    )}
  </div>
);

UserTitleImage.defaultProps = {
  imageUrl: '',
  type: '',
  handleChange: () => {},
  loading: false,
  editing: true,
};

UserTitleImage.propTypes = {
  imageUrl: PropTypes.string,
  type: PropTypes.string,
  handleChange: PropTypes.func,
  loading: PropTypes.bool,
  editing: PropTypes.bool,
};

export default UserTitleImage;
