import * as C from 'actions/constants';
import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import SessionReducer from './lib/session';
import UserReducer from './lib/user';
import LocationReducer from './lib/location';
import MapReducer from './lib/map';
import OrganizationReducer from './lib/organization';
import TourReducer from './lib/tour';
import NotificationReducer from './lib/notification';
import SidebarReducer from './lib/sidebar';
import EventReducer from './lib/event';
import CommunityReducer from './lib/community';

const appReducer = combineReducers({
  session: SessionReducer,
  user: UserReducer,
  location: LocationReducer,
  map: MapReducer,
  organization: OrganizationReducer,
  form: formReducer,
  tour: TourReducer,
  notification: NotificationReducer,
  sidebar: SidebarReducer,
  event: EventReducer,
  community: CommunityReducer,
});

const rootReducer = (state, action) => {
  const newState = ((type) => {
    switch (type) {
      case C.SIGN_OUT:
        return { session: { data: {}, loading: true } };
      case C.SIGN_OUT_SUCCESS:
        return { session: { data: {}, loading: false } };
      default:
        return state;
    }
  })(action.type);

  return appReducer(newState, action);
};

export default rootReducer;
