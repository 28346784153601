import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import posed, { PoseGroup } from 'react-pose';
import {
  Grid,
  Header,
  Icon,
  Button,
  Segment,
} from 'semantic-ui-react';
import MiniMap from './MiniMap';

const Item = posed.div({
  enter: {
    x: 0,
    opacity: 1,
  },
  exit: {
    x: 20,
    opacity: 0,
  },
});

class RelatedLocations extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.toggleVisibility = this.toggleVisibility.bind(this);
  }

  toggleVisibility() {
    const { open } = this.state;

    this.setState({ open: !open });
  }

  render() {
    const {
      handleChange,
      handleDelete,
      initialValues,
      linkedLocations,
    } = this.props;
    const { open } = this.state;

    return (
      <Segment textAlign="center">
        <Header
          as="h4"
          textAlign="left"
        >
          <p><Icon name="point" /> Verknüpfte Baupunkte</p>
        </Header>
        {!open && (
          <Button
            type="button"
            style={{marginTop: '20px', marginBottom: '20px'}}
            onClick={this.toggleVisibility}
          >
            Baupunkte verknüpfen
          </Button>
        )}
        <p style={{ textAlign: 'left', color: '#bbb' }}><i aria-hidden="true" className="info circle icon"></i>Hier kannst du Baupunkte, die inhaltlich z. B. zu einer Gesamtbaumaßnahme gehören, miteinander verknüpfen. Suche hierfür in der Karte nach zusammenhängenden Baupunkten und klicke auf "Hinzufügen".</p>
        {open && (
          <div>
            <MiniMap
              currentId={_.get(initialValues, 'id')}
              lat={_.get(initialValues, 'lat')}
              lng={_.get(initialValues, 'lng')}
              handleMapChange={handleChange}
              linkedLocations={linkedLocations.map(l => l.id)}
            />
            {!_.isEmpty(linkedLocations) && (
              <div style={{ marginTop: '20px' }}>
                <PoseGroup>
                  {linkedLocations.map((location, index) => (
                    <Item i={index} key={location.id} style={{ marginBottom: '15px' }}>
                      <Segment>
                        <Grid>
                          <Grid.Column computer={14} tablet={13} textAlign="left" style={{ overflowWrap: 'break-word' }}>
                            <p>{location.street}</p>
                          </Grid.Column>
                          <Grid.Column computer={2} tablet={3} style={{ display: 'flex', alignItems: 'center' }}>
                            <Icon name="delete" onClick={() => handleDelete(location.id)} style={{ cursor: 'pointer' }} />
                          </Grid.Column>
                        </Grid>
                      </Segment>
                    </Item>
                  ))}
                </PoseGroup>
              </div>
            )}
          </div>
        )}
      </Segment>
    );
  }
};

RelatedLocations.defaultProps = {
  linkedLocations: [],
};

RelatedLocations.propTypes = {
  linkedLocations: PropTypes.array, // eslint-disable-line
  initialValues: PropTypes.object, // eslint-disable-line
  handleChange: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default RelatedLocations;
