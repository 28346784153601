import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';
import {
  Grid,
  Card,
  Header,
  Button,
  Segment,
  Icon,
  List,
} from 'semantic-ui-react';
import * as STATEAPI from 'utils/stateapi';
import * as USER_ACTIONS from 'actions/user';
import AuthHOC from 'containers/AuthHOC';
import * as Alert from 'utils/alert';
import UserPaymentDetail from 'containers/forms/UserPaymentDetail';

class Premium extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formIsOpen: false,
      loading: false,
    };

    this.toggleForm = this.toggleForm.bind(this);
    this.handleForm = this.handleForm.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.toggleLoading = this.toggleLoading.bind(this);
    this.handleResend = this.handleResend.bind(this);
    this.getPlanPrice = this.getPlanPrice.bind(this);
    this.handleCancelSubscription = this.handleCancelSubscription.bind(this);
  }

  componentDidMount() {
    const { changeBlocked } = this.props;

    changeBlocked(true);
  }

  toggleForm() {
    const { formIsOpen } = this.state;

    this.setState({ formIsOpen: !formIsOpen });
  }

  handleForm() {
    const { submitForm } = this.props;

    submitForm();
  }

  toggleLoading() {
    const { loading } = this.state;

    this.setState({ loading: !loading });
  }

  async handleSubmit() {
    const {
      formData,
      createInvoice,
      createOrganizationInvoice,
      refreshLocalStorage,
      patch,
      user,
      isOrga,
      orgType
    } = this.props;
    this.toggleLoading();

    await patch(user.id, formData)

    if(isOrga) {
      await createOrganizationInvoice({
        ...formData,
        plan_type: orgType
      })
    } else {
      await createInvoice()
    }

    refreshLocalStorage(user.id)
      .then(() => {
        Alert.success('Deine Rechnung wurde erstellt und dir per E-Mail geschickt. Bitte prüfe nun deine Mailbox.');
        this.setState({ formIsOpen: false });
      })
      .catch(() => Alert.error('Die Rechnung konnte nicht erstellt werden. Bitte versuche es später noch einmal.'))
      .finally(() => this.toggleLoading());
  }

  handleResend() {
    const { resendInvoice } = this.props;

    this.toggleLoading();
    resendInvoice()
      .then(() => {
        Alert.success('Die Rechnung wurde erneut an deine E-Mail-Adresse geschickt.');
        this.toggleLoading();
      });
  }

  getPlanPrice() {
    const { isOrga, orgType } = this.props
    if(isOrga) {
      return orgType === 'small' ? '2.000,00' : '4.000,00'
    }

    return '99,00'
  }

  handleCancelSubscription() {
    const { cancelSubscription } = this.props
    this.setState({ loading: true }, () => {
      const agreed = window.confirm('Willst du wirklich deine Premium-Mitgliedschaft kündigen?')
      if (!agreed) {
        this.setState({ loading: false })
      } else {
        cancelSubscription()
          .then(() => this.setState({ loading: false }))
      }
    })
  }

  render() {
    const { user, initialValues, remainingDays } = this.props;
    const { formIsOpen, loading } = this.state;
    const waitingPayment = (['orga_paid_plan_payment_pending', 'paid_plan_individual_payment_pending'].indexOf(_.get(user, 'plan_type')) !== -1);
    const planType = _.get(user, 'plan_type')
    const trialPlan = planType === 'free_trial_individual';
    const planIsValid = !_.isEmpty(_.get(user, 'plan_valid_until')) ? moment(user.plan_valid_until).endOf('day').diff(moment().endOf('day'), 'days') >= 0 : false;
    const renewalDate = planIsValid ? moment(user.plan_valid_until) : moment();
    const organizationMember = !_.isEmpty(_.get(user, 'organization_member'))
    const isPremium = _.get(user, 'is_premium')
    const showMemberMessage = organizationMember && !isPremium
    const subscriptionCanceled = _.get(user, 'subscription_canceled')

    const NOTICE_PERIOD_DAYS = 28
    const isWithinNoticePeriod = renewalDate.endOf('day').diff(moment().endOf('day'), 'days') <= NOTICE_PERIOD_DAYS
    const planWillBeCancelledAt = isWithinNoticePeriod ? renewalDate.add(365, 'days') : renewalDate

    return (
      <Grid centered style={{ zIndex: 2 }}>
        <Grid.Column computer={14} tablet={14} mobile={14} style={{ zIndex: 2 }}>
          <Card fluid>
            <Card.Content style={{ textAlign: 'center' }}>
              <Grid.Row style={{ marginTop: '20px', marginBottom: '60px' }}>
                <img width="300" alt="Placeholder" src="/images/premium_expert_header.png" />
              </Grid.Row>
              <Grid>
                <Grid.Column>
                  <Grid.Row style={{ marginBottom: '30px', textAlign: 'center' }}>
                    <Header as="h1" textAlign="center">Dein Premium-Account</Header>
                  </Grid.Row>
                </Grid.Column>
              </Grid>

              {trialPlan && planIsValid && !showMemberMessage && (
                <div>
                  <Grid.Row style={{ marginBottom: '40px', textAlign: 'center' }}>
                    <p>Deine <strong>kostenlose</strong> Premium-Mitgliedschaft hat begonnen und endet am:</p>
                    <Segment color="yellow" compact padded style={{ display: 'inline-table' }}>
                      <p style={{ fontSize: '26px' }}>{moment(user.plan_valid_until).format('DD.MM.YYYY')}</p>
                    </Segment>
                    {trialPlan && remainingDays > 14 && (
                      <p>Du hast ab 14 Tage vor Ablauf deines Probemonats die Möglichkeit deine Premium-Mitgliedschaft zu bezahlen.</p>
                    )}
                  </Grid.Row>
                </div>
              )}

              {!trialPlan && planIsValid && !showMemberMessage && (
                <div>
                  <Grid.Row style={{ marginBottom: '40px', textAlign: 'center' }}>
                    <p>Deine Premium-Mitgliedschaft hat begonnen und wird automatisch verlängert am:</p>
                    <Segment color="yellow" compact padded style={{ display: 'inline-table' }}>
                      <p style={{ fontSize: '26px' }}>{moment(user.plan_valid_until).format('DD.MM.YYYY')}</p>
                    </Segment>
                  </Grid.Row>
                </div>
              )}

              {!planIsValid && !showMemberMessage && (
                <div>
                  <Grid.Row style={{ marginBottom: '40px', textAlign: 'center' }}>
                    <p>Deine Premium-Mitgliedschaft ist <strong>abgelaufen</strong> am:</p>
                    <Segment inverted color="red" compact padded style={{ display: 'inline-table' }}>
                      <p style={{ fontSize: '26px' }}>{moment(user.plan_valid_until).format('DD.MM.YYYY')}</p>
                    </Segment>
                  </Grid.Row>
                </div>
              )}

              {showMemberMessage && (
                <div>
                  <Grid.Row style={{ marginBottom: '20px', textAlign: 'center' }}>
                    <p>Bitte warte, bis der Administrator der Organisation erneut den Premium-Account bezahlt hat. Danach kannst Du die Premium-Funktionen wie gewohnt verwenden.</p>
                  </Grid.Row>
                  <Grid.Row style={{ marginBottom: '20px', textAlign: 'center' }}>
                    <p>Solltest du Fragen haben, wende dich gerne jederzeit an <a href="mailto:feedback@localexpert24.de">feedback@localexpert24.de</a>.</p>
                  </Grid.Row>
                </div>
              )}

              {!trialPlan && planIsValid && !subscriptionCanceled && (
                <div style={{fontSize: '12px'}}>
                  <Grid.Row style={{ marginBottom: '40px', textAlign: 'center' }}>
                    <p>Du kannst deine Premium-Mitgliedschaft zum nächstmöglichen Zeitpunkt <i>({planWillBeCancelledAt.format('DD.MM.YYYY')})</i> kündigen.<br/>Hierbei ist die Kündigungsfrist von 4 Wochen berücksichtigt.</p>
                    <a style={{textDecoration: 'underline'}} href="#" onClick={this.handleCancelSubscription}>Premium-Mitgliedschaft kündigen</a>
                  </Grid.Row>
                </div>
              )}

              {subscriptionCanceled && (
                <div>
                  <Grid.Row style={{ marginBottom: '40px', textAlign: 'center' }}>
                    <p>Du hast deine Premium-Mitgliedschaft am {moment(user.canceled_subscription_at).format('DD.MM.YYYY')} gekündigt.<br/>Du kannst die Premium-Funktionen noch bis zum <strong>{moment(user.plan_valid_until).format('DD.MM.YYYY')}</strong> verwenden.<br/>Danach wirst du automatisch in den Basis-Tarif zurückgestuft.</p>
                  </Grid.Row>
                </div>
              )}

              {waitingPayment && (
                <Grid>
                  <Grid.Column>
                    <Grid.Row style={{ marginBottom: '30px', textAlign: 'center' }}>
                      <Header as="h1" textAlign="center">Vielen Dank für die Bestellung deines Premium-Accounts</Header>
                      <p>Wir haben dir die Rechnung mit der Zahlungsaufforderung per E-Mail geschickt.<br />
                      Solltest du dich noch in dem kostenlosen Testzeitraum befinden, wird dein Premium-Account über den Zeitraum hinweg erst verlängert, wenn die Zahlung eingegangen ist.</p>
                    </Grid.Row>

                    <Grid.Row style={{ margin: '60px 0' }}>
                      <Button disabled={loading} loading={loading} size="huge" color="blue" content="Rechnung erneut senden" onClick={this.handleResend} />
                    </Grid.Row>

                    <Grid.Row>
                      <p>Solltest du Fragen haben, wende dich bitte jederzeit an <a href="mailto:feedback@localexpert24.de">feedback@localexpert24.de</a>.</p>
                    </Grid.Row>
                  </Grid.Column>
                </Grid>
              )}

              {formIsOpen && (
                <Grid.Row style={{ marginBottom: '40px', textAlign: 'center' }}>
                  <Grid centered>
                    <Grid.Column computer={5} mobile={15} textAlign="center">
                      <UserPaymentDetail user={user} initialValues={initialValues} onSubmit={this.handleSubmit} />
                    </Grid.Column>
                    <Grid.Column computer={5} mobile={15} textAlign="center">
                      <Segment color="green" inverted compact padded style={{ display: 'inline-table' }}>
                        <p style={{ fontSize: '26px' }}><small style={{ fontSize: '0.6em' }}>Nur</small><br />
                        {this.getPlanPrice()} € / Jahr<br /><small style={{ fontSize: '0.6em' }}>Inkl. MwSt.</small></p>
                      </Segment>

                      <p>Sicher bezahlen mit:</p>
                      <List horizontal>
                        <List.Item><Icon name="cc visa" size="big" /></List.Item>
                        <List.Item><Icon name="cc mastercard" size="big" /></List.Item>
                        <List.Item><Icon name="cc paypal" size="big" /></List.Item>
                      </List>
                    </Grid.Column>
                  </Grid>

                  <Button disabled={loading} loading={loading} size="huge" color="blue" content="Kostenpflichtig bestellen" style={{ marginTop: '30px', marginBottom: '30px' }} onClick={this.handleForm} />

                  <p>Mit dem Klick auf "Kostenpflichtig bestellen" erhältst du eine E-Mail mit Zahlungshinweisen und die dazugehörige Rechnung.</p>
                  <p><strong>In dieser E-Mail befindet sich ein Link zur sicheren Online-Bezahlung der Rechnung - entweder per PayPal oder Kreditkarte.</strong></p>
                </Grid.Row>
              )}

              {!formIsOpen && !waitingPayment && trialPlan && remainingDays <= 14 && (
                <div>
                  <Grid.Row style={{ marginBottom: '50px', textAlign: 'center' }}>
                    <p>Du kannst ganz bequem innerhalb des Testzeitraums deinen Premium-Account per Paypal oder Kreditkarte bezahlen.<br />
                    Dein bezahlter Premium-Account wird dann bis zum {renewalDate.format('DD.MM.YYYY')} aktiviert und ab diesem Zeitpunkt <strong>jährlich automatisch erneuert</strong>. Du erhältst hierfür automatisiert eine Rechnung. Die Kündigungsfrist beträgt 4 Wochen.</p>
                  </Grid.Row>
                  <Grid.Row style={{ marginBottom: '20px', textAlign: 'center' }}>
                    <Button content="Jetzt sicher bezahlen" size="huge" color="blue" onClick={this.toggleForm} />
                  </Grid.Row>
                  <Grid.Row style={{ marginBottom: '30px', textAlign: 'center' }}>
                    <List horizontal>
                      <List.Item><Icon name="cc visa" size="big" /></List.Item>
                      <List.Item><Icon name="cc mastercard" size="big" /></List.Item>
                      <List.Item><Icon name="cc paypal" size="big" /></List.Item>
                    </List>
                  </Grid.Row>
                  <Grid.Row style={{ marginBottom: '20px', textAlign: 'center' }}>
                    <p>Solltest du Fragen haben, wende dich gerne jederzeit an <a href="mailto:feedback@localexpert24.de">feedback@localexpert24.de</a>.</p>
                  </Grid.Row>
                </div>
              )}
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid>
    );
  }
}

Premium.propTypes = {
  changeBlocked: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  const user = STATEAPI.getCurrentUser(state);
  const fields = [
    'street',
    'postalcode',
    'city',
    'country',
  ];

  const planValidDate = _.get(user, 'plan_valid_until');
  let remainingDays = 0;

  if (planValidDate) {
    const momentValidDate = moment(planValidDate).endOf('day');
    const today = moment().endOf('day');
    remainingDays = momentValidDate.diff(today, 'days');
  }

  const planType = _.get(user, 'plan_type')
  const orgType = _.get(user, 'max_seats') <= 10 ? 'small' : 'large';

  return {
    user,
    planType,
    orgType,
    isOrga: ['orga_paid_plan', 'orga_paid_plan_payment_pending'].indexOf(planType) !== -1,
    formData: _.get(state.form, 'userPaymentDetail.values', {}),
    initialValues: _.pick(user, fields),
    remainingDays,
  };
};

const mapDispatchToProps = {
  submitForm: USER_ACTIONS.submitUserPaymentDetailForm,
  refreshLocalStorage: USER_ACTIONS.refreshLocalStorage,
  patch: USER_ACTIONS.patch,
  createInvoice: USER_ACTIONS.createInvoice,
  createOrganizationInvoice: USER_ACTIONS.createOrganizationInvoice,
  resendInvoice: USER_ACTIONS.resendInvoice,
  cancelSubscription: USER_ACTIONS.cancelSubscription
};

const connectedPremium = connect(mapStateToProps, mapDispatchToProps)(Premium);

export default withRouter(AuthHOC(connectedPremium));

