import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import Breakpoint from 'react-socks';
import PropTypes from 'prop-types';
import { NoPrint } from 'react-easy-print';
import _ from 'lodash';
import {
  Grid,
  Icon,
  Header,
  Segment,
  Label,
  Item,
  Button,
  Image,
  Popup,
  Placeholder,
  Modal,
} from 'semantic-ui-react';
import Lightbox from 'lightbox-react';
import ReactMarkdown from 'react-markdown'
import * as LOCATION_ACTIONS from 'actions/location';
import AuthHOC from 'containers/AuthHOC';
import EmptyState from 'modules/EmptyState';
import { UserPicture } from 'modules/styled/map';
import * as STATEAPI from 'utils/stateapi';
import 'lightbox-react/style.css';
import { ExpertByteLabelSmall } from 'modules/styled/profile';
import LockImagePlaceHolder from 'assets/images/lock-image-placeholder.svg';
import LockVideoPlaceHolder from 'assets/images/lock-video-placeholder.svg';
import * as Alert from 'utils/alert';
import ViewFileIFC from 'modules/Modal/viewFileIFC';

const mailToBody = url => encodeURIComponent(`Hallo LE24-Team,\n\nIch möchte den folgenden Baupunkt als unangemessen melden:\n\nhttps://app.localexpert24.de${url}\n\nGrund:\n\n`);

const Video = ({ url }) => (
  <video controls style={{ marginRight: '10px', width: '100%', height: '100%' }}>
    <track kind="captions" />
    <source src={url} type="video/mp4" />
    Your browser does not support HTML5 video.
  </video>
);

class ViewLocation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      images: [],
      lightboxIsOpen: false,
      currentImage: 0,
      deleteModalOpen: false,
      error: false
    };

    this.handleLightboxNext = this.handleLightboxNext.bind(this);
    this.handleLightboxPrev = this.handleLightboxPrev.bind(this);
    this.handleCloseLightbox = this.handleCloseLightbox.bind(this);
    this.handleOpenLightbox = this.handleOpenLightbox.bind(this);
    this.handleBookmark = this.handleBookmark.bind(this);
    this.handleVote = this.handleVote.bind(this);
    this.loadLocation = this.loadLocation.bind(this);
    this.printLocation = this.printLocation.bind(this);
    this.handleToggleDeleteModal = this.handleToggleDeleteModal.bind(this);
    this.handleDeleteLocation = this.handleDeleteLocation.bind(this);
  }

  componentDidMount() {
    const {
      changeBlocked,
      match: {
        params: { id },
      },
    } = this.props;
    changeBlocked(true);

    if (!_.isEmpty(id)) {
      this.loadLocation(id)
    }
  }

  componentWillReceiveProps(nextProps) {
    const {
      contentElements,
      match: {
        params: { id },
      },
    } = nextProps;
    const {
      contentElements: oldContentElements,
      match: {
        params: { id: oldId },
      },
    } = this.props;

    if (JSON.stringify(contentElements) !== JSON.stringify(oldContentElements)) {
      const images = contentElements
      .filter(element => ['image', 'video'].indexOf(element.type) !== -1)
      .map((element) => {
        if (element.type === 'video') {
          return <Video url={element.data.url} />;
        }
        return element.data.url;
      });

      this.setState({ images });
    }

    if (id !== oldId) {
      this.loadLocation(id);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.loadLocationTimeout);
  }

  loadLocation(id) {
    const {
      isBookmarked,
      userId,
      get,
      isVoted,
    } = this.props;

    const callLoadLocation = () => {
      get(id)
        .then(Promise.all([
          isBookmarked({ location: id, user: userId }),
          isVoted({ location: id, user: userId }),
        ]))
        .then(results => {
          if (results.data.fileIFC[0].status_xkt == 1) {
            this.loadLocationTimeout = setTimeout(() => {
              callLoadLocation();
            }, 10000);
          } else if (results.data.fileIFC[0].status_xkt == 2) {
            Alert.error('Die Datei konnte nicht konvertiert werden.');
          }
        })
        .catch(({ status }) => {
          if (status === 404) {
            this.setState({ error: true });
          }
        });
    };

    callLoadLocation();
  }

  handleLightboxPrev() {
    const { currentImage, images } = this.state;
    const { isPremium } = this.props;

    if (!isPremium) {
      return;
    }

    const limit = images.length - 1;
    let imageIndex = currentImage;

    if (imageIndex === 0) {
      imageIndex = limit;
    } else {
      imageIndex -= 1;
    }

    this.setState({ currentImage: imageIndex });
  }

  handleLightboxNext() {
    const { currentImage, images } = this.state;
    const { isPremium } = this.props;

    if (!isPremium) {
      return;
    }

    const limit = images.length - 1;
    let imageIndex = currentImage;

    if (imageIndex === limit || limit <= 0) {
      imageIndex = 0;
    } else {
      imageIndex += 1;
    }

    this.setState({ currentImage: imageIndex });
  }

  handleOpenLightbox(url) {
    const { images } = this.state;

    this.setState({
      lightboxIsOpen: true,
      currentImage: images.indexOf(url),
    });
  }

  handleCloseLightbox() {
    this.setState({ lightboxIsOpen: false });
  }

  handleBookmark() {
    const {
      bookmarked,
      loadingBookmark,
      bookmark,
      deleteBookmark,
      match: { params: { id } },
      userId,
    } = this.props;

    if (loadingBookmark) {
      return;
    }

    const data = {
      user: userId,
      location: id,
    };

    if (bookmarked) {
      deleteBookmark(data);
    } else {
      bookmark(data);
    }
  }

  handleVote() {
    const {
      voted,
      loadingVote,
      vote,
      deleteVote,
      match: { params: { id } },
      userId,
    } = this.props;

    if (loadingVote) {
      return;
    }

    const data = {
      user: userId,
      location: id,
    };

    if (voted) {
      deleteVote(data);
    } else {
      vote(data);
    }
  }

  handleToggleDeleteModal() {
    const { deleteModalOpen } = this.state;

    this.setState({ deleteModalOpen: !deleteModalOpen });
  }

  handleDeleteLocation() {
    const {
      deleteLocation,
      history,
      match: { params: { id } },
    } = this.props;

    deleteLocation(id)
      .then(() => {
          Alert.success('Baupunkt erfolgreich gelöscht!');
          history.push('/app/');
        }
      );
  }

  printLocation() {
    const { location, history } = this.props;

    history.push(`/app/location/print/${location.id}`);
  }

  state = {
    modalOpen: false,
    nameFileIFC: ''
  }

  handleOpenViewFileIFC = (nameFileIFC) => this.setState({ modalOpen: true, nameFileIFC: nameFileIFC })

  handleCloseViewFileIFC = () => this.setState({ modalOpen: false })


  render() {
    const {
      history,
      location,
      userId,
      locationUserId,
      documents,
      fileIFC,
      contentElements,
      links,
      bookmarked,
      loadingBookmark,
      match: { url },
      voted,
      linkedLocations,
      loadingVote,
      isPremium,
      isAdmin,
    } = this.props;
    const {
      lightboxIsOpen,
      images,
      currentImage,
      deleteModalOpen,
      error,
      modalOpen,
      nameFileIFC
    } = this.state;
    const deletedUser = _.isEmpty(_.get(location, 'user_data'));
    const avatarUrl = _.get(location, 'user_data.avatar_image_url');
    const avatarUrlThumbnail = _.get(location, 'user_data.avatar_thumbnail_url');
    const userExpertBytes = _.get(location, 'user_data.expert_bytes', 0);
    let side = 'right';
    let counter = 0;
    const userOrganization = _.get(location, 'user_data.organization');
    const locationOrganization = _.get(location, 'organization');
    const locationFromCurrentUser = userId === locationUserId || isAdmin;
    const lockContent = !locationFromCurrentUser && !isPremium;
    const statusXKT = _.get(location, 'fileIFC[0].status_xkt');

    return (
      <Grid centered style={{ zIndex: 2 }}>
        <Modal size="mini" open={deleteModalOpen}>
          <Modal.Header>Baupunkt löschen?</Modal.Header>
          <Modal.Content>
            <p>Bist du sicher, dass du diesen Baupunkt unwiderruflich löschen willst?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleToggleDeleteModal}>Nein</Button>
            <Button negative content="Ja" onClick={this.handleDeleteLocation} />
          </Modal.Actions>
        </Modal>
        {!_.isEmpty(images) && lightboxIsOpen && (
          <Lightbox
            mainSrc={images[currentImage]}
            nextSrc={images[(currentImage + 1) % images.length]}
            prevSrc={images[(currentImage + images.length - 1) % images.length]}
            onCloseRequest={this.handleCloseLightbox}
            onMoveNextRequest={this.handleLightboxNext}
            onMovePrevRequest={this.handleLightboxPrev}
          />
        )}
        <Grid.Column computer={14} tablet={14} mobile={14} style={{ zIndex: 2 }}>
          {error &&
            <Segment attached="bottom" style={{ padding: '40px', display: 'relative' }}>
              <EmptyState header="Achtung" content="Dieser Baupunkt ist nicht (mehr) vorhanden" />
            </Segment>
          }
          {!error && (
            <Segment attached="bottom" style={{ padding: '20px', display: 'relative' }}>
              <NoPrint>
                <div style={{ position: 'absolute', right: '-25px', top: '40px', width: '49px' }}>
                  {(locationFromCurrentUser || isAdmin) && (
                    <Popup
                      trigger={(
                        <Button size="mini" color="blue" onClick={() => history.push(`/app/location/edit/${location.id}/`)}>
                          <Button.Content visible>
                            <Icon name="pencil" />
                          </Button.Content>
                        </Button>
                      )}
                      content="Baupunkt bearbeiten"
                    />
                  )}
                  <Popup
                    trigger={(
                      <Button
                        fluid
                        disabled={userId === locationUserId}
                        loading={loadingVote}
                        onClick={this.handleVote}
                        color={voted ? 'yellow' : 'blue'}
                        size="mini"
                        style={{ marginTop: '30px' }}
                      >
                        <Button.Content
                          visible
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                          }}
                        >
                          <Icon
                            name={`thumbs up${!voted ? ' outline' : ''}`}
                            style={{ marginRight: 0 }}
                          />
                          <p style={{ marginTop: '5px' }}>{_.get(location, 'votes_count', 0)}</p>
                        </Button.Content>
                      </Button>
                    )}
                    content={`Dieser Baupunkt gefällt mir ${voted ? 'nicht mehr ' : ''}`}
                  />
                  <Popup
                    trigger={(
                      <Button
                        size="mini"
                        loading={loadingBookmark}
                        color={bookmarked ? 'yellow' : 'blue'}
                        onClick={this.handleBookmark}
                        style={{ marginTop: '5px' }}
                      >
                        <Button.Content visible>
                          <Icon name={`bookmark${!bookmarked ? ' outline' : ''}`} />
                        </Button.Content>
                      </Button>
                    )}
                    content={`Baupunkt ${bookmarked ? 'nicht mehr ' : ''} merken`}
                  />
                  <Popup
                    trigger={(
                      <Button
                        as={isPremium ? 'a' : 'p'}
                        href={isPremium ? `${process.env.REACT_APP_API_ENDPOINT}api/v1/locations/download/?id=${location.id}`: ''}
                        size="mini"
                        color="blue"
                        style={{ marginTop: '5px' }}
                      >
                        <Button.Content>
                          <Icon name="download" />
                        </Button.Content>
                      </Button>
                    )}
                    content={isPremium ? 'Download Medien' : 'Der Medien-Download steht nur für Premium-Benutzer zur Verfügung'}
                  />
                  {!_.isEmpty(fileIFC) && (
                  <Popup
                    trigger={(
                      <Button
                        as={isPremium ? 'a' : 'p'}
                        href={isPremium ? fileIFC[0].file_url: ''}
                        size="mini"
                        color="blue"
                        style={{ marginTop: '5px' }}
                      >
                        <Button.Content>
                          <Icon name="download" />
                        </Button.Content>
                      </Button>
                    )}
                    content={isPremium ? 'Download IFC-Datei' : 'Der Medien-Download steht nur für Premium-Benutzer zur Verfügung'}
                  />)}
                  <Popup
                    trigger={(
                      <Button
                        size="mini"
                        color="blue"
                        style={{ marginTop: '5px' }}
                        onClick={isPremium ? this.printLocation : () => {}}
                      >
                        <Button.Content>
                          <Icon name="print" />
                        </Button.Content>
                      </Button>
                    )}
                    content={isPremium ? 'Drucken' : 'Die optimierte Druckversion steht nur Premium-Benutzern zur Verfügung'}
                  />
                  <Popup
                    trigger={(
                      <Button
                        href={`mailto:feedback@localexpert24.de?subject=Meldung%20unangemessener%20Baupunkt&body=${mailToBody(url)}`}
                        size="mini"
                        color="blue"
                        style={{ marginTop: '30px' }}
                      >
                        <Button.Content>
                          <Icon name="warning sign" />
                        </Button.Content>
                      </Button>
                    )}
                    content="Verstoß melden"
                  />
                  {locationFromCurrentUser && (
                    <Popup
                      trigger={(
                        <Button
                          size="mini"
                          color="red"
                          style={{ marginTop: '15px' }}
                          onClick={this.handleToggleDeleteModal}
                        >
                          <Button.Content visible>
                            <Icon name="trash" />
                          </Button.Content>
                        </Button>
                      )}
                      content="Baupunkt löschen"
                    />
                  )}
                </div>
              </NoPrint>
              <div>
                <Grid centered>
                  <Grid.Row>
                    <Grid.Column computer={16} tablet={16} textAlign="left">
                      <p>#{location.id}</p>
                    </Grid.Column>
                    <Grid.Column computer={12} tablet={16} textAlign="center">
                      <Header as="h2">
                        {location.street}<br />
                        <small style={{ fontWeight: 'normal' }}>{location.city}, {location.country}</small>
                        {location.what_3_words && (
                          <div style={{ display: 'block' }}>
                            <Popup
                              trigger={(
                                <small style={{ color: '#666', fontSize: '14px', fontWeight: 'normal' }}><Icon name='expand' />{location.what_3_words}</small>
                              )}
                              content="what3words-Adresse"
                            />
                          </div>
                        )}
                      </Header>
                    </Grid.Column>
                    <Breakpoint large up>
                      <Grid.Column computer={2} textAlign="right">
                        <p>Zuletzt editiert:<br />{location.updated_at}</p>
                        <p>Erstellt am:<br />{location.created_at}</p>
                      </Grid.Column>
                    </Breakpoint>
                    <Breakpoint medium only>
                      <div style={{ display: 'flex' }}>
                        <p style={{ marginRight: '20px' }}>Zuletzt editiert: {location.updated_at}</p>
                        <p>Erstellt am: {location.created_at}</p>
                      </div>
                    </Breakpoint>
                    <Breakpoint small down>
                      <div style={{ marginTop: '10px' }}>
                        <p>Zuletzt editiert: {location.updated_at}</p>
                        <p>Erstellt am: {location.created_at}</p>
                      </div>
                    </Breakpoint>
                  </Grid.Row>
                  <Grid.Row>
                    <Grid.Column computer={15}>
                      <Header as="h3">{location.description}</Header>
                      {!_.isEmpty(location.tags_pretty) && location.tags_pretty.map(tag => (
                        <Label key={tag.value} as="p" style={{ marginRight: '15px' }}>{tag.title}</Label>
                      ))}
                    </Grid.Column>
                  </Grid.Row>
                </Grid>

                <Grid reversed="computer" centered style={{ marginTop: '40px' }}>
                  <Grid.Column computer={5} tablet={15}>
                    <Grid>
                      <Grid.Column computer={16} tablet={8}>
                        <Segment>
                          <Item.Group divided link style={{ textAlign: 'left' }}>
                            <Item>
                              <Item.Image size="tiny">
                                <div style={{ position: 'relative' }}>
                                  <UserPicture
                                    src={!_.isEmpty(avatarUrl) ? avatarUrlThumbnail : 'https://react.semantic-ui.com/images/wireframe/square-image.png'}
                                  />
                                  <ExpertByteLabelSmall
                                    expertBytes={userExpertBytes}
                                  />
                                </div>
                              </Item.Image>
                              <Item.Content verticalAlign="middle">
                                <Header as="h4" onClick={() => !deletedUser ? history.push(`/app/users/${_.get(location, 'user_data.id')}`) : () => {}}>
                                  {!deletedUser ? (
                                    <span>{_.get(location, 'user_data.first_name')} {_.get(location, 'user_data.last_name')}</span>
                                  ) : (
                                    <span>Gelöschter Benutzer</span>
                                  )}
                                </Header>
                                {!_.isEmpty(userOrganization) && (<Item.Meta style={{ marginTop: 0 }}>{userOrganization.name}</Item.Meta>)}
                              </Item.Content>
                            </Item>
                          </Item.Group>
                        </Segment>
                      </Grid.Column>
                      <Grid.Column computer={16} tablet={8}>
                        {!_.isEmpty(locationOrganization) && (
                          <Breakpoint large up>
                            <p>Dieser Baupunkt wurde für folgende Organisation erstellt:</p>
                          </Breakpoint>
                        )}
                        {!_.isEmpty(locationOrganization) && (
                          <Segment>
                            {!_.isEmpty(locationOrganization) && (
                              <Breakpoint medium down>
                                <p style={{ fontSize: '12px', textAlign: 'center' }}>Dieser Baupunkt wurde für folgende Organisation erstellt:</p>
                              </Breakpoint>
                            )}
                            <Item.Group divided link style={{ textAlign: 'left' }}>
                              <Item onClick={() => history.push(`/app/organizations/${locationOrganization.id}`)}>
                                <Item.Image size="tiny">
                                  <div style={{ position: 'relative' }}>
                                    <Image
                                      src={!_.isEmpty(locationOrganization.logo_url_thumbnail) ? locationOrganization.logo_url_thumbnail : 'https://react.semantic-ui.com/images/wireframe/square-image.png'}
                                      style={{ width: '56px', height: '56px' }}
                                    />
                                    <ExpertByteLabelSmall
                                      expertBytes={locationOrganization.total_expert_bytes}
                                    />
                                  </div>
                                </Item.Image>
                                <Item.Content verticalAlign="middle">
                                  <Header as="h4">{locationOrganization.name}</Header>
                                </Item.Content>
                              </Item>
                            </Item.Group>
                          </Segment>
                        )}
                      </Grid.Column>
                    </Grid>
                    {!_.isEmpty(documents) && (
                      <Segment textAlign="center">
                        <Header as="h4" textAlign="left">
                          <Icon name='file' /> Dateianhänge
                        </Header>
                        {documents.map(document => (
                          <Segment>
                            <Grid>
                              <Grid.Column computer={14} textAlign="left" style={{ overflowWrap: 'break-word' }}>
                                {lockContent ? (
                                  <Popup content="Download des Dokuments nur mit einem Premium-Account möglich." trigger={<p>{decodeURIComponent(_.last(document.file_url.split('/')))}</p>} />
                                ) : (
                                  <a href={document.file_url} target="_blank" rel="noopener noreferrer">{decodeURIComponent(_.last(document.file_url.split('/')))}</a>
                                )}
                              </Grid.Column>
                            </Grid>
                          </Segment>
                        ))}
                      </Segment>
                    )}
                    {!_.isEmpty(fileIFC) && (
                      <Segment textAlign="center">
                        <Header as="h4" textAlign="left">
                          <Icon name='cube' />  IFC-Datei
                        </Header>
                        {fileIFC.map(file => (
                          <Segment key={file.id}>
                            <Grid>
                              <Grid.Column computer={14} textAlign="left" style={{ overflowWrap: 'break-word' }}>
                                {!lockContent ?
                                  <Popup content={statusXKT == 0 ? "Dateien anzeigen." : statusXKT == 1 ? 'Bitte warten Sie, während die Datei konvertiert wird.': 'Die konvertierte IFC-Datei ist fehlerhaft.'}
                                    trigger={<p onClick={() =>statusXKT == 0 && this.handleOpenViewFileIFC(decodeURIComponent(_.last(file.file_url.split('/'))).split('?')[0])}
                                    style={{ cursor: 'pointer', position: 'relative', color: '#4183c4' }}>{decodeURIComponent(_.last(file.file_url.split('/'))).split('?')[0]}
                                    <Icon style={{position: 'absolute', right: '0', opacity: statusXKT == 0 ? 0 : 1, color: '#000000DE' }} name="warning sign" /></p>} />
                                  :<Popup content="Dokumente können nur mit einem Premium-Account eingesehen werden." trigger={<p style={{color: '#4183c4' }}>{decodeURIComponent(_.last(file.file_url.split('/'))).split('?')[0]}</p>} />
                                }
                              </Grid.Column>
                            </Grid>
                          </Segment>
                        ))}
                      </Segment>
                    )}
                    {!_.isEmpty(links) && (
                      <Segment textAlign="center">
                        <Header as="h4" textAlign="left">
                          <Icon name='linkify' /> Links
                        </Header>
                        {links.map(link => (
                          <Segment>
                            <Grid>
                              <Grid.Column computer={14} textAlign="left" style={{ overflowWrap: 'break-word' }}>
                                <a href={link.url} target="_blank" rel="noopener noreferrer">{link.url}</a>
                              </Grid.Column>
                            </Grid>
                          </Segment>
                        ))}
                      </Segment>
                    )}
                    {!_.isEmpty(linkedLocations) && (
                      <Segment textAlign="center">
                        <Header as="h4" textAlign="left">
                          <Icon name='point' /> Verknüpfte Baupunkte
                        </Header>
                        <Grid>
                          <Grid.Column mobile={16} tablet={16} computer={16}>
                            {linkedLocations.map(linkedLocation => (
                              <Segment>
                                <Grid>
                                  <Grid.Column computer={16} textAlign="left">
                                    <Link to={`/app/location/detail/${linkedLocation.id}`}>
                                      <strong>{linkedLocation.street}</strong>
                                    </Link>
                                    <p>
                                      {linkedLocation.user_data.first_name} {linkedLocation.user_data.last_name}
                                      <br />{_.get(linkedLocation, 'user_data.organization.name')}
                                    </p>
                                  </Grid.Column>
                                </Grid>
                              </Segment>
                            ))}
                          </Grid.Column>
                        </Grid>
                      </Segment>
                    )}
                  </Grid.Column>
                  <Grid.Column computer={10} tablet={15}>
                    {contentElements.map((element) => {
                      counter = counter + 1;
                      const hide = counter > 1 && lockContent;
                      if (element.type === 'text') {
                        return hide ? (
                          <Popup
                            trigger={(
                              <Placeholder fluid style={{ marginBottom: '60px' }}>
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                                <Placeholder.Line />
                              </Placeholder>
                            )}
                            content="Dieser Text kann nur mit einem Premium-Account angesehen werden"
                          />
                          ) : (
                          <Grid.Row key={element.uuid} style={{ marginBottom: '60px' }}>
                            <ReactMarkdown>{element.data.text}</ReactMarkdown>
                          </Grid.Row>
                        );
                      }

                      if (['video', 'image'].indexOf(element.type) !== -1) {
                        side = side === 'left' ? 'right' : 'left';
                      }

                      if (element.type === 'image') {
                        return hide ? (
                          <Popup
                            on='click'
                            trigger={(
                              <Grid.Row style={{ marginBottom: '60px' }} key={element.uuid}>
                                <Item.Group>
                                  <Item style={{ cursor: 'pointer' }}>
                                    {side === 'left' && <Item.Image src={LockImagePlaceHolder} size="medium" />}
                                    <Breakpoint small down>
                                      {side === 'right' && <Item.Image src={LockImagePlaceHolder}  size="medium" />}
                                    </Breakpoint>
                                    <Item.Content>
                                      <Item.Description>
                                        <Placeholder>
                                          <Placeholder.Line />
                                          <Placeholder.Line />
                                          <Placeholder.Line />
                                          <Placeholder.Line />
                                        </Placeholder>
                                      </Item.Description>
                                    </Item.Content>
                                    <Breakpoint medium up>
                                      {side === 'right' && <Item.Image size="medium" src={LockImagePlaceHolder} />}
                                    </Breakpoint>
                                  </Item>
                                </Item.Group>
                              </Grid.Row>
                            )}
                          >
                            <Popup.Header>Premium-Account benötigt</Popup.Header>
                            <Popup.Content>
                              Als Basis-Mitglied wird dir nur das erste Foto eines Baupunkts angezeigt.
                              Als Premium-Mitglied stehen dir alle wertvollen Informationen der anderen Baupunkte zur Verfügung.
                              <div class='ui divider'></div>
                              <a href='/app/premium-features' class='ui button mini yellow'>Jetzt Premium-Mitglied werden</a>
                            </Popup.Content>
                          </Popup>
                          ) : (
                          <Grid.Row style={{ marginBottom: '60px' }} key={element.uuid}>
                            <Item.Group>
                              <Item style={{ cursor: 'pointer' }}>
                                {side === 'left' && <Item.Image onClick={() => this.handleOpenLightbox(element.data.url)} size="large" src={!_.isEmpty(element.data.thumbnail_url) ? element.data.thumbnail_url : element.data.url} />}
                                <Breakpoint medium down>
                                  {side === 'right' && <Item.Image onClick={() => this.handleOpenLightbox(element.data.url)} size="medium" src={!_.isEmpty(element.data.thumbnail_url) ? element.data.thumbnail_url : element.data.url} />}
                                </Breakpoint>
                                <Item.Content>
                                  <Item.Description>
                                    <ReactMarkdown>{element.data.text}</ReactMarkdown>
                                  </Item.Description>
                                </Item.Content>
                                <Breakpoint medium up>
                                  {side === 'right' && <Item.Image onClick={() => this.handleOpenLightbox(element.data.url)} size="large" src={!_.isEmpty(element.data.thumbnail_url) ? element.data.thumbnail_url : element.data.url} />}
                                </Breakpoint>
                              </Item>
                            </Item.Group>
                          </Grid.Row>
                        );
                      }

                      if (element.type === 'video') {
                        return hide ? (
                          <Popup
                            on='click'
                            trigger={(
                              <Item.Image src={LockVideoPlaceHolder} size="large" />
                            )}
                          >
                            <Popup.Header>Premium-Account benötigt</Popup.Header>
                            <Popup.Content>
                              Als Basis-Mitglied wird dir nur das erste Foto eines Baupunkts angezeigt.
                              Als Premium-Mitglied kannst du dir dann auch dieses Video ansehen.
                              <div class='ui divider'></div>
                              <a href='/app/premium-features' class='ui button mini yellow'>Jetzt Premium-Mitglied werden</a>
                            </Popup.Content>
                          </Popup>
                          ) : (
                          <Grid>
                            <Grid.Row style={{ marginBottom: '60px' }} key={element.uuid}>
                              {side === 'left' && (
                                <Grid.Column mobile={16} tablet={10} computer={10}>
                                  <video width="100%" controls style={{ marginRight: '10px' }}>
                                    <track kind="captions" />
                                    <source src={element.data.url} type="video/mp4" />
                                    Your browser does not support HTML5 video.
                                  </video>
                                </Grid.Column>
                              )}
                              <Breakpoint small down>
                                {side === 'right' && (
                                  <Grid.Column mobile={16} tablet={10} computer={10}>
                                    <video width="100%" controls style={{ marginRight: '10px' }}>
                                      <track kind="captions" />
                                      <source src={element.data.url} type="video/mp4" />
                                      Your browser does not support HTML5 video.
                                    </video>
                                  </Grid.Column>
                                )}
                              </Breakpoint>
                              <Grid.Column mobile={16} tablet={6} computer={6}>
                                <ReactMarkdown>{element.data.text}</ReactMarkdown>
                              </Grid.Column>
                              <Breakpoint medium up>
                                {side === 'right' && (
                                  <Grid.Column mobile={16} tablet={10} computer={10}>
                                    <video width="100%" controls style={{ marginRight: '10px' }}>
                                      <track kind="captions" />
                                      <source src={element.data.url} type="video/mp4" />
                                      Your browser does not support HTML5 video.
                                    </video>
                                  </Grid.Column>
                                )}
                              </Breakpoint>
                            </Grid.Row>
                          </Grid>
                        );
                      }

                      return null;
                    })}
                    {_.isEmpty(contentElements) && (
                      <EmptyState />
                    )}
                  </Grid.Column>
                </Grid>
              </div>
            </Segment>
          )}
        </Grid.Column>
        <ViewFileIFC isOpen={modalOpen} onClose={this.handleCloseViewFileIFC} fileName={nameFileIFC} id={this.props.location.id} />
      </Grid>
    );
  }
}

ViewLocation.defaultProps = {
  userId: '',
  locationUserId: '',
};

ViewLocation.propTypes = {
  get: PropTypes.func.isRequired,
  changeBlocked: PropTypes.func.isRequired,
  loadingBookmark: PropTypes.bool.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  isBookmarked: PropTypes.func.isRequired,
  bookmarked: PropTypes.bool.isRequired,
  bookmark: PropTypes.func.isRequired,
  deleteBookmark: PropTypes.func.isRequired,
  loadingVote: PropTypes.bool.isRequired,
  isVoted: PropTypes.func.isRequired,
  voted: PropTypes.bool.isRequired,
  vote: PropTypes.func.isRequired,
  deleteVote: PropTypes.func.isRequired,
  deleteLocation: PropTypes.func.isRequired,
  userId: PropTypes.string,
  locationUserId: PropTypes.string,
  isPremium: PropTypes.bool.isRequired,
  documents: PropTypes.array.isRequired, // eslint-disable-line
  fileIFC: PropTypes.array.isRequired, // eslint-disable-line
  contentElements: PropTypes.array.isRequired, // eslint-disable-line
  links: PropTypes.array.isRequired, // eslint-disable-line
  linkedLocations: PropTypes.array.isRequired, // eslint-disable-line
  match: PropTypes.object.isRequired, // eslint-disable-line
  history: PropTypes.object.isRequired, // eslint-disable-line
  location: PropTypes.object.isRequired, // eslint-disable-line
};

function mapStateToProps(state) {
  const location = _.get(state, 'location.data', {});
  const isAdmin = STATEAPI.isAdmin(state)
  return {
    userId: _.get(state, 'session.user.id'),
    location,
    isPremium: isAdmin || _.get(state, 'session.user.is_premium', false),
    isAdmin,
    bookmarked: _.get(state, 'location.bookmarked', false),
    loadingBookmark: _.get(state, 'location.loadingBookmark', false),
    voted: _.get(state, 'location.voted', false),
    loadingVote: _.get(state, 'location.loadingVote', false),
    locationUserId: _.get(location, 'user_data.id'),
    contentElements: _.get(location, 'content_elements', []),
    documents: _.get(location, 'documents', []).filter(document => !_.isEmpty(document.file_url)),
    fileIFC: _.get(location, 'fileIFC', []).filter(file => !_.isEmpty(file.file_url)),
    links: _.get(location, 'links', []),
    linkedLocations: _.get(location, 'linked_locations', []),
  };
}

const mapDispatchToProps = {
  get: LOCATION_ACTIONS.get,
  bookmark: LOCATION_ACTIONS.bookmark,
  deleteBookmark: LOCATION_ACTIONS.deleteBookmark,
  isBookmarked: LOCATION_ACTIONS.isBookmarked,
  isVoted: LOCATION_ACTIONS.isVoted,
  vote: LOCATION_ACTIONS.vote,
  deleteVote: LOCATION_ACTIONS.deleteVote,
  deleteLocation: LOCATION_ACTIONS.deleteLocation,
};

export default AuthHOC(withRouter(connect(mapStateToProps, mapDispatchToProps)(ViewLocation)));
