import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
} from 'semantic-ui-react';
import ElementMedia from './ElementMedia';
import RichTextArea from '../../../../RichTextArea';

const ImageElement = ({
  element,
  addElement,
  handleUpload,
  handleTextChange,
  updateOnUpload,
  handleRotate,
}) => (
  <Grid.Row>
    <Grid.Column mobile={16} tablet={4} computer={4}>
      <ElementMedia
        type="image"
        element={element}
        addElement={addElement}
        handleUpload={handleUpload}
        updateOnUpload={updateOnUpload}
        handleRotate={handleRotate}
      />
    </Grid.Column>
    <Grid.Column mobile={16} tablet={12} computer={12}>
      <RichTextArea
        style={{
          width: '100%',
          border: '1px solid rgba(34,36,38,.15)',
          padding: '10px',
        }}
        value={element.data.text}
        onChange={value => handleTextChange(element, value)}
      />
    </Grid.Column>
  </Grid.Row>
);

ImageElement.propTypes = {
  handleUpload: PropTypes.func.isRequired,
  addElement: PropTypes.func.isRequired,
  updateOnUpload: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  handleRotate: PropTypes.func.isRequired,
  element: PropTypes.object.isRequired, // eslint-disable-line
};

export default ImageElement;
