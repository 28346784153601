import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
} from 'semantic-ui-react';
import RichTextArea from '../../../../RichTextArea';

const TextElement = ({ element, handleTextChange }) => {
  return (
    <Grid.Row>
      <Grid.Column>
        <RichTextArea
          style={{
            width: '100%',
            border: '1px solid rgba(34,36,38,.15)',
            padding: '10px',
          }}
          rows={8}
          value={element.data.text}
          onChange={value => handleTextChange(element, value)}
        />
      </Grid.Column>
    </Grid.Row>
  );
}

TextElement.propTypes = {
  handleTextChange: PropTypes.func.isRequired,
  element: PropTypes.object.isRequired, // eslint-disable-line
};

export default TextElement;