import React, { Component } from 'react';
import Breakpoint from 'react-socks';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import AuthHOC from 'containers/AuthHOC';
import ActiveSidebar from 'modules/activeSidebar';
import Search from 'modules/search';
import { Button, Icon } from 'semantic-ui-react';
import * as LOCATION_ACTIONS from 'actions/location';

class HomePage extends Component {
  componentDidMount() {
    const { changeBlocked, listLocations, mapFilters } = this.props;

    changeBlocked(false);
    listLocations(mapFilters);
  }

  render() {
    const { history } = this.props;

    return (
      <div
        style={{
          position: 'absolute',
          top: '10px',
          right: 0,
          left: 0,
          bottom: 0,
          padding: '10px',
          overflow: 'hidden',
        }}
      >
        <Search />
        <Breakpoint medium up>
          <ActiveSidebar />
        </Breakpoint>
        <Breakpoint small down>
          <ActiveSidebar mobile />
        </Breakpoint>
        <Breakpoint medium up>
          <Button
            primary
            size="medium"
            className="reactour__create-location"
            onClick={() => history.push('/app/location/create/')}
            style={{
              zIndex: '1',
              position: 'absolute',
              top: '10px',
              left: '50%',
            }}
          >
            <Icon name="point" />
            Baupunkt erstellen
          </Button>
        </Breakpoint>
        <Breakpoint small down>
          <Button
            primary
            size="medium"
            onClick={() => history.push('/app/location/create/')}
            className="reactour__create-location"
            style={{
              zIndex: '1',
              position: 'absolute',
              top: '60px',
              left: '25%',
            }}
          >
            <Icon name="point" />
            Baupunkt erstellen
          </Button>
        </Breakpoint>
      </div>
    );
  }
}

HomePage.propTypes = {
  changeBlocked: PropTypes.func.isRequired,
  listLocations: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired, // eslint-disable-line
  mapFilters: PropTypes.object.isRequired, // eslint-disable-line
};

const mapStateToProps = state => ({
  mapFilters: _.get(state, 'map.filters', {}),
});

const mapDispatchToProps = {
  listLocations: LOCATION_ACTIONS.mapLocations,
};

export default AuthHOC(withRouter(connect(mapStateToProps, mapDispatchToProps)(HomePage)));
