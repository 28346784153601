import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid,
  Icon,
  Item,
  Segment,
  Button,
  List,
  Divider,
  Image,
  Dropdown,
  Loader,
} from 'semantic-ui-react';
import LazyLoad from 'react-lazy-load';
import _ from 'lodash';
import * as USER_ACTIONS from 'actions/user';
import * as ORGANIZATION_ACTIONS from 'actions/organization';
import AuthHOC from 'containers/AuthHOC';
import SearchContainer from 'containers/forms/Search';
import { UserPicture } from 'modules/styled/map';
import EmptyState from 'modules/EmptyState';
import ORGANIZATION_TYPES from 'utils/organization';
import { ExpertByteLabelSmall } from 'modules/styled/profile';
import { RankLabel, PremiumLabel } from 'modules/styled/profile';
// import OrganizationItem from 'modules/activeSidebar/partials/OrganizationItem';

const UserItem = ({
  user,
  handleClick,
}) => {
  const organization = _.get(user, 'organization.name', _.get(user, 'organization_member.name'));

  return (
    <Item
      onClick={handleClick}
      key={user.id}
    >
      <div style={{ display: 'flex', flexGrow: 1 }}>
        <LazyLoad width={56} height={56} debounce={false}>
          <UserPicture
            src={!_.isEmpty(user.avatar_image_url) ? user.avatar_thumbnail_url : 'https://react.semantic-ui.com/images/wireframe/square-image.png'}
          />
        </LazyLoad>
        <div style={{ flexGrow: 1, marginLeft: '20px' }}>
          <Grid>
            <Grid.Column computer={8} mobile={15}>
              <Grid.Row style={{ marginBottom: '3px' }}>
                <Item.Header as="a" style={{ fontSize: '16px' }}>{user.first_name} {user.last_name}</Item.Header>
                <p>{user.full_address}</p>
              </Grid.Row>
              <Grid.Row style={{ paddingBottom: '0px' }}>
                <List horizontal>
                  <List.Item>
                    <Icon name="ticket" style={{ marginRight: '5px' }} />{user.expert_bytes}
                  </List.Item>
                  <List.Item>
                    <Icon name="map marker alternate" style={{ marginRight: '5px' }} />{user.locations_count}
                  </List.Item>
                </List>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column computer={8} mobile={15}>
              <Grid.Row>
                {organization}
              </Grid.Row>
              <Grid.Row>
                <span style={{ color: '#aaa' }}>{user.position}</span>
              </Grid.Row>
              <Grid.Row>
                <div style={{ marginTop: '5px'}}>
                  {user.is_premium && (
                    <PremiumLabel />
                  )}
                  <RankLabel
                    rank={user.rank}
                  />
                </div>
              </Grid.Row>
            </Grid.Column>
          </Grid>
        </div>
      </div>
    </Item>
  );
};

const OrganizationItem = ({
  organization,
  handleClick
}) => {
  const type = ORGANIZATION_TYPES.find(orgType => orgType.key === organization.type);
  const logoUrl = _.get(organization, 'logo_url_thumbnail');

  return (
    <Item
      onClick={handleClick}
      key={organization.id}
    >
      <div style={{ display: 'flex' }}>
        <div style={{ position: 'relative' }}>
          <LazyLoad width={56} height={56} debounce={false}>
            <Image
              src={!_.isEmpty(logoUrl) ? logoUrl : 'https://react.semantic-ui.com/images/wireframe/square-image.png'}
              style={{ width: '56px', height: '56px' }}
            />
          </LazyLoad>
          <ExpertByteLabelSmall
            expertBytes={organization.total_expert_bytes}
          />
        </div>
        <Item.Content style={{ marginLeft: '10px' }}>
          <Item.Header as="a" style={{ fontSize: '16px' }}>{organization.name}</Item.Header>
          <Item.Meta style={{ marginTop: 0 }}>{_.get(type, 'text')}</Item.Meta>
          <p style={{ marginTop: '5px' }}>{_.get(organization, 'street')}</p>
        </Item.Content>
      </div>
    </Item>
  );
};

class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: props.selectedOption || 'experten',
      filters: {
        order_by: 'expert_score',
      },
    };

    this.handleChooseOption = this.handleChooseOption.bind(this);
    this.handleClickExperten = this.handleClickExperten.bind(this);
    this.handleClickOrganization = this.handleClickOrganization.bind(this);
    this.applyFilters = this.applyFilters.bind(this);
    this.loadMoreUsers = this.loadMoreUsers.bind(this);
    this.loadMoreOrganizations = this.loadMoreOrganizations.bind(this);
    this.handleChangeOrder = this.handleChangeOrder.bind(this);
  }

  componentDidMount() {
    const { changeBlocked, listUsers } = this.props;

    listUsers();
    changeBlocked(true);
  }

  applyFilters() {
    const { formData } = this.props;
    const { selected, filters } = this.state;

    this.setState({
      filters: {
        ...filters,
        ...formData,
      }
    }, () => {
      this.handleChooseOption(selected);
    });
  }

  loadMoreUsers() {
    const { usersNext, loadMoreUsers } = this.props;

    loadMoreUsers(usersNext);
  }

  loadMoreOrganizations() {
    const { organizationsNext, loadMoreOrganizations } = this.props;

    loadMoreOrganizations(organizationsNext);
  }

  handleChooseOption(option) {
    const { filters } = this.state;
    const { listOrganizations, listUsers, history } = this.props;

    this.setState({ selected: option });

    if (option === 'organisationen') {
      listOrganizations(filters);
      history.push('/app/community/#mitgliedersuche#organisationen')
    } else {
      listUsers(filters);
      history.push('/app/community/#mitgliedersuche')
    }
  }

  handleClickExperten(user) {
    const { history } = this.props;

    history.push(`/app/users/${user.id}`);
  }

  handleClickOrganization(organization) {
    const { history } = this.props;

    history.push(`/app/organizations/${organization.id}`);
  }

  handleChangeOrder(e, { value }) {
    const { filters } = this.state;

    this.setState({
      filters: {
        ...filters,
        order_by: value,
      },
    }, () => {
      this.applyFilters();
    });
  }

  render() {
    const {
      isPremium,
      usersCount,
      usersNext,
      organizationsNext,
      organizationsCount,
      users,
      organizations,
      loading,
    } = this.props;
    const { selected, filters: { order_by } } = this.state;
    const orderOptions = [
      {
        key: 'expert_score',
        text: 'Sortiert nach ExpertScore',
        value: 'expert_score',
      },
      {
        key: 'location_amount',
        text: 'Sortiert nach Anzahl Baupunkte',
        value: 'location_amount',
      },
    ];

    return (
      <div>
        <SearchContainer applyFilters={this.applyFilters} />
        <Divider style={{ margin: '40px auto' }} />
        <Grid centered>
          <Grid.Column textAlign="center" computer={13} tablet={10} mobile={14}>
            <Button.Group>
              <Button primary={selected === 'experten'} onClick={() => this.handleChooseOption('experten')}>Experten</Button>
              <Button primary={selected === 'organisationen'} onClick={() => this.handleChooseOption('organisationen')}>Organisationen</Button>
            </Button.Group>
          </Grid.Column>
          <Grid.Column textAlign="center" computer={3} tablet={6} mobile={14}>
            <Dropdown
              fluid
              value={order_by}
              selection
              options={orderOptions}
              onChange={this.handleChangeOrder}
            />
          </Grid.Column>
        </Grid>
        <Grid centered>
          <Grid.Column computer={14} tablet={16} mobile={14}>
            <Grid.Row>
              {loading ? <Loader active inline="centered" /> : (
                <Item.Group divided link style={{ textAlign: 'left' }}>
                  {selected === 'experten' && !_.isEmpty(users) && users.map(user => (
                    <UserItem key={user.id} user={user} handleClick={() => this.handleClickExperten(user)} />
                  ))}

                  {selected === 'organisationen' && !_.isEmpty(organizations) && organizations.map(organization => (
                    <OrganizationItem key={organization.id} organization={organization} handleClick={() => this.handleClickOrganization(organization)} />
                  ))}

                  {((selected === 'experten' && _.isEmpty(users)) || (selected === 'organisationen' && _.isEmpty(organizations))) && (
                    <EmptyState header="Keine Ergebnisse gefunden" content="Bitte ändere deine Suchparameter" />
                  )}

                  {!isPremium && ((selected === 'experten' && usersCount > 5) || (selected === 'organisationen' && organizationsCount > 5)) && (
                    <Segment textAlign="center">
                      <p>Dir werden aktuell nur <strong>5 Ergebnisse</strong> angezeigt.</p>
                      <p>Mit einer Premium-Mitgliedschaft hast du vollen Zugriff auf das Mitgliedsverzeichnis.</p>
                      <div class='ui divider'></div>
                      <a href='/app/premium-features' class='ui button mini yellow'>Jetzt Premium-Mitglied werden</a>
                    </Segment>
                  )}

                  {isPremium && selected === 'experten' && !_.isEmpty(usersNext) && (
                    <div style={{ display: 'block', textAlign: 'center' }}>
                      <Button color="blue" onClick={this.loadMoreUsers}>Mehr anzeigen</Button>
                    </div>
                  )}

                  {isPremium && selected === 'organisationen' && !_.isEmpty(organizationsNext) && (
                    <div style={{ display: 'block', textAlign: 'center' }}>
                      <Button color="blue" onClick={this.loadMoreOrganizations}>Mehr anzeigen</Button>
                    </div>
                  )}
                </Item.Group>
              )}
            </Grid.Row>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

Search.propTypes = {
  changeBlocked: PropTypes.func.isRequired,
  listUsers: PropTypes.func.isRequired,
  loadMoreUsers: PropTypes.func.isRequired,
  listOrganizations: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  isPremium: PropTypes.bool.isRequired,
  usersCount: PropTypes.number.isRequired,
  usersNext: PropTypes.string,
  organizationsCount: PropTypes.number.isRequired,
  organizationsNext: PropTypes.string,
  formData: PropTypes.object.isRequired, // eslint-disable-line
  history: PropTypes.object.isRequired, // eslint-disable-line
  users: PropTypes.array.isRequired, // eslint-disable-line
  organizations: PropTypes.array.isRequired, // eslint-disable-line
};

function mapStateToProps(state) {
  const isPremium = _.get(state, 'session.user.is_premium', false);
  const users = _.get(state, 'user.list', [])
  return {
    users: isPremium ? users : users.slice(0, 5),
    usersCount: _.get(state, 'user.count', []),
    usersNext: _.get(state, 'user.next', []),
    formData: _.get(state, 'form.search.values', {}),
    isPremium,
    organizations: _.get(state, 'organization.list', []),
    organizationsCount: _.get(state, 'organization.count', []),
    organizationsNext: _.get(state, 'organization.next', []),
    loading: state.user.loading || state.organization.loading,
  };
}

const mapDispatchToProps = {
  listUsers: USER_ACTIONS.list,
  loadMoreUsers: USER_ACTIONS.loadMore,
  listOrganizations: ORGANIZATION_ACTIONS.list,
  loadMoreOrganizations: ORGANIZATION_ACTIONS.loadMore,
};

export default AuthHOC(connect(mapStateToProps, mapDispatchToProps)(withRouter(Search)));
