import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import esriLoader from 'esri-loader';
import * as MAP_UTILS from 'utils/map';
import * as LOCATION_ACTIONS from 'actions/location';

class MiniMap extends Component {
  constructor(props) {
    super(props);

    this.webmap = null;
    this.mapView = null;
    this.renderMap = this.renderMap.bind(this);

    this.renderMap();
  }

  componentDidUpdate() {
    this.renderMap();
  }

  renderMap() {
    const {
      lat,
      lng,
      id,
    } = this.props;

    if (!lat || !lng) {
      return;
    }

    esriLoader.loadModules([
      'esri/Graphic',
      'esri/views/MapView',
      'esri/WebMap',
      'esri/core/watchUtils',
    ])
      .then(([
        Graphic,
        MapView,
        WebMap,
        watchUtils,
      ]) => {
        this.webmap = new WebMap({
          basemap: 'streets',
        });

        this.mapView = new MapView({ // eslint-disable-line
          map: this.webmap,
          container: 'miniMapViewDiv',
          zoom: 17,
          center: [lng, lat],
          ui: {
            components: [],
          },
        });

        const marker = new Graphic({
          geometry: {
            type: 'point',
            longitude: lng,
            latitude: lat,
          },
          symbol: MAP_UTILS.defaultMarker,
        });

        this.mapView.graphics.add(marker);

        watchUtils.whenOnce(this.mapView, 'ready')
          .then(() => {
            setTimeout(() => {
              window.print();
              window.location.href = `/app/location/detail/${id}`; // eslint-disable-line
            }, 2000);
          });

      })
      .catch((err) => {
        console.log('err', err); // eslint-disable-line
      });
  }

  render() {
    return (
      <div
        style={{
          margin: '0 auto',
          width: '750px',
          height: '300px',
        }}
      >
        <div
          id="miniMapViewDiv"
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </div>
    );
  }
}

MiniMap.defaultProps = {
  currentId: undefined,
  locations: [],
  linkedLocations: [],
};

MiniMap.propTypes = {
  currentId: PropTypes.number,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  locations: PropTypes.array, // eslint-disable-line
  linkedLocations: PropTypes.array, // eslint-disable-line
  mapLocationDetail: PropTypes.func.isRequired,
};

export function mapStateToProps(state) {
  return {
    locations: state.location.mapLocations,
  };
}

const mapDispatchToProps = {
  mapLocationsQuery: LOCATION_ACTIONS.mapLocationsQuery,
  mapLocationDetail: LOCATION_ACTIONS.mapLocationDetail,
};

export default connect(mapStateToProps, mapDispatchToProps)(MiniMap);
