import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Grid,
  Card,
  Header,
  Button,
  Tab,
} from 'semantic-ui-react';
import AuthHOC from 'containers/AuthHOC';
import Account from 'containers/settings/Account';

const panes = [
  {
    menuItem: { key: 'account', icon: 'user circle', content: 'Account' },
    render: () => <Account />,
  },
  {
    menuItem: { key: 'sicherheit', icon: 'lock', content: 'Sicherheit' },
    render: () => <Tab.Pane attached={false}>
        <p>Möchtest du dein Passwort ändern?</p>
        <p>Kein Problem! Logge dich dazu einfach aus deinem Account aus und klicke auf <strong>Passwort vergessen</strong>.</p>
        <p>Du erhältst dann eine E-Mail mit einem Link bei dem du ein neues Passwort vegeben kannst.</p>
      </Tab.Pane>,
  },
  {
    menuItem: { key: 'payment', icon: 'usd', content: 'Konto & Rechnungen' },
    render: () =>
      <Tab.Pane attached={false}>
        <p>Solltest du Fragen rund um deine Rechnungen für deinen Premium-Account haben, schreibe uns bitte eine E-Mail an <a href="mailto:feedback@localexpert24.de">feedback@localexpert24.de</a>.</p>
      </Tab.Pane>,
  },
]

class Premium extends Component {
  componentDidMount() {
    const { changeBlocked } = this.props;

    changeBlocked(true);
  }

  render() {
    const { history } = this.props;

    return (
      <Grid centered style={{ zIndex: 2 }}>
        <Grid.Column computer={14} tablet={14} mobile={14} style={{ zIndex: 2 }}>
          <Card fluid>
            <Card.Content style={{ textAlign: 'center' }}>
              <Grid.Row style={{ marginTop: '40px', marginBottom: '60px' }}>
                <Header as="h1" textAlign="center">Deine localexpert24-Einstellungen</Header>
                <p>Hier findest du Einstellungen rund um deinen Account.</p>
              </Grid.Row>

              <Tab menu={{ secondary: true, pointing: true, className: 'wrapped-tab-menu' }} panes={panes} />
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid>
    );
  }
}

Premium.propTypes = {
  changeBlocked: PropTypes.func.isRequired,
};

export default AuthHOC(Premium);
