import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Grid,
  Card,
  Header,
  Button,
  List,
  Segment,
} from 'semantic-ui-react';
import ConfirmModal from 'containers/settings/partials/account/ConfirmModal';
import { RankLabel, PremiumLabel, ExpertByteLabelLarge } from 'modules/styled/profile';
import _ from 'lodash';

class Account extends Component {
  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    const { modalOpen } = this.state;

    this.setState({ modalOpen: !modalOpen });
  }

  render() {
    const { modalOpen } = this.state;
    const { currentUser, history } = this.props;

    return (
      <Grid centered>
        <ConfirmModal
          open={modalOpen}
          toggleModal={this.toggleModal}
        />
        <Grid.Column computer={10} mobile={15}>
          <Grid.Row>
            <Header as="h2">Dein Account</Header>
            <Grid>
              <Grid.Column computer={8} mobile={15}>
                <List>
                  <List.Item>
                    <List.Header>Vorname</List.Header>
                    {_.get(currentUser, 'first_name')}
                  </List.Item>
                  <List.Item>
                    <List.Header>Nachname</List.Header>
                    {_.get(currentUser, 'last_name')}
                  </List.Item>
                  <List.Item>
                    <List.Header>E-Mail</List.Header>
                    {_.get(currentUser, 'email')}
                  </List.Item>
                </List>
                <Button primary size="medium" onClick={() => history.push(`/app/users/edit/${_.get(currentUser, 'id')}`)}>
                  Profil bearbeiten
                </Button>
              </Grid.Column>
              <Grid.Column computer={7} mobile={15}>
                {_.get(currentUser, 'is_premium') && (
                  <PremiumLabel />
                )}
                <RankLabel
                  rank={_.get(currentUser, 'rank')}
                />
              </Grid.Column>
            </Grid>
          </Grid.Row>

          <Grid.Row textAlign="left" style={{ marginTop: "40px" }}>
            <Segment>
              <Header as="h3">Account löschen</Header>
              <p>Es tut uns sehr leid, dass du dein Profil löschen willst. Falls du Probleme mit localexpert24 hattest, können wir dir vielleicht helfen?</p>
              <p>Schreibe uns dazu gerne eine E-Mail an <a href="mailto:feedback@localexpert24.de">feedback@localexpert24.de</a>.</p>
              <p>Bitte beachte, dass nach dem Löschen des Accounts eine Wiederherstellung deiner Daten nicht mehr möglich ist.</p>
              <Button
                size="medium"
                color="red"
                content="Account
                löschen"
                style={{ marginTop: '20px' }}
                onClick={this.toggleModal}
              />
            </Segment>
          </Grid.Row>
        </Grid.Column>
      </Grid>
    );
  }
}

Account.propTypes = {
  currentUser: PropTypes.object, // eslint-disable-line
  history: PropTypes.object.isRequired, // eslint-disable-line
};

function mapStateToProps(state) {
  return {
    currentUser: _.get(state, 'session.user'),
  };
}

const mapDispatchToProps = {
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Account));
