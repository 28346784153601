import REST from '../rest';

const BASE_URL = '/api/v1/organizations/';

export const create = (data, contentType) => REST.post(
  BASE_URL,
  data,
  {},
  { 'Content-Type': contentType },
);

export const get = (uuid, params) => REST.get(
  BASE_URL,
  uuid,
  params,
);

export const patch = (id, data, contentType) => REST.patch(
  BASE_URL,
  id,
  data,
  {},
  { 'Content-Type': contentType },
);

export const invites = params => REST.get(
  `${BASE_URL}invites/`,
  '',
  params,
);

export const invite = data => REST.post(
  `${BASE_URL}invite/`,
  data,
  {},
  { 'Content-Type': 'application/json' },
);

export const apply = data => REST.post(
  `${BASE_URL}apply/`,
  data,
  {},
  { 'Content-Type': 'application/json' },
);

export const deleteInvite = body => REST.delete(
  `${BASE_URL}invite/`,
  '',
  '',
  {},
  body,
  { 'Content-Type': 'application/json' },
);

export const members = organizationId => REST.get(
  `${BASE_URL}members/`,
  '',
  { organization_id: organizationId },
);

export const approve = inviteId => REST.post(
  `${BASE_URL}approve/`,
  { invite_id: inviteId },
  {},
  { 'Content-Type': 'application/json' },
);

export const reject = inviteId => REST.post(
  `${BASE_URL}reject/`,
  { invite_id: inviteId },
  {},
  { 'Content-Type': 'application/json' },
);

export const hasInvite = organizationId => REST.get(
  `${BASE_URL}has_invite/`,
  '',
  { organization_id: organizationId },
);

export const kick = memberId => REST.post(
  `${BASE_URL}kick/`,
  { member_id: memberId },
  {},
  { 'Content-Type': 'application/json' },
);

export const leave = organizationId => REST.post(
  `${BASE_URL}leave/`,
  { organization_id: organizationId },
  {},
  { 'Content-Type': 'application/json' },
);

export const deleteOrganization = () => REST.post(
  `${BASE_URL}delete/`,
  {},
  {},
  { 'Content-Type': 'application/json' },
);

export const patchOrganizationMember = (id, data) => REST.patch(
  '/api/v1/organization-members/',
  id,
  data,
  {},
  { 'Content-Type': 'application/json' },
);

export const setAdmin = data => REST.post(
  `${BASE_URL}set_admin/`,
  data,
  {},
  { 'Content-Type': 'application/json' },
);

export const loadMore = url => REST.get(url);
