import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { UserProfilePicture } from 'modules/styled/profile';

const UserAvatarImage = ({
  imageUrl,
  type,
  handleChange,
  loading,
  editing,
  containerStyle,
}) => (
  <div
    style={{
      width: '180px',
      marginTop: '-120px',
      display: 'inline-block',
      position: 'relative',
      ...containerStyle,
    }}
  >
    <UserProfilePicture loading={loading} src={imageUrl || 'https://react.semantic-ui.com/images/wireframe/square-image.png'} />
    {editing && (
      <label htmlFor={type}>
        <input
          id={type}
          type="file"
          accept="image/x-png,image/gif,image/jpeg"
          style={{ display: 'none' }}
          onChange={handleChange}
        />
        <Button
          type="button"
          primary
          size="huge"
          circular
          icon="plus"
          loading={loading}
          onClick={(event) => {
            event.preventDefault();
            document.getElementById(type).click();
          }}
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            marginLeft: '-25px',
            marginTop: '-25px',
          }}
        />
      </label>
    )}
  </div>
);

UserAvatarImage.defaultProps = {
  imageUrl: '',
  type: '',
  handleChange: () => {},
  containerStyle: {},
  loading: false,
  editing: true,
};

UserAvatarImage.propTypes = {
  imageUrl: PropTypes.string,
  type: PropTypes.string,
  handleChange: PropTypes.func,
  loading: PropTypes.bool,
  editing: PropTypes.bool,
  containerStyle: PropTypes.object, // eslint-disable-line
};

export default UserAvatarImage;
