import React, { Component } from 'react';
import { Modal } from 'semantic-ui-react';
import { Viewer, XKTLoaderPlugin, NavCubePlugin  } from '@xeokit/xeokit-sdk';
import API from 'api';

class ViewFileIFC extends Component {
  constructor(props) {
    super(props);
    this.viewerRef = React.createRef();
    this.navCubeRef = React.createRef();
  }

  componentDidMount() {
    if (this.props.isOpen) {
      this.loadViewer();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isOpen !== this.props.isOpen) {
    if (this.props.isOpen) {
      this.loadViewer();
    } else {
      this.viewer.destroy();
    }
    }
  }

  loadViewer() {
    this.viewer = new Viewer({
      canvasId: this.viewerRef.current.id
    });
    const xktLoader = new XKTLoaderPlugin(this.viewer);
    this.viewer.camera.eye = [-3.933, 2.855, 27.018];
    this.viewer.camera.look = [4.400, 3.724, 8.899];
    this.viewer.camera.up = [-0.018, 0.999, 0.039];

    const navCubePlugin = new NavCubePlugin(this.viewer, {
      canvasId: this.navCubeRef.current.id,
      visible: true,
      cameraFly: true,
      cameraFitFOV: 60,
      cameraFlyDuration: 0.5,
      fitVisible: false,
      synchProjection: false,
    });

    API.location.getXKTFile(this.props.id)
      .then(file => {
        xktLoader.load({
          id: 'model',
          src: file.body.data.xkt_url,
          edges: true // Emphasise edges
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }

  componentWillUnmount() {
    if (this.viewer) {
      this.viewer.destroy();
    }
  }

  render() {
    const { isOpen, onClose, fileName } = this.props;

    return (
      <Modal open={isOpen} onClose={onClose} style={{ width: '80%', height: '75%' }} closeIcon>
        <Modal.Header>{fileName}</Modal.Header>
        <Modal.Content style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>
        <div style={{ flex: 1, position: 'relative' }}>
          <canvas ref={this.viewerRef} id="canvasXKT" style={{ width: '100%', height: '100%', position: 'absolute' }} />
          <canvas ref={this.navCubeRef} id="navCubeCanvas" style={{ width: '150px', height: '150px', position: 'absolute', bottom: '10px', right: '10px' }} />
        </div>
      </Modal.Content>
      </Modal>
    );
  }
}

export default ViewFileIFC;
