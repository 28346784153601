import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  z-index: 999;
  position: absolute;
  padding-top: 5em;
`;

export const Box = styled.div`
  background-color: white;
`;

export const Image = styled.img`
  width: 100%;
  max-width: 430px;
  margin-bottom: 2em;
`;
