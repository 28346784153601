import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Grid,
  Header,
  Form,
  Checkbox,
  Icon,
  Segment,
  Button,
  Divider,
} from 'semantic-ui-react';
import {
  Field,
  reduxForm,
  change,
} from 'redux-form';
import * as what3words from '@what3words/api';
import * as Fields from 'modules/Fields';
import { filterTags, searchByLngLat } from 'api/lib/location';
import {fromCoordinates, isValid, toCoordinates} from 'api/what3words';
import { toComponent } from 'utils/select';
import * as STATEAPI from 'utils/stateapi';
import MiniMap from './partials/MiniMap';
import countries from 'const/countries';

const validate = (values) => {
  const errors = {};
  const errorMessage = 'Pflichtfeld';

  if (!values.street) {
    errors.street = errorMessage;
  }

  if (!values.created_at) {
    errors.created_at = errorMessage;
  }

  if (!values.description) {
    errors.description = errorMessage;
  }

  if (!values.tags) {
    errors.tags = errorMessage;
  }

  return errors;
};

class LocationDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputType: 'address'
    };

    this.what3Words = _.debounce((lat, lng) => {
      fromCoordinates(lat, lng)
        .then(({ words }) => this.handleWhat3WordsChange(words));
    }, 500).bind(this);
    this.handleMapChange = this.handleMapChange.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleWhat3WordsChange = this.handleWhat3WordsChange.bind(this);
    this.handleInputTypeChange = this.handleInputTypeChange.bind(this);

    this.searchByInputW3W = this.searchByInputW3W.bind(this);
    this.searchByInputLatLng = this.searchByInputLatLng.bind(this);
  }

  handleMapChange(value) {
    const { changeFieldValue } = this.props;

    changeFieldValue('lat', value.location.y);
    changeFieldValue('lng', value.location.x);

    //this.what3Words(value.location.y, value.location.x);
  }

  handleWhat3WordsChange(words) {
    const { changeFieldValue } = this.props;
    changeFieldValue('what_3_words', words);
  }

  handleAddressChange(value) {
    const { changeFieldValue } = this.props;

    this.handleMapChange(value);
    changeFieldValue('postalcode', value.attributes.Postal);
    changeFieldValue('street', value.attributes.StAddr);
    changeFieldValue('city', value.attributes.City);
    changeFieldValue('edit_address', false);
  }

  handleInputTypeChange(type) {
    const { changeFieldValue } = this.props;

    changeFieldValue('postalcode', '');
    changeFieldValue('street', '');
    changeFieldValue('Match_addr', '');
    changeFieldValue('city', '');
    changeFieldValue('lat', '');
    changeFieldValue('lng', '');
    changeFieldValue('lat_input', '');
    changeFieldValue('lng_input', '');
    changeFieldValue('what_3_words', '');
    changeFieldValue('what_3_words_input', '');
    changeFieldValue('edit_address', false);

    this.setState({ inputType: type });
  }

  searchByInputW3W() {
    const { formValues, changeFieldValue } = this.props;
    const what_3_words = _.get(formValues, 'what_3_words_input');
    this.setCoordinatesByW3WAddress(what_3_words);
  }

  searchByInputLatLng() {
    const { formValues, changeFieldValue } = this.props;
    const lat = _.get(formValues, 'lat_input');
    const lng = _.get(formValues, 'lng_input');
    changeFieldValue('lat', lat);
    changeFieldValue('lng', lng);
  }

  setCoordinatesByW3WAddress(w3w_address) {
    const { changeFieldValue } = this.props;
    
    if (isValid(w3w_address)) {
      toCoordinates(w3w_address)
        .then(({ coordinates }) => {
          changeFieldValue('lat', coordinates.lat);
          changeFieldValue('lng', coordinates.lng);
        });
    }
  }



  componentDidUpdate(prevProps) {
    const {formValues, changeFieldValue} = this.props;
    const {formValues: prevFormValues} = prevProps;
    const { inputType } = this.state;

    if (_.get(formValues, 'lat') !== _.get(prevFormValues, 'lat') || _.get(formValues, 'lng') !== _.get(prevFormValues, 'lng')) {

        this.what3Words(formValues.lat, formValues.lng);  

        searchByLngLat(`${_.get(formValues, 'lng')},${_.get(formValues, 'lat')}`)
          .then((res) => {
            console.log(res.body)
            let street = _.get(res.body, 'address.Address')
            let addrType = _.get(res.body, 'address.Addr_type')
            if (_.isEmpty(street) || !_.includes(["StreetName", "PointAddress", "StreetAddress"], addrType)) {
              street = "- "
            }
            let city = _.get(res.body, 'address.City')
            if (_.isEmpty(city)) {
              city = "-"
            }
            let postalcode = _.get(res.body, 'address.Postal')
            if (_.isEmpty(postalcode)) {
              postalcode = "-"
            }
            changeFieldValue('postalcode', postalcode);
            changeFieldValue('street', street);
            changeFieldValue('city', city);
          });
      
    }
  }

  render() {
    const {
      handleSubmit,
      formValues,
      initialValues,
      onSubmit,
      user,
      isAdmin,
    } = this.props;
    const { inputType } = this.state;
    const lat = _.get(formValues, 'lat');
    const lng = _.get(formValues, 'lng');
    const street = _.get(formValues, 'street');
    const city = _.get(formValues, 'city');
    const country = _.get(formValues, 'country');

    const organization = _.get(user, 'organization');
    const organizationMember = _.get(user, 'organization_member');
    const hasOrganization = !_.isEmpty(organization) || !_.isEmpty(organizationMember);

    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Grid.Row style={{ marginTop: '20px' }}>
          <Grid centered style={{ marginTop: '50px' }}>
            <Grid.Column mobile={15} tablet={15} computer={15}>
              <Grid.Row>
                <Header as="h2">Ortsangaben</Header>
              </Grid.Row>
              <Grid.Row style={{ marginTop: '10px' }}>
                Eingabe per:&nbsp;
                <Button.Group size="mini">
                  <Button type="button" primary={inputType == "address"} onClick={() => this.handleInputTypeChange('address')}>Adresse</Button>
                  <Button type="button" primary={inputType == "coordinates"} onClick={() => this.handleInputTypeChange('coordinates')}>Koordinaten</Button>
                  <Button type="button" primary={inputType == "w3w"} onClick={() => this.handleInputTypeChange('w3w')}>what3words</Button>
                </Button.Group>
              </Grid.Row>
              <Grid.Row>
                <Grid style={{ marginTop: 0 }}>
                  {(inputType == "address") && (
                    <Grid style={{ width: '100%'}}>
                      <Grid.Column mobile={15} tablet={10} computer={8}>
                        <Segment>
                          <small style={{ color: '#666', fontSize: '14px', fontWeight: 'normal'}}><Icon name='home' /> Adresse</small>
                          <Grid style={{ width: '100%'}}>
                            <Grid.Column mobile={15} tablet={6} computer={4}>
                              <Field
                                name="country"
                                placeholder="Land"
                                initialValue={!_.isEmpty(country) ? country : 'DE'}
                                component={Fields.renderSelect}
                                options={countries}
                              />
                            </Grid.Column>
                            <Grid.Column mobile={15} tablet={10} computer={12}>
                              <Field
                                name="Match_addr"
                                placeholder="Adresse des Baupunkts"
                                component={Fields.AddressSearch}
                                country={country}
                                clickCallback={this.handleAddressChange}
                              />
                            </Grid.Column>
                          </Grid>
                        </Segment>
                      </Grid.Column>
                    </Grid>
                  )}
                  {(inputType == "coordinates") && (
                    <Grid style={{ width: '100%'}}>
                      <Grid.Column mobile={15} tablet={10} computer={8}>
                        <Segment>
                          <small style={{ color: '#666', fontSize: '14px', fontWeight: 'normal'}}><Icon name='map marker alternate' /> Eingabe Koordinaten</small>
                          <Grid style={{ width: '100%'}}>
                            <Grid.Column mobile={15} computer={6}>
                              <Field
                                name="lat_input"
                                placeholder="Breitengrad"
                                component={Fields.renderInput}
                              />
                            </Grid.Column>
                            <Grid.Column mobile={15} computer={6}>
                              <Field
                                name="lng_input"
                                placeholder="Längengrad"
                                component={Fields.renderInput}
                              />
                            </Grid.Column>
                            <Grid.Column mobile={15} computer={3}>
                              <Button type="button" primary={true} onClick={() => this.searchByInputLatLng()}>Suchen</Button>
                            </Grid.Column>
                          </Grid>
                        </Segment>
                      </Grid.Column>
                    </Grid>
                  )}
                  {(inputType == "w3w") && (
                    <Grid style={{ width: '100%'}}>
                      <Grid.Column mobile={15} tablet={10} computer={8}>
                        <Segment>
                          <small style={{ color: '#666', fontSize: '14px', fontWeight: 'normal'}}><Icon name='expand' /> Eingabe what3words-Adresse</small>
                          <Grid style={{ width: '100%'}}>
                            <Grid.Column mobile={15} computer={12}>
                              <Field
                                disabled={inputType != "w3w"}
                                name="what_3_words_input"
                                placeholder="what 3 words"
                                component={Fields.renderInput}
                              />
                            </Grid.Column>
                            <Grid.Column mobile={15} computer={3}>
                              <Button type="button" primary={true} onClick={() => this.searchByInputW3W()}>Suchen</Button>
                            </Grid.Column>
                          </Grid>
                        </Segment>
                      </Grid.Column>
                    </Grid>
                  )}

                  {(lat && lng) && (
                    <Grid style={{ width: '100%'}}>
                      <Grid.Column mobile={15} tablet={10} computer={8}>
                        <Segment>
                          <small style={{ color: '#666', fontSize: '14px', fontWeight: 'normal'}}><Icon name='map marker alternate' /> Koordinaten</small>
                          <Grid style={{ width: '100%'}}>
                            <Grid.Column mobile={15} computer={8}>
                              <Field
                                disabled={true}
                                name="lat"
                                placeholder="Breitengrad"
                                component={Fields.renderInput}
                              />
                            </Grid.Column>
                            <Grid.Column mobile={15} computer={8}>
                              <Field
                                disabled={true}
                                name="lng"
                                placeholder="Längengrad"
                                component={Fields.renderInput}
                              />
                            </Grid.Column>
                          </Grid>
                        </Segment>
                      </Grid.Column>
                    </Grid>
                  )}

                  {(lat && lng) && (
                    <Grid style={{ width: '100%'}}>
                      <Grid.Column mobile={15} tablet={10} computer={8}>
                        <Segment>
                          <small style={{ color: '#666', fontSize: '14px', fontWeight: 'normal'}}><Icon name='expand' /> what3words-Adresse</small>
                          <Grid style={{ width: '100%'}}>
                            <Grid.Column mobile={15} computer={15}>
                              <Field
                                disabled={true}
                                name="what_3_words"
                                placeholder="what 3 words"
                                component={Fields.renderInput}
                              />
                            </Grid.Column>
                          </Grid>
                        </Segment>
                      </Grid.Column>
                    </Grid>
                  )}

                  {!_.isEmpty(city) && (
                    <Grid style={{ width: '100%'}}>
                      <Divider style={{ width: '100%'}}/>
                      <Grid.Column mobile={15} tablet={6} computer={2}>
                        <Field
                          name="postalcode"
                          placeholder="PLZ"
                          component={Fields.renderInput}
                        />
                      </Grid.Column>
                      <Grid.Column mobile={15} tablet={9} computer={4}>
                        <Field
                          name="city"
                          placeholder="Stadt"
                          component={Fields.renderInput}
                        />
                      </Grid.Column>
                      <Grid.Column mobile={15} tablet={16} computer={5}>
                        <Field
                          name="street"
                          placeholder="Straße"
                          component={Fields.renderInput}
                        />
                      </Grid.Column>
                    </Grid>
                  )}
                </Grid>
              </Grid.Row>
              {(lat && lng) && (
                <Grid.Row>
                  <Grid.Column width={16}>
                    <MiniMap
                      handleMapChange={this.handleMapChange}
                      lat={lat}
                      lng={lng}
                    />
                    <p><Icon name="info circle" />Du kannst die Position des Pins mit einem Klick in der Kartenansicht noch anpassen.</p>
                  </Grid.Column>
                </Grid.Row>
              )}
              <Grid.Row>
                <Header as="h2" style={{ marginTop: '20px' }}>Baupunkt Informationen</Header>
              </Grid.Row>
              <Grid.Row style={{ marginTop: '10px' }}>
                <Grid style={{ marginTop: 0 }}>
                  <Grid.Column mobile={15} tablet={5} computer={4}>
                    <Field
                      name="created_at"
                      placeholder="Datum"
                      component={Fields.renderDatePicker}
                    />
                  </Grid.Column>
                  {hasOrganization && (
                    <Grid.Column mobile={15} tablet={11} computer={12}>
                      <Field
                        style={{ marginTop: '10px' }}
                        name="organization"
                        value={!_.isEmpty(formValues, 'organization')}
                        label="Baupunkt für Organisation erstellen"
                        component={Fields.renderCheckBox}
                      />
                      <p><i aria-hidden="true" className="info circle icon"></i>Aktiviere dieses Häkchen, sofern du diesen Baupunkt für (d)eine Organisation gemacht hast. Dann erscheint dieser Baupunkt im Kartenfilter deiner Organisation.</p>
                    </Grid.Column>
                  )}
                  <Grid.Column mobile={15} tablet={16} computer={16}>
                    <Field
                      name="description"
                      placeholder="Titel der Baumaßnahme"
                      rows={2}
                      component={Fields.renderTextArea}
                    />
                  </Grid.Column>
                </Grid>
              </Grid.Row>
              <Grid.Row>
                <Grid>
                  <Grid.Column mobile={15} tablet={16} computer={16}>
                    <Field
                      multiple
                      name="tags"
                      tagType="tag"
                      placeholder="Kategorien"
                      component={Fields.renderTagSelect}
                      filterTags={filterTags}
                      options={toComponent(initialValues.tags_pretty)}
                    />
                  </Grid.Column>
                </Grid>
              </Grid.Row>
              {isAdmin && (
                <Grid.Row>
                  <Grid>
                    <Grid.Column mobile={15} tablet={16} computer={16}>
                      <Field
                        name="user"
                        labelText={!_.isEmpty(initialValues.user_data) ? `${initialValues.user_data.first_name} ${initialValues.user_data.last_name} (${initialValues.user_data.email})` : `${user.first_name} ${user.last_name} (${user.email})`}
                        placeholder="User suchen..."
                        component={Fields.UserSearch}
                      />
                    </Grid.Column>
                  </Grid>
                </Grid.Row>
              )}
            </Grid.Column>
          </Grid>
        </Grid.Row>
      </Form>
    );
  }
}

LocationDetail.defaultProps = {
  initialValues: {},
  values: {},
  user: {},
};

LocationDetail.propTypes = {
  values: PropTypes.object, // eslint-disable-line
  initialValues: PropTypes.object, // eslint-disable-line
  formValues: PropTypes.object, // eslint-disable-line
  user: PropTypes.object, // eslint-disable-line
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  changeFieldValue: PropTypes.func.isRequired,
};

const FORM_NAME = 'locationDetailForm';

const mapStateToProps = state => ({
  isAdmin: STATEAPI.isAdmin(state),
  formValues: _.get(state, `form.${FORM_NAME}.values`, {}),
  user: state.session.user,
});

const mapDispatchToProps = dispatch => ({
  changeFieldValue: (field, value) => {
    dispatch(change(FORM_NAME, field, value));
  },
});

const LocationDetailForm = reduxForm({
  form: FORM_NAME,
  validate,
  onSubmit: (values, dispatch, props) => props.onSubmit,
})(LocationDetail);

export default connect(mapStateToProps, mapDispatchToProps)(LocationDetailForm);
