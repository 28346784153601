import React from 'react';
import { connect } from 'react-redux';
import Breakpoint from 'react-socks';
import PropTypes from 'prop-types';
import {
  change,
  reduxForm,
} from 'redux-form';
import {
  Grid,
  Divider,
  Button,
  Form,
} from 'semantic-ui-react';
import _ from 'lodash';
import Content from './partials/Content';
import Info from './partials/Info';
import Invites from './partials/Invites';

const validate = (values) => {
  let errors = [];
  const requiredFields = [
    'name',
    'type',
    'street',
    'city',
    'postalcode',
    // 'number_of_employees',
  ];

  for (let i = 0; i < requiredFields.length; i++) { // eslint-disable-line
    const field = requiredFields[i];
    if (!values[field]) {
      errors = [...errors, field];
    }
  }

  const errorsObj = _.reduce(errors, (obj, param) => {
    obj[param] = 'Pflichtfeld'; // eslint-disable-line
    return obj;
  }, {});

  return errorsObj;
};

const CreateOrganization = ({
  step,
  lastStep,
  notFirstStep,
  formData,
  changeFieldValue,
  handleSubmit,
  onSubmit,
  handlePreviousStepClick,
  handleNextStepClick,
  handleCancel,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Info changeFieldValue={changeFieldValue} visible={step === 'info'} />
    <Content formData={formData} changeFieldValue={changeFieldValue} visible={step === 'content'} />
    {formData.id && <Invites organizationId={formData.id} visible={step === 'invite'} />}

    <Divider style={{ marginTop: '30px' }} />
    <Grid.Row textAlign="right">
      <Breakpoint small down>
        <Grid centered style={{ marginBottom: '5px' }}>
          <Grid.Column width={16}>
            {notFirstStep && (
              <Button fluid size="large" onClick={handlePreviousStepClick}>Zurück</Button>
            )}
            {step === 'invite' && (
              <Button fluid size="large" onClick={handleCancel}>
                Abbrechen
              </Button>
            )}
          </Grid.Column>
          <Grid.Column width={16}>
            <Button fluid primary size="large" onClick={handleNextStepClick}>{lastStep ? 'Speichern' : 'Weiter'}</Button>
          </Grid.Column>
        </Grid>
      </Breakpoint>
      <Breakpoint medium up>
        <Grid centered style={{ marginBottom: '5px' }}>
          <Grid.Column mobile={16} tablet={5} computer={7} widescreen={7} textAlign="left">
            {notFirstStep && (
              <Button size="large" onClick={handlePreviousStepClick}>Zurück</Button>
            )}
            {step === 'invite' && (
              <Button size="large" onClick={handleCancel}>
                Abbrechen
              </Button>
            )}
          </Grid.Column>
          <Grid.Column mobile={16} tablet={5} computer={7} widescreen={7} textAlign="right">
            <Button primary size="large" onClick={handleNextStepClick}>{lastStep ? 'Speichern' : 'Weiter'}</Button>
          </Grid.Column>
        </Grid>
      </Breakpoint>
    </Grid.Row>
  </Form>
);

CreateOrganization.defaultProps = {
  initialValues: {},
  formData: {},
};

CreateOrganization.propTypes = {
  step: PropTypes.string.isRequired,
  initialValues: PropTypes.object, // eslint-disable-line
  formData: PropTypes.object, // eslint-disable-line
  lastStep: PropTypes.bool.isRequired,
  notFirstStep: PropTypes.bool.isRequired,
  handlePreviousStepClick: PropTypes.func.isRequired,
  handleNextStepClick: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  changeFieldValue: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleCancel: PropTypes.func.isRequired,
};

const FORM_NAME = 'createOrganization';

const mapStateToProps = state => ({
  formData: _.get(state, `form.${FORM_NAME}.values`, {}),
});

const mapDispatchToProps = dispatch => ({
  changeFieldValue: (field, value) => {
    dispatch(change(FORM_NAME, field, value));
  },
});

const form = reduxForm({
  form: 'createOrganization',
  validate,
  onSubmit: (values, dispatch, props) => props.onSubmit,
})(CreateOrganization);

export default connect(mapStateToProps, mapDispatchToProps)(form);
