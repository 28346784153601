import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm, SubmissionError } from 'redux-form';
import * as C from 'actions/constants';
import {
  Grid,
  Button,
  Form,
} from 'semantic-ui-react';
import _ from 'lodash';
import { geolocated } from "react-geolocated";

import * as USER_ACTIONS from 'actions/user';
import * as SESSION_ACTIONS from 'actions/session';
import * as Fields from 'modules/Fields';
import * as Alert from 'utils/alert';
import * as USER_CONST from 'const/user';

import SommerAktion from 'assets/images/sommeraktion.jpg';

class SimpleRegisterContainer extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    const {
      formData,
      register,
      getToken,
      history,
      location,
      isGeolocationEnabled,
      coords,
    } = this.props;
    const search = location.search || '';
    const ref = _.get(search.split('?ref='), '[1]', '');

    let userData = { ...formData, ref };

    if (isGeolocationEnabled && coords) {
      userData = {
        ...formData,
        lat: coords.latitude,
        lng: coords.longitude,
      };
    }

    return register(userData)
      .then(({ type, data }) => {
        if (type === C.REGISTER_USER_SUCCESS) {
          return Object.assign({}, formData, data);
        }

        let errors = {
          _error: 'Registrierung konnte nicht abgeschlossen werden.',
        };
        const dataKeys = Object.keys(data.data);

        _.each(dataKeys, (key) => {
          errors = Object.assign({}, errors, { [key]: data.data[key].join('\n') });
        });

        throw new SubmissionError(errors);
      })
      .then((data) => {
        getToken({
          username: data.email,
          password: data.password,
        })
          .then((res) => {
            if (res.type === C.GET_TOKEN_SUCCESS) {
              localStorage.clear();
              history.push('/auth/register/confirm/');
              return;
            }

            Alert.error('Registrierung konnte nicht abgeschlossen werden. Bitte lade die Seite neu.');
          });
      });
  }

  render() {
    const { loading, handleSubmit, location } = this.props;
    const search = location.search || '';
    const ref = _.get(search.split('?ref='), '[1]', '');

    return (
      <Form onSubmit={handleSubmit(this.handleSubmit)}>
        <Field
          name="gender"
          placeholder="Anrede"
          component={Fields.renderSelect}
          options={USER_CONST.GENDER_OPTIONS}
        />
        <Field name="first_name" component={Fields.renderInput} type="text" placeholder="Vorname" />
        <Field name="last_name" component={Fields.renderInput} type="text" placeholder="Nachname" />
        <Field name="email" component={Fields.renderInput} type="email" placeholder="E-Mail-Adresse" />
        <Field name="password" component={Fields.renderInput} type="password" placeholder="Passwort" />
        <Field
          name="core_qualification"
          placeholder="Tiefbau-Qualifikation"
          component={Fields.renderSelect}
          options={USER_CONST.QUALIFICATION_OPTIONS}
        />

        <Grid centered columns={1}>
          <Grid.Column>
            <Button fluid primary size="large" loading={loading}>Registrieren</Button>
          </Grid.Column>
        </Grid>
      </Form>
    );
  }
}

SimpleRegisterContainer.propTypes = {
  getToken: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  formData: PropTypes.object.isRequired, // eslint-disable-line
  history: PropTypes.object.isRequired, // eslint-disable-line
  location: PropTypes.object.isRequired, // eslint-disable-line
};

function mapStateToProps(state) {
  return {
    formData: _.get(state.form, 'simpleRegister.values', {}),
    loading: _.includes([state.user.loading, state.session.loading], true),
  };
}

const mapDispatchToProps = {
  register: USER_ACTIONS.register,
  getToken: SESSION_ACTIONS.getToken,
};

const LoginForm = reduxForm({
  form: 'simpleRegister',
})(SimpleRegisterContainer);

export default geolocated({
  positionOptions: {
    enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
  watchPosition: true,
})(withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginForm)));
