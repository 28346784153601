import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Select,
  Form,
} from 'semantic-ui-react';
import { search, suggest } from 'api/lib/location';
import { locationToValue } from 'utils/select';

class AddressSearch extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
      loading: false,
    };

    this.handleSearch = this.handleSearch.bind(this);
    this.search = this.search.bind(this);
  }

  componentDidMount() {
    const { input } = this.props;

    if (_.isString(input.value)) {
      this.setState({ options: [{ key: input.value, value: input.value, text: input.value }] });
    }
  }

  componentWillReceiveProps(nextProps) {
    const { input } = this.props;

    if (!_.isEmpty(nextProps.input.value) && input.value !== nextProps.input.value) {
      const { options } = this.state;
      const getCurrentOption = options.find(option => option.value === nextProps.value);

      if (_.isEmpty(getCurrentOption)) {
        this.setState({
          options: [{
            key: nextProps.input.value,
            value: nextProps.input.value,
            text: nextProps.input.value,
          }],
        });
      }
    }
  }

  search() {
    const { options } = this.state;

    return options;
  }

  handleSearch(event, data) {
    const { country } = this.props;
    const { searchQuery } = data;

    if (searchQuery.length < 3) {
      return;
    }

    this.setState({ loading: true }, () => {
      suggest(searchQuery, country)
        .then(({ body: { suggestions } }) => {
          if (_.isEmpty(suggestions)) {
            this.setState({
              options: [],
              loading: false,
            });

            return [];
          }

          const results = suggestions
            .filter(result => result.isCollection === false)
            .map(result => ({
              key: result.magicKey,
              value: result.magicKey,
              text: result.text,
            }));

          return this.setState({
            options: _.uniqBy(results, 'key'),
            loading: false,
          });
        });
    });
  }

  render() {
    const {
      placeholder,
      clickCallback,
      style,
      disabled,
      input,
      meta: {
        touched,
        error,
      },
    } = this.props;
    const { options, loading } = this.state;

    return (
      <Form.Field style={style}>
        <Select
          {...input}
          style={style}
          fluid
          disabled={disabled}
          value={input.value}
          search={this.search}
          loading={loading}
          onSearchChange={_.debounce(this.handleSearch, 500)}
          minCharacters={3}
          name={input.name}
          options={options}
          placeholder={placeholder}
          onBlur={() => {}}
          onChange={(props, data) => {
            const currentOption = data.options.find(option => option.value === data.value);

            search(currentOption.value)
              .then(({ body: { candidates } }) => {
                if (_.isEmpty(candidates)) {
                  this.setState({
                    options: [],
                    loading: false,
                  });

                  return [];
                }

                const results = candidates
                  .map(result => ({
                    key: locationToValue(result),
                    value: result.attributes.Match_addr,
                    text: result.attributes.Match_addr,
                  }));

                this.setState({
                  options: _.uniqBy(results, 'key'),
                  loading: false,
                });

                return _.head(results);
              })
              .then((option) => {
                input.onChange(option.value);

                try {
                  const parsedValue = JSON.parse(option.key);
                  clickCallback(parsedValue);
                } catch (e) {}; // eslint-disable-line
              });
          }}
        />
        {touched && error && <span className="ui negative message mini">{error}</span>}
      </Form.Field>
    );
  }
}

AddressSearch.defaultProps = {
  style: {},
};

AddressSearch.propTypes = {
  input: PropTypes.object, // eslint-disable-line
  style: PropTypes.object, // eslint-disable-line
  meta: PropTypes.object, // eslint-disable-line
  placeholder: PropTypes.string.isRequired,
  clickCallback: PropTypes.func.isRequired,
};

export default AddressSearch;
