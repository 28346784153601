import _ from 'lodash';

export const getUserOrganization = (state) => {
  const organization = _.get(state, 'session.user.organization');

  if (!_.isEmpty(organization)) {
    return organization;
  }

  const organizationMember = _.get(state, 'session.user.organization_member', {});

  return organizationMember;
};

export const getCurrentUser = state => _.get(state, 'session.user', {});

export const isAdmin = state => _.get(state, 'session.user.is_admin', false);

export const userIsPremium = state => _.get(state, 'session.user.is_premium', false);
