import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Grid,
  Header,
  Segment,
  Image,
} from 'semantic-ui-react';

import { RankLabel, ExpertByteLabelLarge } from 'modules/styled/profile';
import * as USER_ACTIONS from 'actions/user';
import * as USER_CONSTS from 'const/user';
import AuthHOC from 'containers/AuthHOC';
import * as Timeline from 'modules/Timeline';


class ExpertTimeline extends Component {
  componentDidMount() {
    const { changeBlocked, get, userId } = this.props;
    changeBlocked(true);

    get(userId);
  }

  render() {
    const { user } = this.props;

    return (
      <Grid centered style={{ zIndex: 2 }}>
        <Grid.Column computer={14} tablet={14} mobile={14} style={{ zIndex: 2 }}>
          <Segment attached="bottom" style={{ padding: '20px', display: 'relative' }}>
            <Grid centered>
              <Grid.Column textAlign="center" computer={10} mobile={16}>
                <Header as="h2" style={{ marginTop: '40px' }} textAlign="center">Dein ExpertScore</Header>
                <p style={{ fontSize: '1.2em' }}>Der ExpertScore bietet dir die Möglichkeit, deine Expertise und dein Engagement sichtbar zu machen. Du sammelst für unterschiedliche Aktivitäten auf localexpert24 ExpertBytes (EB), die deinen ExpertScore automatisch erhöhen – z. B. für das Erstellen von Baupunkten oder ein gut gepflegtes Profil.</p>
                <p style={{ fontSize: '1.2em' }}>Du kannst deinen ExpertScore also selbst heben <strong>und damit</strong> einen <strong>Bronze-, Silber- oder Goldstatus</strong> erreichen. Viel Spaß und Erfolg dabei!</p>

                <div style={{ marginTop: '40px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <ExpertByteLabelLarge
                    expertBytes={user.expert_bytes}
                  />

                  <RankLabel
                    labelProps={{
                      style: { marginTop: '20px' },
                    }}
                    rank={user.rank}
                  />
                </div>
              </Grid.Column>
            </Grid>
          </Segment>

          <Segment style={{ padding: '20px', marginTop: '10px', display: 'relative' }}>
            <Header style={{ marginTop: '40px' }} as="h2" textAlign="center">Dein Weg zum Gold-Experten</Header>
            <Grid centered>
              <Grid.Column computer={10} mobile={15}>
                <Timeline.Container>
                  <Timeline.Header title="Bronze Experte (ab 0 EB)" />
                  {USER_CONSTS.BADGES.filter(badge => badge.bytes <= 300).map(badge => (
                    <Timeline.Item color="bronze" active={badge.bytes <= user.expert_bytes} badge={badge} image={badge.badgeImage} />
                  ))}

                  <Timeline.Header title="Silber Experte (ab 300 EB)" />
                  {USER_CONSTS.BADGES.filter(badge => badge.bytes > 300 && badge.bytes <= 1000).map(badge => (
                    <Timeline.Item color="silver" active={badge.bytes <= user.expert_bytes} badge={badge} image={badge.badgeImage} />
                  ))}

                  <Timeline.Header title="Gold Experte (ab 1000 EB)" />
                  {USER_CONSTS.BADGES.filter(badge => badge.bytes > 1000).map(badge => (
                    <Timeline.Item color="gold" active={badge.bytes <= user.expert_bytes} badge={badge} image={badge.badgeImage} />
                  ))}
                </Timeline.Container>
              </Grid.Column>
            </Grid>
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

ExpertTimeline.propTypes = {
  get: PropTypes.func.isRequired,
  changeBlocked: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired, // eslint-disable-line
};

function mapStateToProps(state) {
  return {
    user: _.get(state, 'user.data'),
    userId: _.get(state, 'session.user.id'),
  };
}

const mapDispatchToProps = {
  get: USER_ACTIONS.get,
};

export default AuthHOC(withRouter(connect(mapStateToProps, mapDispatchToProps)(ExpertTimeline)));
