import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import posed from 'react-pose';
import {
  Button,
  Grid,
  Item,
  Input,
  Dropdown,
  Popup,
  Icon,
  Segment,
  Loader,
} from 'semantic-ui-react';
import {
  reduxForm,
  Field,
} from 'redux-form';
import * as Fields from 'modules/Fields';
import * as MAP_ACTIONS from 'actions/map';
import * as SIDEBAR_ACTIONS from 'actions/sidebar';
import { filterTags, get } from 'api/lib/location';
import * as STATEAPI from 'utils/stateapi';
import LocationItem from './partials/LocationItem';
import {isValid, toCoordinates} from 'api/what3words';

const AnimatedBox = posed.div({
  visible: {
    height: 'calc(100vh - 120px)',
    padding: '10px',
  },
  hidden: {
    height: 0,
    padding: 0,
  },
});

const dropdownIcon = (filters) => {
  if (!_.isEmpty(filters.user)) {
    return 'user circle';
  }

  if (filters.bookmarked) {
    return 'bookmark';
  }

  if (filters.organization) {
    return 'building';
  }

  return 'map marker alternate';
};

const LAYERS = [
  {
    url: 'https://services2.arcgis.com/jUpNdisbWqRpMo35/arcgis/rest/services/DE_BGR_Bodenarten/FeatureServer/0/query?outFields=*&where=1%3D1',
    name: 'BGR-Bodenarten',
  },
  {
    url: 'https://services9.arcgis.com/L32TtVblyVlWGSqv/arcgis/rest/services/20190516_Versorgungsträger/FeatureServer',
    name: 'Versorgungsgebiete',
  },
  {
    url: 'https://services2.arcgis.com/jUpNdisbWqRpMo35/arcgis/rest/services/Naturschutzgebiete_RLP/FeatureServer',
    name: 'Naturschutzgebiete',
  },
  {
    url: 'https://services2.arcgis.com/jUpNdisbWqRpMo35/arcgis/rest/services/DE_BGR_Hydrogeologische_%C3%9Cbersichtskarte/FeatureServer',
    name: 'Hydrogeologische Übersichtskarte',
  },
  {
    url: 'https://services2.arcgis.com/jUpNdisbWqRpMo35/arcgis/rest/services/ATKIS_Schutzgebiete_NRW/FeatureServer/2',
    name: 'Schutzgebiete NRW',
  },
  {
    url: 'https://services9.arcgis.com/L32TtVblyVlWGSqv/arcgis/rest/services/Flubereinugungsverfahren_NRW/FeatureServer/1',
    name: 'Flurbereinigungsverfahren NRW',
  },
  {
    url: 'https://services6.arcgis.com/oVSalkrBEjVgPXn9/arcgis/rest/services/Bodenzustandserhebung_Landwirtschaft_Auszug/FeatureServer',
    name: 'Bodenzustandserhebung Landwirtschaft',
  }
];

class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
      searchType: 'text',
      tags: [],
    };

    this.handleFilterClick = this.handleFilterClick.bind(this);
    this.handleVisibility = this.handleVisibility.bind(this);
    this.handleAddressChange = this.handleAddressChange.bind(this);
    this.handleToggleSearchType = this.handleToggleSearchType.bind(this);
    this.handleTagChange = this.handleTagChange.bind(this);
    this.handleFeatureLayer = this.handleFeatureLayer.bind(this);
    this.getIcon = this.getIcon.bind(this);
    this.getButtonContent = this.getButtonContent.bind(this);
    this.handleWhat3Words = this.handleWhat3Words.bind(this);
  }

  componentDidMount() {
    filterTags(null, '')
      .then(({ body }) => {
        this.setState({
          tags: body.map(tag => ({
            key: tag.value,
            value: tag.value,
            text: tag.title,
          })),
        });
      });
  }

  handleFilterClick(type) {
    const { userId, filterLocation, userOrganization } = this.props;

    if (type === 'org') {
      filterLocation(userOrganization, type);
    } else {
      filterLocation(userId, type);
    }
  }

  handleVisibility() {
    const { open } = this.state;
    const { load, mapBounds, filters } = this.props;

    if (!open) {
      load({ ...mapBounds, ...filters });
    }

    this.setState({ open: !open });
  }

  handleAddressChange(value) {
    const { setMapCenter } = this.props;

    setMapCenter({
      center: [value.location.x, value.location.y],
      zoom: 16,
    });
  }

  handleWhat3Words(coordinates) {
    const { setMapCenter } = this.props;

    setMapCenter({
      center: [coordinates.lng, coordinates.lat],
      zoom: 21,
      w3wSquare: true,
    });
  }

  handleToggleSearchType() {
    const { searchType } = this.state;
    const options = ['text', 'tag', 'id', 'what3words']
    const currentIndex = options.indexOf(searchType)
    const maxIndex = options.length - 1

    const nextIndex = currentIndex === maxIndex ? 0 : currentIndex + 1

    this.setState({ searchType: options[nextIndex] });
  }

  handleTagChange(event, data) {
    const { setTagFilter } = this.props;
    const value = data.value || [];

    setTagFilter(value);
  }

  handleFeatureLayer(id) {
    const { featureLayer, isPremium, history } = this.props;

    if (!isPremium) {
      return history.push('/app/premium-features');
    }

    featureLayer(id);
  }

  getIcon() {
    const { searchType } = this.state
    if (searchType === 'text') {
      return 'tags'
    }

    if (searchType === 'tag') {
      return 'hashtag'
    }

    if (searchType === 'id') {
      return 'expand'
    }

    return 'search'
  }

  getButtonContent() {
    const { searchType } = this.state
    if (searchType === 'text') {
      return 'Wechsel zum Kategorie-Filter'
    }

    if (searchType === 'tag') {
      return 'Wechsel zur ID-Suche'
    }

    if (searchType === 'id') {
      return 'Wechsel zur what3words-Suche'
    }

    return 'Wechsel zur Adress-Suche'
  }

  render() {
    const { open, searchType, tags } = this.state;
    const {
      filters,
      locations,
      userOrganization,
      currentLayer,
      locationsNext,
      loadMore,
      loading,
      setMapCenter,
    } = this.props;
    const icon = this.getIcon()
    const content = this.getButtonContent()

    return (
      <Grid style={{ zIndex: 2, height: open ? '100%' : 'auto' }}>
        <Grid.Column widescreen={4} computer={7} tablet={7} mobile={16} style={{ zIndex: 2 }}>
          <Input style={{ zIndex: 2, borderRadius: 0, width: '100%' }} type="text" action>
            <Dropdown
              icon={dropdownIcon(filters)}
              floating
              button
              className={!_.isEmpty(filters) ? 'yellow icon reactour__filter' : 'icon reactour__filter'}
              style={{
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
            >
              <Dropdown.Menu>
                <Dropdown.Header content="LOCALEXPERT24" />
                <Dropdown.Item
                  active={_.isEmpty(filters)}
                  onClick={() => this.handleFilterClick('all')}
                  icon="map marker alternate"
                  text="Alle Baupunkte"
                />
                <Dropdown.Item
                  active={!_.isEmpty(filters.user)}
                  onClick={() => this.handleFilterClick('own')}
                  icon="user circle"
                  text="Meine Baupunkte"
                />
                {!_.isEmpty(userOrganization) && (
                  <Dropdown.Item
                    active={!_.isEmpty(filters.organization)}
                    onClick={() => this.handleFilterClick('org')}
                    icon="building"
                    text="Meine Organisation"
                  />
                )}
                <Dropdown.Item
                  active={filters.bookmarked}
                  onClick={() => this.handleFilterClick('bookmarked')}
                  icon="bookmark"
                  text="Gemerkte Baupunkte"
                />

                <Dropdown.Header content="Premium-Inhalte" />

                {LAYERS.map(layer => (
                  <Dropdown.Item
                    active={layer.url === currentLayer}
                    onClick={() => this.handleFeatureLayer(layer)}
                    icon="clone"
                    text={layer.name}
                  />
                ))}
              </Dropdown.Menu>
            </Dropdown>
            {searchType === 'text' && (
              <Field
                name="search_address"
                placeholder="Suche nach Adresse..."
                component={Fields.AddressSearch}
                clickCallback={this.handleAddressChange}
                style={{ width: '100%', borderRadius: 0 }}
              />
            )}
            {searchType === 'tag' && (
              <Dropdown
                fluid
                multiple
                search
                selection
                placeholder="Filter nach Kategorie"
                options={tags}
                onChange={this.handleTagChange}
                value={filters.tags || []}
              />
            )}
            {searchType === 'id' && (
              <form onSubmit={(e) => {
                e.preventDefault()
                const { history } = this.props
                const numberPattern = /\d+/g;
                const locationId = e.target.search_id.value.match(numberPattern).join('')
                if (!_.isEmpty(locationId)) {

                get(locationId)
                  .then((res) => {
                    const lat = res.body.lat;
                    const lng = res.body.lng;
                    setMapCenter({
                      center: [lng, lat],
                      zoom: 16,
                    });
                    setTimeout(() => {
                      history.push(`/app/location/detail/${locationId}`)
                    }, 1000);
                  })
                  .catch(({ status }) => {
                    if (status === 404) {
                      alert("Baupunkt-ID wurde nicht gefunden");
                    }
                  });
                }
              }}>
                <Field
                  name="search_id"
                  placeholder="Suche nach ID"
                  component={Fields.renderInput}
                  style={{ width: '100%', borderRadius: 0 }}
                />
              </form>
            )}
            {searchType === 'what3words' && (
              <form onSubmit={(e) => {
                e.preventDefault()
                const field = e.target.search_what3words;
                const value = field.value;

                if (!isValid(value)) {
                  field.focus();
                  return;
                }

                toCoordinates(value)
                  .then(({ coordinates }) => {
                    this.handleWhat3Words(coordinates);
                  });
              }}>
                <Field
                  name="search_what3words"
                  placeholder="Suche nach what3words-Adresse"
                  component={Fields.renderInput}
                  style={{ width: '100%', borderRadius: 0 }}
                />
              </form>
            )}
            <Popup
              trigger={(
                <Button
                  onClick={this.handleToggleSearchType}
                  icon={icon}
                  className="reactour__toggle-tags"
                  style={{
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                  }}
                />
              )}
              content={content}
            />
            <Button
              onClick={this.handleVisibility}
              type="submit"
              icon="list ul"
              className="reactour__list"
              style={{
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
              }}
            />
          </Input>

          <AnimatedBox
            pose={open ? 'visible' : 'hidden'}
            style={{
              position: 'absolute',
              top: '52px',
              left: '15px',
              width: '94%',
              zIndex: 3,
              backgroundColor: '#FFF',
            }}
          >
            <div style={{ position: 'relative', height: '100%', overflowY: 'scroll', zIndex: 3 }}>
              <Item.Group divided link style={{ textAlign: 'left' }}>
                {open && loading && _.isEmpty(locations) && <Loader active inline="centered" />}
                {(open && !_.isEmpty(locations)) && locations.map(location => (
                  <LocationItem key={location.id} data={location} />
                ))}
                {open && _.isEmpty(locations) && !loading && (
                  <Segment textAlign='center'>
                    <Icon name='inbox' size='big' color='grey' />
                    <p>Keine Baupunkte in dieser Ansicht gefunden</p>
                  </Segment>
                )}
              </Item.Group>
              {locationsNext && (
                <div style={{ textAlign: 'center', margin: '5px 0 20px' }}>
                  <Button
                    disabled={loading}
                    loading={loading}
                    color="blue"
                    size="mini"
                    onClick={() => loadMore(locationsNext)}
                  >
                    Mehr anzeigen
                  </Button>
                </div>
              )}
            </div>
          </AnimatedBox>
        </Grid.Column>
      </Grid>
    );
  }
}

Search.defaultProps = {
  filters: {},
  userId: '',
  userOrganization: '',
  locations: [],
  currentLayer: '',
  locationsNext: '',
};

Search.propTypes = {
  filterLocation: PropTypes.func.isRequired,
  setMapCenter: PropTypes.func.isRequired,
  setTagFilter: PropTypes.func.isRequired,
  featureLayer: PropTypes.func.isRequired,
  loadMore: PropTypes.func.isRequired,
  userId: PropTypes.string,
  userOrganization: PropTypes.string,
  locationsNext: PropTypes.string,
  currentLayer: PropTypes.string,
  load: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  mapBounds: PropTypes.object.isRequired, // eslint-disable-line
  filters: PropTypes.object, // eslint-disable-line
  locations: PropTypes.array, // eslint-disable-line
  history: PropTypes.object.isRequired, // eslint-disable-line
};

function mapStateToProps(state) {
  const mapBounds = _.get(state, 'map.bounds', {});

  return {
    filters: _.get(state, 'map.filters'),
    locations: _.get(state, 'sidebar.locations', []),
    isPremium: STATEAPI.userIsPremium(state),
    userId: _.get(state, 'session.user.id', ''),
    userOrganization: _.get(STATEAPI.getUserOrganization(state), 'id'),
    currentLayer: _.get(state, 'map.layer.url'),
    locationsNext: _.get(state, 'sidebar.locationsNext'),
    loading: _.get(state, 'sidebar.loadingLocations'),
    mapBounds,
  };
}

const mapDispatchToProps = {
  filterLocation: MAP_ACTIONS.filterLocation,
  setMapCenter: MAP_ACTIONS.setMapCenter,
  setTagFilter: MAP_ACTIONS.setTagFilter,
  featureLayer: MAP_ACTIONS.featureLayer,
  load: SIDEBAR_ACTIONS.load,
  loadMore: SIDEBAR_ACTIONS.loadMore,
};

const SearchForm = reduxForm({
  form: 'mapSearch',
})(Search);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchForm));
