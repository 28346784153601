import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Breakpoint from 'react-socks';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import esriLoader from 'esri-loader';
import ReactDOM from 'react-dom';
import _ from 'lodash';
import {
  Loader,
  Button,
} from 'semantic-ui-react';
import * as MAP_UTILS from 'utils/map';
import * as LOCATION_ACTIONS from 'actions/location';
import * as MAP_ACTIONS from 'actions/map';
import urlify from 'utils/string';

const getSymbol = (location, hoverId, organizationHoverId) => {
  const isCluster = location.clusterItems.length > 1;

  // if (isCluster && (!_.isEmpty(hoverId) || !_.isEmpty(organizationHoverId))) {
  //   const organizationId = _.get(location, 'organization.id');
  //   const active = !_.isEmpty(location.clusterItems.find(location => (location.user_data.id === hoverId || (!_.isEmpty(organizationId) && organizationId === organizationHoverId))));

  //   if (active) {
  //     return MAP_UTILS.clusterActiveTextMarker(location.clusterItems.length);
  //   }
  // }

  if (isCluster) {
    return MAP_UTILS.clusterTextMarker(location.clusterItems.length);
  }

  // if (!_.isEmpty(hoverId) && location.user_data.id === hoverId) {
  //   return MAP_UTILS.activeMarker;
  // }

  // const organizationId = _.get(location, 'organization.id');
  // if (!_.isEmpty(organizationHoverId) && !_.isEmpty(organizationId) && organizationId === organizationHoverId) {
  //   return MAP_UTILS.activeMarker;
  // }

  // if (_.isEmpty(hoverId) && _.isEmpty(organizationHoverId)) {
  //   return MAP_UTILS.defaultMarker;
  // }

  if (location.type == "infopunkt") {
    return MAP_UTILS.infoMarker;
  }

  return MAP_UTILS.defaultMarker;
};

const filterLocationsHover = (location, hoverId, organizationHoverId) => {
  if (!_.isEmpty(hoverId) && location.user_data.id === hoverId) {
    return true;
  }

  const organizationId = _.get(location, 'organization.id');
  if (!_.isEmpty(organizationHoverId) && !_.isEmpty(organizationId) && organizationId === organizationHoverId) {
    return true;
  }

  if (_.isEmpty(hoverId) && _.isEmpty(organizationHoverId)) {
    return true;
  }

  return false;
};


const DEBOUNCE_TIMEOUT = 500;

class Map extends Component {
  constructor(props) {
    super(props);

    this.state = {
      zoom: 0,
    };

    this.webmap = undefined;
    this.view = undefined;
    this.w3wLayer = undefined;
    this.graphicsLayer = undefined;
    this.featureLayer = undefined;
    this.loadMap = this.loadMap.bind(this);
    this.handleCreateLocationClick = this.handleCreateLocationClick.bind(this);
    this.handleFeatureLayer = this.handleFeatureLayer.bind(this);
    this.queryLocationContent = this.queryLocationContent.bind(this);
    this.updateBounds = _.debounce(this.updateBounds.bind(this), DEBOUNCE_TIMEOUT, {
      leading: true,
      trailing: true,
    });
    this.updateMap = _.debounce(this.updateMap.bind(this), DEBOUNCE_TIMEOUT, {
      leading: true,
      trailing: true,
    });

    this.loadMap();
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {
      locations: currentLocations,
      hoverId: currentHoverId,
      organizationHoverId: currentOrganizationHoverId,
      mapCenter: currentMapCenter,
      blocked: currentBlocked,
      loading: currentLoading,
      currentLayer: oldLayer,
    } = this.props;
    const {
      locations,
      hoverId,
      organizationHoverId,
      mapCenter,
      blocked,
      loading,
      currentLayer,
    } = nextProps;
    const {
      zoom: oldZoom,
    } = this.state;
    const {
      zoom,
    } = nextState;

    const sameLocations = JSON.stringify(locations) === JSON.stringify(currentLocations);
    const samePosition = JSON.stringify(mapCenter) === JSON.stringify(currentMapCenter);
    const sameHoverId = hoverId === currentHoverId;
    const sameOrganizationHoverId = organizationHoverId === currentOrganizationHoverId;
    const sameBlocked = blocked === currentBlocked;
    const sameLoading = loading === currentLoading;
    const sameLayer = oldLayer === currentLayer;
    const sameZoom = oldZoom === zoom;

    if (!sameLocations || !sameHoverId || !samePosition
      || !sameBlocked || !sameLoading || !sameOrganizationHoverId
      || !sameLayer || !sameZoom) {
      return true;
    }

    return false;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      mapCenter: prevMapCenter,
      locations: prevLocations,
      hoverId: prevHoverId,
      organizationHoverId: prevOrganizationHoverId,
      currentLayer: prevLayer,
    } = prevProps;
    const {
      mapCenter,
      locations,
      hoverId,
      organizationHoverId,
      currentLayer,
      blocked,
      readOnly,
    } = this.props;
    const {
      zoom,
    } = this.state;
    const {
      zoom: oldZoom,
    } = prevState;

    if (blocked && !readOnly) {
      return;
    }

    const sameZoom = oldZoom === zoom;
    const samePosition = JSON.stringify(mapCenter) === JSON.stringify(prevMapCenter);
    const sameLocations = JSON.stringify(prevLocations) === JSON.stringify(locations);
    const sameLayer = prevLayer === currentLayer;
    let payload = {};

    if (!sameLocations || !sameZoom) {
      payload = {
        removeGraphics: _.differenceBy(prevLocations, locations, 'id').map(location => location.id),
        addGraphics: locations,
      };
    }

    if (prevHoverId !== hoverId) {
      payload = {
        updateGraphics: true,
        removeGraphics: [],
        addGraphics: [],
      };
    }

    if (prevOrganizationHoverId !== organizationHoverId) {
      payload = {
        updateGraphics: true,
        removeGraphics: [],
        addGraphics: [],
      };
    }

    if (!_.isEmpty(payload) || !samePosition) {
      this.updateMap(payload)
        .then(() => {
          if (!samePosition) {
            this.view.goTo(mapCenter, { animate: false })
              .then(() => this.updateBounds());
          }
        });
    }

    if (!sameLayer) {
      this.handleFeatureLayer(currentLayer);
    }

    this.updateBounds();
  }

  queryLocationContent(target) {
    const { graphic: { attributes: { id } } } = target;
    const { mapLocationDetail, readOnly } = this.props;

    if (!readOnly) {
      return mapLocationDetail(id).then(({ data }) => MAP_UTILS.popupTemplate(data));
    } else {
      return mapLocationDetail(id).then(({ data }) => MAP_UTILS.popupTemplateReadOnly(data));
    }
  }

  updateBounds() {
    const { updateBounds, blocked } = this.props;

    if (!_.isObject(_.get(this.view, 'extent'))) {
      setTimeout(this.updateBounds, 500);
      return;
    }

    if (!this.view || blocked) {
      return;
    }

    esriLoader.loadModules([
      'esri/geometry/support/webMercatorUtils',
    ])
      .then(([webMercatorUtils]) => {
        const { xmax, xmin, ymax, ymin } = this.view.extent;
        const [maxLong, maxLat] = webMercatorUtils.xyToLngLat(xmax, ymax);
        const [minLong, minLat] = webMercatorUtils.xyToLngLat(xmin, ymin);

        updateBounds({
          minLong,
          maxLong,
          minLat,
          maxLat,
        });

        const mapDetails = {
          latitude: this.view.center.latitude,
          longitude: this.view.center.longitude,
          zoom: this.view.zoom,
        };

        localStorage.setItem('mapDetails', JSON.stringify(mapDetails));

        this.setState({ zoom: this.view.zoom });
      });
  }

  handleCreateLocationClick(event, history) {
    const { mapPoint } = event;
    history.push({
      pathname: '/app/location/create/',
      state: {
        lat: mapPoint.latitude,
        lng: mapPoint.longitude,
      },
    });
  }

  loadMap() {
    const { history, readOnly } = this.props;

    esriLoader.loadModules([
      'esri/tasks/Locator',
      'esri/views/MapView',
      'esri/WebMap',
      'esri/layers/GraphicsLayer',
      'esri/widgets/BasemapToggle',
      'esri/core/watchUtils',
    ])
      .then(([
        Locator,
        MapView,
        WebMap,
        GraphicsLayer,
        BasemapToggle,
        watchUtils,
      ]) => {
        const locatorTask = new Locator({
          url: 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer',
        });

        this.webmap = new WebMap({
          basemap: 'streets',
        });

        const mapDetailsFromStorage = localStorage.getItem('mapDetails');

        const mapDetails = !_.isEmpty(mapDetailsFromStorage) ? JSON.parse(mapDetailsFromStorage) : {
          latitude: 51.5,
          longitude: 10,
          zoom: 6,
        };

        this.view = new MapView({ // eslint-disable-line
          map: this.webmap,
          container: 'viewDiv',
          zoom: mapDetails.zoom,
          center: [mapDetails.longitude, mapDetails.latitude],
          ui: {
            components: ["attribution"],
          },
          popup: readOnly ? {} : {
            dockEnabled: true,
            dockOptions: {
              buttonEnabled: false,
              breakpoint: false,
              position: 'top-center',
            },
          },
        });

        const toggle = new BasemapToggle({
          view: this.view,
          nextBasemap: 'hybrid',
        });

        // Add widget to the top right corner of the view
        if (!readOnly) {
          this.view.ui.add(toggle, 'bottom-left');
        }

        this.w3wLayer = new GraphicsLayer({
          graphics: [],
        });

        this.graphicsLayer = new GraphicsLayer({
          graphics: [],
        });

        this.webmap.add(this.graphicsLayer);
        this.webmap.add(this.w3wLayer);

        const loadLocationAddress = (event) => {
          this.view.popup.title = 'Loading...';
          locatorTask.locationToAddress(event.mapPoint).then((response) => {
            this.view.popup.title = response.address;
          }).catch(() => {
            this.view.popup.title = 'No address was found for this location';
          });
          const node = document.createElement('div');
          ReactDOM.render(
            <div>
              <p>Du kannst an dieser Position einen neuen Baupunkt erstellen.</p><Button onClick={() => this.handleCreateLocationClick(event, history)} content='Baupunkt hier erstellen' icon='add' labelPosition='left' />
            </div>,
            node,
          );
          return node;
        };

        this.view.popup.dockOptions = {
          buttonEnabled: false,
        };

        this.view.on('click', (event) => {
          // If is right click
          if (!readOnly) {
            if (event.button === 2) {
              this.view.popup.open({
                content: loadLocationAddress(event),
                location: event.mapPoint,
              });
            }
          }
        });

        this.view.on('drag', this.updateBounds);
        this.view.on('mouse-wheel', this.updateBounds);
        this.view.on('resize', this.updateBounds);

        this.view.constraints = {
          minZoom: 6,
        };

        watchUtils.whenOnce(this.view, 'ready')
          .then(() => {
            this.updateBounds();
          });
      })
      .catch((err) => {
        console.log('err', err); // eslint-disable-line
      });
  }

  updateMap(payload) {
    const { readOnly, hoverId, organizationHoverId, locations: propsLocation, mapCenter } = this.props;

    return new Promise((resolve) => {
      if (_.isEmpty(this.view)) {
        setTimeout(() => {
          this.updateMap(payload);
        }, 1000);

        return resolve();
      }

      this.updateBounds();

      return esriLoader.loadModules([
        'esri/Graphic',
      ])
        .then(([
          Graphic,
        ]) => {
          if (!_.isEmpty(payload.removeGraphics) && _.isEmpty(payload.addGraphics)) {
            this.graphicsLayer.removeAll();
            this.w3wLayer.removeAll();
          }

          if (!_.isEmpty(payload.addGraphics) || payload.updateGraphics) {
            this.graphicsLayer.removeAll();
            this.w3wLayer.removeAll();
            let locations = [];
            if (!_.isEmpty(payload.addGraphics)) {
              locations = MAP_UTILS.clusterLocations(payload.addGraphics, this.view.zoom);
            } else {
              const filteredLocations = propsLocation.filter(location => filterLocationsHover(location, hoverId, organizationHoverId));
              locations = MAP_UTILS.clusterLocations(filteredLocations, this.view.zoom);
            }
            let clustersGraphics = [];

            const graphics = locations.map((cluster) => {
              const length = cluster.items.length;

              if (length > 1) {
                clustersGraphics.push({
                  geometry: {
                    type: 'point',
                    longitude: cluster.lng,
                    latitude: cluster.lat,
                  },
                  symbol: {
                    type: 'simple-marker',
                    style: 'circle',
                    color: [0, 132, 201, 0.8],
                    size: '30px',
                    xoffset: '5px',
                    yoffset: '8px',
                    outline: {
                      color: [0, 63, 96, 1.0],
                      width: 1,
                    },
                  }
                });
              }

              const location = {
                ...cluster.items[0],
                clusterItems: cluster.items,
              };
              const popupTemplate = readOnly ? {
                title: location.street,
                content: this.queryLocationContent,
              } : {
                title: location.street,
                content: this.queryLocationContent,
                actions: [],
                overwriteActions: true,
              };
              const pointGraphic = new Graphic({
                geometry: {
                  type: 'point', // autocasts as new Point()
                  longitude: cluster.lng,
                  latitude: cluster.lat,
                },
                symbol: getSymbol(location, hoverId, organizationHoverId),
                attributes: location,
                popupTemplate,
              });

              return pointGraphic;
            });

            if (!_.isEmpty(clustersGraphics)) {
              this.graphicsLayer.addMany(clustersGraphics);
            }

            this.graphicsLayer.addMany(graphics);
          }

          if (_.get(mapCenter, "w3wSquare")) {
            this.w3wLayer.removeAll();
            const w3wSquareGraphic = [];
            if (this.view.zoom > 19) {
              w3wSquareGraphic.push({
                geometry: {
                  type: 'point',
                  latitude: _.get(mapCenter, "center")[1],
                  longitude: _.get(mapCenter, "center")[0],
                },
                symbol: {
                  type: 'simple-marker',
                  style: 'square',
                  color: [225, 31, 38, 0.8],
                  size: '30px',
                }
              });
            }
            this.w3wLayer.addMany(w3wSquareGraphic);
          } else {
            this.w3wLayer.removeAll();
          }
          return resolve();
        });
    });
  }

  handleFeatureLayer(fLayer) {
    if (!fLayer.url) {
      if (this.webmap && this.featureLayer) {
        this.webmap.remove(this.featureLayer);
        this.featureLayer = undefined;
      }
    } else {
      return new Promise(resolve => esriLoader.loadModules(['esri/layers/FeatureLayer'])
        .then(([
          FeatureLayer,
        ]) => {
          let layer = new FeatureLayer({
            visible: true,
            url: fLayer.url,
            outFields: ["*"],
          });

          if (!_.isEmpty(this.featureLayer)) {
            this.webmap.removeMany([this.featureLayer, this.graphicsLayer]);
          }

          this.featureLayer = layer;

          this.webmap.addMany([this.featureLayer, this.graphicsLayer]);

          let getFeaturePopupInfo = (feature) => {
            let content = '<ul>';
            const graphic = feature.graphic;
            let attributes = graphic.attributes;
            for (let key in attributes) {
              if (attributes[key] !== null && attributes[key].toString().trim() !== '') {
                // Skip internal attributes
                if (key.lastIndexOf('Shape_', 0) === 0) {
                  continue;
                }
                content += '<li><strong>' + key + '</strong>: ' + urlify(attributes[key].toString()) + '</li>';
              }
            };
            content += '</ul>';
            return content;
          };

          const featureLayerPopupTemplate = {
            title: fLayer.name,
            content: getFeaturePopupInfo,
          };

          this.featureLayer.popupTemplate = featureLayerPopupTemplate;

          resolve();
        }));
    }
  }

  render() {
    const { blocked, loading } = this.props;

    return (
      <div>
        {loading && <Loader active style={{ zIndex: 1 }} />}
        <Breakpoint medium up>
          <div
            style={blocked || loading ? {
              position: 'fixed',
              width: '100vw',
              height: '100%',
              top: 0,
              left: 0,
              zIndex: 1,
              filter: 'blur(5px)',
            } : {}}
          >
            <div
              style={blocked || loading ? {
                position: 'fixed',
                width: '100vw',
                height: '100%',
                top: 0,
                left: 0,
                zIndex: 1,
              } : {}}
            />
            <div
              id="viewDiv"
              style={{
                width: '100vw',
                height: '100%',
                position: 'fixed',
                top: 0,
                padding: 0,
                margin: 0,
                left: 0,
                zIndex: 0,
              }}
            />
          </div>
        </Breakpoint>
        <Breakpoint small down>
          <div
            style={blocked || loading ? {
              position: 'fixed',
              width: '100vw',
              height: '100%',
              top: 0,
              left: 0,
              zIndex: 1,
              filter: 'blur(5px)',
            } : {}}
          >
            <div
              style={blocked || loading ? {
                position: 'fixed',
                width: '100vw',
                height: '100%',
                top: 0,
                left: 0,
                zIndex: 1,
              } : {}}
            />
            <div
              id="viewDiv"
              style={{
                width: '100vw',
                height: '100%',
                position: 'fixed',
                top: 0,
                padding: 0,
                margin: 0,
                left: 0,
                zIndex: 0,
              }}
            />
          </div>
        </Breakpoint>
      </div>
    );
  }
}

Map.defaultProps = {
  locations: [],
  hoverId: '',
  organizationHoverId: '',
  currentLayer: '',
  readOnly: false,
};

Map.propTypes = {
  blocked: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool,
  hoverId: PropTypes.string,
  organizationHoverId: PropTypes.string,
  currentLayer: PropTypes.string,
  mapLocationDetail: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired, // eslint-disable-line
  locations: PropTypes.array, // eslint-disable-line
  mapCenter: PropTypes.object, // eslint-disable-line
};

export function mapStateToProps(state) {
  return {
    mapCenter: state.map.mapCenter,
    hoverId: state.map.hoverId,
    organizationHoverId: state.map.organizationHoverId,
    locations: state.map.locations,
    loading: state.location.loading,
    currentLayer: _.get(state, 'map.layer'),
  };
}

const mapDispatchToProps = {
  mapLocationDetail: LOCATION_ACTIONS.mapLocationDetail,
  updateBounds: MAP_ACTIONS.updateBounds,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Map));
