import Alert from 'react-s-alert';

const config = {
  position: 'top-right',
  effect: 'slide',
};

export const error = message => Alert.error(message, config);

export const info = message => Alert.info(message, config);

export const success = message => Alert.success(message, config);
