import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  TextArea,
} from 'semantic-ui-react';

const TextAreaInput = ({
  input,
  placeholder,
  rows,
  style,
  meta: {
    touched,
    error,
  },
}) => (
  <Form.Field>
    <TextArea {...input} style={style} rows={rows} placeholder={placeholder} />
    {touched && error && <span class='ui negative message mini'>{error}</span>}
  </Form.Field>
);

TextAreaInput.propTypes = {
  input: PropTypes.object, // eslint-disable-line
  style: PropTypes.object, // eslint-disable-line
  rows: PropTypes.number.isRequired,
  placeholder: PropTypes.string.isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
};

export default TextAreaInput;
