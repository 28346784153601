import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Field, reduxForm } from 'redux-form';
import {
  Grid,
  Header,
  Form,
} from 'semantic-ui-react';
import _ from 'lodash';
import * as Fields from 'modules/Fields';

const validate = (values) => {
  const errors = {};
  const errorMessage = 'Pflichtfeld';

  if (!values.organization_name) {
    errors.organization_name = errorMessage;
  }

  if (!values.address) {
    errors.address = errorMessage;
  }

  if (!values.postalcode) {
    errors.postalcode = errorMessage;
  }

  if (!values.city) {
    errors.city = errorMessage;
  }

  if (!values.vat_id) {
    errors.vat_id = errorMessage;
  }

  return errors;
};

class OrgaPaymentDetailContainer extends Component {
  render() {
    const { handleSubmit, onSubmit } = this.props;
    return (
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Header as="h2" textAlign="left">Deine Rechnungsanschrift</Header>
        <Grid>
          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Field
              name="organization_name"
              placeholder="Firmenname"
              component={Fields.renderInput}
              type="text"
            />
          </Grid.Column>

          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Field
              name="address"
              placeholder="Straße"
              component={Fields.renderInput}
              type="text"
            />
          </Grid.Column>

          <Grid.Column mobile={5} tablet={5} computer={5}>
            <Field
              name="postalcode"
              placeholder="PLZ"
              component={Fields.renderInput}
              type="text"
            />
          </Grid.Column>
          <Grid.Column mobile={11} tablet={11} computer={11}>
            <Field
              name="city"
              placeholder="Ort"
              component={Fields.renderInput}
              type="text"
            />
          </Grid.Column>

          <Grid.Column mobile={16} tablet={16} computer={16}>
            <Field
              name="vat_id"
              placeholder="USt.ID"
              component={Fields.renderInput}
              type="text"
            />
          </Grid.Column>
        </Grid>
      </Form>
    );
  }
}

OrgaPaymentDetailContainer.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired, // eslint-disable-line
  history: PropTypes.object.isRequired, // eslint-disable-line
};

function mapStateToProps(state) {
  return {
    formData: _.get(state.form, 'orgaPaymentDetail.values', {}),
  };
}

const mapDispatchToProps = {};

const OrgaPaymentDetailForm = reduxForm({
  form: 'orgaPaymentDetail',
  validate,
  onSubmit: (values, dispatch, props) => props.onSubmit,
})(OrgaPaymentDetailContainer);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(OrgaPaymentDetailForm));
