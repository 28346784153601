import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid,
  Card,
  Header,
  Divider,
} from 'semantic-ui-react';
import _ from 'lodash';
import AuthHOC from 'containers/AuthHOC';
import * as SRC from 'modules/styled';
import * as STATEAPI from 'utils/stateapi';
import UserDetailFormContainer from 'containers/forms/UserDetail';
import Logo from 'assets/images/logo.png';
import * as TOUR_ACTIONS from 'actions/tour';
import * as MAP_ACTIONS from 'actions/map';

class RegisterProfilePage extends Component {
  constructor(props) {
    super(props);

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    const { history, openTour, user, setMapCenter } = this.props;

    if (user.lat && user.lng) {
      setMapCenter({
        center: [user.lat, user.lng],
        zoom: 6,
      });
      localStorage.setItem('mapDetails', JSON.stringify({ latitude: user.lat, longitude: user.lng, zoom: 6 }));
    }

    history.push('/app/');

    setTimeout(() => {
      openTour();
    }, 1000);
  }

  render() {
    return (
      <SRC.MapBG blocked>
        <Grid centered padded style={{ zIndex: 2 }}>
          <Grid.Column computer={14} tablet={14} mobile={14} style={{ zIndex: 2 }}>
            <Card fluid>
              <Card.Content>
                <Grid.Row textAlign="center">
                  <SRC.Logo logo={Logo} style={{ margin: '0 auto' }} />
                  <Header as="h1" textAlign="center">Willkommen bei localexpert24!</Header>
                  <p>
                    Du hast es fast geschafft. Bitte schließe die Anmeldung ab, indem du etwas über dich persönlich und auch über deinen beruflichen Hintergrund preisgibst.
                  </p>
                  <p>
                    So können dich andere lokale Experten besser finden, zuordnen und um Rat fragen.
                  </p>
                  <Divider />
                </Grid.Row>

                <UserDetailFormContainer handleSubmit={this.handleSubmit} />
              </Card.Content>
            </Card>
          </Grid.Column>
        </Grid>
      </SRC.MapBG>
    );
  }
}

RegisterProfilePage.propTypes = {
  history: PropTypes.object.isRequired, // eslint-disable-line
  openTour: PropTypes.func.isRequired,
  setMapCenter: PropTypes.func.isRequired,
};


const mapStateToProps = state => ({
  user: STATEAPI.getCurrentUser(state),
});

const mapDispatchToProps = {
  openTour: TOUR_ACTIONS.open,
  setMapCenter: MAP_ACTIONS.setMapCenter,
};

const ConnectedRegisterProfilePage = connect(
  mapStateToProps, mapDispatchToProps)(RegisterProfilePage)

export default AuthHOC(withRouter(ConnectedRegisterProfilePage));
