import _ from 'lodash';
import moment from 'moment';

export const get = data => ({
  ...data,
  country: data.country === 'Germany' ? 'DE' : data.country,
  content_elements: JSON.parse(_.get(data, 'content_elements', '[]')),
  created_at: moment(data.created_at).format('DD.MM.YYYY'),
  updated_at: moment(data.updated_at).format('DD.MM.YYYY'),
});

export const listPaginated = data => ({
  data: data.results.map(get),
  next: data.next,
  prev: data.previous
})

export const list = data => data.map(get)

export const patch = data => (_.has(data, 'created_at') ? {
  ...data,
  created_at: moment(data.created_at, 'DD.MM.YYYY').format('YYYY-MM-DDT00:00:00+0000'),
} : data);
