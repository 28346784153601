import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Header,
  Button,
  Modal,
  Form,
  Input,
  Grid,
  Label,
  Icon,
} from 'semantic-ui-react';
import _ from 'lodash';
import * as Alert from 'utils/alert';
import { isEmail } from 'utils/validate';
import * as USER_ACTIONS from 'actions/user';

class InvitesModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      inputValue: '',
      invites: [],
    };

    this.handleClose = this.handleClose.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }

  handleCancel() {
    const { closeInvitesModal } = this.props;
    closeInvitesModal();
  }

  handleClose() {
    const { closeInvitesModal, sendInvites } = this.props;
    const { invites } = this.state;

    if (!_.isEmpty(invites)) {
      sendInvites({ invites })
        .then(() => {
            Alert.success('Vielen Dank! Deine Einladungen wurden per E-Mail verschickt!');
            closeInvitesModal();
          },
        );
    } else {
      closeInvitesModal();
    }
  }

  handleAdd() {
    const { invites, inputValue } = this.state;
    const { list } = this.props;

    if (!isEmail(inputValue)) {
      return;
    }

    list({ email: inputValue })
      .then(({ count }) => {
        if (count === 0) {
          this.setState({
            invites: [
              ...invites,
              inputValue,
            ],
            inputValue: '',
          });
        } else {
          this.setState({ inputValue: '' });
          Alert.info('Es ist bereits ein Benutzer mit dieser E-Mail-Adresse registriert');
        }
      });
  }

  handleChange(e) {
    this.setState({ inputValue: e.target.value });
  }

  handleRemove(email) {
    const { invites } = this.state;

    this.setState({ invites: invites.filter(invite => invite !== email) })
  }

  render() {
    const { inputValue, invites } = this.state;
    const { modalIsOpen } = this.props;
    const node = document.getElementById('modals');

    return (
      <Modal mountNode={node} size="small" open={modalIsOpen}>
        <Modal.Content>
          <Modal.Description style={{ textAlign: 'center' }}>
            <Header>Baue dein Netzwerk aus.</Header>
            <p>Und lade weitere Kollegen oder Bekannte zu unserem Tiefbaunetzwerk ein.</p>
            <Grid centered>
              <Grid.Column width={15}>
                <Form.Field>
                  <Input
                    fluid
                    value={inputValue}
                    placeholder="E-Mail-Adresse"
                    onChange={this.handleChange}
                    action={(
                      <Button
                        onClick={this.handleAdd}
                        primary
                      >
                        Hinzufügen
                      </Button>
                    )}
                  />
                </Form.Field>
              </Grid.Column>
            </Grid>
            <div style={{ marginTop: '40px' }}>
              {invites.map(invite => (
                <Label key={invite} circular as="div" style={{ marginRight: '10px', marginBottom: '10px' }}>
                  <Icon circular name="user circle" size="large" />
                  {invite}
                  <Icon
                    name="delete"
                    onClick={() => this.handleRemove(invite)}
                  />
                </Label>
              ))}
            </div>
            <p><i aria-hidden="true" className="info circle icon"></i>Dies ist eine allgemeine Einladung zu unserer Plattform. Sofern du bereits eine Organisation erstellt hast und Kollegen zu dieser einladen möchtest, nutze bitte den Einladungsmechanismus in deinem Organisations-Profil.</p>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions style={{ textAlign: 'center' }}>
          <Button
              size="medium"
              content="Abbrechen"
              onClick={this.handleCancel}
            />
          <Button
            size="medium"
            positive
            content="Einladungen abschicken"
            onClick={this.handleClose}
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

InvitesModal.defaultProps = {};

InvitesModal.propTypes = {
  closeInvitesModal: PropTypes.func.isRequired,
  sendInvites: PropTypes.func.isRequired,
  list: PropTypes.func.isRequired,
  modalIsOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = state => ({
  modalIsOpen: _.get(state, 'user.invitesModal', false),
});

const mapDispatchToProps = {
  closeInvitesModal: USER_ACTIONS.closeInvitesModal,
  sendInvites: USER_ACTIONS.sendInvites,
  list: USER_ACTIONS.list,
};

export default connect(mapStateToProps, mapDispatchToProps)(InvitesModal);
