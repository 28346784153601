import _ from 'lodash';

function serialize(obj, prefix) {
  const str = [];
  Object.keys(obj).forEach((p) => {
    let k = p;
    const v = obj[p];
    if (prefix) {
      if (_.isObject(v) || !k.match(/\d+/)) {
        k = `${prefix}[${p}]`;
      } else {
        k = `${prefix}`;
      }
    }
    str.push((v !== null && _.isObject(v))
      ? serialize(v, k)
      : `${encodeURIComponent(k)}=${encodeURIComponent(v)}`);
  });
  if (!str.length) {
    return '';
  }

  return str.join('&');
}

export function buildURL(endpoint, id, action, params) { // eslint-disable-line
  // const allowedQueryParams = ['search'];
  const queryParams = {};

  _.forOwn(params, (value, key) => {
    // if (_.includes(allowedQueryParams, key)) {
    queryParams[key] = value;
    // }
  });

  const baseEndpoint = `${endpoint}${id ? `${id}/` : ''}${action ? `/${action}` : ''}`;
  const serializedQueryParams = serialize(queryParams);

  return `${baseEndpoint}${serializedQueryParams ? `?${serializedQueryParams}` : ''}`;
}

export const mergeIfPresent = (obj, elem, diff) => (
  _.isEmpty(elem) ? obj : _.merge(obj, diff(elem))
);
