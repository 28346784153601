import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Breakpoint from 'react-socks';
import { connect } from 'react-redux';
import { submit } from 'redux-form';
import _ from 'lodash';
import {
  Grid,
  Segment,
  Button,
  Divider,
} from 'semantic-ui-react';
import AuthHOC from 'containers/AuthHOC';
import UserDetailFormContainer from 'containers/forms/UserDetail';

class UserProfile extends Component {
  constructor(props) {
    super(props);

    this.handleSaveButton = this.handleSaveButton.bind(this);
  }

  componentDidMount() {
    const { changeBlocked } = this.props;
    changeBlocked(true);
  }

  handleSaveButton() {
    const { dispatch } = this.props;

    dispatch(submit('userDetailForm'));
  }

  render() {
    const { history, userId } = this.props;

    return (
      <Grid centered style={{ zIndex: 2 }}>
        <Grid.Column computer={14} tablet={14} mobile={14} style={{ zIndex: 2 }}>
          <Segment attached="bottom" style={{ padding: '40px', display: 'relative' }}>
            <div>
              <UserDetailFormContainer showFooter={false} />
            </div>
            <Divider style={{ marginTop: '30px' }} />
            <Breakpoint small down>
              <Grid centered>
                <Grid.Column width={16}>
                  <Button fluid size="large" onClick={() => history.push(`/app/users/${userId}`)}>Abbrechen</Button>
                </Grid.Column>
                <Grid.Column width={16}>
                  <Button fluid primary size="large" onClick={this.handleSaveButton}>Speichern</Button>
                </Grid.Column>
              </Grid>
            </Breakpoint>
            <Breakpoint medium up>
              <Grid centered>
                <Grid.Column width={8}>
                  <Button floated="left" size="large" onClick={() => history.push(`/app/users/${userId}`)}>Abbrechen</Button>
                </Grid.Column>
                <Grid.Column width={8}>
                  <Button primary floated="right" size="large" onClick={this.handleSaveButton}>Speichern</Button>
                </Grid.Column>
              </Grid>
            </Breakpoint>
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

UserProfile.propTypes = {
  changeBlocked: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired, // eslint-disable-line
};

function mapStateToProps(state) {
  return {
    userId: _.get(state, 'session.user.id'),
  };
}

export default AuthHOC(connect(mapStateToProps)(UserProfile));
