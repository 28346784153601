import React from 'react';
import PropTypes from 'prop-types';
import { Image } from 'semantic-ui-react';

const Item = ({ badge, image, active, color='' }) => (
  <li className={`timeline-item timeline-item-${color} ${!active ? 'timeline-item--disabled' : ''}`}>
    <div className="timeline-info">
      <span>{badge.bytes} EB</span>
    </div>
    <div className="timeline-marker" />
    <div className="timeline-content">
      <Image src={image} size="small" />
      <h3 className="timeline-title">{badge.name}</h3>
      <p className="timeline-description">{badge.description}</p>
    </div>
  </li>
);

Item.propTypes = {
  active: PropTypes.bool.isRequired, // eslint-disable-line
  badge: PropTypes.object.isRequired, // eslint-disable-line
  image: PropTypes.object.isRequired, // eslint-disable-line
};

export default Item;
