import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Dropdown,
  List,
  Icon,
  Label,
  Loader,
  Button,
} from 'semantic-ui-react';
import _ from 'lodash';
import moment from 'moment';
import * as NOTIFICATION_ACTIONS from 'actions/notification';

class Notifications extends Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false,
    };

    this.openNotifications = this.openNotifications.bind(this);
    this.closeNotifications = this.closeNotifications.bind(this);
    this.handleNotificationClick = this.handleNotificationClick.bind(this);
    this.checkUnSeen = this.checkUnSeen.bind(this);
  }

  componentDidMount() {
    const { getUnreadCount } = this.props;

    getUnreadCount();
  }

  openNotifications() {
    this.setState({ open: true });
  }

  closeNotifications() {
    this.setState({ open: false });
  }

  checkUnSeen() {
    const { notifications, setNotificationsRead, getUnreadCount } = this.props;

    const notSeen = notifications.filter(r => r.read === false).map(r => r.id);

    if (!_.isEmpty(notSeen)) {
      setNotificationsRead(notSeen)
        .then(() => getUnreadCount());
    }
  }

  handleNotificationClick(notification) {
    const { history } = this.props;
    if (['join_organization_invite', 'join_organization_apply', 'join_organization'].indexOf(notification.type) !== -1) {
      history.push(`/app/organizations/${notification.extra}`);
    }
  }

  render() {
    const {
      unreadCount,
      notifications,
      loading,
      mobile,
      next,
      loadMore,
    } = this.props;
    const { open } = this.state;
    const mobileStyle = {
      width: '100vw',
      left: '-50vw',
    };
    const menuStyle = {
      marginTop: '5px',
      maxHeight: '390px',
      overflowX: 'auto',
    };

    return (
      <Dropdown
        fluid
        className="user-dropdown"
        open={open}
        icon={(
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Icon
              circular
              inverted
              color="blue"
              name="bell"
              size="large"
            />
            {unreadCount > 0 && (
              <Label color="red" size="mini" attached="bottom right" style={{ bottom: '-5px', right: '-5px' }}>
                {unreadCount}
              </Label>
            )}
          </div>
        )}
        onBlur={this.closeNotifications}
        onOpen={() => {
          this.openNotifications();
          const { listNotifications } = this.props;

          listNotifications()
            .then(() => this.checkUnSeen());
        }}
      >
        <Dropdown.Menu
          className="notification-dropdown"
          style={mobile ? {
            ...menuStyle,
            ...mobileStyle,
          } : menuStyle}
        >
          <Dropdown.Header icon="bell" content="Deine Benachrichtigungen" />
          {!loading && notifications.length === 0 && (
            <Dropdown.Item>
              Du hast aktuell keine Benachrichtigungen.
            </Dropdown.Item>
          )}

          {notifications.map(notification => (
            <Dropdown.Item
              key={notification.id}
              description="test"
              onClick={() => this.handleNotificationClick(notification)}
            >
              <p style={{ fontWeight: `${notification.read ? 'normal' : 'bold'}` }}>
                {notification.message}<br/>
                {(notification.extra != null && ['join_organization_invite', 'join_organization_apply', 'join_organization'].indexOf(notification.type) !== -1) && (
                  <div style={{ margin: '10px 0 10px 0' }}>
                    <Button size='mini'>Jetzt ansehen</Button>
                  </div>
                )}
                <span style={{ color: '#aaa' }}>{moment(notification.created_at).format('DD.MM.YYYY HH:MM')} Uhr</span>
              </p>
            </Dropdown.Item>
          ))}

          {loading && (
            <Dropdown.Item>
              <Loader active size="tiny" />
            </Dropdown.Item>
          )}

          {next && (
            <div style={{ textAlign: 'center', margin: '5px 0' }}>
              <Button
                color="blue"
                size="mini"
                onClick={(event, obj) => {
                  const { children } = obj;

                  if (children === 'Mehr anzeigen') {
                    loadMore(next)
                      .then(() => this.checkUnSeen());
                  }
                }}
              >
                Mehr anzeigen
              </Button>
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

Notifications.defaultProps = {
  unreadCount: 0,
  next: '',
  mobile: false,
  notifications: [],
};

Notifications.propTypes = {
  getUnreadCount: PropTypes.func.isRequired,
  listNotifications: PropTypes.func.isRequired,
  setNotificationsRead: PropTypes.func.isRequired,
  loadMore: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  mobile: PropTypes.bool.isRequired,
  next: PropTypes.string,
  unreadCount: PropTypes.number,
  notifications: PropTypes.array, // eslint-disable-line
  history: PropTypes.object.isRequired, // eslint-disable-line
};

const mapStateToProps = state => ({
  loading: state.notification.loading,
  unreadCount: state.notification.unreadCount,
  notifications: state.notification.list,
  next: state.notification.next,
});

const mapDispatchToProps = {
  getUnreadCount: NOTIFICATION_ACTIONS.unreadCount,
  listNotifications: NOTIFICATION_ACTIONS.list,
  setNotificationsRead: NOTIFICATION_ACTIONS.setRead,
  loadMore: NOTIFICATION_ACTIONS.loadMore,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Notifications));
