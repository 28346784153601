export const toComponent = data => (!data ? [] : data.map(item => ({
  key: item.value,
  value: item.value,
  text: item.title,
})));

export const fromComponent = data => (!data ? [] : data.map(item => ({
  value: item.value,
  text: item.title,
})));

export const locationToValue = data => JSON.stringify({
  location: data.location,
  attributes: data.attributes,
});
