import React from 'react';
import PropTypes from 'prop-types';
import {
  Form,
  Checkbox,
} from 'semantic-ui-react';

const CheckBox = ({
  input,
  disabled,
  label,
  style,
}) => (
  <Form.Field
    style={style}
    disabled={disabled}
    checked={!!input.value}
    name={input.name}
    control={Checkbox}
    label={{ children: label }}
    onChange={(e, { checked }) => input.onChange(checked)}
  />
);

CheckBox.defaultProps = {
  style: {},
  disabled: false,
};

CheckBox.propTypes = {
  input: PropTypes.object, // eslint-disable-line
  style: PropTypes.object, // eslint-disable-line
  label: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export default CheckBox;
