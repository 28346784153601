import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import _ from 'lodash';
import {
  Grid,
  Icon,
  Header,
  Image,
  Segment,
  Item,
  Button,
  Popup,
  Loader,
  Modal,
} from 'semantic-ui-react';
import ReactMarkdown from 'react-markdown'
import AuthHOC from 'containers/AuthHOC';
import { UserPicture } from 'modules/styled/map';
import { ExpertByteLabelSmall } from 'modules/styled/profile';
import UserTitleImage from 'modules/UserTitleImage';
import * as EVENT_ACTIONS from 'actions/event';
import * as STATEAPI from 'utils/stateapi';
import EmptyState from 'modules/EmptyState';
import { nl2br } from 'utils/string';

const UserItem = ({
  user,
  handleClick,
}) => {
  const organization = _.get(user, 'organization.name', _.get(user, 'organization_member.name'));

  return (
    <Item
      onClick={handleClick}
      key={user.id}
    >
      <div style={{ display: 'flex' }}>
        <div style={{ position: 'relative' }}>
          <UserPicture
            src={!_.isEmpty(user.avatar_image_url) ? user.avatar_thumbnail_url : 'https://react.semantic-ui.com/images/wireframe/square-image.png'}
          />
          <ExpertByteLabelSmall
            expertBytes={user.expert_bytes}
          />
        </div>
        <Item.Content style={{ marginLeft: '10px' }}>
          <Item.Header as="a" style={{ fontSize: '16px' }}>{user.first_name} {user.last_name}</Item.Header>
          <Item.Meta style={{ marginTop: 0 }}>{organization}</Item.Meta>
        </Item.Content>
      </div>
    </Item>
  );
};

class EventsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      deleteModalOpen: false,
      eventId: null,
    };

    this.handleOpenDeleteModal = this.handleOpenDeleteModal.bind(this);
    this.handleCloseDeleteModal = this.handleCloseDeleteModal.bind(this);
    this.deleteEvent = this.deleteEvent.bind(this);
  }

  componentDidMount() {
    const {
      changeBlocked,
      loadEvents,
      isPremium,
      history,
      planType,
    } = this.props;

    changeBlocked(true);

    if (!isPremium) {
      if (planType === 'free_trial_individual') {
        history.push('/app/premium-features');
      } else {
        history.push('/app/premium');
      }
    } else {
      loadEvents();
    }
  }

  handleOpenDeleteModal(eventId) {
    const { deleteModalOpen } = this.state;

    this.setState({
      eventId,
      deleteModalOpen: true,
    });
  }

  handleCloseDeleteModal(eventId) {
    const { deleteModalOpen } = this.state;

    this.setState({ deleteModalOpen: false });
  }

  deleteEvent() {
    const { deleteEvent, loadEvents } = this.props;
    const { eventId } = this.state;

    deleteEvent(eventId)
      .then(() => loadEvents())
      .then(() => this.setState({
        eventId: null,
        deleteModalOpen: false,
      }));
  }

  render() {
    const { deleteModalOpen } = this.state;
    const {
      history,
      userId,
      isAdmin,
      events,
      loading,
    } = this.props;

    return (
      <Grid centered style={{ zIndex: 2 }}>
        <Modal size="mini" open={deleteModalOpen}>
          <Modal.Header>Termin löschen?</Modal.Header>
          <Modal.Content>
            <p>Willst du wirklich diesen Termin löschen?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={this.handleCloseDeleteModal}>Abbrechen</Button>
            <Button negative content="Löschen" onClick={this.deleteEvent} />
          </Modal.Actions>
        </Modal>
        <Grid.Column computer={14} tablet={14} mobile={14} style={{ zIndex: 2 }}>
          <Segment attached="bottom" style={{ padding: '20px', display: 'relative' }}>
            <Header as="h2" textAlign="center" style={{ marginBottom: '40px' }}>Termine</Header>
            <div style={{ textAlign: 'right', marginTop: '-40px', marginBottom: '40px' }}>
              <Button color="blue" onClick={() => history.push('/app/events/create')} ><Icon name="plus" /> Neuer Termin</Button>
            </div>
            {loading ? <Loader active inline="centered" /> : (
              <Grid style={{ justifyContent: 'center' }}>
                <Grid.Column width={15}>
                  {!_.isEmpty(events) ? events.map(event => {
                    const eventDate = moment(event.date, "YYYY-MM-DD");
                    const canEdit = event.user.id === userId || isAdmin;

                    return (
                      <Segment style={{ padding: '20px', display: 'relative' }}>
                        <div style={{ position: 'absolute', right: '-25px', top: '20px', width: '49px' }}>
                          {canEdit && (
                            <Popup
                              trigger={(
                                <Button
                                  size="mini"
                                  color="blue"
                                  onClick={() => history.push(`/app/events/edit/${event.id}`)}
                                >
                                  <Button.Content visible>
                                    <Icon name="pencil" />
                                  </Button.Content>
                                </Button>
                              )}
                              content="Termin bearbeiten"
                            />
                          )}
                          {canEdit && (
                            <Popup
                              trigger={(
                                <Button
                                  size="mini"
                                  color="red"
                                  style={{ margin: '10px 0' }}
                                  onClick={() => this.handleOpenDeleteModal(event.id)}
                                >
                                  <Button.Content visible>
                                    <Icon name="trash" />
                                  </Button.Content>
                                </Button>
                              )}
                              content="Termin löschen"
                            />
                          )}
                        </div>
                        <Grid>
                          <Grid.Column computer={2} mobile={15}>
                            <Segment inverted color="blue">
                              <Header as="h3" style={{ textAlign: 'center', marginTop: '10px', fontWeight: 'bold' }}>{eventDate.format('DD')}. {eventDate.format('MMMM')}<small style={{ color: '#ccc', display: 'block' }}>{eventDate.format('YYYY')}</small></Header>
                            </Segment>
                            <Image src={_.isEmpty(event.image) ? 'https://react.semantic-ui.com/images/wireframe/square-image.png' : event.image} fluid />
                          </Grid.Column>
                          <Grid.Column computer={9} mobile={11}>
                            <UserTitleImage editing={false} imageUrl={event.title_image_url} />
                            <Header as="h3" style={{ marginBottom: '0' }}>{event.name}</Header>
                            <p style={{ marginBottom: '20px' }}>{event.street}, {event.postalcode} {event.city}</p>
                            <ReactMarkdown>{event.description}</ReactMarkdown>
                          </Grid.Column>
                          <Grid.Column computer={4} mobile={15}>
                            <Header as="h5">Organisator:</Header>
                            <UserItem user={event.user} />
                          </Grid.Column>
                        </Grid>
                      </Segment>
                    );
                  }) : (
                    <EmptyState header="Keine Termine gefunden" content="Bis jetzt wurden noch keine Termine angelegt" />
                  )}
                </Grid.Column>
              </Grid>
            )}
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

EventsView.defaultProps = {
  userId: '',
  events: [],
  loading: true,
};

EventsView.propTypes = {
  changeBlocked: PropTypes.func.isRequired,
  loadEvents: PropTypes.func.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  userId: PropTypes.string,
  isAdmin: PropTypes.bool.isRequired,
  isPremium: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  events: PropTypes.array, // eslint-disable-line
  history: PropTypes.object.isRequired, // eslint-disable-line
};

function mapStateToProps(state) {
  const user = STATEAPI.getCurrentUser(state);
  const planType = _.get(user, 'plan_type')
  return {
    userId: _.get(state, 'session.user.id'),
    isAdmin: STATEAPI.isAdmin(state),
    isPremium: STATEAPI.userIsPremium(state),
    events: _.get(state, 'event.list', []),
    planType,
    loading: _.get(state, 'event.loading'),
  };
}

const mapDispatchToProps = {
  loadEvents: EVENT_ACTIONS.list,
  deleteEvent: EVENT_ACTIONS.deleteEvent,
};

export default connect(mapStateToProps, mapDispatchToProps)(AuthHOC(EventsView));
