import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Header,
} from 'semantic-ui-react';
import { Field } from 'redux-form';
import _ from 'lodash';
import * as Fields from 'modules/Fields';
import UserTitleImage from 'modules/UserTitleImage';
import UserAvatarImage from 'modules/UserAvatarImage';
import getBase64 from 'utils/file';

const Content = ({
  formData,
  changeFieldValue,
  visible,
}) => (
  <div style={{ display: visible ? 'block' : 'none' }}>
    <Grid centered style={{ marginTop: '20px' }}>
      <Grid.Column width={15}>
        <UserTitleImage
          type="title_image_url"
          imageUrl={_.get(formData, 'title_image_url_base64', formData.title_image_url)}
          handleChange={(event) => {
            event.preventDefault();
            const files = [...event.target.files];

            if (files.length > 0) {
              getBase64(files[0])
                .then((res) => {
                  changeFieldValue('title_image_url_base64', res);
                  changeFieldValue('title_image_url', files[0]);
                });
            }
          }}
        />
      </Grid.Column>
    </Grid>

    <Grid centered>
      <Grid.Column width={15}>
        <Grid>
          <Grid.Column mobile={15} tablet={3} computer={4} widescreen={3}>
            <UserAvatarImage
              type="logo_url"
              imageUrl={_.get(formData, 'logo_url_base64', formData.logo_url)}
              handleChange={(event) => {
                event.preventDefault();
                const files = [...event.target.files];

                if (files.length > 0) {
                  getBase64(files[0])
                    .then((res) => {
                      changeFieldValue('logo_url_base64', res);
                      changeFieldValue('logo_url', files[0]);
                    });
                }
              }}
            />
          </Grid.Column>
          <Grid.Column mobile={15} tablet={16} computer={10} widescreen={6}>
            <Grid.Row>
              <Header as="h2">{formData.name}</Header>
              <p>{formData.street}</p>
            </Grid.Row>
            <Grid.Row style={{ marginTop: '15px' }}>
              <Grid>
                <Grid.Column mobile={16} tablet={8} computer={8}>
                  <Field
                    type="number"
                    name="number_of_employees"
                    placeholder="Mitarbeiteranzahl"
                    component={Fields.renderInput}
                  />
                </Grid.Column>

                <Grid.Column mobile={16} tablet={8} computer={8}>
                  <Field
                    type="number"
                    name="year_established"
                    placeholder="Gründungsjahr"
                    component={Fields.renderInput}
                  />
                </Grid.Column>

                <Grid.Column mobile={16} tablet={16} computer={16}>
                  <Field
                    name="about"
                    placeholder="Erzähle uns etwas über deine Organisation"
                    component={Fields.renderRichTextInput}
                    rows={5}
                  />
                </Grid.Column>
              </Grid>
            </Grid.Row>
          </Grid.Column>
        </Grid>
      </Grid.Column>
    </Grid>

    <Grid centered style={{ marginTop: '50px' }}>
      <Grid.Column width={15}>
        <Grid.Row>
          <Header as="h2">Kontakt-Informationen</Header>
        </Grid.Row>
        <Grid.Row style={{ marginTop: '15px' }}>
          <Grid>
            <Grid.Column mobile={15} tablet={8} computer={5}>
              <Field
                name="phone"
                placeholder="Telefon"
                component={Fields.renderInput}
              />
            </Grid.Column>
            <Grid.Column mobile={15} tablet={8} computer={5}>
              <Field
                name="fax"
                placeholder="Fax"
                component={Fields.renderInput}
              />
            </Grid.Column>
            <Grid.Column mobile={15} tablet={16} computer={5}>
              <Field
                type="email"
                name="email"
                placeholder="E-Mail-Adresse"
                component={Fields.renderInput}
              />
            </Grid.Column>
          </Grid>
        </Grid.Row>
        <Grid.Row style={{ marginTop: '15px' }}>
          <Grid>
            <Grid.Column mobile={15} tablet={16} computer={5}>
              <Field
                name="xing_url"
                placeholder="Xing"
                component={Fields.renderInput}
              />
            </Grid.Column>
            <Grid.Column mobile={15} tablet={16} computer={5}>
              <Field
                name="linkedin_url"
                placeholder="LinkedIn"
                component={Fields.renderInput}
              />
            </Grid.Column>
            <Grid.Column mobile={15} tablet={16} computer={5}>
              <Field
                name="kununu_url"
                placeholder="Kununu"
                component={Fields.renderInput}
              />
            </Grid.Column>
          </Grid>
        </Grid.Row>
        <Grid.Row style={{ marginTop: '15px' }}>
          <Grid>
            <Grid.Column mobile={15} tablet={16} computer={5}>
              <Field
                name="website_url"
                placeholder="Website"
                component={Fields.renderInput}
              />
            </Grid.Column>
          </Grid>
        </Grid.Row>
      </Grid.Column>
    </Grid>
  </div>
);

Content.defaultProps = {
  formData: {},
};

Content.propTypes = {
  formData: PropTypes.object, // eslint-disable-line
  visible: PropTypes.bool.isRequired,
  changeFieldValue: PropTypes.func.isRequired,
};

export default Content;
