const ORGANIZATION_TYPES = [
  {
    key: 'network_owners',
    value: 'network_owners',
    text: 'Netzeigentümer und Kommunen',
  },
  {
    key: 'engineering_offices',
    value: 'engineering_offices',
    text: 'Ingenieurbüros, Planer und Gutachter',
  },
  {
    key: 'civil_engineering',
    value: 'civil_engineering',
    text: 'Tiefbaufirma',
  },
  {
    key: 'schools_colleges',
    value: 'schools_colleges',
    text: 'Schulen und Hochschulen',
  },
  {
    key: 'network',
    value: 'network',
    text: 'Verbund',
  },
];

export default ORGANIZATION_TYPES;
