import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
  Grid,
  Header,
  Form,
  Button,
  Divider,
  Icon,
} from 'semantic-ui-react';

class LogoutPage extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div>
        <Grid.Row textAlign="center">
          <Icon name="check circle outline" size='big' color='green'/>
          <Header as="h3" textAlign="center">Danke, dass du gemeinsam mit uns für zukunftsfähige Infrastruktur sorgst!</Header>
          <p>Du bist nun vollständig abgemeldet. Bis bald auf localexpert24!</p>
          <Divider style={{ marginTop: '30px' }} />
          <p><Link to="/">Zurück zum Login</Link></p>
          <p><a href="https://www.localexpert24.de/">Zu localexpert24.de</a></p>
        </Grid.Row>
      </div>
    );
  }
}


export default LogoutPage;
