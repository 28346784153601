import React from 'react';
import Map from 'modules/map';
import styled from 'styled-components';
import { NoPrint } from 'react-easy-print';
export const AppWrapper = styled.div`
  width: 100vw;
  height: calc(100vh - 64px);
`;

export const MainScreen = styled.div`
  width: 100vw;
  height: calc(100vh - 64px);
`;

export const AppContent = styled.div`
  padding: 0 8%;
`;

export const Buffer = styled.div`
  height: ${({ height }) => height || '1px'};
  width: ${({ width }) => width || '1px'};
`;

export const CenteredWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const Logo = styled.div`
  width: ${({ small }) => (small ? '140px' : '200px')};
  height: ${({ small }) => (small ? '43px' : '60px')};
  background: ${({ logo }) => `url("${logo}") no-repeat center / contain`};
`;

export const LogoMobile = styled.div`
  width: 43px;
  margin-left: 20px;
  height: 43px;
  background: ${({ logo }) => `url("${logo}") no-repeat center / contain`};
`;

export const MapBG = ({ children, blocked, readOnly }) => (
  <div style={{ left: 0, right: 0, bottom: 0, position: 'absolute', top: '58px' }}>
    {children}
    <NoPrint><Map blocked={blocked} readOnly={readOnly} /></NoPrint>
  </div>
);


export const Footer = styled.div`
  border-top: 2px solid #e3e3e3;
  padding-top: 10px;
  padding-bottom: 40px;
  background-color: #fafafa;
  height: 100px;
  box-sizing: border-box;
`;
