import React from 'react';
import {
  Label,
  Icon,
} from 'semantic-ui-react';

export const LikeLabel = ({likes}) => (
  <Label
    size="small"
    color="yellow"
    style={{
      padding: '4px',
      position: 'absolute',
      bottom: '-8px',
      width: '56px',
      left: '50%',
      marginLeft: '-28px',
      textAlign: 'center',
    }}
  >
    <Icon name="thumbs up" />{likes}
  </Label>
);

export default LikeLabel;
