import * as what3words from '@what3words/api';

what3words.setOptions({ key: 'A15IHEO1' });

export const isValid = (words) => {
  const regex = /^\/{0,}[^0-9`~!@#$%^&*()+\-_=[{\]}\\|'<,.>?/";:£§º©®\s]{1,}[・.。][^0-9`~!@#$%^&*()+\-_=[{\]}\\|'<,.>?/";:£§º©®\s]{1,}[・.。][^0-9`~!@#$%^&*()+\-_=[{\]}\\|'<,.>?/";:£§º©®\s]{1,}$/i;

  return regex.test(words);
};

export const toCoordinates = async (words) => {
  const response = await what3words.convertToCoordinates(words);

  return response;
};

export const fromCoordinates = async (lat, lng) => {
  const response = await what3words.convertTo3wa({lat, lng}, "de");

  return response;
}
