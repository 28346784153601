import React from 'react';
import { withRouter } from 'react-router-dom';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  Item,
  Icon,
  List,
} from 'semantic-ui-react';
import LazyLoad from 'react-lazy-load';
import { LocationCoverPicture } from 'modules/styled/map';
import { LikeLabel } from 'modules/styled/location';
import moment from 'moment';

const LocationItem = ({ data, history }) => {
  const coverImage = _.get(data, 'cover_image');
  const createdAt = moment(data.created_at).isValid() ? moment(data.created_at).format('DD.MM.YYYY') : data.created_at;
  const truncate = (input) => (input !== null && input.length > 96) ? `${input.substring(0, 96)}...` : input;
  return (
    <Item
      as="a"
      href={`/app/location/detail/${data.id}`}
      onClick={() => history.push(`/app/location/detail/${data.id}`)}
    >
      <div
        style={{
          position: 'relative',
          textAlign: 'center',
          display: 'flex',
          height: '80px',
          marginBottom: '20px',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <LazyLoad width={90} height={80} debounce={false}>
          <LocationCoverPicture
            src={!_.isEmpty(coverImage) ? coverImage : 'https://react.semantic-ui.com/images/wireframe/square-image.png'}
          />
        </LazyLoad>
        <LikeLabel
          likes={_.get(data, 'votes_count', 0)}
        />
      </div>
      <Item.Content style={{ paddingLeft: '1em' }}>
        <Item.Meta style={{ marginTop: 0, display: 'flex', justifyContent: 'space-between' }}>
          <span>{createdAt}</span>
          <span style={{ fontWeight: 'bold' }}>#{_.get(data, 'id')}</span>
        </Item.Meta>
        <Item.Header as="a" style={{ fontSize: '16px' }}>{truncate(data.description)}</Item.Header>
        <Item.Meta style={{ marginTop: '5px' }}>{data.street} {data.postalcode} {data.city}</Item.Meta>
        <Item.Description>
          {_.get(data, 'user_data.first_name')} {_.get(data, 'user_data.last_name')}
        </Item.Description>
        <List horizontal style={{ marginTop: '5px', color: '#000' }}>
          <List.Item>
            <Icon name="align left" /> {_.get(data, 'counters.text_elements')}
          </List.Item>
          <List.Item>
            <Icon name="images" /> {_.get(data, 'counters.image_elements')}
          </List.Item>
          <List.Item>
            <Icon name="file video" /> {_.get(data, 'counters.video_elements')}
          </List.Item>
          <List.Item>
            <Icon name="file" /> {_.get(data, 'counters.documents')}
          </List.Item>
          <List.Item>
            <Icon name="linkify" /> {_.get(data, 'counters.links')}
          </List.Item>
          <List.Item>
            <Icon name="cube" /> {_.get(data, 'counters.document_ifc')}
          </List.Item>
        </List>
      </Item.Content>
    </Item>
  );
};

LocationItem.propTypes = {
  data: PropTypes.object.isRequired, // eslint-disable-line
  history: PropTypes.object.isRequired, // eslint-disable-line
};

export default withRouter(LocationItem);
