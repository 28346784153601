import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { NoPrint } from 'react-easy-print';
import NavBar from 'modules/navbar';
import * as SRC from 'modules/styled';
import InvitesModal from 'modules/InvitesModal';
import PremiumModal from 'modules/PremiumModal';

class RouteLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      blocked: false,
    };

    this.changeBlocked = this.changeBlocked.bind(this);
  }

  changeBlocked(blocked) {
    this.setState({ blocked });
  }

  render() {
    const { component: ChildrenComponent, ...props } = this.props;
    const { blocked } = this.state;

    return (
      <Route
        {...props}
        render={matchProps => (
          <SRC.MapBG blocked={blocked}>
            <InvitesModal />
            <PremiumModal />
            <NoPrint><NavBar /></NoPrint>
            <ChildrenComponent
              {...matchProps}
              changeBlocked={this.changeBlocked}
            />
          </SRC.MapBG>
        )}
      />
    );
  }
}

RouteLayout.propTypes = {
  component: PropTypes.func.isRequired, // eslint-disable-line
};

export default RouteLayout;
