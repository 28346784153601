import _ from 'lodash';
import * as C from './constants';
import { mapLocations } from './location';

export const filterLocation = (id, option) => (dispatch, getState) => {
  if (option === 'own') {
    dispatch({ type: C.FILTER_LOCATION_USER, id });
  }

  if (option === 'all') {
    dispatch({ type: C.FILTER_LOCATION_ALL });
  }

  if (option === 'org') {
    dispatch({ type: C.FILTER_LOCATION_ORGANIZATION, id });
  }

  if (option === 'bookmarked') {
    dispatch({ type: C.FILTER_LOCATION_BOOKMARKED });
  }

  const { map: { filters } } = getState();
  dispatch(mapLocations(filters));
};

export const setTagFilter = values => (dispatch, getState) => {
  dispatch({ type: C.SET_TAG_FILTER, values });

  const { map: { filters } } = getState();

  dispatch(mapLocations(filters));
};

export const setMapCenter = mapCenter => dispatch => dispatch({
  type: C.SET_MAP_CENTER, mapCenter,
});

export const expertMouseEnter = id => dispatch => dispatch({ type: C.EXPERT_MOUSE_ENTER, id });

export const expertMouseLeave = () => dispatch => dispatch({ type: C.EXPERT_MOUSE_LEAVE });

export const organizationMouseEnter = id => dispatch => dispatch({
  type: C.ORGANIZATION_MOUSE_ENTER, id,
});

export const organizationMouseLeave = () => dispatch => dispatch({
  type: C.ORGANIZATION_MOUSE_LEAVE,
});

export const featureLayer = id => (dispatch, getState) => {
  const { map: { layer } } = getState();

  if (id === layer) {
    dispatch({ type: C.DISABLE_LAYER });
  } else {
    dispatch({ type: C.ENABLE_LAYER, id });
  }
};

export const filterLocationsByBounds = () => (dispatch, getState) => {
  const {
    location: { mapLocations: locations },
    map: { bounds, locations: currentLocations },
  } = getState();

  if (_.isEmpty(bounds)) {
    return;
  }

  const {
    minLong,
    maxLong,
    minLat,
    maxLat,
  } = bounds;

  const filteredLocations = locations.filter(l => l.lng >= minLong && l.lng <= maxLong && l.lat >= minLat && l.lat <= maxLat);
  const sameLocations = JSON.stringify(filteredLocations) === JSON.stringify(currentLocations);

  if (!sameLocations) {
    dispatch({ type: C.FILTER_LOCATIONS_BY_BOUNDS, locations: filteredLocations });
  }
};

export const updateBounds = bounds => (dispatch) => {
  dispatch({ type: C.UPDATE_BOUNDS, bounds });
  dispatch(filterLocationsByBounds());
};
