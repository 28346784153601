import _ from 'lodash';
import Badge1 from 'assets/images/badges/badge_1.png';
import Badge2 from 'assets/images/badges/badge_2.png';
import Badge3 from 'assets/images/badges/badge_3.png';
import Badge4 from 'assets/images/badges/badge_4.png';
import Badge5 from 'assets/images/badges/badge_5.png';
import Badge6 from 'assets/images/badges/badge_6.png';
import Badge7 from 'assets/images/badges/badge_7.png';
import Badge8 from 'assets/images/badges/badge_8.png';
import Badge9 from 'assets/images/badges/badge_9.png';

export const QUALIFICATION_OPTIONS = [
  {
    key: 'freiberuflerin',
    value: 'freiberuflerin',
    text: 'Freiberufler/in',
  },
  {
    key: 'tatigodermitarbeiter',
    value: 'tatigodermitarbeiter',
    text: 'Tätig oder Mitarbeiter/in bei Organisation',
  },
  {
    key: 'tiefbauerin',
    value: 'tiefbauerin',
    text: 'Tiefbauer/in in Ausbildung',
  },
];

export const LEVEL_OF_EDUCATION_OPTIONS = [
  {
    key: 'none',
    value: '',
    text: 'Keiner',
  },
  {
    key: 'diplingl',
    value: 'diplingl',
    text: 'Dipl.-Ing.',
  },
  {
    key: 'diplinglfh',
    value: 'diplinglfh',
    text: 'Dipl.-Ing. (FH)',
  },
  {
    key: 'diplinglth',
    value: 'diplinglth',
    text: 'Dipl.-Ing. (TH)',
  },
  {
    key: 'dring',
    value: 'dring',
    text: 'Dr.-Ing.',
  },
  {
    key: 'dr',
    value: 'dr',
    text: 'Dr.',
  },
  {
    key: 'beng',
    value: 'beng',
    text: 'B.-Eng.',
  },
  {
    key: 'meng',
    value: 'meng',
    text: 'M.-Eng.',
  },
  {
    key: 'bsc',
    value: 'bsc',
    text: 'B.Sc.',
  },
  {
    key: 'msc',
    value: 'msc',
    text: 'M.Sc.',
  },
  {
    key: 'techniker',
    value: 'techniker',
    text: 'Techniker',
  },
  {
    key: 'bauzeichner',
    value: 'bauzeichner',
    text: 'Bauzeichner',
  },
];

export const GENDER_OPTIONS = [
  {
    key: 'herr',
    value: 'm',
    text: 'Herr',
  },
  {
    key: 'frau',
    value: 'f',
    text: 'Frau',
  },
];

export const RANKS = {
  bronze: 'Bronze',
  silver: 'Silver',
  gold: 'Gold',
  diamond: 'Diamant',
  young: 'Young',
};

export const RANK_PONTUATION = {
  bronze: 300,
  silver: 1000,
  gold: 3500,
  diamond: 0,
  young: 0,
};

export const getRankPercentage = (rank, expertBytes) => {
  const pontuation = _.get(RANK_PONTUATION, rank, 0);

  if (pontuation === 0) {
    return 100;
  }

  return Math.round((expertBytes / pontuation) * 100);
};

export const getRankProgressLabel = (rank, expertBytes) => {
  if (['diamond', 'young', 'gold'].indexOf(rank) !== -1) {
    return `${expertBytes} ExpertBytes`;
  }

  return `${expertBytes}/${RANK_PONTUATION[rank]} ExpertBytes`;
};

export const getRankProgressColor = (percentage) => {
  if (percentage < 30) {
    return 'red';
  }

  if (percentage < 70) {
    return 'yellow';
  }

  return 'green';
};

export const BADGES = [
  {
    bytes: 50,
    name: 'Baupunkt-Starter',
    badgeImage: Badge1,
    description: 'Herzlich willkommen auf localexpert24. Jeder neue Baupunkt hilft dir dabei, deinen Expert-Score zu erhöhen.',
  },
  {
    bytes: 100,
    name: 'Baupunkt-Kenner',
    badgeImage: Badge2,
    description: 'Super, jetzt kennst du alle Grundfunktionen von localexpert24.',
  },
  {
    bytes: 300,
    name: 'Baupunkt-Profi',
    badgeImage: Badge3,
    description: 'Nur noch ein Abzeichen und du erreichst den Silber-Status. Mach weiter so.',
  },
  {
    bytes: 500,
    name: 'Baustellen-Problemlöser',
    badgeImage: Badge4,
    description: 'Du bist ab sofort Silber-Experte. Herzlichen Glückwunsch zu dieser tollen Leistung.',
  },
  {
    bytes: 750,
    name: 'Baustellen-Held',
    badgeImage: Badge5,
    description: 'Dein unterirdisches Wissen hilft der ganzen Region.',
  },
  {
    bytes: 1000,
    name: 'Baustellen-Star',
    badgeImage: Badge6,
    description: 'Hilf auch anderen lokalen Experten sich zu vernetzen. Du kennst dich bestens auf den Baustellen in deiner Region aus.',
  },
  {
    bytes: 1500,
    name: 'Infrastruktur-Profi',
    badgeImage: Badge7,
    description: 'Du bist ein Gold-Experte! Mit dir zusammenzuarbeiten ist eine tolle Erfahrung.',
  },
  {
    bytes: 2500,
    name: 'Infrastruktur-Ass',
    badgeImage: Badge8,
    description: 'Wow! Dein Wissen ist für dutzende erfolgreiche Baumaßnahmen ausschlaggebend.',
  },
  {
    bytes: 3500,
    name: 'Infrastruktur-Vorbild',
    badgeImage: Badge9,
    description: 'Du sorgst für zukunftsfähig Infrastruktur in deiner Region und bist ein echtes Vorbild.',
  },
];
