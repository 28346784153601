import API from 'api';
import * as C from './constants';
import receiveNetworkException from './network';
import error from './error';

let timer = null;

export const list = () => (dispatch) => {
  dispatch({ type: C.LIST_NOTIFICATIONS });

  return API.notification.list()
    .then(({
      body: data,
    }) => dispatch({ type: C.LIST_NOTIFICATIONS_SUCCESS, ...data }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.LIST_NOTIFICATIONS_ERROR, {
        message: 'Failed to get notifications list',
        description: 'Please contact server admin.',
        data: err.data,
      }));
    });
};

export const loadMore = url => (dispatch) => {
  dispatch({ type: C.LOAD_MORE_NOTIFICATIONS });

  return API.notification.loadMore(url)
    .then(({
      body: data,
    }) => dispatch({ type: C.LOAD_MORE_NOTIFICATIONS_SUCCESS, ...data }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.LOAD_MORE_NOTIFICATIONS_ERROR, {
        message: 'Failed to get notifications list',
        description: 'Please contact server admin.',
        data: err.data,
      }));
    });
};

export const unreadCount = () => (dispatch) => {
  clearInterval(timer);
  dispatch({ type: C.GET_UNREAD_NOTIFICATIONS });

  return API.notification.unreadCount()
    .then(({
      body: { count },
    }) => {
      // Run this action each 5 minutes
      timer = setInterval(() => dispatch(unreadCount()), 1000 * 60 * 5);

      return dispatch({ type: C.GET_UNREAD_NOTIFICATIONS_SUCCESS, count });
    })
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.GET_UNREAD_NOTIFICATIONS_ERROR, {
        message: 'Failed to get notifications count',
        description: 'Please contact server admin.',
        data: err.data,
      }));
    });
};

export const setRead = ids => (dispatch) => {
  dispatch({ type: C.READ_NOTIFICATIONS });

  return API.notification.setRead({ ids })
    .then(() => dispatch({ type: C.READ_NOTIFICATIONS_SUCCESS }))
    .catch((err) => {
      dispatch(receiveNetworkException(err));
      return dispatch(error(C.READ_NOTIFICATIONS_ERROR, {
        message: 'Failed to set notifications read',
        description: 'Please contact server admin.',
        data: err.data,
      }));
    });
};
