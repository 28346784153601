import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Header,
  Form,
  Segment,
  Input,
} from 'semantic-ui-react';
import { reduxForm } from 'redux-form';
import Links from './partials/Links';
import Documents from './partials/Documents';
import ContentElements from './partials/ContentElements';
import RelatedLocations from './partials/RelatedLocations';
import FileIFC from './partials/FileIFC';

const LocationContentForm = ({
  contentElements,
  documents,
  fileIFC,
  links,
  uploading,
  uploadingIFC,
  percentUploadIFC,
  handleAddLink,
  handleDeleteLink,
  handleUploadFile,
  handleDeleteFile,
  handleUploadFileIFC,
  handleDeleteFileIFC,
  handleUploadContent,
  handleDeleteContent,
  updateContentElements,
  initialValues,
  linkedLocations,
  handleAddLocation,
  handleDeleteLocation,
  handleRotateContent,
  nameFileIFC,
}) => (
  <div>
    <Grid reversed="computer" centered>
      <Grid.Column computer={5} tablet={16} mobile={16}>
        <Documents
          uploading={uploading}
          documents={documents}
          handleChange={handleUploadFile}
          handleDelete={handleDeleteFile}
        />
        <FileIFC
          uploading={uploadingIFC}
          fileIFC={fileIFC}
          percentUploadIFC={percentUploadIFC}
          handleChange={handleUploadFileIFC}
          handleDelete={handleDeleteFileIFC}
          nameFileIFC={nameFileIFC}
        />
        <Links
          links={links}
          handleChange={handleAddLink}
          handleDelete={handleDeleteLink}
        />
        <RelatedLocations
          initialValues={initialValues}
          linkedLocations={linkedLocations}
          handleChange={handleAddLocation}
          handleDelete={handleDeleteLocation}
        />
      </Grid.Column>
      <Grid.Column computer={9} tablet={16} mobile={16}>
        <ContentElements
          data={contentElements}
          handleUpload={handleUploadContent}
          handleDelete={handleDeleteContent}
          updateContentElements={updateContentElements}
          handleRotateContent={handleRotateContent}
        />
      </Grid.Column>
    </Grid>
  </div>
);

LocationContentForm.defaultProps = {
  contentElements: [],
  documents: [],
  fileIFC: [],
  links: [],
  linkedLocations: [],
};

LocationContentForm.propTypes = {
  contentElements: PropTypes.array, // eslint-disable-line
  documents: PropTypes.array, // eslint-disable-line
  fileIFC: PropTypes.array, // eslint-disable-line
  links: PropTypes.array, // eslint-disable-line
  initialValues: PropTypes.object, // eslint-disable-line
  linkedLocations: PropTypes.array, // eslint-disable-line
  handleAddLink: PropTypes.func.isRequired,
  handleDeleteLink: PropTypes.func.isRequired,
  handleUploadFile: PropTypes.func.isRequired,
  handleDeleteFile: PropTypes.func.isRequired,
  handleUploadContent: PropTypes.func.isRequired,
  handleDeleteContent: PropTypes.func.isRequired,
  handleRotateContent: PropTypes.func.isRequired,
  handleAddLocation: PropTypes.func.isRequired,
  handleDeleteLocation: PropTypes.func.isRequired,
  updateContentElements: PropTypes.func.isRequired,
  uploading: PropTypes.bool.isRequired,
  uploadingIFC: PropTypes.bool.isRequired,
};

const LoginForm = reduxForm({
  form: 'locationContentForm',
})(LocationContentForm);

export default LoginForm;
