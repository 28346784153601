import React, { Component } from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  Image,
  Loader,
  Dimmer,
  Button,
  Icon,
  Grid,
} from 'semantic-ui-react';
import * as C from 'actions/constants';
import AddImagePlaceHolder from 'assets/images/add-image-placeholder.svg';
import AddVideoPlaceHolder from 'assets/images/add-video-placeholder.svg';

class ElementMedia extends Component {
  static handleClick(event, elementUuid) {
    event.preventDefault();
    document.getElementById(`element-media-${elementUuid}`).click();
  }

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
    };

    this.handleUpload = this.handleUpload.bind(this);
    this.renderComponent = this.renderComponent.bind(this);
    this.renderVideo = this.renderVideo.bind(this);
    this.handleRotateImage = this.handleRotateImage.bind(this);
  }

  async handleUpload(event) {
    event.preventDefault()
    this.setState({ loading: true });

    const { element, addElement, handleUpload, updateOnUpload } = this.props;
    const files = event.target.files

    if (files.length > 1) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i]
        const newElement = i === 0 ? element : addElement(element.type)

        const res = await handleUpload([file], newElement.uuid, newElement.type)
        if (_.get(res, 'type') === C.UPLOAD_LOCATION_CONTENT_SUCCESS) {
          await updateOnUpload(newElement, res.data.file_url, res.data.file_url_thumbnail)
        }
      }
      this.setState({ loading: false });
    } else {
      handleUpload(event.target.files, element.uuid, element.type)
        .then((res) => {
          if (_.get(res, 'type') === C.UPLOAD_LOCATION_CONTENT_SUCCESS) {
            updateOnUpload(element, res.data.file_url, res.data.file_url_thumbnail);
          }

          this.setState({ loading: false });
        });
    }
  }

  handleRotateImage(element, direction) {
    const { handleRotate } = this.props;
    this.setState({ loading: true }, () => {
      handleRotate(element, direction)
        .then(() => {
          this.setState({ loading: false });
        });
    });
  }

  renderComponent() {
    const { element, type } = this.props;
    const { loading } = this.state;

    return type === 'image' || _.isEmpty(element.data.url) ? (
      <div>
        <Image
          style={{ marginBottom: '10px' }}
          fluid
          src={!loading && element.data.url ? element.data.url : (type === 'video' ? AddVideoPlaceHolder : AddImagePlaceHolder)}
          onClick={event => ElementMedia.handleClick(event, element.uuid)}
        />
        {type === 'video' && (
          <small style={{ color: '#666' }}><Icon name="info circle" />Max. 200 MB. Bitte MP4-Dateien verwenden.</small>
        )}

        {!_.isEmpty(element.data.url) && (
          <div style={{ textAlign: 'center' }}>
            <Button
              disabled={loading}
              loading={loading}
              size="tiny"
              onClick={(e) => {
                e.preventDefault();
                this.handleRotateImage(element, 'left');
              }}
            >
              <Icon name="undo" style={{ margin: 0 }} />
            </Button>
            <Button
              disabled={loading}
              loading={loading}
              size="tiny"
              onClick={(e) => {
                e.preventDefault();
                this.handleRotateImage(element, 'right');
              }}
            >
              <Icon name="redo" style={{ margin: 0 }} />
            </Button>
          </div>
        )}

      </div>
    ) : this.renderVideo();
  }

  renderVideo() {
    const { element } = this.props;

    if (!_.isEmpty(element.data.url)) {
      return (
        <Grid>
          <Grid.Column width={16}>
            <video width="100%" controls>
              <track kind="captions" />
              <source src={element.data.url} type="video/mp4" />
              Your browser does not support HTML5 video.
            </video>
          </Grid.Column>
          <Grid.Column width={16} textAlign="center">
            <Button
              style={{ marginBottom: '10px' }}
              size="tiny"
              onClick={event => ElementMedia.handleClick(event, element.uuid)}
            >
              Video tauschen
            </Button>
          </Grid.Column>
        </Grid>
      );
    }

    return (
      <Image
        fluid
        src={element.data.url}
        onClick={event => ElementMedia.handleClick(event, element.uuid)}
      />
    );
  }

  render() {
    const { loading } = this.state;
    const { element } = this.props;

    return (
      <label htmlFor={`element-media-${element.uuid}`}>
        <input
          id={`element-media-${element.uuid}`}
          type="file"
          style={{ display: 'none' }}
          accept={element.type === 'image' ? 'image/*' : 'video/*'}
          multiple={element.type === 'image'}
          onChange={this.handleUpload}
        />
        {loading ? (
          <Dimmer active>
            <Loader />
          </Dimmer>
        ) : (
          this.renderComponent()
        )}
      </label>
    );
  }
}

ElementMedia.propTypes = {
  handleUpload: PropTypes.func.isRequired,
  addElement: PropTypes.func.isRequired,
  updateOnUpload: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  element: PropTypes.object.isRequired, // eslint-disable-line
};

export default ElementMedia;
