import * as session from './lib/session';
import * as user from './lib/user';
import * as location from './lib/location';
import * as organization from './lib/organization';
import * as notification from './lib/notification';
import * as event from './lib/event';
import getCommunityData from './lib/community';

const API = {
  session,
  user,
  location,
  organization,
  notification,
  event,
  community: {
    get: getCommunityData,
  },
};

export default API;
