import API from 'api';
import { saveAuth } from 'utils/session';
import * as C from './constants';
import receiveNetworkException from './network';
import error from './error';
import { login } from './user';


export const getToken = params => (dispatch) => {
  dispatch({ type: C.GET_TOKEN });
  const body = {
    grant_type: 'password',
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
    ...params,
  };

  return API.session.getToken(body)
    .then(({ body: data }) => {
      const tokenData = saveAuth(data);
      return dispatch(login({ email: params.username, password: params.password }))
        .then(() => dispatch({ type: C.GET_TOKEN_SUCCESS, data: tokenData }));
    })
    .catch((e) => {
      dispatch(receiveNetworkException(e));
      return dispatch(error(C.GET_TOKEN_ERROR, {
        message: 'Failed to get token',
        description: 'Please contact server admin.',
      }));
    });
};

export const refreshToken = token => (dispatch) => {
  dispatch({ type: C.REFRESH_TOKEN });
  const body = {
    grant_type: 'refresh_token',
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
    refresh_token: token,
  };

  return API.session.getToken(body)
    .then(({ body: data }) => {
      const tokenData = saveAuth(data);
      dispatch({ type: C.GET_TOKEN_SUCCESS, data: tokenData });
    })
    .catch((e) => {
      dispatch(receiveNetworkException(e));
      return dispatch(error(C.REFRESH_TOKEN_ERROR, {
        message: 'Your session has expired',
        description: 'Please login again.',
      }));
    });
};

export const signOut = token => (dispatch) => {
  localStorage.clear();
  dispatch({ type: C.SIGN_OUT });

  const body = {
    client_id: process.env.REACT_APP_CLIENT_ID,
    client_secret: process.env.REACT_APP_CLIENT_SECRET,
    token,
  };

  return API.session.signOut(body)
    .then(() => dispatch({ type: C.SIGN_OUT_SUCCESS }));
};
