import * as C from 'actions/constants';

export const blankState = {
  data: JSON.parse(localStorage.getItem('auth')) || {},
  user: JSON.parse(localStorage.getItem('user')) || {},
  loading: false,
};

const SessionReducer = (state = blankState, action) => {
  switch (action.type) {
    case C.GET_TOKEN:
    case C.LOGIN_USER:
    case C.REFRESH_TOKEN: {
      return { ...state, loading: true };
    }
    case C.REFRESH_TOKEN_SUCCESS:
    case C.GET_TOKEN_SUCCESS: {
      const { data } = action;
      return { ...state, loading: false, data };
    }
    case C.LOGIN_USER_SUCCESS: {
      const { data } = action;
      return { ...state, loading: false, user: data };
    }
    case C.REFRESH_USER_DATA_SUCCESS: {
      const { data } = action;
      return { ...state, user: data };
    }
    case C.CREATE_ORGANIZATION_SUCCESS: {
      const { data } = action;
      const user = { ...state.user, organization: data };
      localStorage.setItem('user', JSON.stringify(user));

      return { ...state, loading: false, user };
    }
    case C.LOGIN_USER_ERROR:
    case C.GET_TOKEN_ERROR:
    case C.REFRESH_TOKEN_ERROR: {
      return { ...state, loading: false };
    }
    default:
      return state;
  }
};

export default SessionReducer;
