import React, { Component } from 'react';
import AccountView from 'modules/settings/Account';


class Account extends Component {
  render() {
    return (
      <AccountView />
    )
  }
}


export default Account;
