import REST from '../rest';

const BASE_URL = '/api/v1/events/';

export const list = () => REST.get(BASE_URL);

export const get = id => REST.get(
  BASE_URL,
  id,
);

export const create = (data, contentType) => REST.post(
  BASE_URL,
  data,
  {},
  { 'Content-Type': contentType },
);

export const patch = (id, data, contentType) => REST.patch(
  BASE_URL,
  id,
  data,
  {},
  { 'Content-Type': contentType },
);

export const deleteEvent = id => REST.delete(
  BASE_URL,
  id,
);

export const loadMore = url => REST.get(url);
