import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  Header,
  Button,
  Modal,
  Grid,
} from 'semantic-ui-react';
import _ from 'lodash';
import { renderSelect } from 'modules/Fields';
import * as ORGANIZATION_ACTIONS from 'actions/organization';
import * as USER_ACTIONS from 'actions/user';
import * as SESSION_ACTIONS from 'actions/session';
import * as Alert from 'utils/alert';

const STEPS = [
  'organization',
  'confirm',
];

class ConfirmModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 'organization',
      members: [],
      memberSelected: '',
    };

    this.handleNextStep = this.handleNextStep.bind(this);
    this.handleChangeMember = this.handleChangeMember.bind(this);
    this.setAdmin = this.setAdmin.bind(this);
    this.handleConfirmDelete = this.handleConfirmDelete.bind(this);
  }

  componentDidUpdate(prevProps, prevState) {
    const { step: prevStep } = prevState;
    const { step } = this.state;
    const { open: prevOpen } = prevProps;
    const { getMembers, organization, userId, open } = this.props;

    if (!prevOpen && open && _.isEmpty(organization)) {
      this.setState({ step: 'confirm' });
    }

    if (prevStep !== step && step === 'organization' && !_.isEmpty(organization)) {
      getMembers(organization.id)
        .then(({ data: { members } }) => members)
        .then(members => members.filter(member => member.user.id !== userId))
        .then(members => members.map(m => ({
          key: m.user.id,
          value: m.user.id,
          text: `${m.user.first_name} ${m.user.last_name}`,
        })))
        .then(members => this.setState({ members }));
    }
  }

  setAdmin() {
    const { organization, setAdmin } = this.props;
    const { memberSelected } = this.state;

    return setAdmin(organization.id, memberSelected);
  }

  handleNextStep() {
    const { step, memberSelected } = this.state;
    const index = STEPS.indexOf(step);

    if (step === 'organization' && _.isEmpty(memberSelected)) {
      return;
    }

    this.setState({ step: STEPS[index + 1] });
  }

  handleChangeMember(memberSelected) {
    this.setState({ memberSelected });
  }

  handleConfirmDelete() {
    const { organization, deleteUser, userId } = this.props;
    let promises = [];

    if (!_.isEmpty(organization)) {
      promises.push(this.setAdmin());
    }

    promises.push(deleteUser(userId));

    Promise.all(promises)
      .then(() => this.handleSignOut());
  }

  handleSignOut() {
    const { history, signOut, token } = this.props;

    signOut(token)
      .then(() => {
        Alert.success('Dein Account wurde erfolgreich gelöscht!');
        history.push('/');
      });
  }

  render() {
    const { step, members, memberSelected } = this.state;
    const { open, toggleModal } = this.props;

    return (
      <Modal
        size="mini"
        open={open}
        onClose={() => this.setState({
            step: 'organization',
            memberSelected: '',
            members: [],
          }, () => toggleModal())
        }
      >
        <Modal.Content>
          <Modal.Description style={{ textAlign: 'center' }}>
            <Grid centered>
              {step === 'organization' && (
                <Grid.Column width={15} textAlign="center">
                  <Grid.Row>
                    <Header as="h2">Du bist Administrator einer Organisation.</Header>
                    <p>Bitte teile einen neuen Administrator zu, oder lösche zuerst deine Organisation.</p>
                  </Grid.Row>

                  <Grid.Row style={{ marginTop: '40px' }}>
                    {renderSelect({
                      input: {
                        value: memberSelected,
                        name: 'member',
                        onChange: this.handleChangeMember,
                      },
                      options: members,
                      placeholder: 'Wähle einen neuen Administrator',
                      meta: {
                        touched: false,
                        error: false,
                      },
                    })}
                  </Grid.Row>

                  <Grid.Row style={{ marginTop: '40px', marginBottom: '60px' }}>
                    <Button onClick={this.handleNextStep} fluid size="huge" content="Weiter" />
                  </Grid.Row>
                </Grid.Column>
              )}

              {step === 'confirm' && (
                <Grid.Column width={15} textAlign="center">
                  <Grid.Row>
                    <Header as="h2">Der Account kann jetzt gelöscht werden</Header>
                    <p>Bitte beachte, dass dein Account unwiderruflich gelöscht wird und nicht wiederhergestellt werden kann.</p>
                    <p>Möchtest du deinen Account wirklich löschen?</p>
                  </Grid.Row>

                  <Grid.Row style={{ marginTop: '80px', marginBottom: '80px' }}>
                    <Button onClick={this.handleConfirmDelete} fluid color="red" size="huge" content="Account löschen" />
                  </Grid.Row>
                </Grid.Column>
              )}
            </Grid>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions style={{ textAlign: 'center' }}>
          <p>Hast du weitere Fragen?</p>
          <a href="mailto:feedback@localexpert24.de">Unser Support hilft dir gerne!</a>
        </Modal.Actions>
      </Modal>
    );
  }
}

ConfirmModal.defaultProps = {};

ConfirmModal.propTypes = {};

const mapStateToProps = state => ({
  userId: _.get(state, 'session.user.id'),
  token: _.get(state, 'session.data.access_token'),
  organization: _.get(state, 'session.user.organization'),
});

const mapDispatchToProps = {
  getMembers: ORGANIZATION_ACTIONS.members,
  setAdmin: ORGANIZATION_ACTIONS.setAdmin,
  deleteUser: USER_ACTIONS.deleteUser,
  signOut: SESSION_ACTIONS.signOut,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ConfirmModal));
