import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Item,
  Icon,
  Grid,
} from 'semantic-ui-react';
import _ from 'lodash';
import { UserPicture } from 'modules/styled/map';
import { ExpertByteLabelSmall } from 'modules/styled/profile';

const propTypes = {
  user: PropTypes.object.isRequired, // eslint-disable-line
  history: PropTypes.object.isRequired, // eslint-disable-line
};

const UserItem = (props) => {
  const { user, history } = props;
  const organization = _.get(user, 'organization.name');
  const bpInView = _.get(user, 'bpInView');
  const componentProps = _.pick(props, ['onMouseEnter', 'onMouseLeave']);

  return (
    <Item
      {...componentProps}
      onClick={() => history.push(`/app/users/${user.id}`)}
      key={user.id}
    >
      <div style={{ display: 'flex' }}>
        <div style={{ position: 'relative' }}>
          <UserPicture
            src={!_.isEmpty(user.avatar_image_url) ? user.avatar_thumbnail_url : 'https://react.semantic-ui.com/images/wireframe/square-image.png'}
          />
          <ExpertByteLabelSmall
            expertBytes={user.expert_bytes}
          />
        </div>
        <Item.Content style={{ marginLeft: '10px' }}>
          <Item.Header as="a" style={{ fontSize: '16px' }}>{user.first_name} {user.last_name}</Item.Header>
          <Item.Meta style={{ marginTop: 0 }}>{organization}</Item.Meta>
          {_.has(user, 'bpInView') && (
            <Item.Description>
              <Icon name="map marker alternate" /> {bpInView}
            </Item.Description>
          )}
        </Item.Content>
      </div>
    </Item>
  );
};

UserItem.propTypes = propTypes;

export default withRouter(UserItem);
