import React, { Component } from 'react';
import { connect } from 'react-redux';
import Breakpoint from 'react-socks';
import PropTypes from 'prop-types';
import _ from 'lodash';
import posed from 'react-pose';
import {
  Segment,
  Button,
  Item,
  Icon,
  Loader,
} from 'semantic-ui-react';
import * as MAP_ACTIONS from 'actions/map';
import * as SIDEBAR_ACTIONS from 'actions/sidebar';
import UserItem from './partials/UserItem';
import OrganizationItem from './partials/OrganizationItem';

const AnimatedBox = posed.div({
  visible: {
    right: '0%',
  },
  hidden: {
    right: ({ mobile }) => (mobile ? '-81%' : '-30%'),
  },
  props: {
    mobile: false,
  }
});

class ActiveSidebar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: 'experten',
      open: false,
    };

    this.handleChooseOption = this.handleChooseOption.bind(this);
    this.handleVisibility = this.handleVisibility.bind(this);
  }

  handleChooseOption(option) {
    this.setState({ selected: option });
  }

  handleVisibility() {
    const { open, selected } = this.state;
    const { load, mapBounds } = this.props;

    if (!open) {
      load({ ...mapBounds, type: selected, paginate: false });
    }

    this.setState({ open: !open });
  }

  render() {
    const { selected, open } = this.state;
    const {
      users,
      mobile,
      organizations,
      handleExpertMouseEnter,
      handleExpertMouseLeave,
      handleOrganizationMouseEnter,
      handleOrganizationMouseLeave,
      loading,
    } = this.props;

    return (
      <AnimatedBox
        pose={open ? 'visible' : 'hidden'}
        mobile={mobile}
        style={{
          height: '100%',
          width: mobile ? '80%' : '30%',
          position: 'absolute',
          top: mobile ? '60px' : '10px',
          zIndex: 2,
        }}
      >
        <Segment
          textAlign="center"
          style={{ position: 'relative', height: '100%', borderTopLeftRadius: 0 }}
        >
          <div style={{ position: 'relative', height: 'calc(100% - 50px)' }}>
            <Breakpoint medium up>
              <Button
                primary
                icon={`chevron ${open ? 'right' : 'left'}`}
                onClick={this.handleVisibility}
                className="reactour__active-experts"
                style={{
                  position: 'absolute',
                  top: '-15px',
                  left: '-53px',
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
              />
            </Breakpoint>
            <Breakpoint small down>
              <Button
                primary
                icon={`chevron ${open ? 'right' : 'left'}`}
                onClick={this.handleVisibility}
                className="reactour__active-experts"
                style={{
                  position: 'absolute',
                  top: '40%',
                  left: '-53px',
                  borderTopRightRadius: 0,
                  borderBottomRightRadius: 0,
                }}
              />
            </Breakpoint>
            <Button.Group size="mini">
              <Button primary={selected === 'experten'} onClick={() => this.handleChooseOption('experten')}>Experten</Button>
              <Button primary={selected === 'organisationen'} onClick={() => this.handleChooseOption('organisationen')}>Organisationen</Button>
            </Button.Group>

            <div style={{ position: 'relative', height: '100%', overflowY: 'scroll', marginTop: '20px' }}>
              {open && (
                <Item.Group divided link style={{ textAlign: 'left' }}>
                  {loading && <Loader active inline="centered" />}
                  {selected === 'experten' && users.map(user => (
                    <UserItem
                      key={user.id}
                      user={user}
                      onMouseEnter={() => handleExpertMouseEnter(user.id)}
                      onMouseLeave={handleExpertMouseLeave}
                    />
                  ))}
                  {selected === 'experten' && _.isEmpty(users) && !loading && (
                    <Segment textAlign='center'>
                      <Icon name='inbox' size='big' color='grey' />
                      <p>Keine Experten in dieser Ansicht gefunden</p>
                    </Segment>
                  )}

                  {selected === 'organisationen' && organizations.map(organization => (
                    <OrganizationItem
                      key={organization.id}
                      organization={organization}
                      onMouseEnter={() => handleOrganizationMouseEnter(organization.id)}
                      onMouseLeave={handleOrganizationMouseLeave}
                    />
                  ))}
                  {selected === 'organisationen' && _.isEmpty(organizations) && !loading && (
                    <Segment textAlign='center'>
                      <Icon name='inbox' size='big' color='grey' />
                      <p>Keine Organisationen in dieser Ansicht gefunden</p>
                    </Segment>
                  )}
                </Item.Group>
              )}
            </div>
          </div>
        </Segment>
      </AnimatedBox>
    );
  }
}

ActiveSidebar.defaultProps = {
  mobile: false,
  loading: true,
};

ActiveSidebar.propTypes = {
  handleExpertMouseEnter: PropTypes.func.isRequired,
  handleExpertMouseLeave: PropTypes.func.isRequired,
  handleOrganizationMouseEnter: PropTypes.func.isRequired,
  handleOrganizationMouseLeave: PropTypes.func.isRequired,
  load: PropTypes.func.isRequired,
  mobile: PropTypes.bool,
  loading: PropTypes.bool,
  users: PropTypes.array.isRequired, // eslint-disable-line
  organizations: PropTypes.array.isRequired, // eslint-disable-line
  mapBounds: PropTypes.object.isRequired, // eslint-disable-line
};

function mapStateToProps(state) {
  const mapLocations = _.get(state, 'map.locations', []);
  const experts = _.get(state, 'sidebar.experts', []);
  const mapBounds = _.get(state, 'map.bounds', {});
  const users = _.uniqBy(experts, 'user_data.id')
    .map(user => ({
      ...user.user_data,
      organization: user.organization,
    }))
    .map(user => ({
      ...user,
      bpInView: mapLocations.filter(location => _.get(location, 'user_data.id') === user.id).length,
    }))
    .filter(user => user.bpInView > 0)
    .sort((u1, u2) => u2.expert_bytes - u1.expert_bytes);

  const organizations = _.without(_.uniqBy(users, 'organization.id')
    .map(user => ({ organization: user.organization, isPremium: user.is_premium })), null)
    .map(({ organization, isPremium }) => ({
      ...organization,
      isPremium,
      bpInView: mapLocations.filter(location => _.get(location, 'organization.id') === _.get(organization, 'id')).length,
    }))
    .filter(organization => organization.bpInView > 0 && organization.isPremium);

  return {
    users,
    organizations: _.orderBy(organizations, ['total_expert_bytes'], ['desc']),
    mapBounds,
    loading: _.get(state, 'sidebar.loadingExperts'),
  };
}

const mapDispatchToProps = {
  handleExpertMouseEnter: MAP_ACTIONS.expertMouseEnter,
  handleExpertMouseLeave: MAP_ACTIONS.expertMouseLeave,
  handleOrganizationMouseEnter: MAP_ACTIONS.organizationMouseEnter,
  handleOrganizationMouseLeave: MAP_ACTIONS.organizationMouseLeave,
  load: SIDEBAR_ACTIONS.load,
};

export default connect(mapStateToProps, mapDispatchToProps)(ActiveSidebar);
