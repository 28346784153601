import React from 'react';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  Grid,
  Icon,
  List,
  Checkbox,
} from 'semantic-ui-react';
import TextElement from './TextElement';
import ImageElement from './ImageElement';
import VideoElement from './VideoElement';

const TYPE_TO_COMPONENT = {
  text: TextElement,
  image: ImageElement,
  video: VideoElement,
};

const ElementWrapper = ({
  element,
  previewUuid,
  type,
  removeElement,
  addElement,
  handlePositionChange,
  handleTextChange,
  handleSelectPreview,
  handleUpload,
  updateOnUpload,
  firstImage,
  handleRotate,
}) => (
  <Grid style={{ marginBottom: '20px' }}>
    <Grid.Column mobile={10} tablet={13} computer={13}>
      {element.type === 'image' && (
        <Checkbox
          style={{ fontSize: '13px' }}
          label="Als Vorschaubild festlegen"
          checked={previewUuid === element.uuid || firstImage}
          disabled={!_.isEmpty(previewUuid) && !(previewUuid === element.uuid)}
          onChange={(event, { checked }) => handleSelectPreview(element, checked)}
        />
      )}
    </Grid.Column>
    <Grid.Column mobile={2} tablet={1} computer={1}>
      <Icon link name="arrow up" onClick={() => handlePositionChange(element, 'up')} />
    </Grid.Column>
    <Grid.Column mobile={2} tablet={1} computer={1}>
      <Icon link name="arrow down" onClick={() => handlePositionChange(element, 'down')} />
    </Grid.Column>
    <Grid.Column mobile={2} tablet={1} computer={1}>
      <Icon link name="trash" onClick={() => removeElement(element)} />
    </Grid.Column>
    {TYPE_TO_COMPONENT[element.type]({
      element,
      type,
      handleUpload,
      handleTextChange,
      updateOnUpload,
      handleRotate,
      addElement,
    })}
  </Grid>
);

ElementWrapper.defaultProps = {
  previewUuid: '',
  type: '',
  firstImage: false,
};

ElementWrapper.propTypes = {
  handlePositionChange: PropTypes.func.isRequired,
  removeElement: PropTypes.func.isRequired,
  handleSelectPreview: PropTypes.func.isRequired,
  handleTextChange: PropTypes.func.isRequired,
  handleUpload: PropTypes.func.isRequired,
  updateOnUpload: PropTypes.func.isRequired,
  handleRotate: PropTypes.func.isRequired,
  previewUuid: PropTypes.string,
  type: PropTypes.string,
  firstImage: PropTypes.bool,
  element: PropTypes.object.isRequired, // eslint-disable-line
};

export default ElementWrapper;
