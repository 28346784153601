import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Item,
  Icon,
  Label,
  Image,
} from 'semantic-ui-react';
import _ from 'lodash';
import ORGANIZATION_TYPES from 'utils/organization';
import { ExpertByteLabelSmall } from 'modules/styled/profile';

const propTypes = {
  organization: PropTypes.object.isRequired, // eslint-disable-line
  history: PropTypes.object.isRequired, // eslint-disable-line
};

const OrganizationItem = (props) => {
  const { organization, history } = props;
  const type = ORGANIZATION_TYPES.find(orgType => orgType.key === organization.type);
  const logoUrl = _.get(organization, 'logo_url_thumbnail', _.get(organization, 'logo_url'));
  const bpInView = _.get(organization, 'bpInView');
  const componentProps = _.pick(props, ['onMouseEnter', 'onMouseLeave']);

  return (
    <Item
      {...componentProps}
      onClick={() => history.push(`/app/organizations/${organization.id}`)}
      key={organization.id}
    >
      <div style={{ display: 'flex' }}>
        <div style={{ position: 'relative' }}>
          <Image
            src={!_.isEmpty(logoUrl) ? logoUrl : 'https://react.semantic-ui.com/images/wireframe/square-image.png'}
            style={{ width: '56px', height: '56px' }}
          />
          <ExpertByteLabelSmall
            expertBytes={organization.total_expert_bytes}
          />
        </div>
        <Item.Content style={{ marginLeft: '10px' }}>
          <Item.Header as="a" style={{ fontSize: '16px' }}>{organization.name}</Item.Header>
          <Item.Meta style={{ marginTop: 0 }}>{_.get(type, 'text')}</Item.Meta>
          {_.has(organization, 'bpInView') && (
            <Item.Description>
              <Icon name="map marker alternate" /> {bpInView}
            </Item.Description>
          )}
        </Item.Content>
      </div>
    </Item>
  );
};

OrganizationItem.propTypes = propTypes;

export default withRouter(OrganizationItem);
