import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  withRouter,
  matchPath,
  Route,
  Switch,
  Link,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { Grid, Button, Segment } from 'semantic-ui-react';
import * as LOCATION_ACTIONS from 'actions/location';
import getCommunity from 'actions/community';
import PublicSearchPage from 'pages/PublicSearch';
import * as SRC from '../modules/styled';

class PublicSearch extends Component {
  componentDidMount() {
    const { listLocations, mapFilters, getCommunityData } = this.props;
    listLocations(mapFilters);
    getCommunityData();
  }

  render() {
    const {
      location: { pathname },
      locationsCount,
      organizationsCount,
      usersCount,
    } = this.props;
    const isMapPage = !!matchPath(pathname, '/public-search/map');
    return (
      <div>
        <Switch>
          <Route
            path="/public-search/map"
            component={() => (
              <div>
                <div
                  style={{
                    position: 'absolute',
                    top: '30px',
                    left: 0,
                    padding: '0 30px',
                    zIndex: 999,
                    width: '100vw',
                  }}
                >
                  <Grid>
                    <Grid.Column width={8}>
                      <Grid.Row>
                        <Link
                          to="/public-search"
                          style={{
                            display: 'inline-block',
                            borderRadius: '0.28rem',
                            backgroundColor: 'white',
                            border: '1px solid #2185d0',
                            fontSize: '1.14rem',
                            padding: '0.78em 1.5em',
                          }}
                        >
                          Zurück
                        </Link>
                      </Grid.Row>
                    </Grid.Column>
                    <Grid.Column width={8} textAlign="right">
                      <Grid.Row>
                        <Button
                          size="large"
                          primary
                          onClick={() => {
                            document.location.href = 'https://app.localexpert24.de/auth/register';
                          }}
                        >
                          Jetzt lokaler Experte werden
                        </Button>
                      </Grid.Row>
                    </Grid.Column>
                  </Grid>
                </div>
                <div
                  style={{
                    position: 'absolute',
                    bottom: '10px',
                    left: 0,
                    padding: '0 30px',
                    zIndex: 999,
                    width: '100vw',
                    overflow: 'hidden',
                    textAlign: 'center',
                  }}
                >
                  <Segment
                    color="yellow"
                    style={{
                      display: 'inline-table',
                      fontSize: '12pt',
                      paddingLeft: '20px',
                      paddingRight: '20px',
                    }}
                    compact
                  >
                    <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Unsere localexpert24-Plattform auf einen Blick: </p>
                    <Grid columns={3} divided>
                      <Grid.Column>
                        <p style={{ fontSize: '25px', marginBottom: '0px', fontWeight: 'bold' }}>{locationsCount}</p>
                        <p>Baupunkte</p>
                      </Grid.Column>
                      <Grid.Column>
                        <p style={{ fontSize: '25px', marginBottom: '0px', fontWeight: 'bold' }}>{usersCount}</p>
                        <p>Experten</p>
                      </Grid.Column>
                      <Grid.Column>
                        <p style={{ fontSize: '25px', marginBottom: '0px', fontWeight: 'bold' }}>{organizationsCount}</p>
                        <p>Organisationen</p>
                      </Grid.Column>
                    </Grid>
                  </Segment>
                </div>
              </div>
            )}
          />
          <Route path="/" component={PublicSearchPage} />
        </Switch>
        <SRC.MapBG blocked={!isMapPage} readOnly />
      </div>
    );
  }
}

PublicSearch.defaultProps = {
  locationsCount: 0,
  usersCount: 0,
  organizationsCount: 0,
};

PublicSearch.propTypes = {
  location: PropTypes.object.isRequired, // eslint-disable-line
  listLocations: PropTypes.func.isRequired,
  mapFilters: PropTypes.object.isRequired, // eslint-disable-line
  getCommunityData: PropTypes.func.isRequired,
  locationsCount: PropTypes.number,
  usersCount: PropTypes.number,
  organizationsCount: PropTypes.number,
};

function mapStateToProps(state) {
  const count = _.get(state, 'community.counts', {});

  return {
    mapFilters: _.get(state, 'map.filters', {}),
    usersCount: _.get(count, 'users', 0),
    locationsCount: _.get(count, 'locations', 0),
    organizationsCount: _.get(count, 'organizations', 0),
  };
}

const mapDispatchToProps = {
  listLocations: LOCATION_ACTIONS.mapLocations,
  getCommunityData: getCommunity,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PublicSearch));
