import React from 'react';
import ReactDOMServer from 'react-dom/server';
import _ from 'lodash';
import {
  Item,
  Icon,
  Label,
  List,
} from 'semantic-ui-react';
import { UserPicture, LocationCoverPicture } from 'modules/styled/map';
import Pin from 'assets/images/pin.png';
import PinInfo from 'assets/images/pin_info.png';
import PinActive from 'assets/images/pin_active.png';
import { ExpertByteLabelSmall } from 'modules/styled/profile';
import { LikeLabel } from 'modules/styled/location';

export const defaultMarker = {
  type: 'picture-marker', // autocasts as new SimpleMarkerSymbol()
  url: Pin,
  width: 20,
  height: 30,
  yoffset: 15,
};

export const activeMarker = {
  type: 'picture-marker', // autocasts as new SimpleMarkerSymbol()
  url: PinActive,
  width: 20,
  height: 30,
};

export const infoMarker = {
  type: 'picture-marker', // autocasts as new SimpleMarkerSymbol()
  url: PinInfo,
  width: 20,
  height: 30,
};

export const clusterTextMarker = clusterItems => ({
  type: 'text',
  color: 'white',
  borderLineColor: '#000',
  borderLineSize: '1px',
  haloColor: 'black',
  haloSize: '1px',
  text: clusterItems,
  xoffset: 3,
  yoffset: 3,
  font: {
    size: 10,
    family: 'sans-serif',
    weight: 'bold',
  },
});

export const clusterActiveTextMarker = clusterItems => ({
  type: 'text',
  color: '#f7dd00',
  borderLineColor: '#f7dd00',
  borderLineSize: '1px',
  haloColor: 'black',
  haloSize: '1px',
  text: clusterItems,
  xoffset: 3,
  yoffset: 3,
  font: {
    size: 10,
    family: 'sans-serif',
    weight: 'bold',
  },
});

export const popupTemplate = (location) => {
  const deletedUser = _.isEmpty(_.get(location, 'user_data'));
  const avatarUrl = _.get(location, 'user_data.avatar_image_url');
  const avatarUrlThumbnail = _.get(location, 'user_data.avatar_thumbnail_url');
  const coverImage = _.get(location, 'cover_image');
  const tags = _.get(location, 'tags_pretty', []);
  const truncate = (input) => (input !== null && input.length > 96) ? `${input.substring(0, 96)}...` : input;

  const content = (
    <Item.Group
      divided
      link
      style={{ textAlign: 'left' }}
    >
      <Item style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ position: 'relative' }}>
          <a href={`/app/location/detail/${location.id}`}>
            <LocationCoverPicture
              src={!_.isEmpty(coverImage) ? coverImage : 'https://react.semantic-ui.com/images/wireframe/square-image.png'}
            />
          </a>
          <LikeLabel
            likes={_.get(location, 'votes_count', 0)}
          />
        </div>
        <Item.Content style={{ paddingLeft: '1em' }}>
          <Item.Meta style={{ marginTop: 0, display: 'flex', justifyContent: 'space-between' }}>
            <span>{location.created_at}</span>
            <span style={{ fontWeight: 'bold' }}>#{location.id}</span>
          </Item.Meta>
          <Item.Header style={{ fontSize: '16px' }}><a href={`/app/location/detail/${location.id}`}>{truncate(location.description)}</a></Item.Header>
          <Item.Meta style={{ marginTop: '5px' }}>{location.street} {location.postalcode} {location.city}</Item.Meta>
          {tags.length > 0 && <p style={{ marginTop: '10px' }}>{location.tags_pretty.map(tag => (
            <Label key={tag.value} as="p" style={{ marginRight: '15px' }}>{tag.title}</Label>
          ))}</p>}
        </Item.Content>
      </Item>
      <List horizontal style={{ marginTop: '5px', marginBottom: '10px' }}>
        <List.Item>
          <Icon name="align left" /> {location.counters.text_elements}
        </List.Item>
        <List.Item>
          <Icon name="images" /> {location.counters.image_elements}
        </List.Item>
        <List.Item>
          <Icon name="file video" /> {location.counters.video_elements}
        </List.Item>
        <List.Item>
          <Icon name="file" /> {location.counters.documents}
        </List.Item>
        <List.Item>
          <Icon name="linkify" /> {location.counters.links}
        </List.Item>
        <List.Item>
          <Icon name="cube" /> {location.counters.document_ifc}
        </List.Item>
      </List>
      <Item style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ position: 'relative' }}>
          <UserPicture
            src={!_.isEmpty(avatarUrl) ? avatarUrlThumbnail : 'https://react.semantic-ui.com/images/wireframe/square-image.png'}
            style={{ width: '90px !important', height: '90px !important' }}
          />
          <ExpertByteLabelSmall
            expertBytes={_.get(location, 'user_data.expert_bytes', 0)}
          />
        </div>
        <Item.Content style={{ paddingLeft: '1em' }}>
          <Item.Header style={{ fontSize: '16px' }}>
            {!deletedUser ? (
              <a href={`/app/users/${location.user_data.id}`}>{location.user_data.first_name} {location.user_data.last_name}</a>
            ) : (
              <p>Gelöschter Benutzer</p>
            )}
          </Item.Header>
          {!_.isEmpty(_.get(location, 'user_data.organization')) && (
            <Item.Meta style={{ marginTop: 0 }}>
              <a href={`/app/organizations/${location.user_data.organization.id}`}>{location.user_data.organization.name}</a>
            </Item.Meta>
          )}
        </Item.Content>
      </Item>
    </Item.Group>
  );

  return ReactDOMServer.renderToString(content);
};

export const popupTemplateReadOnly = (location) => {
  const coverImage = _.get(location, 'cover_image');
  const tags = _.get(location, 'tags_pretty', []);
  const truncate = (input) => (input !== null && input.length > 96) ? `${input.substring(0, 96)}...` : input;

  const content = (
    <Item.Group
      divided
      link
      style={{ textAlign: 'left' }}
    >
      <Item style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={{ position: 'relative' }}>
          <a href={`/app/location/detail/${location.id}`}>
            <LocationCoverPicture
              src={!_.isEmpty(coverImage) ? coverImage : 'https://react.semantic-ui.com/images/wireframe/square-image.png'}
            />
          </a>
        </div>
        <Item.Content style={{ paddingLeft: '1em' }}>
          <p>{`ID: ${location.id}`}</p>
          {tags.length > 0 && <p style={{ marginTop: '10px' }}>{location.tags_pretty.map(tag => (
            <Label key={tag.value} as="p" style={{ marginRight: '15px' }}>{tag.title}</Label>
          ))}</p>}
          <List horizontal style={{ marginTop: '5px', marginBottom: '10px' }}>
            <List.Item>
              <Icon name="align left" /> {location.counters.text_elements}
            </List.Item>
            <List.Item>
              <Icon name="images" /> {location.counters.image_elements}
            </List.Item>
            <List.Item>
              <Icon name="file video" /> {location.counters.video_elements}
            </List.Item>
            <List.Item>
              <Icon name="file" /> {location.counters.documents}
            </List.Item>
            <List.Item>
              <Icon name="linkify" /> {location.counters.links}
            </List.Item>
            <List.Item>
              <Icon name="cube" /> {location.counters.document_ifc}
            </List.Item>
          </List>
        </Item.Content>
      </Item>
    </Item.Group>
  );

  return ReactDOMServer.renderToString(content);
};

const getDistanceForZoom = (zoom) => {
  if (zoom >= 15) {
    return -1;
  }

  if (zoom >= 14) {
    return 0;
  }

  if (zoom >= 13) {
    return 0.5;
  }

  if (zoom >= 12) {
    return 2;
  }

  if (zoom >= 11) {
    return 3;
  }

  if (zoom >= 10) {
    return 5;
  }

  if (zoom >= 9) {
    return 8;
  }

  if (zoom >= 8) {
    return 10;
  }

  if (zoom >= 7) {
    return 20;
  }

  return 30;
};

const distanceBetweenPoints = (p1, p2) => {
  if (!p1 || !p2) {
    return 0;
  }

  const R = 6371; // Radius of the Earth in km

  const degreesToRadians = degree => (degree * Math.PI / 180);
  const sinDouble = degree => Math.pow(Math.sin(degree / 2), 2);
  const cosSquared = (point1, point2) => Math.cos(degreesToRadians(point1.lat)) * Math.cos(degreesToRadians(point2.lat));

  const dLat = degreesToRadians(p2.lat - p1.lat);
  const dLon = degreesToRadians(p2.lng - p1.lng);
  const a = sinDouble(dLat) + cosSquared(p1, p2) * sinDouble(dLon);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;

  return d;
};

export const clusterLocations = (locations, zoom) => {
  let clusters = [];

  locations.forEach((location) => {
    if (_.isEmpty(clusters)) {
      clusters.push({
        lat: location.lat,
        lng: location.lng,
        items: [location],
      });
    } else {
      const maxDistance = getDistanceForZoom(zoom);
      let clusterToAddTo = false;

      clusters.forEach((cluster) => {
        const distance = distanceBetweenPoints(cluster, location);

        if (distance <= maxDistance) {
          clusterToAddTo = cluster;
        }
      });

      if (clusterToAddTo) {
        clusterToAddTo.items = [...clusterToAddTo.items, location];
      } else {
        clusters = [
          ...clusters,
          {
            lat: location.lat,
            lng: location.lng,
            items: [location],
          },
        ];
      }
    }
  });

  return clusters;
};
