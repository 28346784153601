import { encodeURI } from 'utils/urlOperations';
import REST from '../rest';

export const getToken = data => REST.post(
  'oauth2/token/',
  encodeURI(data),
  {},
  { 'Content-Type': 'application/x-www-form-urlencoded' },
);

export const signOut = data => REST.post(
  'oauth2/revoke_token/',
  encodeURI(data),
  {},
  { 'Content-Type': 'application/x-www-form-urlencoded' },
);
