
import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  Grid,
  Header,
  Form,
  Button,
  Divider,
  Icon,
} from 'semantic-ui-react';
import * as Alert from 'utils/alert';
import AuthHOC from 'containers/AuthHOC';
import * as C from 'actions/constants';
import * as USER_ACTIONS from 'actions/user';

class ResetPasswordPage extends Component {
  constructor(props) {
    super(props);

    this.handleConfirmAccount = this.handleConfirmAccount.bind(this);
    this.handleResendConfirm = this.handleResendConfirm.bind(this);
    this.handleAlreadyVerified = this.handleAlreadyVerified.bind(this);
  }

  componentDidMount() {
    const { history, user, match: { params } } = this.props;

    if (_.isEmpty(user)) {
      history.push('/');
    }

    const uid = _.get(params, 'uid');
    const token = _.get(params, 'token');

    if (!_.isEmpty(uid) && !_.isEmpty(token)) {
      this.handleConfirmAccount(uid, token);
    }
  }

  handleConfirmAccount(uid, token) {
    const { history, confirmAccount } = this.props;

    confirmAccount({
      uid,
      token,
    })
      .then((res) => {
        if (res.type === C.CONFIRM_ACCOUNT_SUCCESS) {
          Alert.success('Dein Account wurde aktiviert! Bitte logge dich jetzt ein.');
          history.push('/?next=/auth/register/profile');
        } else {
          Alert.error('Dein Account konnte nicht aktiviert werden. Bitte verifiziere diesen erneut.');
        }
      });
  }

  handleAlreadyVerified(e) {
    const { history } = this.props;
    localStorage.clear();
    history.push('/');
  }

  handleResendConfirm(e) {
    e.preventDefault();

    const { user, resendConfirm } = this.props;

    resendConfirm(user.email)
      .then((res) => {
        if (res.type === C.RESEND_CONFIRM_ACCOUNT_SUCCESS) {
          Alert.success('Die Verifizierungs-E-Mail wurde erneut gesendet. Bitte prüfe dein Postfach und ggf. deinen Spam-Ordner.');
        } else {
          Alert.error('Die Verifizierungs-E-Mail konnte nicht erneut gesendet werden. Bitte versuche es zu einem späteren Zeitpunkt nochmal.');
        }
      });
  }

  render() {
    const { match: { params } } = this.props;
    const uid = _.get(params, 'uid');
    const token = _.get(params, 'token');

    const isActivating = !_.isEmpty(uid) && !_.isEmpty(token);

    return (
      <div>
        <Grid.Row style={{ marginTop: '20px' }}>
          <Header as="h3" textAlign="center">Bitte verifiziere nun deinen Account</Header>
        </Grid.Row>

        <Grid centered>
          <Grid.Column computer={12} tablet={12} mobile={16}>
            <Grid.Row style={{ marginTop: '50px', textAlign: 'center' }}>
              <p><Icon name='send' size='huge' /></p>
              <p>{!isActivating ? 'Wir haben dir per E-Mail einen Verifizierungslink geschickt..' : 'Dein Account wird aktiviert, bitte warten...'}</p>
            </Grid.Row>
          </Grid.Column>
        </Grid>

        <Divider style={{ marginTop: '40px' }} />

        <Grid.Row textAlign="center">
          <p>E-Mail nicht erhalten?</p>
          <Button basic content="Verifizierungs-E-Mail erneut senden" onClick={this.handleResendConfirm} />
        </Grid.Row>

        <Grid.Row textAlign="center" style={{ marginTop: '25px'}}>
          <p>Bereits verifiziert?</p>
          <Button basic content="Zurück zum Login" onClick={this.handleAlreadyVerified} />
        </Grid.Row>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  user: _.get(state, 'session.user'),
});

const mapDispatchToProps = {
  confirmAccount: USER_ACTIONS.confirmAccount,
  resendConfirm: USER_ACTIONS.resendConfirm,
};

const ConnectedResetPasswordPage = connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage);

export default withRouter(AuthHOC(ConnectedResetPasswordPage));
