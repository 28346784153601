import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Grid,
  Header,
  Segment,
  Divider,
  Menu,
  Statistic,
  Loader,
  Icon,
  Item,
  List,
  Button,
} from 'semantic-ui-react';
import LazyLoad from 'react-lazy-load';
import _ from 'lodash';
import getCommunity from 'actions/community';
import AuthHOC from 'containers/AuthHOC';
import LocationItem from 'modules/search/partials/LocationItem';
import SearchPage from './Search';
import { UserPicture } from 'modules/styled/map';
import * as STATEAPI from 'utils/stateapi';


const UserItem = ({
  user,
  handleClick,
  showDate,
}) => {
  const organization = _.get(user, 'organization.name', _.get(user, 'organization_member.name'));

  return (
    <Item
      onClick={handleClick}
      key={user.id}
    >
      <div style={{ display: 'flex', flexGrow: 1 }}>
        <LazyLoad width={56} height={56} debounce={false}>
          <UserPicture
            src={!_.isEmpty(user.avatar_image_url) ? user.avatar_thumbnail_url : 'https://react.semantic-ui.com/images/wireframe/square-image.png'}
          />
        </LazyLoad>
        <div style={{ flexGrow: 1, marginLeft: '20px' }}>
          <Grid>
            <Grid.Column computer={8} mobile={15}>
              <Grid.Row style={{ marginBottom: '3px' }}>
                <Item.Header as="a" style={{ fontSize: '16px' }}>{user.first_name} {user.last_name}</Item.Header>
                <p>{user.full_address}</p>
              </Grid.Row>
              {user.amount_locations && user.amount_locations > 0 && (
                <Grid.Row style={{ paddingBottom: '0px' }}>
                  <p>{user.amount_locations} Baupunkt(e) erstellt</p>
                </Grid.Row>
              )}
              <Grid.Row style={{ paddingBottom: '0px' }}>
                {showDate && (
                    <p style={{ marginBottom: '0px' }}><Icon name="calendar outline" style={{ marginRight: '5px' }} />{moment(user.created_at).format('DD.MM.YYYY')}</p>
                  )}
                <List horizontal>
                  <List.Item>
                    <Icon name="ticket" style={{ marginRight: '5px' }} />{user.expert_bytes}
                  </List.Item>
                  <List.Item>
                    <Icon name="map marker alternate" style={{ marginRight: '5px' }} />{user.locations_count}
                  </List.Item>
                </List>
              </Grid.Row>
            </Grid.Column>
            <Grid.Column computer={8} mobile={15}>
              <Grid.Row>
                {user.is_premium && organization}
              </Grid.Row>
              <Grid.Row>
                <span style={{ color: '#aaa' }}>{user.position}</span>
              </Grid.Row>
            </Grid.Column>
          </Grid>
        </div>
      </div>
    </Item>
  );
};



class Search extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentTab: 'Übersicht',
      selectedOption: ''
    };

    this.handleItemClick = this.handleItemClick.bind(this);
    this.handleClickExperten = this.handleClickExperten.bind(this);
  }

  componentDidMount() {
    const { changeBlocked, getCommunityData, history: { location: { hash } } } = this.props;

    changeBlocked(true);
    getCommunityData();

    if (hash.includes('mitgliedersuche')) {
      this.setState({ currentTab: 'Mitgliedersuche' })
    }

    if(hash.includes('organisationen')) {
      this.setState({ selectedOption: 'organisationen' })
    }
  }

  handleItemClick(e, { name }) {
    const { history } = this.props

    history.push(`/app/community#${name.toLowerCase()}`)

    this.setState({ currentTab: name })
  }

  handleClickExperten(user) {
    const { history } = this.props;

    history.push(`/app/users/${user.id}`);
  }

  render() {
    const { currentTab, selectedOption } = this.state;
    const {
      changeBlocked,
      usersCount,
      locationsCount,
      organizationsCount,
      latestUsers,
      latestLocations,
      activeExperts,
      loading,
      isAdmin,
      isPremium,
    } = this.props;

    return (
      <Grid centered style={{ zIndex: 2 }}>
        <Grid.Column computer={14} tablet={14} mobile={14} style={{ zIndex: 2 }}>
          <Menu attached="top" tabular>
            <Menu.Item name="Übersicht" active={currentTab === 'Übersicht'} onClick={this.handleItemClick}>Übersicht</Menu.Item>
            <Menu.Item name="Mitgliedersuche" active={currentTab === 'Mitgliedersuche'} onClick={this.handleItemClick} />
          </Menu>

          <Segment attached="bottom" style={{ padding: '20px', display: 'relative' }}>
            {currentTab && loading && <Loader active />}
            {!loading && currentTab === 'Übersicht' && (
              <div>
                <Grid>
                  <Grid columns={4} stackable>
                    <Grid.Column>
                      <Segment textAlign="center">
                        <Statistic>
                          <Statistic.Value>{locationsCount}</Statistic.Value>
                          <Statistic.Label>Baupunkte</Statistic.Label>
                        </Statistic>
                      </Segment>
                    </Grid.Column>
                    {isAdmin && (
                      <Grid.Column>
                        <Segment textAlign="center">
                          <Statistic>
                            <Statistic.Value>{usersCount}</Statistic.Value>
                            <Statistic.Label>Lokale Experten</Statistic.Label>
                          </Statistic>
                        </Segment>
                      </Grid.Column>
                    )}
                    {isAdmin && (
                      <Grid.Column>
                        <Segment textAlign="center">
                          <Statistic>
                            <Statistic.Value>{organizationsCount}</Statistic.Value>
                            <Statistic.Label>Organisationen</Statistic.Label>
                          </Statistic>
                        </Segment>
                      </Grid.Column>
                    )}
                    <Grid.Column>
                      <Segment textAlign='center' color='yellow' secondary>
                        <p style={{ marginBottom: "5px" }}><Icon name='group' size='large' /><br/>Community-Richtlinien</p>
                        <p><a href="https://le24-media.s3.eu-central-1.amazonaws.com/downloads/localexpert24_Richtlinien_DINA4_Lay_2.pdf" target="_blank" class="ui button mini yellow">Download</a></p>

                      </Segment>
                    </Grid.Column>
                  </Grid>
                </Grid>

                <Divider />

                <Grid>
                  <Grid columns={3} stackable>
                    <Grid.Column>
                      <Header as="h2">Neueste Baupunkte</Header>
                      {isPremium ? (
                        <Item.Group divided link>
                          {latestLocations.map(location => <LocationItem key={location.id} data={location} />)}
                        </Item.Group>
                      ) : (
                        <Segment textAlign="center">
                          <p>Die neuesten Baupunkte werden dir nur als <strong>Premium-Mitglied</strong> angezeigt.</p>
                          <div class='ui divider'></div>
                          <a href='/app/premium-features' class='ui button mini yellow'>Mehr erfahren</a>
                        </Segment>
                      )}
                    </Grid.Column>
                    <Grid.Column>
                      <Header as="h2">Neueste Experten</Header>
                      {isPremium ? (
                        <Item.Group divided link>
                          {latestUsers.map(user => <UserItem showDate={true} key={user.id} user={user} handleClick={() => this.handleClickExperten(user)} />)}
                        </Item.Group>
                      ) : (
                        <Segment textAlign="center">
                          <p>Die neuesten Experten werden dir nur als <strong>Premium-Mitglied</strong> angezeigt.</p>
                          <div class='ui divider'></div>
                          <a href='/app/premium-features' class='ui button mini yellow'>Mehr erfahren</a>
                        </Segment>
                      )}
                    </Grid.Column>
                    <Grid.Column>
                      <Header as="h2">Aktivste Experten</Header>
                      <p style={{color: '#666'}}><Icon name="info circle" />Die meisten Baupunkte der letzten 30 Tage erstellten...</p>
                      {isPremium ? (
                        <Item.Group divided link>
                          {activeExperts.map(user => <UserItem key={user.id} user={user} handleClick={() => this.handleClickExperten(user)} />)}
                        </Item.Group>
                      ) : (
                        <Segment textAlign="center">
                          <p>Die aktivsten Experten werden dir nur als <strong>Premium-Mitglied</strong> angezeigt.</p>
                          <div class='ui divider'></div>
                          <a href='/app/premium-features' class='ui button mini yellow'>Mehr erfahren</a>
                        </Segment>
                      )}
                    </Grid.Column>
                  </Grid>
                </Grid>
              </div>
            )}
            {currentTab === 'Mitgliedersuche' && (
              <SearchPage selectedOption={selectedOption} changeBlocked={changeBlocked} />
            )}
          </Segment>
        </Grid.Column>
      </Grid>
    );
  }
}

Search.propTypes = {
  changeBlocked: PropTypes.func.isRequired,
  getCommunityData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired, // eslint-disable-line
};

function mapStateToProps(state) {
  const count = _.get(state, 'community.counts', {});
  const latest = _.get(state, 'community.latest', {});

  return {
    isAdmin: STATEAPI.isAdmin(state),
    isPremium: STATEAPI.userIsPremium(state),
    loading: _.get(state, 'community.loading'),
    usersCount: _.get(count, 'users', 0),
    locationsCount: _.get(count, 'locations', 0),
    organizationsCount: _.get(count, 'organizations', 0),
    latestUsers: _.get(latest, 'users', []),
    latestLocations: _.get(latest, 'locations', []),
    activeExperts: _.get(latest, 'active_experts', []),
  };
}

const mapDispatchToProps = {
  getCommunityData: getCommunity,
};

export default AuthHOC(connect(mapStateToProps, mapDispatchToProps)(withRouter(Search)));
