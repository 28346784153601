import * as C from 'actions/constants';

export const blankState = {
  unreadCount: 0,
  count: 0,
  next: '',
  list: [],
  data: {},
  loading: true,
};

const EventReducer = (state = blankState, action) => {
  switch (action.type) {
    case C.GET_EVENT:
    case C.CREATE_EVENT:
    case C.LIST_EVENTS: {
      return { ...state, loading: true };
    }
    case C.LIST_EVENTS_SUCCESS: {
      const {
        count,
        next,
        results,
      } = action;

      return {
        ...state,
        loading: false,
        count,
        next,
        list: results,
      };
    }
    case C.GET_EVENT_SUCCESS: {
      const { data } = action;

      return {
        ...state,
        data,
        loading: false,
      };
    }
    case C.CREATE_EVENT_SUCCESS: {
      return { ...state, loading: false };
    }
    default:
      return state;
  }
};

export default EventReducer;
