import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import configureStore from './store/configureStore';
import Root from './containers/Root';
import registerServiceWorker from './registerServiceWorker';

if (process.env.NODE_ENV === 'production') {
  Sentry.init({ dsn: 'https://fb22f44abf9e4068984bb794ded1f66b@sentry.io/1510094' });
}

const store = configureStore();

ReactDOM.render(<Root store={store} />, document.getElementById('root'));

registerServiceWorker();
