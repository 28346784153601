import React from 'react';
import _ from 'lodash';
import Breakpoint from 'react-socks';
import PropTypes from 'prop-types';
import posed, { PoseGroup } from 'react-pose';
import {
  Grid,
  Header,
  Icon,
  Button,
  Segment,
} from 'semantic-ui-react';

const Item = posed.div({
  enter: {
    x: 0,
    opacity: 1,
  },
  exit: {
    x: 20,
    opacity: 0,
  },
});

const Documents = ({
  documents,
  uploading,
  handleChange,
  handleDelete,
}) => {
  return (
    <Segment textAlign="center">
      <Header as="h4" textAlign="left">
        <Icon name='file'/> Dateianhänge</Header>
      <label htmlFor="document-upload">
        <input
          type="file"
          id="document-upload"
          style={{ display: 'none' }}
          onChange={handleChange}
        />
        <Button
          loading={uploading}
          type="button"
          onClick={(event) => {
            event.preventDefault();
            document.getElementById('document-upload').click();
          }}
        >
          Datei hochladen
        </Button>
      </label>
      {!_.isEmpty(documents) && (
        <div style={{ marginTop: '20px' }}>
          <PoseGroup>
            {documents.map((document, index) => (
              <Item i={index} key={document.id} style={{ marginBottom: '15px' }}>
                <Segment>
                  <Grid>
                    <Grid.Column computer={14} tablet={13} textAlign="left" style={{ overflowWrap: 'break-word' }}>
                      <a href={document.file_url} rel="noopener noreferrer" target="_blank">{_.isEmpty(document.file_url) ? document.id : decodeURIComponent(_.last(document.file_url.split('/')))}</a>
                    </Grid.Column>
                    <Grid.Column computer={2} tablet={3} style={{ display: 'flex', alignItems: 'center' }}>
                      <Icon name="delete" onClick={() => handleDelete(document.id)} style={{ cursor: 'pointer' }} />
                    </Grid.Column>
                  </Grid>
                </Segment>
              </Item>
            ))}
          </PoseGroup>
        </div>
      )}
    </Segment>
  );
};

Documents.defaultProps = {
  documents: [],
};

Documents.propTypes = {
  uploading: PropTypes.bool.isRequired,
  documents: PropTypes.array, // eslint-disable-line
  handleChange: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
};

export default Documents;
