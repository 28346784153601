import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Grid,
  Card,
  Header,
  Divider,
  Step,
  Loader,
} from 'semantic-ui-react';
import _ from 'lodash';
import * as C from 'actions/constants';
import difference from 'utils/difference';
import AuthHOC from 'containers/AuthHOC';
import * as ORGANIZATION_ACTIONS from 'actions/organization';
import * as USER_ACTIONS from 'actions/user';
import * as Alert from 'utils/alert';
import * as STATEAPI from 'utils/stateapi';
import CreateOrganization from 'modules/forms/CreateOrganization';

class CreateOrganizationForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      steps: [
        'info',
        'content',
        'invite',
      ],
      currentStep: _.get(props, 'match.params.step', 'info'),
    };

    this.handleNextStepClick = this.handleNextStepClick.bind(this);
    this.handlePreviousStepClick = this.handlePreviousStepClick.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.changeStep = this.changeStep.bind(this);
  }

  componentDidMount() {
    const { changeBlocked, get, history, match: { params: { id } }, user, canEdit, history: { location: { hash } } } = this.props;
    const userPlan = _.get(user, 'plan_type');

    changeBlocked(true);

    if (!_.isEmpty(id) && !canEdit) {
      history.push(`/app/organizations/${id}`);
      return;
    }

    if (userPlan !== 'orga_paid_plan') {
      history.push('/app/organizations/create');
    } else {
      if (!_.isEmpty(id)) {
        get(id);

        if(hash.includes('content') || hash.includes('content')) {
          this.changeStep(hash)
        }
      }
    }
  }

  handleSubmit() {
    const {
      create,
      history,
      user,
      formData,
      get,
      initialValues,
      patch,
      refreshLocalStorage,
      match: { params: { id } },
    } = this.props;
    if (id) {
      const diff = difference(formData, initialValues);
      if (!_.isEmpty(diff)) {
        patch(initialValues.id, diff)
          .then(({ type }) => {
            if (type === C.PATCH_ORGANIZATION_SUCCESS) {
              refreshLocalStorage(user.id)
                .then(() => {
                    Alert.success('Die Organisation wurde erfolgreich gespeichert!');
                    history.push(`/app/organizations/${initialValues.id}/`);
                  },
                );
            }
          });
      } else {
        Alert.success('Die Organisation wurde erfolgreich gespeichert!');
        history.push(`/app/organizations/${initialValues.id}/`);
      }
    } else {
      const dataWithUser = {
        ...formData,
        user: user.id,
      };

      create(dataWithUser)
        .then(({ type, data }) => {
          if (type === C.CREATE_ORGANIZATION_SUCCESS) {
            refreshLocalStorage(user.id)
              .then(() => {
                history.push(`/app/organizations/create/data/${data.id}`);
                get(data.id);
                this.changeStep('invite')
              });
          }
        });
    }
  }

  handleNextStepClick(event) {
    event.preventDefault();

    const { steps, currentStep } = this.state;
    const {
      submitForm,
      formErrors,
      match: { params: { id } },
    } = this.props;
    const currentIndex = steps.indexOf(currentStep);
    let nextStep;

    if ((currentStep === 'content' && _.isEmpty(id)) || (currentIndex === steps.length - 1)) {
      submitForm();
    } else {
      nextStep = steps[currentIndex + 1];
      const firstStepRequiredFields = ['name', 'type', 'postalcode'];
      if (!_.isEmpty(_.intersection(firstStepRequiredFields, Object.keys(formErrors)))) {
        submitForm();
      } else {
        this.changeStep(nextStep)
      }
    }
  }

  handlePreviousStepClick(event) {
    event.preventDefault();

    const { steps, currentStep } = this.state;
    const currentIndex = steps.indexOf(currentStep);
    const nextStep = steps[currentIndex - 1];

    this.changeStep(nextStep)
  }

  handleCancel() {
    const { history } = this.props;

    history.goBack();
  }

  changeStep(step) {
    this.setState({ currentStep: step });
  }

  render() {
    const { currentStep } = this.state;

    const steps = [
      {
        key: 'info',
        active: currentStep === 'info',
        icon: 'check',
        disabled: !currentStep === 'info',
        title: 'SCHRITT 01',
        description: 'Benötigte Angaben',
      },
      {
        key: 'content',
        active: currentStep === 'content',
        icon: 'th list',
        disabled: !currentStep === 'content',
        title: 'SCHRITT 02',
        description: 'Optionale Angaben',
      },
      {
        key: 'invite',
        active: currentStep === 'invite',
        icon: 'user add',
        disabled: !currentStep === 'invite',
        title: 'SCHRITT 03',
        description: 'Mitglieder einladen',
      },
    ];
    const { initialValues, match: { params: { id } } } = this.props;
    const currentStepIndex = _.findIndex(steps, { key: currentStep });
    const notFirstStep = currentStepIndex > 0;
    const lastStep = currentStepIndex === steps.length - 1;

    return (
      <Grid centered style={{ zIndex: 2 }}>
        <Grid.Column computer={14} tablet={14} mobile={14} style={{ zIndex: 2 }}>
          <Card fluid>
            <Card.Content style={{ textAlign: 'center' }}>
              <Grid.Row style={{ marginTop: '20px' }}>
                <Step.Group items={steps} size="mini" />
              </Grid.Row>

              <Grid.Row style={{ marginTop: '20px' }}>
                <Header as="h1">Präsentiere deine Organisation auf localexpert24.</Header>
                <p>
                  Zeige, welche Rolle deine Organisation in der Welt der Infrastruktur einnimmt,
                  welche Expertise ihr im Tiefbau beherrscht und informiere das Netzwerk,
                  welche weiteren lokalen Experten deinem Team angehören.
                </p>
                <Divider />
              </Grid.Row>
              {id && _.isEmpty(initialValues) ? (
                <Grid.Row style={{ marginTop: '20px' }}>
                  <Loader active />
                </Grid.Row>
              ) : (
                <CreateOrganization
                  initialValues={initialValues}
                  step={currentStep}
                  organizationId={id}
                  lastStep={lastStep}
                  notFirstStep={notFirstStep}
                  handlePreviousStepClick={this.handlePreviousStepClick}
                  handleNextStepClick={this.handleNextStepClick}
                  onSubmit={this.handleSubmit}
                  handleCancel={this.handleCancel}
                />
              )}
            </Card.Content>
          </Card>
        </Grid.Column>
      </Grid>
    );
  }
}

CreateOrganizationForm.defaultProps = {
  initialValues: {},
  formData: {},
  formErrors: {},
};

CreateOrganizationForm.propTypes = {
  changeBlocked: PropTypes.func.isRequired,
  refreshLocalStorage: PropTypes.func.isRequired,
  submitForm: PropTypes.func.isRequired,
  create: PropTypes.func.isRequired,
  get: PropTypes.func.isRequired,
  patch: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired, // eslint-disable-line
  initialValues: PropTypes.object, // eslint-disable-line
  formData: PropTypes.object, // eslint-disable-line
  formErrors: PropTypes.object, // eslint-disable-line
  user: PropTypes.object, // eslint-disable-line
  history: PropTypes.object, // eslint-disable-line
};

function mapStateToProps(state) {
  const organization = _.get(state, 'organization.data', {});
  const user = STATEAPI.getCurrentUser(state);
  const isAdmin = STATEAPI.isAdmin(state);

  return {
    user,
    formData: _.get(state, 'form.createOrganization.values', {}),
    initialValues: organization,
    formErrors: _.get(state, 'form.createOrganization.syncErrors'),
    canEdit: isAdmin || _.get(organization, 'user.id') === user.id,
  };
}

const mapDispatchToProps = {
  submitForm: ORGANIZATION_ACTIONS.submitCreateForm,
  create: ORGANIZATION_ACTIONS.create,
  get: ORGANIZATION_ACTIONS.get,
  patch: ORGANIZATION_ACTIONS.patch,
  refreshLocalStorage: USER_ACTIONS.refreshLocalStorage,
};

const connectedForm = connect(mapStateToProps, mapDispatchToProps)(CreateOrganizationForm);

export default withRouter(AuthHOC(connectedForm));
