import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import posed, { PoseGroup } from 'react-pose';
import {
  Grid,
  Header,
  Form,
  Button,
  Divider,
} from 'semantic-ui-react';
import LoginContainer from 'containers/Login';
import RecoverPasswordContainer from 'containers/RecoverPassword';
import CookieConsent from "react-cookie-consent";

const AnimatedContent = posed.div({
  enter: { opacity: 1 },
  exit: { opacity: 0, x: '-800px', transition: { duration: 300 } },
});

class LoginPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      recover: false,
    };

    this.handleRecoverClick = this.handleRecoverClick.bind(this);
    this.handleCancelRecoverClick = this.handleCancelRecoverClick.bind(this);
  }

  componentDidMount() {
    const { location: { search }, history } = this.props;

    // if (resetPassword) {
    //   history.push('/auth/reset-password');
    // }
  }

  handleRecoverClick() {
    this.setState({ recover: true });
  }

  handleCancelRecoverClick() {
    this.setState({ recover: false });
  }

  render() {
    const { recover } = this.state;

    return (
      <div>
        <CookieConsent
          buttonText="OK"
          buttonStyle={{ backgroundColor: '#f9b000' }}
        >
          <span style={{ fontSize: '13px' }}>
            Cookies erleichtern die Bereitstellung unserer Dienste. Mit der Nutzung unserer Dienste erklärst du dich damit einverstanden, dass wir Cookies verwenden.
          </span>
        </CookieConsent>
        <PoseGroup>
          {!recover && (
            <AnimatedContent key="login">
              <Grid.Row style={{ marginTop: '20px' }}>
                <Header as="h2" textAlign="center">Willkommen bei localexpert24</Header>
              </Grid.Row>

              <Grid centered>
                <Grid.Column computer={12} tablet={12} mobile={16}>
                  <Grid.Row style={{ marginTop: '50px' }}>
                    <LoginContainer />
                  </Grid.Row>
                </Grid.Column>
              </Grid>

              <Grid.Row textAlign="center" style={{ marginTop: '30px' }}>
                <Link to="/" onClick={this.handleRecoverClick}>Passwort vergessen?</Link>
              </Grid.Row>
            </AnimatedContent>
          )}
          {recover && (
            <AnimatedContent key="recover">
              <Grid.Row style={{ marginTop: '20px' }}>
                <Header as="h4" textAlign="center">Gib deine E-Mail-Adresse ein um das Passwort zurück zu setzen.</Header>
              </Grid.Row>

              <Grid.Row style={{ marginTop: '50px' }}>
                <RecoverPasswordContainer cancelFunction={ this.handleCancelRecoverClick } />
              </Grid.Row>

              <Grid.Row textAlign="center" style={{ marginTop: '30px' }}>
                <Link to="/" onClick={this.handleCancelRecoverClick}>Abbrechen</Link>
              </Grid.Row>
            </AnimatedContent>
          )}
        </PoseGroup>

        <Divider style={{ marginTop: '20px' }} />

        <Grid.Row textAlign="center">
          <p>Noch kein Mitglied?</p>
          <Link to="/auth/register">Jetzt registrieren!</Link>
        </Grid.Row>
      </div>
    );
  }
}

LoginPage.propTypes = {
  location: PropTypes.object, // eslint-disable-line
  history: PropTypes.object, // eslint-disable-line
};

export default LoginPage;
