import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import _ from 'lodash';
import {
  Field,
  reduxForm,
  FieldArray,
  change,
} from 'redux-form';
import {
  Grid,
  Header,
  Form,
  Divider,
  Button,
  Image,
  Card,
  Icon,
} from 'semantic-ui-react';
import * as Fields from 'modules/Fields';
import * as USER_CONST from 'const/user';
import { toComponent } from 'utils/select';
import { filterTags } from 'api/lib/user';
import UserTitleImage from 'modules/UserTitleImage';
import UserAvatarImage from 'modules/UserAvatarImage';
import ImageUpload from 'modules/ImageUpload';
import RecommendedLinks from './partials/RecommendedLinks';

const RegisterForm = ({
  initialValues,
  handleSubmit,
  onSubmit,
  showFooter,
  loading,
  changeFieldValue,
  hasOrganization,
  invites,
  approveInvite,
  rejectInvite,
}) => (
  <Form onSubmit={handleSubmit(onSubmit)}>
    <Grid centered style={{ marginTop: '20px' }}>
      <Grid.Column width={15}>
        <ImageUpload type="title_image_url" imageUrl={initialValues.title_image_url}>
          <UserTitleImage type="title_image_url" imageUrl={initialValues.title_image_url} />
        </ImageUpload>
      </Grid.Column>
    </Grid>
    <Grid centered>
      <Grid.Column width={15}>
        <Grid>
          <Grid.Column mobile={15} tablet={3} computer={3}>
            <ImageUpload type="avatar_image_url" imageUrl={!_.isEmpty(initialValues.avatar_image_url) && initialValues.avatar_image_url}>
              <UserAvatarImage type="avatar_image_url" imageUrl={!_.isEmpty(initialValues.avatar_image_url) && initialValues.avatar_thumbnail_url} />
            </ImageUpload>
          </Grid.Column>
          <Grid.Column mobile={15} tablet={16} computer={13}>
            <Grid.Row>
              <Grid>
                <Grid.Column mobile={16} tablet={4} computer={5}>
                  <Field
                    name="gender"
                    placeholder="Anrede"
                    component={Fields.renderSelect}
                    options={USER_CONST.GENDER_OPTIONS}
                  />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={6} computer={6}>
                  <Field
                    name="first_name"
                    placeholder="Vorname"
                    component={Fields.renderInput}
                  />
                </Grid.Column>
                <Grid.Column mobile={16} tablet={6} computer={5}>
                  <Field
                    name="last_name"
                    placeholder="Nachname"
                    component={Fields.renderInput}
                  />
                </Grid.Column>
              </Grid>
            </Grid.Row>
            <Grid.Row style={{ marginTop: '15px' }}>
              <Form.Field mobile={15} tablet={8} computer={6}>
                <Field
                  name="full_address"
                  placeholder="PLZ deiner Heimatregion"
                  component={Fields.AddressSearch}
                  clickCallback={(value) => {
                    changeFieldValue('lat', value.location.y);
                    changeFieldValue('lng', value.location.x);
                    changeFieldValue('postalcode', value.attributes.Postal);
                  }}
                />
              </Form.Field>
              <Form.Field mobile={15} tablet={10} computer={10}>
                <Field
                  name="about_me"
                  placeholder="Erzähle uns etwas über dich und stelle dich dem Netzwerk vor."
                  component={Fields.renderRichTextInput}
                  rows={6}
                  style={{ resize: 'none' }}
                />
              </Form.Field>
            </Grid.Row>
          </Grid.Column>
        </Grid>
      </Grid.Column>
    </Grid>
    <Grid centered style={{ marginTop: '50px' }}>
      <Grid.Column width={15}>
        <Grid.Row>
          <Header as="h3">Berufliche Informationen</Header>
        </Grid.Row>
        <Grid.Row style={{ marginTop: '15px' }}>
          <Grid>
            <Grid.Column mobile={15} tablet={7} computer={7}>
              <Field
                name="position"
                placeholder="Position"
                component={Fields.renderInput}
              />
            </Grid.Column>
            <Grid.Column mobile={15} tablet={8} computer={8}>
              <Field
                multiple
                name="specializations"
                tagType="specialization"
                placeholder="Fachthemen"
                component={Fields.renderTagSelect}
                filterTags={filterTags}
                options={toComponent(initialValues.specializations_pretty)}
              />
            </Grid.Column>
          </Grid>
        </Grid.Row>
        <Grid.Row>
          <Grid>
            <Grid.Column mobile={15} tablet={7} computer={7}>
              <Field
                name="level_of_education"
                placeholder="Ausbildungsgrad"
                component={Fields.renderSelect}
                options={USER_CONST.LEVEL_OF_EDUCATION_OPTIONS}
              />
            </Grid.Column>
            <Grid.Column mobile={15} tablet={8} computer={8}>
              <Field
                multiple
                name="qualifications"
                tagType="qualification"
                placeholder="Qualifikationen"
                component={Fields.renderTagSelect}
                filterTags={filterTags}
                options={toComponent(initialValues.qualifications_pretty)}
              />
            </Grid.Column>
          </Grid>
        </Grid.Row>
      </Grid.Column>
    </Grid>

    {!_.isEmpty(invites) && (
      <Grid centered>
        <Grid.Column width={15}>
          <Grid columns={4}>
            <Grid.Row>
              {invites.map(invite => (
                <Grid.Column>
                  <Card fluid>
                    <Card.Content>
                      {invite.organization_image != null && (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '20px' }}>
                          <Image size="medium" src={invite.organization_image} />
                        </div>
                      )}
                      <Card.Header>{invite.organization_name}</Card.Header>
                      <Card.Meta>{invite.organization_street}</Card.Meta>
                      <Card.Description>
                        Du hast eine Einladung erhalten der Organisation <strong>{invite.organization_name}</strong> beizutreten.
                      </Card.Description>
                    </Card.Content>
                    <Card.Content extra>
                      <div className='ui two buttons'>
                        <Button
                          basic
                          color="green"
                          onClick={(e) => {
                            e.preventDefault();
                            approveInvite(invite);
                          }}
                        >
                          Akzeptieren
                        </Button>
                        <Button
                          basic
                          color="red"
                          onClick={(e) => {
                            e.preventDefault();
                            rejectInvite(invite);
                          }}
                        >
                          Ablehnen
                        </Button>
                      </div>
                    </Card.Content>
                  </Card>
                </Grid.Column>
              ))}
            </Grid.Row>
          </Grid>
        </Grid.Column>
      </Grid>
    )}

    {!hasOrganization && (
      <Grid centered>
        <Grid.Column width={15}>
          <Grid>
            <Grid.Column mobile={15} tablet={16} computer={16}>
              <Header as="h4">Du arbeitest aktuell für eine Organisation oder bist dessen Administrator?</Header>
              <p>Prüfe gleich, ob diese bereits bei localexpert24 dabei ist und trete dieser bei.</p>
              <Form.Field>
                <Field
                  name="organization"
                  placeholder="Organisation suchen..."
                  component={Fields.OrganizationSearch}
                />
              </Form.Field>
              <p><Icon name="info circle" />Organisation nicht gefunden? Du kannst <a href="/app/organizations/create">hier</a> eine neue Organisation anlegen.</p>
            </Grid.Column>
          </Grid>
        </Grid.Column>
      </Grid>
    )}

    <Grid centered style={{ marginTop: '50px' }}>
      <Grid.Column width={15}>
        <Grid.Row>
          <Header as="h3">Kontakt-Informationen</Header>
        </Grid.Row>
        <Grid.Row style={{ marginTop: '20px' }}>
          <Grid>
            <Grid.Column mobile={15} tablet={5} computer={5}>
              <Field
                name="phone"
                placeholder="Telefonnummer Festnetz"
                component={Fields.renderInput}
              />
            </Grid.Column>
            <Grid.Column mobile={15} tablet={5} computer={5}>
              <Field
                name="phone_mobile"
                placeholder="Telefonnummer Mobil"
                component={Fields.renderInput}
              />
            </Grid.Column>
            <Grid.Column mobile={15} tablet={6} computer={6}>
              <Field
                disabled
                name="email"
                placeholder="E-Mail Adresse"
                component={Fields.renderInput}
              />
            </Grid.Column>
          </Grid>
        </Grid.Row>
        <Grid.Row style={{ marginTop: '20px' }}>
          <Grid>
            <Grid.Column mobile={15} tablet={5} computer={5}>
              <Field
                name="xing_url"
                placeholder="Xing URL"
                component={Fields.renderInput}
              />
            </Grid.Column>
            <Grid.Column mobile={15} tablet={5} computer={5}>
              <Field
                name="linkedin_url"
                placeholder="LinkedIn URL"
                component={Fields.renderInput}
              />
            </Grid.Column>
            <Grid.Column mobile={15} tablet={6} computer={6}>
              <Field
                name="website_url"
                placeholder="Website URL"
                component={Fields.renderInput}
              />
            </Grid.Column>
          </Grid>
        </Grid.Row>
      </Grid.Column>
    </Grid>

    <Grid centered style={{ marginTop: '50px' }}>
      <Grid.Column width={15}>
        <Grid.Row>
          <Header as="h3">Persönliche Informationen</Header>
        </Grid.Row>
        <Grid.Row style={{ marginTop: '15px' }}>
          <Grid>
            <Grid.Column mobile={15} tablet={5} computer={5}>
              <Field
                name="birth_date"
                placeholder="Geburtstag"
                component={Fields.renderDatePicker}
              />
            </Grid.Column>
            <Grid.Column mobile={15} tablet={11} computer={11}>
              <Field
                multiple
                name="user_interests"
                tagType="user-interest"
                placeholder="Interessen / Hobbies"
                component={Fields.renderTagSelect}
                filterTags={filterTags}
                options={toComponent(initialValues.user_interests_pretty)}
              />
            </Grid.Column>
          </Grid>
        </Grid.Row>
      </Grid.Column>
    </Grid>

    <Grid centered style={{ marginTop: '50px' }}>
      <Grid.Column mobile={15} tablet={15} computer={15}>
        <Grid.Row>
          Bist du häufig auf anderen Webseiten aktiv und möchtest diese auch anderen lokalen Experten empfehlen? Dann trage die Links zu den Webseiten gerne hier ein:
        </Grid.Row>
      </Grid.Column>
      <FieldArray name="recommended_links" component={RecommendedLinks} />
    </Grid>

    {showFooter && (
      <div>
        <Divider style={{ marginTop: '30px' }} />

        <Grid.Row textAlign="right">
          <Button primary size="large" loading={loading}>Fertig</Button>
        </Grid.Row>
      </div>
    )}
  </Form>
);

RegisterForm.defaultProps = {
  initialValues: {},
  hasOrganization: false,
  values: {},
};

RegisterForm.propTypes = {
  values: PropTypes.object, // eslint-disable-line
  initialValues: PropTypes.object, // eslint-disable-line
  showFooter: PropTypes.bool.isRequired,
  hasOrganization: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  changeFieldValue: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const FORM_NAME = 'userDetailForm';

const mapStateToProps = () => ({});

const mapDispatchToProps = dispatch => ({
  changeFieldValue: (field, value) => {
    dispatch(change(FORM_NAME, field, value));
  },
});

const userDetailForm = reduxForm({
  form: FORM_NAME,
  onSubmit: (values, dispatch, props) => props.onSubmit,
})(RegisterForm);

export default connect(mapStateToProps, mapDispatchToProps)(userDetailForm);
