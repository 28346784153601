import _ from 'lodash';
import moment from 'moment';

export const get = (data) => {
  const recommendedLinks = _.get(data, 'recommended_links');
  const birth_date = _.get(data, 'birth_date');
  const recentVisitors = _.get(data, 'recent_visitors');

  return {
    ...data,
    recommended_links: _.isEmpty(recommendedLinks) ? [] : JSON.parse(recommendedLinks),
    birth_date: birth_date && moment(data.birth_date, 'YYYY-MM-DD').format('DD.MM.YYYY'),
    recent_visitors: _.isEmpty(recentVisitors) ? [] : JSON.parse(recentVisitors),
  };
};

export const patch = (data) => {
  let patchData = data;

  if (_.has(data, 'xing_url')) {
    patchData = Object.assign({}, patchData, {
      xing_url: data.xing_url.startsWith('http') ? data.xing_url : `http://${data.xing_url}`,
    });
  }

  if (_.has(data, 'linkedin_url')) {
    patchData = Object.assign({}, patchData, {
      linkedin_url: data.linkedin_url.startsWith('http') ? data.linkedin_url : `http://${data.linkedin_url}`,
    });
  }

  if (_.has(data, 'website_url')) {
    patchData = Object.assign({}, patchData, {
      website_url: data.website_url.startsWith('http') ? data.website_url : `http://${data.website_url}`,
    });
  }

  if (_.has(data, 'recommended_links')) {
    patchData = Object.assign({}, patchData, {
      recommended_links: JSON.stringify(data.recommended_links),
    });
  }

  if (_.has(data, 'birth_date')) {
    patchData = Object.assign({}, patchData, {
      birth_date: moment(data.birth_date, 'DD.MM.YYYY').format('YYYY-MM-DD'),
    });
  }

  return patchData;
};


export const userList = data => data.map(get);
